/*
 * File: ReactDatePicker.js
 * Author: Bhavin Prajapati
 * Created Date: November 24th, 2022
 * Last Modified Date: November 30th, 2022
 * Description: This code snippet creates a reusable date picker component in React.
 */
import React, { useState } from "react";
import { Label } from "reactstrap";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";

const ReactDatePicker = ({
  selectedDate,
  setSelectedDate,
  label,
  options,
}) => {
  //* State variables
  const [isShow, setIsShow] = useState(false);

  return (
    <div className={`cu-form-group floating-label-group`}>
      <Flatpickr
        className={`form-control ${
          selectedDate && selectedDate.length ? "date-input-value" : ""
        }`}
        placeholder={label}
        value={selectedDate}
        onChange={(date) => {
          setSelectedDate(date);
        }}
        onClose={() => setIsShow(false)}
        onFocus={() => setIsShow(true)}
        options={options}
      
      />
      <Label
        className={`floating-label ${
          (selectedDate && selectedDate.length) || isShow ? "show" : ""
        }`}
      >
        {label}
      </Label>
    </div>
  );
};

export default ReactDatePicker;

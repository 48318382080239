/*
 * File: deviceAction.js
 * Author: Harsh Chauhan
 * Created Date: November 28th, 2022
 * Last Modified Date: November 28th, 2022
 * Description: This file contains the device actions.
 */

import { DEVICE_TOKEN } from "../../../constants/appConstants";

/**
 * A function that sets the device token with the provided value.
 *
 * @param {any} value - The value to be set as the payload.
 * @return {object} An object with the type set to DEVICE_TOKEN and the payload set to the provided value.
 */
export const setDevice = (value) => {
  return {
    type: DEVICE_TOKEN,
    payload: value,
  };
};

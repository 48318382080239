/*
 * File: LanguagePicker.js
 * Author: Harsh Chauhan
 * Created Date: November 3rd, 2022
 * Last Modified Date: September 5th, 2023
 * Description: This component provides a language selection dropdown menu with internationalization support.
 */
import { useTranslation } from "react-i18next";

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { LANGUAGE_AR, LANGUAGE_EN } from "../../constants/appConstants";
import { GlobeIcon } from "../../assets/images/svg";

function LanguagePicker() {
  // ** Hooks
  const { t, i18n } = useTranslation();

  // ** Function to switch Language
  const handleLangUpdate = (e, lang) => {
    e.preventDefault();
    localStorage.setItem("ejadLangCode", lang);
    setTimeout(() => {
      i18n.changeLanguage(lang);
    }, 500);
    window.location.reload();
  };
  useEffect(() => {
    let data = Cookies.get("ejadCustomerUser");
    setTimeout(() => {
      if (data) {
      } else {
      }
    }, 5000);
  }, [i18n]);

  return (
    <UncontrolledDropdown
      href="/"
      tag="li"
      className="dropdown-language nav-item dropdown-user with-lang"
      placeholder="language"
    >
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="country-flag-data">
          <GlobeIcon/>
        </div>
        <span className="selected-language">
          {i18n.language === LANGUAGE_EN
            ? t("langCode.en")
            : i18n.language === LANGUAGE_AR
            ? t("langCode.ae")
            : t("langCode.fr")}
        </span>
      </DropdownToggle>
      <DropdownMenu className="mt-0 with-lang">
        <DropdownItem onClick={(e) => handleLangUpdate(e, "en")}>
          <span>{t("header.language.english")}</span>
        </DropdownItem>
        <DropdownItem onClick={(e) => handleLangUpdate(e, "ar")}>
          <span>{t("header.language.arabic")} </span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default LanguagePicker;

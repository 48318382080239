import dayjs from "dayjs";
import Cookies from "js-cookie";
import { components } from 'react-select'

//Convert milliseconds to date format (DD/MM/YYYY)
export const millisecondToDateConvert = (val) => {
  let date = new Date(val);
  return `${`0${date.getDate()}`.slice(-2)}/${`0${date.getMonth() + 1}`.slice(
    -2
  )}/${date.getFullYear()}`;
};

//check object or string is empty or not
export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

export const clearUserCookies = () => {
  Cookies.remove("ejadCustomerUser");
  Cookies.remove("ejadCustomerToken");
};

export const convertObjToQueryString = (obj) => {
  /*eslint-disable*/
  // ?search=jira
  return "?" + new URLSearchParams(obj).toString();
};

export const setUserCookies = (userData) => {
  Cookies.set("ejadCustomerUser", JSON.stringify({ ...userData }));
};
/**
 * Sets user cookies with the provided user data and sets the customer token.
 *
 * @param {object} userData - The user data to be stored in cookies.
 * @param {string} token - The customer token to be set.
 */
export const loginUser = (userData, token) => {
  setUserCookies({ ...userData });
  Cookies.set("ejadCustomerToken", token);
};
/**
 * Retrieves the login user data from the "ejadCustomerUser" cookie.
 *
 * @return {any} The login user data.
 */
export const getLoginUser = () => {
  let data = Cookies.get("ejadCustomerUser");
  return data;
};

export const isUserLoggedIn = () => Cookies.get("ejadCustomerToken");

/**
 * Generates a thumbnail URL for a given YouTube video URL.
 *
 * @param {string} url - The YouTube video URL.
 * @return {string} The thumbnail URL for the video.
 */
export const getThumbnail = (url) => {
  if (!url) return true;
  let file;
  let id;

  id = url.substring(url.lastIndexOf("v=") + 2);
  file = "https://img.youtube.com/vi/" + id + "/hqdefault.jpg";

  return file;
};


/**
 * The `removeFocus` function takes an array of element IDs or class names and removes focus from those
 * elements.
 * @param data - The `data` parameter is an array of strings. Each string represents an element's ID or
 * class name.
 */
export const removeFocus = (data) => {
  data && data.length ? data?.map((id) => {
    if (id?.includes('phoneNo')) {
      document.getElementsByClassName(id)[0].blur();
    } else {
      document.getElementById(id).blur()
    }
  }) : null
}
/**
 * Renders an icon option component.
 *
 * @param {Object} data - The data object containing the icon and branch name.
 * @param {Object} props - Additional props to be spread onto the component.
 * @return {JSX.Element} The rendered icon option component.
 */
export const IconOption = ({ data, ...props }) => {
  const Icon = data?.icon

  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center justify-content-center gap-1">
        {Icon && <Icon />}
        <span>{data?.branchName}</span>
      </div>
    </components.Option>
  );
}

/**
 * Checks if the time gap between the current time and a given value is greater than or equal to 300 seconds.
 *
 * @param {number} val - The value to compare with the current time.
 * @return {boolean} Returns true if the time gap is greater than or equal to 300 seconds, otherwise false.
 */
export const checkTimeGap = (val) => {

  // Get current time in seconds
  const currentTime = dayjs().unix();
  // Calculate the difference in seconds
  const timeDifference = currentTime - val;
  return timeDifference >= 300
};
// This code snippet defines a function checkVideoLength that asynchronously checks if a video file's duration exceeds a specified allowed duration in minutes. It creates a video element, sets the source to the input file, and then checks the video's duration once it's loaded.
export const checkVideoLength = async (file, allowedDuration) => {
  const video = document.createElement('video');
  video.src = URL.createObjectURL(file);
  video.preload = 'metadata';

  const promise = new Promise((resolve) => {

    video.onloadedmetadata = () => {
      video.currentTime = 100000;

      video.ontimeupdate = () => {
        video.ontimeupdate = null; // Remove the event listener to prevent multiple calls
        const durationInSeconds = Math.floor(video.duration);
        resolve(durationInSeconds > (allowedDuration * 60))
        video.remove(); // Clean up the video element
      };
    }
  })

  const result = await promise
  return result
};
/*
 * File: Estimation.js
 * Author: Ankit Pithiya
 * Created Date: February 14th, 2024
 * Last Modified Date: April 18th, 2024
 * Description: 
 */
import React, { useEffect, useState } from 'react'

//* page scss
import './Estimation.scss'
import OrderSummary from '../../component/modal/service/OrderSummary'
import { Button, Container } from 'reactstrap'
import CommonShimmer from '../../component/shimmer/commonShimmer'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getExpressPerticularReqApi } from '../../redux/actions/express/expressAction'
import { getReplyDetailsRfpApi } from '../../redux/actions/rfp/rfpAction'
import { useTranslation } from 'react-i18next'
import Footer from '../../component/common/Footer'
import { CancelIcon, CheckIcon, MoneyIcon } from '../../assets/images/svg'
import ConfirmActionModal from '../../component/modal/ConfirmActionModal'
import { postEstimationStatusApi } from '../../redux/actions/estimation/EstimationAction'
import { getPerticularReqApi } from '../../redux/actions/home/homeAction'
import { DONE_ORDER, WAITING_PAYMENT } from '../../constants/appConstants'

function Estimation() {
    
    //* Initializing react-hook-form
    const dispatch = useDispatch();

    //* Multilangual
    const { t } = useTranslation();

    //* Hook for Navigation
    const navigate = useNavigate();

    //* State variables
    const [isLoading, setIsLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [modalType, setModalType] = useState('')
    const [serviceData, setServiceData] = useState({})

    //* Get search parameters from URL
    const [searchParams] = useSearchParams();
    
    //* Extracting parameters from URL
    const serviceId = searchParams.get("serviceId");
    const serviceType = searchParams.get("type");
    
    useEffect(() => {
        getOrderData(serviceType, serviceId)
    }, [serviceId])

  /**
   * The function `getOrderData` is an asynchronous function that takes in a `type` and `id`
   * parameter, and makes an API call based on the `type` to retrieve order data.
   * @param type - The `type` parameter is a string that represents the type of service. It can have
   * one of the following values:
   * @param id - The `id` parameter is the unique identifier for the order data that you want to
   * retrieve.
   */
  const getOrderData = async (type, id) => {
    setIsLoading(true);
    // api object according to service type
    const apiCallMap = {
      T: getPerticularReqApi,
      E: getExpressPerticularReqApi,
      R: getReplyDetailsRfpApi,
    };
    const payloadKey = {
      E: id,
      T: { id },
      R: { rfpId: id },
    };
    //call api according to service type and set payload key
    const res = await dispatch(apiCallMap[type](payloadKey[type]));
    if (!res?.data?.error) {
      setServiceData(
        res?.data?.data?.active?.[0] ||
          res?.data?.data?.recent?.[0] ||
          res?.data?.data
      );
      //redirect to payment page
      const ratingOfSp =
        res?.data?.data?.ratingForServiceProvider ||
        res?.data?.data?.active?.[0]?.ratingForCustomer ||
        res?.data?.data?.recent?.[0]?.ratingForCustomer;
      const feesAmount =
        res?.data?.data?.totalAmountOfCustomer ||
        res?.data?.data?.active?.[0]?.totalAmountOfCustomer ||
        res?.data?.data?.recent?.[0]?.totalAmountOfCustomer;
      const feesStatus =
        res?.data?.data?.customerFeesStatus ||
        res?.data?.data?.active?.[0]?.customerFeesStatus ||
        res?.data?.data?.recent?.[0]?.customerFeesStatus;
      if (feesAmount && !ratingOfSp && feesStatus === "ACPT") {
        navigate(`/payment?serviceId=${serviceId}&type=${serviceType}`);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  /**
   * The function `handleEstimationStatusUpdate` updates the fees status of a service and logs the
   * response data.
   * @param feesStatus - The `feesStatus` parameter is a variable that represents the status of the
   * fees for a service. It is used in the `handleEstimationStatusUpdate` function to determine the
   * value of the `feesStatus` property in the `payload` object. The value of `feesStatus
   */
  const handleEstimationStatusUpdate = async () => {
    const payload = {
      serviceId,
      type: serviceType === "T" ? "DA" : serviceType,
      feesStatus: modalType === "accept" ? "ACPT" : "REJT",
    };
    const res = await dispatch(postEstimationStatusApi(payload));
    if (!res?.data?.error) {
      if (modalType === "accept") {
        window.location.reload();
      } else navigate(`/payment?serviceId=${serviceId}&type=${serviceType}`);
    }
  };
  return (
    <>
      <div className="myorder-page common-design-section">
        <div className="common-design-section-data single-data">
          <div className="right-design-data">
            <section className="service-section reply-section">
              <Container fluid="lg">
                <div className="service-section-data">
                  {!isLoading ? (
                    <div className="estimation-left-data">
                      <div className="review-left-data">
                        <h2 className="heads-data">{t("estimation.title")}</h2>
                        <div>
                          <p className="header-text">
                            {t("estimation.heading")}
                          </p>
                          <p className="ms-1">
                            {serviceData?.estimateProblemDesc || "-"}
                          </p>
                        </div>
                        <div>
                          <p className="header-text">
                            {t("estimation.heading1")}
                          </p>
                          <p className="ms-1">
                            {serviceData?.sparePartDescription || "-"}
                          </p>
                        </div>
                        <div className="my-4 payment-mode">
                          <MoneyIcon />
                          <span className="ms-2">{t("estimation.cash")}</span>
                        </div>
                        {/* fees details  */}
                        <div className="fees-details">
                          <p>
                            {t("estimation.visitFees")}{" "}
                            <span>
                              {serviceData?.serviceProviderId
                                ?.minimumRatePerVisit ||
                                serviceData?.reply?.serviceProviderId
                                  ?.minimumRatePerVisit ||
                                "-"}{" "}
                              {t(
                                "serviceDetails.serviceProviderCard.jdHrLabel2"
                              )}
                            </span>
                          </p>
                        </div>
                        <div className="fees-details">
                          <p>
                            {t("estimation.serviceFees")}{" "}
                            <span>
                              {serviceData?.estimateServiceFees || "-"}{" "}
                              {t(
                                "serviceDetails.serviceProviderCard.jdHrLabel2"
                              )}
                            </span>
                          </p>
                        </div>
                        <div className="fees-details">
                          <p>
                            {t("estimation.parts")}{" "}
                            <span>
                              {serviceData?.estimateSparePartFees || "-"}{" "}
                              {t(
                                "serviceDetails.serviceProviderCard.jdHrLabel2"
                              )}
                            </span>
                          </p>
                        </div>
                        {/* notes  */}
                        <ul className="notes">
                          <li>{t("estimation.note")}</li>
                          <li>{t("estimation.note1")}</li>
                        </ul>
                        {serviceData?.customerFeesStatus === "PEND" ? (
                          <div className="my-4 d-flex gap-3 justify-content-center">
                            <Button
                              color="main-outline"
                              className="cu-btn br12 min150"
                              type="submit"
                              onClick={() => {
                                setModal(true), setModalType("reject");
                              }}
                            >
                              {t("estimation.reject")}
                            </Button>
                            <Button
                              color="main"
                              className="cu-btn br12 min150 "
                              type="submit"
                              onClick={() => {
                                setModal(true), setModalType("accept");
                              }}
                            >
                              {t("estimation.accept")}
                            </Button>
                          </div>
                        ) : null}
                        {serviceData?.status === DONE_ORDER ||
                        serviceData?.status === WAITING_PAYMENT ? (
                          <div className="text-center">
                            <Button
                              color="main"
                              className="cu-btn br12 min150"
                              type="submit"
                              onClick={() =>
                                navigate(
                                  `/payment?serviceId=${serviceId}&type=${serviceType}`
                                )
                              }
                            >
                              {t("estimation.pay")}
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <div className="service-details-left-data text-center">
                      <CommonShimmer />
                    </div>
                  )}
                  {serviceData?.type === "T" || serviceData?.type === "E" ? (
                    <>
                      <OrderSummary
                        isLoading={isLoading}
                        data={serviceData?.categoryId?.name}
                        dataSubService={serviceData?.subCategoryId?.name}
                        branchName={serviceData?.branchName}
                        providerData={serviceData?.serviceProviderId}
                        mainData={serviceData}
                      />
                    </>
                  ) : (
                    <OrderSummary
                      isLoading={isLoading}
                      fromRfpSummary={true}
                      data={serviceData}
                      branchName={serviceData.branchName}
                      reply={serviceData?.reply}
                      mainData={serviceData?.reply}
                      startDateVal={serviceData?.startDate}
                      endDateVal={serviceData?.endDate}
                    />
                  )}
                </div>
              </Container>
            </section>

            {/* Common Footer */}
            <Footer />
          </div>
        </div>
      </div>
      <ConfirmActionModal
        modal={modal}
        toggleModal={() => setModal(!modal)}
        onContinue={() => {
          setModal(false), handleEstimationStatusUpdate();
        }}
        titleText={
          modalType === "accept"
            ? t("estimation.confirmText")
            : t("estimation.rejectText")
        }
        succesBtnText={t("estimation.yes")}
        rejectBtnText={t("estimation.no")}
        icon={modalType === "accept" ? <CheckIcon /> : <CancelIcon />}
      />
    </>
  );
}

export default Estimation;

import { useEffect, useRef, useState } from "react";
import { Button, Col, Label, Modal, ModalBody, Row } from "reactstrap";
import { CloseIcon } from "../../../assets/images/svg";
import Select from "react-select";
import ReactDatePicker from "../../common/ReactDatePicker";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { SORTING_OPTIONS, STATUS_OPTIONS } from "../../../constants/appConstants";

const RfpListDateFilterModal = ({
  modal,
  toggleModal,
  filteredData,
  setFilteredData,
  getDataList,
  activeListType,
  isFromAvailableRfpList,
  serviceType
}) => {
  const formRef = useRef();
  // Multilangual
  const { t, i18n } = useTranslation();

  //* State variables
  const [selectedDateSorting, setSelectedDateSorting] = useState("");
  const [selectedActiveOrderStatus, setSelectedActiveOrderStatus] = useState('');
  const [selectedCompletedOrderStatus, setSelectedCompletedOrderStatus] = useState('');
  const [rangeDate, setRangeDate] = useState(null);
  const [rangeHistoryDate, setRangeHistoryDate] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [isShowStatus, setIsShowStatus] = useState(false);

  //* Check if modal is opened and clear data if necessary
  useEffect(() => {
    if (
      modal &&
      filteredData?.dateStart?.length === 0 &&
      filteredData?.dateEnd?.length === 0 &&
      filteredData?.dateSort?.length === 0 &&
      filteredData?.status?.length === 0
    )
      allDataClearHandler();
    else {
      let temp = [...SORTING_OPTIONS];
      let sortLabel = temp?.find((item) => item?.value === filteredData?.dateSort);
      // setRangeDate([filteredData.dateStart, filteredData.dateEnd]);
      setSelectedDateSorting(sortLabel);
    }
  }, [modal]);

  //* Update filtered data when range date changes
  useEffect(() => {
    if (rangeDate && rangeDate.length) {
      let startDate = rangeDate[0] ? dayjs(rangeDate[0]).startOf('day').unix() : null;
      let endDate = rangeDate[1] ? dayjs(rangeDate[1]).endOf('day').unix() : null;
      setFilteredData({
        ...filteredData,
        dateStart: startDate,
        dateEnd: endDate,
      });
    }
  }, [rangeDate]);

  //* Update filtered data when history range date changes
  useEffect(() => {
    if (rangeHistoryDate && rangeHistoryDate.length) {
      let startDate = rangeHistoryDate[0] ? dayjs(rangeHistoryDate[0]).startOf('day').unix() : null;
      let endDate = rangeHistoryDate[0] ? dayjs(rangeHistoryDate[1]).endOf('day').unix() : null;
      setFilteredData({
        ...filteredData,
        dateStart: startDate,
        dateEnd: endDate,
      });
    }
  }, [rangeHistoryDate]);

  /**
   * Function for handle search events
   */
  const onSearchHandler = () => {
    getDataList();
    toggleModal();
  };

  /**
   * on clear form handler
   */
  const allDataClearHandler = () => {
    formRef?.current?.reset();
    setFilteredData({
      dateStart: "",
      dateEnd: "",
      dateSort: "",
      status: ""
    });
    if (activeListType) {
      setRangeDate(null);
      setSelectedActiveOrderStatus('')
    } else {
      setRangeHistoryDate(null);
      setSelectedCompletedOrderStatus('')
    }
    setSelectedDateSorting("");
  };

  return (
    <Modal
      contentClassName="success-shadow"
      className="common-modal"
      backdropClassName="opw1"
      isOpen={modal}
      toggle={() => toggleModal()}
      backdrop="static"
      keyboard={false}
      centered
    >
      <ModalBody className="filter-body">
        <div
          className="close-icon diff bottom-diff"
          onClick={() => {
            toggleModal();
          }}
        >
          <CloseIcon />
        </div>
        <h3 className="head-data">{t("rfp.filterModal.heading")}</h3>
        <form ref={formRef}>
          <Row>
            <Col md={12} className="mb20">
              <ReactDatePicker
                selectedDate={activeListType ? rangeDate : rangeHistoryDate}
                setSelectedDate={(val) =>
                  activeListType ? setRangeDate(val) : setRangeHistoryDate(val)
                }
                label={t("myOrder.dateText")}
                options={{
                  mode: "range",
                  // defaultDate: ['2020-02-01', '2020-02-15']
                }}
              />
            </Col>
            <Col md={12} className={`"cu-form-group floating-label-group ${!isFromAvailableRfpList ? "mb20" : "mb40"}`}>
              <Label
                className={`floating-label ${selectedDateSorting || isShow ? "show" : ""
                  }`}
                for="pname"
              >
                {t("rfp.filterModal.dateSortingLabel")}
              </Label>
              <Select
                className={`react-select ${selectedDateSorting ? "isActive" : ""
                  }`}
                classNamePrefix="select"
                name="priceSort"
                id="priceSort"
                placeholder={t("rfp.filterModal.dateSortingLabel")}
                options={SORTING_OPTIONS}
                value={selectedDateSorting}
                onBlur={() => setIsShow(false)}
                onFocus={() => setIsShow(true)}
                onChange={(val) => {
                  setSelectedDateSorting(val);
                  setFilteredData({ ...filteredData, dateSort: val.value });
                }}
              />
            </Col>
            {!isFromAvailableRfpList ? <Col md={12} className="cu-form-group floating-label-group mb40">
              <Label
                className={`floating-label ${selectedActiveOrderStatus || selectedCompletedOrderStatus || isShowStatus ? "show" : ""
                  }`}
                for="status"
              >
                {t("rfp.filterModal.statusSortingLabel")}
              </Label>
              <Select
                className={`react-select ${selectedDateSorting ? "isActive" : ""
                  }`}
                classNamePrefix="select"
                name="status"
                id="status"
                placeholder={t("rfp.filterModal.statusSortingLabel")}
                options={STATUS_OPTIONS.get(serviceType)}
                value={activeListType ? selectedActiveOrderStatus : selectedCompletedOrderStatus}
                onBlur={() => setIsShowStatus(false)}
                onFocus={() => setIsShowStatus(true)}
                onChange={(val) => {
                  activeListType ? setSelectedActiveOrderStatus(val) : setSelectedCompletedOrderStatus(val);
                  setFilteredData({ ...filteredData, status: val.value });
                }}
              />
            </Col> : null}
            <Col className="all_center">
              <Button
                color="main-outline "
                className="cu-btn br20 min120 me-2"
                type="button"
                onClick={() => {
                  allDataClearHandler(),
                    getDataList({
                      dateStart: "",
                      dateEnd: "",
                      dateSort: "",
                      status: ""
                    })
                }}
              >
                {t("btnLabel.clearBtn")}
              </Button>
              <Button
                color="main"
                className="cu-btn br20 min120 ms-2"
                type="button"
                onClick={() => {
                  onSearchHandler();
                }}
              >
                {t("btnLabel.applyBtn")}
              </Button>
            </Col>
          </Row>
        </form>
      </ModalBody>
    </Modal >
  );
};

export default RfpListDateFilterModal;

import React, { useState } from "react";
import { EyeOff, Eye } from "react-feather";
import { Label, Input, InputGroup, InputGroupText } from "reactstrap";
import { Message } from "../../constants/messages";
const FormPassword = ({
  fgClass,
  id,
  name,
  type,
  placeholder,
  required,
  label,
  rules,
  errors,
  register,
  iconSize,
  passwordValue,
  isConfirmPassword,
  hideIcon,
  showIcon,
  visible,
  invalid,
  confirmPasswordMessage,
  autoFill
}) => {
  if (isConfirmPassword === true) {
    rules.validate = (value) =>
      value === passwordValue ||
      confirmPasswordMessage ||
      Message.passwordNotMatch;
  }
  const { ref: ref, ...rest } = register(name, rules);

  const [inputVisibility, setInputVisibility] = useState(visible);

  // ** Renders Icon Based On Visibility
  const renderIcon = () => {
    const size = iconSize ? iconSize : 16;

    if (inputVisibility === false) {
      return hideIcon ? hideIcon : <EyeOff size={size} />;
    } else {
      return showIcon ? showIcon : <Eye size={size} />;
    }
  };
  return (
    <>
      {/* ${errors[name]?.message ? "error-feild" : ""} */}
      <div className={`cu-form-group floating-label-group ${fgClass} `}>
        <InputGroup className="password-group">
          <Input
            innerRef={ref}
            name={name}
            invalid={invalid}
            type={inputVisibility === false ? "password" : "text"}
            id={id}
            autoComplete={autoFill || 'off'}
            placeholder={placeholder}
            /*eslint-disable */
            {...rest}
          /*eslint-enable */
          />
          <Label htmlFor={id} className="floating-label">
            {label}
          </Label>
          <InputGroupText
            className="pointer eye-text"
            onClick={() => setInputVisibility(!inputVisibility)}
          >
            {renderIcon()}
          </InputGroupText>
        </InputGroup>
        {required && <span className="required abs">*</span>}
        <span className="error-msg">{errors[name]?.message}</span>
      </div>
    </>
  );
};

export default FormPassword;

import React from "react";

// Multilangual
import { useTranslation } from "react-i18next";
import Rating from "react-rating";
import { GoStar } from "react-icons/go";
import { CheckBoxIcon, RedCrossIcon } from "../../../assets/images/svg";
import DateLabel from "../../common/DateLabel";
import { DONE_ORDER } from "../../../constants/appConstants";

const OrderSummary = ({
  isLoading,
  data,
  dataSubService,
  branchName,
  providerData,
  mainData,
  startDateVal,
  endDateVal,
  fromRfpSummary,
  reply,
  orderDate,
}) => {
  // Multilangual
  const { t } = useTranslation();
  const orderNo = data?.orderNo || mainData?.orderNo;
  return (
    <>
      {!isLoading ? (
        <div className="order-summary-data">
          <div className="head-data mb20 d-flex justify-content-between">
            {!orderNo ? (
              <h3>
                {fromRfpSummary
                  ? t("OrderCard.titleWithoutNum")
                  : t("OrderCard.title1WithoutNum")}
              </h3>
            ) : (
              <h3>
                {fromRfpSummary
                  ? t("OrderCard.title", { orderNo })
                  : t("OrderCard.title1", { orderNo })}
              </h3>
            )}
          </div>
          <div className="scroll-data">
            {startDateVal && endDateVal && (
              <div className="order-box mb20">
                <p className="head-text">{t("myOrder.dateText")}</p>
                <p className="list-text">
                  {t("myOrder.startText1")} :{" "}
                  <span className="selected">
                    <DateLabel date={startDateVal} />
                  </span>
                </p>
                <p className="list-text">
                  {t("myOrder.endText1")} :{" "}
                  <span className="selected">
                    <DateLabel date={endDateVal} />
                  </span>
                </p>
              </div>
            )}

            {!fromRfpSummary ? (
              <div className="order-box mb20">
                <p className="head-text">{t("service.title")}</p>
                <ul className="outer-ul">
                  {data && (
                    <li className="outer-li">
                      <p className="list-text">
                        {data} / {dataSubService}
                      </p>
                    </li>
                  )}
                </ul>
              </div>
            ) : (
              <>
                {data && data.tasks && data.tasks.length && (
                  <div className="order-box mb20">
                    <p className="head-text">{t("service.title")}</p>
                    {data.tasks.map((c, index) => (
                      <ul className="outer-ul" key={index}>
                        <li className="outer-li">
                          <p className="list-text">
                            {c.category} / {c.subCategoryName}
                          </p>
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </>
            )}
            {branchName && (
              <div className="order-box mb20">
                <p className="head-text">
                  {t("profile.branchData.branchTitle")}
                </p>
                <p className="list-text">{branchName}</p>
              </div>
            )}
            {orderDate && (
              <div className="order-box mb20">
                <p className="head-text">{t("myOrder.dateText")}</p>
                <p className="list-text">
                  <DateLabel date={orderDate} format={"DD-MM-YYYY HH:mm"} />
                </p>
              </div>
            )}
            {providerData && mainData?.status !== DONE_ORDER && (
              <div className="order-box">
                <p className="head-text">{t("OrderCard.providerTitle")}</p>
                <div className="order-provider-box">
                  <ul>
                    <li>
                      <div className="name-data">
                        {providerData?.name}
                        {providerData?.isCertified !== false && (
                          <CheckBoxIcon />
                        )}
                      </div>
                    </li>
                    <li>
                      <div className="star-data">
                        <Rating
                          className="user-select-none"
                          initialRating={
                            providerData?.rating || mainData?.rating || 0
                          }
                          emptySymbol={
                            <GoStar size={14} fill="#C8C8C8" stroke="#C8C8C8" />
                          }
                          fullSymbol={
                            <GoStar
                              size={14}
                              fill={"#FFD700"}
                              stroke={"#FFD700"}
                            />
                          }
                        />
                        <div className="comment-data">
                          ({providerData?.total || mainData?.total || 0})
                        </div>
                      </div>
                    </li>
                    {providerData?.dailyRate && (
                      <li>
                        <div className="icon-data">
                          {/* <div className="icon-i">
                            <DollerIcon />
                          </div> */}
                          <div className="text-i ispan text-wrap-balance">
                            {t("serviceDetails.serviceProviderCard.dailyRate")}
                            {providerData?.dailyRate}{" "}
                            {t("serviceDetails.serviceProviderCard.jdHrLabel")}
                          </div>
                        </div>
                      </li>
                    )}
                    {providerData?.minimumRatePerVisit && (
                      <li>
                        <div className="icon-data">
                          {/* <div className="icon-i">
                            <DollerIcon />
                          </div> */}
                          <div className="text-i ispan">
                            {t(
                              "serviceDetails.serviceProviderCard.minVisitRate"
                            )}
                            {providerData?.minimumRatePerVisit}{" "}
                            {t("serviceDetails.serviceProviderCard.jdHrLabel2")}
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            )}
            {/* rate and review of completed orders  */}
            {mainData && mainData.status === DONE_ORDER ? (
              <div className="order-box">
                <p className="head-text">{t("newTextRFP.rateReview")}</p>
                <p className="list-text">
                  <Rating
                    className="user-select-none"
                    readonly
                    initialRating={
                      mainData?.ratingForServiceProvider?.rating || 0
                    }
                    emptySymbol={
                      <GoStar size={14} fill="#C8C8C8" stroke="#C8C8C8" />
                    }
                    fullSymbol={
                      <GoStar size={14} fill={"#FFD700"} stroke={"#FFD700"} />
                    }
                  />
                </p>
                <p className="list-text">
                  {mainData?.ratingForServiceProvider?.description || ""}
                </p>
              </div>
            ) : null}
            {reply || (mainData && mainData.status === DONE_ORDER) ? (
              <>
                {reply?.price || mainData?.totalAmountOfCustomer ? (
                  <div className="order-box">
                    <p className="head-text">{t("newTextRFP.priceText")}</p>
                    <p className="list-text">
                      {reply?.price || mainData?.totalAmountOfCustomer}&nbsp;
                      {t("serviceDetails.serviceProviderCard.jdHrLabel2")}
                    </p>
                  </div>
                ) : null}
                <div className="order-box mb20">
                  <p className="head-text">
                    <p className="head-text font-lower">
                      {reply?.spareParts ||
                      mainData?.estimateIsSparePartNeeded ||
                      mainData?.isSparePartNeeded ? (
                        <>
                          {" "}
                          <CheckBoxIcon /> &nbsp;&nbsp;{" "}
                          {t("newTextRFP.sparePartText1")}{" "}
                        </>
                      ) : (
                        <>
                          <RedCrossIcon /> &nbsp;&nbsp;{" "}
                          {t("newTextRFP.sparePartText2")}
                        </>
                      )}
                    </p>
                  </p>
                </div>
                {reply?.sparePartFees || mainData?.sparePartFees ? (
                  <div className="order-box">
                    <p className="head-text">{t("newTextRFP.sparePartFees")}</p>

                    <p className="list-text">
                      {reply?.sparePartFees || mainData?.sparePartFees}{" "}
                      {t("serviceDetails.serviceProviderCard.jdHrLabel2")}
                    </p>
                  </div>
                ) : null}
                {reply?.paymentTerms ? (
                  <div className="order-box mb20">
                    <p className="head-text">
                      {t("newTextRFP.paymentTermsText")}
                    </p>
                    <p className="list-text">{reply?.paymentTerms}</p>
                  </div>
                ) : null}
                {mainData?.sparePartDescription ? (
                  <div className="order-box">
                    <p className="head-text">
                      {t("newTextRFP.spareDescriptions")}
                    </p>
                    <p className="list-text">
                      {mainData?.sparePartDescription}
                    </p>
                  </div>
                ) : null}
                {reply?.isGuarantee ? (
                  <div className="order-box mb20">
                    <p className="head-text">{t("newTextRFP.guaranteeText")}</p>
                    <p className="list-text">
                      <DateLabel date={reply?.expiryDate} />
                    </p>
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="order-summary-shimmer cu-shimmer"></div>
      )}
    </>
  );
};

export default OrderSummary;

/*
 * File: Licence.js
 * Author: Harsh Chauhan
 * Created Date: November 18th, 2022
 * Last Modified Date: December 28th, 2022
 * Description: This component is responsible for displaying a licence agreement page with multilingual support and navigation capabilities.
 */
import React from "react";
import { ChevronLeft } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "reactstrap";
import Logo from "../../assets/images/logo/logo.svg";
import "./Licence.scss";

const LicenceAgreement = () => {
  //* Multilangual
  const { t } = useTranslation();
  //* Hook for Navigation
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>EJAD - {t("LicenceAggrement.headText")}</title>
      </Helmet>
      <div className="full-section">
        <img src={Logo} className="img-fluid logo-img" alt="images" />
        <Container fluid={"lg"}>
          <div className="licence-box">
            <h1>{t("LicenceAggrement.headText")}</h1>
            <p>{t("LicenceAggrement.paraText")}</p>
            <Button
              type="button"
              className="cu-btn min150"
              color="warn"
              onClick={() => navigate("/landing")}
            >
              <ChevronLeft size="18" className="me-50" /> {t("LicenceAggrement.backToLanding")}
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
};

export default LicenceAgreement;

/*
 * File: ProgressBar.js
 * Author: Niyati Shah
 * Created Date: February 29th, 2024
 * Last Modified Date: April 17th, 2024
 * Description: This code snippet represents a progress bar component that can display different statuses and icons based on the provided props and variables.
 */
import {
  OpenIcon,
  AcceptedIcon,
  CompletedIcon,
  OnTheWayIcon,
  UnderProgressIcon,
  GreyOpenIcon,
  GreyAcceptedIcon,
  GreyOnTheWayIcon,
  GreyUnderProgressIcon,
  GreyFinishedIcon,
  WaitingProposalIcon,
  GreyWaitingProposalIcon,
  CancelledIcon,
  FinishedIcon,
  GreyCompletedIcon,
} from "../../assets/images/svg";
import {
  ACCEPTED_ORDER,
  APPROVED_ORDER,
  FINISHED_ORDER,
  ON_THE_WAY_ORDER,
  REQUESTED_ORDER,
  UNDER_PROGRESS_ORDER,
  DONE_ORDER,
  CANCEL_ORDER,
  RECEIVING_PROPOSAL,
  WAITING_PAYMENT,
} from "../../constants/appConstants";
import { useTranslation } from "react-i18next";

const ProgressBar = ({ orderStatus, lastStatus, orderType }) => {
  //* Checking status to determine which icons to display
  const isFinished =
    lastStatus === FINISHED_ORDER || orderStatus === FINISHED_ORDER;
  const isCompleted =
    isFinished || lastStatus === DONE_ORDER || orderStatus === DONE_ORDER;
  const isUnderProgress =
    isFinished ||
    lastStatus === UNDER_PROGRESS_ORDER ||
    lastStatus === WAITING_PAYMENT ||
    orderStatus === UNDER_PROGRESS_ORDER ||
    orderStatus === WAITING_PAYMENT;
  const isOnTheWay =
    isUnderProgress ||
    isCompleted ||
    isFinished ||
    lastStatus === ON_THE_WAY_ORDER ||
    orderStatus === ON_THE_WAY_ORDER;
  const isAccepted =
    lastStatus === ACCEPTED_ORDER ||
    orderStatus === ACCEPTED_ORDER ||
    isOnTheWay ||
    isUnderProgress ||
    isCompleted ||
    isFinished;
  const isApproved =
    lastStatus === APPROVED_ORDER ||
    orderStatus === APPROVED_ORDER ||
    isAccepted ||
    isOnTheWay ||
    isUnderProgress ||
    isCompleted ||
    isFinished;
  const isRequested =
    lastStatus === REQUESTED_ORDER ||
    orderStatus === REQUESTED_ORDER ||
    isApproved ||
    isAccepted ||
    isOnTheWay ||
    isUnderProgress ||
    isCompleted ||
    isFinished;

  //* Using multilingual support
  const { t } = useTranslation();

  //*status array according to order type
  const statusArr =
    orderType === "rfp"
      ? [
          REQUESTED_ORDER,
          RECEIVING_PROPOSAL,
          ACCEPTED_ORDER,
          ON_THE_WAY_ORDER,
          UNDER_PROGRESS_ORDER,
          DONE_ORDER,
          CANCEL_ORDER,
        ]
      : orderType === "express"
      ? [
          REQUESTED_ORDER,
          ACCEPTED_ORDER,
          ON_THE_WAY_ORDER,
          UNDER_PROGRESS_ORDER,
          DONE_ORDER,
          CANCEL_ORDER,
        ]
      : [
          REQUESTED_ORDER,
          ACCEPTED_ORDER,
          ON_THE_WAY_ORDER,
          UNDER_PROGRESS_ORDER,
          DONE_ORDER,
          CANCEL_ORDER,
        ];

  //*icons array according to order type
  const iconArr =
    orderType === "rfp"
      ? [
          <OpenIcon />,
          <WaitingProposalIcon />,
          <AcceptedIcon />,
          <OnTheWayIcon />,
          <UnderProgressIcon />,
          <CompletedIcon />,
          <CancelledIcon />,
        ]
      : [
          <OpenIcon />,
          <AcceptedIcon />,
          <OnTheWayIcon />,
          <UnderProgressIcon />,
          <CompletedIcon />,
          <CancelledIcon />,
        ];

  //* Mapping status text to respective order status
  const textMap =
    orderType === "rfp"
      ? {
          [REQUESTED_ORDER]: "requestedText",
          [RECEIVING_PROPOSAL]: "receivingProposal",
          [APPROVED_ORDER]: "approvedText",
          [ACCEPTED_ORDER]: "acceptedText",
          [ON_THE_WAY_ORDER]: "onTheWay",
          [UNDER_PROGRESS_ORDER]: "underProgress",
          [DONE_ORDER]: "finished",
          [CANCEL_ORDER]: "cancelText",
        }
      : {
          [REQUESTED_ORDER]: "requestedText",
          [APPROVED_ORDER]: "approvedText",
          [ACCEPTED_ORDER]: "acceptedText",
          [ON_THE_WAY_ORDER]: "onTheWay",
          [UNDER_PROGRESS_ORDER]: "underProgress",
          [DONE_ORDER]: "finished",
          [CANCEL_ORDER]: "cancelText",
        };
  //* Mapping status index to hide icons after cancel status
  const statusobj =
    orderType === "rfp"
      ? {
          [REQUESTED_ORDER]: 0,
          [RECEIVING_PROPOSAL]: 1,
          [ACCEPTED_ORDER]: 2,
          [ON_THE_WAY_ORDER]: 3,
          [UNDER_PROGRESS_ORDER]: 4,
          [DONE_ORDER]: 5,
        }
      : orderType === "express"
      ? {
          [REQUESTED_ORDER]: 0,
          [APPROVED_ORDER]: 1,
          [ON_THE_WAY_ORDER]: 2,
          [UNDER_PROGRESS_ORDER]: 3,
          [DONE_ORDER]: 4,
        }
      : {
          [REQUESTED_ORDER]: 0,
          [APPROVED_ORDER]: 1,
          [ON_THE_WAY_ORDER]: 2,
          [UNDER_PROGRESS_ORDER]: 3,
          [DONE_ORDER]: 4,
        };

  return (
    <div className="head-data mb-20 justify-content-center">
      <div className="choose-provider-data grey-border">
        <ul className="choose-ul">
          <li className="choose-li">
            {!lastStatus ? (
              <div className="progress-bar-icons">
                <div className="progress-bar-item">
                  {isRequested ||
                  isAccepted ||
                  isApproved ||
                  isOnTheWay ||
                  isUnderProgress ||
                  isCompleted ||
                  isFinished ? (
                    <OpenIcon />
                  ) : (
                    <GreyOpenIcon />
                  )}

                  <p>{t("myOrder.requestedText")}</p>
                </div>

                <div className="progress-bar-line"></div>
                {orderType === "rfp" && (
                  <>
                    <div className="progress-bar-item">
                      <div className="waiting-proposal">
                        {isRequested ||
                        isAccepted ||
                        isApproved ||
                        isOnTheWay ||
                        isUnderProgress ||
                        isCompleted ||
                        isFinished ? (
                          <WaitingProposalIcon />
                        ) : (
                          <GreyWaitingProposalIcon />
                        )}
                      </div>
                      <p className="on-p">{t("myOrder.receivingProposal")}</p>
                    </div>
                    <div className="progress-bar-line"></div>
                    <div className="progress-bar-item">
                      {isAccepted ||
                      isApproved ||
                      isOnTheWay ||
                      isUnderProgress ||
                      isCompleted ||
                      isFinished ? (
                        <AcceptedIcon />
                      ) : (
                        <GreyAcceptedIcon />
                      )}

                      <p className="on-p">{t("myOrder.acceptedText")}</p>
                    </div>
                  </>
                )}

                {orderType !== "rfp" && (
                  <div className="progress-bar-item">
                    {isAccepted ||
                    isApproved ||
                    isOnTheWay ||
                    isUnderProgress ||
                    isCompleted ||
                    isFinished ? (
                      <AcceptedIcon />
                    ) : (
                      <GreyAcceptedIcon />
                    )}

                    <p className="on-p">{t("myOrder.acceptedText")}</p>
                  </div>
                )}
                <div className="progress-bar-line"></div>

                <div className="progress-bar-item">
                  <div className="on-way">
                    {isOnTheWay ||
                    isUnderProgress ||
                    isCompleted ||
                    isFinished ? (
                      <OnTheWayIcon />
                    ) : (
                      <GreyOnTheWayIcon />
                    )}

                    <p>{t("myOrder.onTheWay")}</p>
                  </div>
                </div>
                <div className="progress-bar-line"></div>

                <div className="progress-bar-item">
                  <div className="under-progress">
                    {isUnderProgress || isCompleted || isFinished ? (
                      <UnderProgressIcon />
                    ) : (
                      <GreyUnderProgressIcon />
                    )}

                    <p className="under-p">{t("myOrder.underProgress")}</p>
                  </div>
                </div>
                <div className="progress-bar-line"></div>
                <div className="progress-bar-item">
                  {isCompleted || isFinished ? (
                    <CompletedIcon />
                  ) : (
                    <GreyCompletedIcon />
                  )}

                  <p>{t("myOrder.doneText")}</p>
                </div>
                <div className="progress-bar-line"></div>
                <div className="progress-bar-item">
                  {isFinished ? <FinishedIcon /> : <GreyFinishedIcon />}

                  <p>{t("myOrder.finished")}</p>
                </div>
              </div>
            ) : (
              <div className="progress-bar-icons">
                {statusArr?.map((ele, index) => {
                  return (
                    <>
                      {index <= statusobj[lastStatus] ? (
                        <>
                          <div className="progress-bar-item" key={index}>
                            <div
                              className={`${
                                orderType !== "rfp" &&
                                (index === 3 || index === 2)
                                  ? "pt-3"
                                  : orderType === "rfp" &&
                                    (index === 4 || index === 3)
                                  ? "pt-3"
                                  : ""
                              }`}
                            >
                              {iconArr[index]}
                            </div>
                            <p
                              className={`${
                                ele === "receivingProposal" ? "mb-0" : ""
                              }`}
                            >
                              {t(`myOrder.${textMap[ele]}`)}
                            </p>
                          </div>
                          {index !== statusArr.length - 1 ? (
                            <div className="progress-bar-line"></div>
                          ) : null}
                        </>
                      ) : null}
                    </>
                  );
                })}

                <div className="progress-bar-item">
                  <CancelledIcon />
                  <p>{t("myOrder.cancelText")}</p>
                </div>
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProgressBar;

/*
 * File: store.js
 * Author: Harsh Chauhan
 * Created Date: November 2nd, 2022 
 * Last Modified Date: November 2nd, 2022 
 * Description: This file contains the store.
 */


import { createStore, applyMiddleware, compose } from 'redux';

import { persistStore } from 'redux-persist';
import createDebounce from 'redux-debounced';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';

const middlewares = [thunk, createDebounce()];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);
const persistor = persistStore(store);
export { store, persistor };

import React, { useEffect, useState } from "react";
import { Button, Input, Label, Row, Col, Form } from "reactstrap";
// Images scss
import Logo from "../../../assets/images/logo/dark-logo.svg";
import FormInput from "../../Form/FormInput";
import { IoMdClose } from "react-icons/io";
import { phone } from "phone";

// Multilangual
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  BranchNameRules,
  checkboxRules,
  CompanyNameRules,
  ConfirmPasswordRules,
  ContactNameRules,
  customerSelectRules,
  EmailRules,
  IndustrySelectRules,
  passwordInvalidRules,
  PhoneNumberRules,
} from "../../../constants/rules";
import FormPassword from "../../Form/FormPassword";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerTypeListApi,
  signupApi,
} from "../../../redux/actions/auth/loginActions";
import {
  getIndustryApi,
} from "../../../redux/actions/profile/profileAction";
import {
  ACCEPT_IMAGE,
  FILE_MAX_LIMIT,
  FILE_SIZE_LIMIT,
  LANGUAGE_AR,
  LANGUAGE_EN,
  libraries,
  TOAST_AUTO_CLOSE,
} from "../../../constants/appConstants";
import { toast } from "react-toastify";
import { Message } from "../../../constants/messages";
import FormSelectInput from "../../Form/FormSelectInput";
import FormCheckbox from "../../Form/FormCheckbox";
import { AttachIcon } from "../../../assets/images/svg";
import MapContainer from "../../common/Map";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  getAddressDetail,
  getAddressDetailFromLatLong,
} from "../../../constants/GetAddressInfo";
import { useJsApiLoader } from "@react-google-maps/api";
import { usePosition } from "../../../utility/hooks/usePosition";
import FormCountryInput from "../../Form/FormCountryInput";

const SignupBox = ({ toggleClick }) => {
  // setAttachDoc
  const [attachDoc, setAttachDoc] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [customerTypeList, setCustomerTypeList] = useState([]);
  const [selectCustomer, setSelectCustomer] = useState(null);
  const [address, setAddress] = useState('');
  const [fullCustomerAddress, setFullCustomerAddress] = useState();
  const [mapLat, setMapLat] = useState("");
  const [mapLong, setMapLong] = useState("");
  const [isRequired, setIsRequired] = useState(false);
  const [areaValue, setAreaValue] = useState("");
  const [areaError, setAreaError] = useState(false);
  const [map, setMap] = useState(null)
  const { state } = useLocation();

  //* Hook for navigation
  const navigate = useNavigate();

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();
  // Multilangual
  const { t, i18n } = useTranslation();
  // useForm
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  // get device token 
  const devices = useSelector((state) => state?.device?.device);

  //* useEffect to fetch customer type list when language changes
  useEffect(() => {
    getCustomerTypeList();
  }, [i18n.language]);

  //* useEffect to fetch category dropdown when customer type changes
  useEffect(() => {
    if (selectCustomer) getCategoryDropdown(selectCustomer?.value);
  }, [selectCustomer]);

  //* useEffect to store form data in localStorage when navigating between pages
  useEffect(() => {
    if (!state?.signUp) {
      localStorage.setItem("ejadSignupData", JSON.stringify(getValues()));
    } else {
      navigate(".", {});
    }
  }, [getValues(), attachDoc]);

  //* useEffect to populate form with stored data from localStorage
  useEffect(() => {
    const value = localStorage.getItem("ejadSignupData");
    reset({
      ...JSON.parse(value),
    });
  }, []);

  // usePosition
  const { latitude, longitude, error } = usePosition();

  //* watch for changes in form fields
  const country = watch("phoneNo");
  const industry = watch("categoryId")
  const customerType = watch("customerId")

  //* useEffect to update latitude and longitude states when location changes
  useEffect(() => {
    if (latitude && longitude) {
      setMapLat(latitude);
      setMapLong(longitude);
    } else {
      setMapLat(31.9454);
      setMapLong(35.9284);
    }
  }, [latitude, longitude]);

  const passwordValue = watch("password");

  /**
   * Loads Google Maps API using `useJsApiLoader` hook.
   * `isLoaded` indicates if API has finished loading.
   */
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: libraries,
    // apiOptions: { language: "tr", radius:500 },
  });
  /**
   * Function for get all categories
   */
  const getCategoryDropdown = async (id) => {
    const res = await dispatch(getIndustryApi({ customerType: id }));
    if (!res.data.error) {
      let temp = [...res.data.data];
      let resultArr = temp.map((item) => {
        return {
          value: item.industryId,
          label:
            i18n.language === LANGUAGE_EN
              ? item.industryEnglishName
              : i18n.language === LANGUAGE_AR
                ? item.industryArabicName
                : item.industryFrenchName,
        };
      });
      setIndustryData([...resultArr]);
    }
  };

  /**
   * Function for get customer type
   */
  const getCustomerTypeList = async () => {
    const res = await dispatch(getCustomerTypeListApi());
    if (!res.data.error) {
      let temp = [...res.data.data];
      let resultArr = temp.map((item) => {
        return {
          value: item.id,
          label:
            i18n.language === LANGUAGE_EN
              ? item.name
              : i18n.language === LANGUAGE_AR
                ? item.arabicName
                : item.frenchName,
        };
      });
      setCustomerTypeList([...resultArr]);
    }
  };
  /**
   * Function for upload Attachment and Manage
   * @param {obj} data
   */
  const onAttachChange = (e) => {
    if (
      e.target.files[0].type !== "image/jpeg" &&
      e.target.files[0].type !== "image/png" &&
      e.target.files[0].type !== "application/pdf"
    ) {
      toast.error(t("errorMessages.fileType"), {
        autoClose: TOAST_AUTO_CLOSE,
      });
      return;
    }
    if (attachDoc.length + e.target.files.length > FILE_MAX_LIMIT) {
      toast.error(t("errorMessages.fileNumber"), {
        autoClose: TOAST_AUTO_CLOSE,
      });
      return;
    }
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > FILE_SIZE_LIMIT) {
        toast.error(t("errorMessages.fileSize"), {
          autoClose: TOAST_AUTO_CLOSE,
        });
        return;
      }
    }
    const data = [...attachDoc];
    for (let i = 0; i < e.target.files.length; i++) {
      data.push(e.target.files[i]);
    }

    setAttachDoc(data);
  };

  /**
   * Function for Delete Attachments
   * @param {*} i
   */
  const deleteAttachments = (i) => {
    let oldArray = [...attachDoc];
    oldArray.splice(i, 1);
    setAttachDoc(oldArray);
  };

  /**
   * Function for submit the all field in form
   * @param {obj} data
   */

  const onFormSubmit = async (data) => {
    localStorage?.removeItem("ejadSignupData");
    localStorage.removeItem("ejadLangPicker");
    if (!fullCustomerAddress) return;
    const tempCategory = [...data.categoryId];
    const FinalVal = phone(`+${country}`);
    let final = [];
    if (tempCategory && tempCategory.length) {
      tempCategory.map((item) => {
        final.push(item.value);
      });
    }
    if (!areaValue) {
      return;
    }
    const formData = new FormData();
    formData.append("companyName", data.companyName);
    formData.append("email", data.email?.trim());
    formData.append("name", data.name);
    formData.append(
      "phoneNo",
      FinalVal.phoneNumber.replace(FinalVal.countryCode, "")
    );
    formData.append("countryCode", FinalVal.countryCode);
    formData.append("password", data.password);
    formData.append("confirmPwd", data.confirmPwd);
    formData.append("latitude", fullCustomerAddress.lat);
    formData.append("longitude", fullCustomerAddress.long);
    formData.append("country", fullCustomerAddress.country);
    formData.append("city", fullCustomerAddress.city);
    formData.append("area", fullCustomerAddress.line1);
    formData.append("branchName", data.branchName);
    formData.append("industry", JSON.stringify(final));
    formData.append("customerType", selectCustomer?.value);
    formData.append("deviceToken", devices);
    formData.append("deviceType", 'W');
    formData.append("isAttachments", attachDoc && attachDoc.length > 0);
    if (attachDoc && attachDoc.length > 0) {
      for (let i = 0; i < attachDoc.length; i++) {
        formData.append("attachments", attachDoc[i]);
      }
    }

    const res = await dispatch(signupApi(formData));

    if (!res.data.error) {
      navigate(`/email-verification?id=${res.data.data.id}`);
    }
  };

  /**
   * Runs the `getAddressFromLatLong` function when either `mapLat` or `mapLong` changes.
   * This function retrieves the address detail from the latitude and longitude coordinates and sets the state variables accordingly.
   */
  useEffect(() => {
    if (mapLat || mapLong) getAddressFromLatLong();
  }, [mapLat, mapLong]);

  //* Retrieves address detail from latitude and longitude coordinates
  const getAddressFromLatLong = async () => {
    let address = await getAddressDetailFromLatLong({
      lat: mapLat,
      long: mapLong,
      map: map
    });
    if (!address.line1) {
      address.line1 = address.city;
    } else {
      address.line1 = address.line1;
    }

    setAreaValue(address.placeName ? address.placeName : address.city);

    setFullCustomerAddress(address);
  };

  //* Function to change address input and update state variables
  const changeAddress = async (address) => {
    setAddress(address);
    setAreaValue(address);
    setAreaError(true);
  };

  //* Function to change selected address and update state variables
  const selectAddress = async (address) => {
    setMapLat("");
    setMapLong("");
    setAddress(address);
    const result = await getAddressDetail({ address, map });
    if (!result.line1) {
      result.line1 = result.city;
    } else {
      result.line1 = result.line1;
    }
    setAreaValue(result.placeName ? result.placeName : result.city);

    setFullCustomerAddress(result);
  };

  //* useEffect to reset form when language changes
  useEffect(() => {
    reset();
  }, [i18n.language]);

  //* useEffect to fill company name automatically when customer type is home
  useEffect(() => {
    const contactPersonName = getValues('name')
    if ((selectCustomer?.value === 'a027fa56-393b-45fa-9230-6fb7b06f4486')) setValue('companyName', contactPersonName)
  }, [watch('name'), watch('customerId')]);

  return (
    <>
      <div className="right-login-data animate__animated animate__fadeIn">
        <div className="inner-data">
          <div className="common-lang-header">
            <img src={Logo} className="img-fluid log-img" alt="images" />
            {/* <ul className="lang-ul">
              <LanguagePicker />
            </ul> */}
          </div>
          <div className="form-data">
            <p className="note">
              {t("notes.text1")} ( <span className="text-danger">*</span> ){" "}
              {t("notes.text2")}
            </p>
            <Form
              className="mb-0"
              onSubmit={handleSubmit(onFormSubmit)}
              noValidate
              autoComplete="off"
            >
              <div className="field-group mb40">
                <span className="main-label">
                  {t("signupPage.companyInfoLabel")}
                </span>
                <FormSelectInput
                  fgClass="mb20"
                  control={control}
                  name={"customerId"}
                  label={t("signupPage.customerTypeLabel")}
                  placeholder={t("signupPage.customerTypeLabel")}
                  options={customerTypeList}
                  errors={errors}
                  optionValue="value"
                  optionLabel="label"
                  isSearchable={false}
                  rules={customerSelectRules}
                  required={true}
                  onChangeCallback={(val) => {
                    setSelectCustomer(val);
                    // if user selected other type instead of "Residential"
                    //  then name and  companyName field's pre-filled values should be cleared 
                    if (val?.label !== 'Residential') {
                      setValue('companyName', '')
                      setValue('name', '')
                    }
                    val.value !== selectCustomer &&
                      setValue("categoryId", null);
                  }}
                />
                <FormSelectInput
                  fgClass="mb20"
                  multiple={true}
                  control={control}
                  name={"categoryId"}
                  label={t("signupPage.industryTypeLabel")}
                  placeholder={t("signupPage.industryTypeLabel")}
                  options={industryData}
                  errors={errors}
                  // optionValue="id"
                  // optionLabel="name"
                  optionValue="value"
                  optionLabel="label"
                  isSearchable={false}
                  rules={IndustrySelectRules}
                  required={true}
                  isDisabled={!selectCustomer || !customerType}
                />


                <FormInput
                  fgClass="mb20"
                  id="name"
                  name="name"
                  type="text"
                  label={t("profile.contactNameLabel")}
                  placeholder={t("profile.contactNameLabel")}
                  required={true}
                  rules={ContactNameRules}
                  register={register}
                  errors={errors}
                  disabled={!industry?.length || !customerType}
                />
                <FormCountryInput
                  fgClass="mb20"
                  required={true}
                  id="phoneNo"
                  name="phoneNo"
                  label={t("signupPage.contactNumberLabel")}
                  placeholder={t("signupPage.contactNumberLabel")}
                  rules={PhoneNumberRules}
                  errors={errors}
                  register={register}
                  control={control}
                  isDisabled={!industry?.length || !customerType}
                />
                <FormInput
                  fgClass="mb20"
                  id="email"
                  name="email"
                  type="email"
                  label={t("signupPage.contactEmailLabel")}
                  placeholder={t("signupPage.contactEmailLabel")}
                  required={true}
                  rules={EmailRules}
                  register={register}
                  errors={errors}
                  disabled={!industry?.length || !customerType}
                // readOnlyType={emailReadable}
                // onFocusEvent={() => setEmailReadable(false)}
                />
                <FormInput
                  fgClass="mb20"
                  id="companyName"
                  name="companyName"
                  type="text"
                  label={t("signupPage.companyNameLabel")}
                  placeholder={t("signupPage.companyNameLabel")}
                  required={true}
                  rules={CompanyNameRules}
                  register={register}
                  maxLength={50}
                  errors={errors}
                  disabled={!industry?.length || !customerType}
                />

              </div>
              <div className="field-group mb40">
                <span className="main-label">
                  {t("signupPage.attachLabel")}
                </span>
                {attachDoc && attachDoc.length > 0 && (
                  <ul className="listing-attach">
                    {attachDoc.map((item, i) => {
                      return (
                        <li key={i}>
                          <div className="list-data">
                            <div
                              className="one"
                              onClick={() => deleteAttachments(i)}
                            >
                              <IoMdClose />
                            </div>
                            <div className="two">{item.name}</div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
                <div className="cu-form-group mb-0">
                  <Input
                    type="file"
                    hidden
                    id="attach"
                    multiple
                    onChange={onAttachChange}
                    accept={ACCEPT_IMAGE}
                  />
                  <Label for="attach" className="attach-btns">
                    <AttachIcon />
                    {t("signupPage.attachBtn")}
                  </Label>
                </div>
              </div>
              <div className="field-group mb20">
                <span className="main-label">
                  {t("signupPage.locationLabel")}
                </span>
                <div className="map-box">
                  <MapContainer
                    latitude={mapLat || fullCustomerAddress?.lat || 60.2517722}
                    longitude={
                      mapLong || fullCustomerAddress?.long || 24.8989093
                    }
                    setMapLat={(val) => setMapLat(val)}
                    setMapLong={(val) => setMapLong(val)}
                    setMap={(val) => setMap(val)}
                    mapLat={mapLat}
                    mapLong={mapLong}
                    fromSignup={true}
                    draggable={true}
                  />
                </div>

                <Row className="mt-3">
                  <Col md={4}>
                    {isLoaded ? (
                      <div className="flex-fill">
                        <PlacesAutocomplete
                          value={address}
                          onChange={changeAddress}
                          onSelect={(val) => selectAddress(val)}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div className="cu-form-group floating-label-group  mb20">
                              <Label
                                htmlFor="area"
                                className={`floating-label ${fullCustomerAddress?.country ? "show" : ""
                                  }`}
                              >
                                {t("signupPage.areaLabel")}
                              </Label>

                              <Input
                                className="form-control"
                                {...getInputProps({
                                  placeholder: t("signupPage.searchText"),
                                  className: "location-search-input",
                                  id: "area",
                                  name: "area",
                                })}
                                value={areaValue}
                              />

                              <span className="required abs">*</span>

                              <div className="autocomplete-dropdown-container">
                                {suggestions.map((suggestion, i) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                    : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                      color: "#7a8897",
                                    };
                                  return (
                                    <div
                                      key={i}
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                              {areaError && !areaValue && (
                                <span className="error-msg">
                                  {t("errorMessages.areaRequired")}
                                </span>
                              )}
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                    ) : null}
                  </Col>
                  <Col md={4}>
                    <FormInput
                      fgClass="mb20"
                      id="city"
                      name="city"
                      type="text"
                      label={t("signupPage.cityLabel")}
                      placeholder={t("signupPage.cityLabel")}
                      required={true}
                      rules={{}}
                      register={register}
                      errors={errors}
                      value={fullCustomerAddress?.city}
                      disabled
                    />
                  </Col>
                  <Col md={4}>
                    <FormInput
                      fgClass="mb20"
                      id="country"
                      name="country"
                      type="text"
                      label={t("signupPage.countryLabel")}
                      placeholder={t("signupPage.countryLabel")}
                      required={true}
                      rules={{}}
                      register={register}
                      errors={errors}
                      value={fullCustomerAddress?.country}
                      disabled
                    />
                  </Col>
                </Row>
                <FormInput
                  fgClass="mb20 font-6"
                  id={`branchName`}
                  name={`branchName`}
                  type="text"
                  label={t("profile.branchData.branchTitle")}
                  placeholder={t("profile.branchData.branchName")}
                  required={true}
                  rules={BranchNameRules}
                  register={register}
                  errors={errors}
                  maxLength={50}
                />
                {isRequired && !fullCustomerAddress && (
                  <span className="error-msg">{Message.Location}</span>
                )}
              </div>
              <div className="field-group mb40">
                <span className="main-label">
                  {t("loginPage.passwordLabel")}
                </span>
                <FormPassword
                  fgClass="mb20"
                  id="mpassword"
                  name="password"
                  type="password"
                  label={t("loginPage.passwordLabel")}
                  placeholder={t("loginPage.passwordLabel")}
                  required={true}
                  rules={passwordInvalidRules}
                  register={register}
                  errors={errors}
                  visible={false}
                  autoFill='new-password'
                />
                <FormPassword
                  fgClass="mb-0"
                  id="confirmPwd"
                  name="confirmPwd"
                  type="password"
                  label={t("signupPage.confirmPasswordLabel")}
                  placeholder={t("signupPage.confirmPasswordLabel")}
                  required={true}
                  rules={ConfirmPasswordRules}
                  passwordValue={passwordValue}
                  isConfirmPassword={true}
                  register={register}
                  errors={errors}
                  visible={false}
                />
              </div>
              <div className="field-group mb20">
                <div className="full-check">
                  <FormCheckbox
                    type="checkbox"
                    id="accept"
                    hidden
                    name="terms"
                    register={register}
                    rules={checkboxRules}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="cu-form-group mb-0 text-center">
                <Button
                  color="main"
                  className="cu-btn br20 w-100"
                  type="submit"
                  onClick={() => setIsRequired(true)}
                >
                  {t("loginPage.createAccountBtn")}
                </Button>
              </div>
            </Form>
          </div>
          <p className="note bottom-note">{t("signupPage.registered")}</p>
          <div className="cu-form-group mb-0 text-center">
            <Button
              color="main-light"
              className="cu-btn cu-sm-btn br15 min150"
              type="button"
              onClick={() => toggleClick("login")}
            >
              {t("loginPage.loginBtn")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupBox;

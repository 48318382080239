/*
 * File: ProfileDropdown.js
 * Author: Harsh Chauhan
 * Created Date: November 9th, 2022
 * Last Modified Date: October 4th, 2023 
 * Description: This component is used to render the profile dropdown menu.
 */
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
//* Images scss
import UserLogo from "../../assets/images/icon/user-small.png";

//* Multilangual
import { useTranslation } from "react-i18next";

//* Logout API
import { useDispatch, useSelector } from "react-redux";
import { clearUserCookies, getLoginUser } from "../../constants/utils";
import { logoutApi } from "../../redux/actions/auth/loginActions";

import {
  LogoutIcon,
  ProfileIcon,
  ReportIcon,
  UsageIcon,
} from "../../assets/images/svg";
import { LANGUAGE_AR } from "../../constants/appConstants";

function ProfileDropdown() {
  //* State variables for profile photo, loading state, and navigation
  const [showProfilePhoto, setProfilePhoto] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  //* Hook for navigation
  const navigate = useNavigate();

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();

  //* Function to handle logout
  const logoutHandler = async () => {
    setIsLoading(true)
    const res = await dispatch(logoutApi());
    if (!res.data.error) {
      clearUserCookies();
      navigate("/landing");
      setIsLoading(false)
      i18n.changeLanguage(LANGUAGE_AR)
      localStorage.setItem("ejadLangCode", LANGUAGE_AR);
      window.location.reload();
    }
  };

  //* Effect to set profile photo on component mount
  useEffect(() => {
    const profileData = getLoginUser();
    const data = JSON.parse(profileData);
    if (data && data.email) {
      setProfilePhoto(data.profilePhoto);
    }
  }, []);

  //* Multilangual
  const { t, i18n } = useTranslation();

  //* Redux state for profile data
  const profileListData = useSelector(
    (state) => state?.profileReducer?.profile
  );

  return (
    <>
      <UncontrolledDropdown nav inNavbar className="common-dropdown">
        <DropdownToggle nav>
          <div className="user-img">
            <img src={profileListData?.profilePhoto || UserLogo} alt="" />
          </div>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem tag={Link} to="/profile">
            <div className="one">
              <ProfileIcon />
            </div>
            <div className="two">{t("header.profile.viewText")}</div>
          </DropdownItem>
          <DropdownItem tag={Link} to="/termscondition">
            <div className="one">
              <ReportIcon />
            </div>
            <div className="two">{t("header.profile.tcText")}</div>
          </DropdownItem>
          <DropdownItem tag={Link} to="/privacypolicy">
            <div className="one">
              <UsageIcon />
            </div>
            <div className="two">{t("header.profile.privacyText")}</div>
          </DropdownItem>
          <div className="dropdown-item w-100">
            <button className="dropdown-item" style={{ border: 'none', padding: '0px' }} disabled={isLoading} onClick={() => logoutHandler()}>
              <div className="one">
                <LogoutIcon />
              </div>
              <div className="two">{t("header.profile.logoutText")}</div>
            </button>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

export default ProfileDropdown;

/*
 * File: reviewAction.js
 * Author: Harsh Chauhan
 * Created Date: February 9th, 2023
 * Last Modified Date: December 7th, 2023
 * Description: This file contains the review actions.
 */

import { api } from "../../../api/api";
import { POST_REVIEW, POST_SPARE_PARTS } from "../../../constants/ApiUrl";

/**
 * This API is used for Add review and rating to perticular service.
 * @param {*} data
 * @returns
 */
export const addRatingReview = (data) => {
  return async () => {
    const response = await api(POST_REVIEW, data);
    return response;
  };
};

/**
 * Sends a POST request to the API with the given data and returns the response.
 *
 * @param {Object} data - The data to be sent in the request body.
 * @return {Promise<Object>} A promise that resolves with the response from the API.
 */
export const postSparePartsDetails = (data) => {
  return async () => {
    const response = await api(POST_SPARE_PARTS, data);
    return response;
  };
};


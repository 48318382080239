/*
 * File: HeaderStatic.js
 * Author: Harsh Chauhan
 * Created Date: January 2nd, 2023
 * Last Modified Date: January 24th, 2024
 * Description: this code snippet represents a header component with a responsive design and multilingual support.
 */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Collapse, Nav, Navbar, NavbarToggler } from "reactstrap";
//* Multilangual
import { useTranslation } from "react-i18next";

//* Images scss
import Logo from "../../assets/images/logo/dark-logo.svg";
import LightLogo from "../../assets/images/logo/logo.svg";
import LanguagePicker from "./LanguagePicker";
import ContactUsModel from "../modal/ContactUsModal";

const HeaderStatic = ({ fixed, fromHeader }) => {
  //* State variables
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false)

  //* Multilangual
  const { t } = useTranslation();

  return (
    <>
      <header className={`${fixed ? "common-header" : "static-header"}`}>
        <Navbar expand="lg" container="lg" className="">
          <Link className="navbar-brand" to="/home">
            {fixed ? (
              <img src={Logo} className="img-fluid main-logo" alt="images" />
            ) : (
              <img src={LightLogo} className="img-fluid main-logo" alt="images" />
            )}
          </Link>
          <ul className="mobile-list align-items-center">
            <span className={fixed ? "contactUS" : ''} onClick={() => setModal(true)}>{t('landing.contact')}</span>
            <LanguagePicker fromHeader={fromHeader} />
          </ul>
          <Collapse isOpen={isOpen} navbar>
            <Nav className="second navbar-nav align-items-center">
              <span className={fixed ? "contactUS" : ''} onClick={() => setModal(true)}> {t('landing.contact')}</span>
              <LanguagePicker fromHeader={fromHeader} />
            </Nav>
          </Collapse>
        </Navbar>
      </header>
      <ContactUsModel
        modal={modal}
        toggleModal={() => setModal(!modal)}
      />
    </>
  );
};

export default HeaderStatic;

import React from "react";
import HeaderStatic from "../component/common/HeaderStatic";

const CustomLayout = ({ children }) => {
  return (
    <>
      <HeaderStatic fixed={true} />
      {children}
    </>
  );
};

export default CustomLayout;

/*
 * File: PrivacyPolicy.js
 * Author: Harsh Chauhan
 * Created Date: December 27th, 2022 
 * Last Modified Date: April 1st, 2024
 * Description: This component is responsible for displaying a privacy policy page with multilingual support and navigation capabilities.
 */
import React, { useState, useEffect } from "react";
import { ChevronLeft } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import Footer from "../../component/common/Footer";
import { isUserLoggedIn } from "../../constants/utils";
//* page scss
import "./PrivacyPolicy.scss";
import { getPrivacyPolicyApi } from "../../redux/actions/privacyPolicy/privacyPolicyAction";
import { useDispatch } from "react-redux";

const PrivacyPolicy = () => {
  //* Multilangual
  const { t } = useTranslation();
  const [privacyPolicyData, setPrivacyPolicyData] = useState({});

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();
  
  //* Hook for navigation
  const navigate = useNavigate();
  /**
   * Navigates the user back to the landing page with a state object containing the signUp flag set to true.
   *
   * @return {void} No return value.
   */
  const handleBack = () => {
    navigate("/landing", { state: { signUp: true } });
  };

  //* Effect to get privacy policy
  useEffect(() => {
    const fetchData = async () => {
      const res = await dispatch(
        getPrivacyPolicyApi({ isTermsAndCondition: false })
      );
      if (!res.data.error) {
        setPrivacyPolicyData(res.data.data);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>EJAD - {t("PrivacyPolicyPage.headText")}</title>
      </Helmet>
      <div className="common-design-section static-data">
        <div className="common-design-section-data">
          <div className="right-design-data">
            <div className="spacing-right-data">
              <Container>
                <div className="static-box">
                  <div className="inner-title-data">
                    <div
                      className="arrowDiv"
                      onClick={() =>
                        isUserLoggedIn() ? navigate(-1) : handleBack()
                      }
                    >
                      <ChevronLeft className="pointer text-primary1" />
                    </div>

                    <div className="box-data">
                      <div
                        contentEditable="false"
                        dangerouslySetInnerHTML={{
                          __html: privacyPolicyData?.value,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </Container>
            </div>

            {/* Common Footer */}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;

import React, { useState } from "react";
import { phone } from "phone";
import { Label } from "reactstrap";
import { Controller } from "react-hook-form";
import i18n from "../../i18n/i18n";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const FormCountryInput = ({
  fgClass,
  label,
  placeholder,
  name,
  id,
  required,
  value,
  register,
  rules,
  maxLength,
  pattern,
  control,
  errors,
  errorMsg,
  isDisabled
}) => {
  const [isShow, setIsShow] = useState(false);
  const validateHandler = (val) => {
    const PlusData = `+${val}`;
    const FinalVal = phone(PlusData);

    return FinalVal.isValid;
  };

  rules.validate = (value) => {
    return (
      (value && validateHandler(value)) || i18n.t("errorMessages.phoneValidMsg")
    );
  };
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value, ref, ...field } }) => (
        <div
          className={`cu-form-group floating-label-group telephone ${fgClass} ${value || isShow ? "telephone-active" : ""
            }`}
        >
          <div className="PhoneInput">
            <PhoneInput
              country="jo"
              {...field}
              value={value}
              name={name}
              id={id}
              innerRef={ref}
              placeholder={placeholder}
              onChange={(value) => {
                let mobileNo = value;
                if (!mobileNo.includes("+")) {
                  mobileNo = `+${mobileNo}`;
                }
                onChange(mobileNo);
              }}
              onFocus={() => setIsShow(true)}
              onBlur={() => setIsShow(false)}
              inputClass={name}
              disabled={isDisabled}
            />
            <Label
              className={`floating-label ${value || isShow ? "show" : ""}`}
            >
              {label}
            </Label>
          </div>
          {required && <span className="required abs">*</span>}
          <span className="error-msg">{errorMsg || errors[name]?.message}</span>
        </div>
      )}
    />
  );
};

export default FormCountryInput;

import React, { useState } from "react";
import { Button, Form } from "reactstrap";
// Images scss
import Logo from "../../../assets/images/logo/dark-logo.svg";
import FormInput from "../../Form/FormInput";
import FormPassword from "../../Form/FormPassword";
// Rules
import { EmailRules, passwordInvalidRules } from "../../../constants/rules";
import { useForm } from "react-hook-form";
import { useNavigate, useNavigation, useSearchParams } from "react-router-dom";
import {
  customerLoginApi,
  sendDeviceToken,
} from "../../../redux/actions/auth/loginActions";
import { loginUser } from "../../../constants/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_PROFILE_DATA, LANGUAGE_EN,
} from "../../../constants/appConstants";
import { useTranslation } from "react-i18next";

const LoginBox = ({ toggleClick }) => {
  //* Hook for navigation
  const navigate = useNavigate();

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false)
  // Multilangual
  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});
  //* Fetching device information from Redux store
  const devices = useSelector((state) => state?.device?.device);

  /**
   * Function for submit the all field in form
   * @param {obj} data
   */
  const onFormSubmit = async (data) => {
    setIsLoading(true)
    localStorage.removeItem("ejadLangPicker");
    let res = await dispatch(customerLoginApi({ ...data, deviceToken: devices }));
    if (!res.data.error) {
      if (res.data.data?.accountNotVerified) {
        navigate(`/email-verification?email=${res.data.data.email}`);
        setIsLoading(false)
        return;
      }
      dispatch({
        type: GET_PROFILE_DATA,
        payload: { data: res.data.data },
      });
      const { authToken } = res.data.data;
      loginUser(res.data.data, authToken);
      navigate("/home");
    } else {
      setIsLoading(false)
    }
  };

  return (
    <>
      <div className="right-login-data animate__animated animate__fadeIn">
        <div className="inner-data">
          <div className="common-lang-header">
            <img src={Logo} className="img-fluid log-img" alt="images" />
            {/* <ul className="lang-ul">
              <LanguagePicker />
            </ul> */}
          </div>
          <div className="form-data">
            <p className="note">
              {t("notes.text1")} ( <span className="text-danger">*</span> ){" "}
              {t("notes.text2")}
            </p>
            <Form className="mb-0" onSubmit={handleSubmit(onFormSubmit)}>
              <FormInput
                fgClass="mb20"
                id="email"
                name="email"
                type="text"
                label={t("loginPage.emailLabel")}
                placeholder={t("loginPage.emailLabel")}
                required={true}
                rules={EmailRules}
                register={register}
                errors={errors}
              />
              <FormPassword
                fgClass="mb20"
                id="password"
                name="password"
                type="password"
                label={t("loginPage.passwordLabel")}
                placeholder={t("loginPage.passwordLabel")}
                required={true}
                rules={passwordInvalidRules}
                register={register}
                errors={errors}
                visible={false}
              />
              <div className="cu-form-group mb40 text-center">
                <Button
                  color="main-outline"
                  className="cu-btn cu-sm-btn br15 min150"
                  type="button"
                  onClick={() => toggleClick("forgot")}
                >
                  {t("loginPage.forgotButton")}
                </Button>
              </div>
              <div className="cu-form-group mb-0 text-center">
                <Button
                  color="main"
                  className={`cu-btn br20 w-100`}
                  type="submit"
                  disabled={isLoading}
                >
                  {t("loginPage.loginBtn")}
                </Button>

              </div>
            </Form>
          </div>
          <p className="note bottom-note">{t("loginPage.notRegistered")}</p>
          <div className="cu-form-group mb-0 text-center">
            <Button
              color="main-light"
              className="cu-btn cu-sm-btn br15 min150"
              type="button"
              onClick={() => toggleClick("signup")}
            >
              {t("loginPage.createAccountBtn")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginBox;

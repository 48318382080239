/*
 * File: RfpDetailsView.js
 * Author: Bhavin Prajapati
 * Created Date: November 28th, 2022 
 * Last Modified Date: April 12th, 2024
 * Description: It renders a page for displaying details of Request for Proposals (RFPs).
 */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  cancelRfpOrder,
  getReplyDetailsRfpApi,
  sendReplyProposalsRfpApi,
} from "../../redux/actions/rfp/rfpAction";
import { Helmet } from "react-helmet-async";
import { Button, Col, Collapse, Container, Input, Label } from "reactstrap";
import DummyCircle from "../../assets/images/icon/user.png";
import OrderSummary from "../../component/modal/service/OrderSummary";
import ReplyModal from "../../component/modal/service/ReplyModal";
import { ShimmerCircularImage, ShimmerThumbnail } from "react-shimmer-effects";
import DateLabel from "../../component/common/DateLabel";
import GalleryModal from "../../component/modal/service/GalleryModal";

import "../Service/Service.scss";
import DummyCover from "../../assets/images/custom/dummy-cover.png";
import DummyPDFCover from "../../assets/images/custom/dummy-pdf-cover.png";
import {
  ACCEPTED_ORDER,
  APPROVED_ORDER,
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_PDF,
  MEDIA_TYPE_VIDEO,
  QUESTION_TYPE_MULTI,
  QUESTION_TYPE_SINGLE,
  QUESTION_TYPE_TEXT,
  REQUESTED_ORDER,
  WAITING_PAYMENT,
} from "../../constants/appConstants";
import ProviderModal from "../../component/modal/service/ProviderModal";
import { getProblemLimitationData, getSingleProviderApi } from "../../redux/actions/service/serviceAction";
import { checkTimeGap, getThumbnail } from "../../constants/utils";
import PlayImg from "../../assets/images/icon/playIcon.svg";
import VideoModal from "../../component/modal/service/VideoModal";
import { AiFillPlayCircle } from "react-icons/ai";
import Footer from "../../component/common/Footer";
import Rating from "react-rating";
import {
  BriefCaseIcon,
  CancelIcon,
  CarIcon,
  CheckBoxIcon,
  UserIcon,
} from "../../assets/images/svg";
import { GoStar } from "react-icons/go";
import { ChevronRight } from "react-feather";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import ConfirmActionModal from "../../component/modal/ConfirmActionModal";
import ProgressBar from "../../component/common/progressBar";

const RfpDetailsView = () => {
  //* Multilangual
  const { t, i18n } = useTranslation();

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();

  //* Get search parameters from URL
  const [searchParams] = useSearchParams();
  let replyId = searchParams.get("replyId");
  let rfpId = searchParams.get("rfpId");

  //* Hook for navigation
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState({});

  const [replyModal, setReplyModal] = useState(false);
  const [galleryModal, setGalleryModal] = useState(false);
  const [galleryItem, setgalleryItem] = useState([]);
  const [videoModal, setVideoModal] = useState(false);
  const [videoDetails, setVideoDetails] = useState({});
  const [providerModal, setProviderModal] = useState(false);
  const [providerDetails, setProviderDetails] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [modal, setModal] = useState(false);
  const [showFeesCancelText, setShowFeesCancelText] = useState(false);
  const [problemLimits, setProblemLimits] = useState(null);
  
  /** */
  useEffect(() => {
    getRfpDetailsRequest();
  }, [rfpId, replyId]);

  /**
   * Function is used for get particular provider data after hired
   * @param {*} id
   */
  const getSingleData = async (id) => {
    setLoading(true);
    const res = await dispatch(getSingleProviderApi(id));
    if (!res.data.error) {
      setProviderDetails({ ...res.data.data });
    }
    setLoading(false);
  };

  /**
   * Function for get request from API
   */
  const getRfpDetailsRequest = async () => {
    setLoading(true);
    let data;
    if (!replyId) {
      data = { rfpId: rfpId };
    } else {
      data = { rfpId: rfpId, replyId: replyId };
    }
    let res = await dispatch(getReplyDetailsRfpApi(data));
    if (!res.data.error) {
      setRequestData({ ...res.data.data });
      //redirect user to the estimation screen when sp has submitted Estimation
      if (res?.data?.data?.customerFeesStatus === 'PEND' || (res?.data?.data?.customerFeesStatus === 'ACPT' && !res?.data?.data?.totalAmountOfCustomer)) navigate(`/estimation?serviceId=${rfpId}&type=R`)
      else if (res?.data?.data?.status === WAITING_PAYMENT) {
        navigate(`/estimation?serviceId=${rfpId}&type=R`)
      }
      getSingleData(res?.data?.data?.serviceProviderId)
    }
    setLoading(false);
  };

  /**
   * approve proposal handler
   */
  const sendProposalHandler = async () => {
    let data = {
      rfpId: rfpId,
      replyId: replyId,
      isApproved: true,
    };
    const res = await dispatch(sendReplyProposalsRfpApi(data));
    if (!res.data.error) {
      navigate("/rfps");
    }
  };
  const toggleCollapse = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };
  const handleCancelOrder = async (id) => {
    await dispatch(cancelRfpOrder({ rfpId: id }));
    navigate("/rfps");
  };

  const getProblemLimitation = async (id) => {
    setLoading(true)
    try {
      const res = await dispatch(getProblemLimitationData(id))
      if (!res.data.error) {
        const problemLimits = res.data.data.reduce((acc, item) => {
          acc[item.key] = parseInt(item.value, 10)
          return acc
        }, {})
        setProblemLimits(problemLimits)

      }
    } catch (error) {
      
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getProblemLimitation(requestData?.reply?.serviceProviderId?.id)
  }, [])
  return (
    <>
      <Helmet>
        <title>EJAD - Rfp Details</title>
      </Helmet>
      <div className="home-section common-design-section">
        <div className="common-design-section-data single-data">
          <div className="right-design-data">
            <section className="service-section reply-section">
              <Container fluid="lg">
                <div className="service-section-data">
                  {!isLoading ? (
                    <div className="service-details-left-data">
                      <ProgressBar
                        orderStatus={requestData?.status}
                        orderType="rfp"
                        lastStatus={requestData?.lastOrderStatus}
                      />
                      <div className="choose-provider-data profile-card">
                        <ul className="choose-ul">
                          <li className="choose-li">
                            <div
                              className="inner-provider-card"
                              onClick={() => {
                                setProviderModal(!providerModal);

                                getSingleData(
                                  requestData?.reply?.serviceProviderId?.id
                                );
                              }}
                            >
                              <div
                                className="one"
                                style={{
                                  background: `url(${requestData?.reply?.serviceProviderId
                                    ?.profilePhoto || DummyCircle
                                    })`,
                                }}
                              ></div>
                              <div className="two profile-card-two">
                                <div className="small-details-data">
                                  <div className="small-data-one span-two">
                                    <ul>
                                      <li>
                                        <div className="name-data">
                                          {requestData?.reply?.serviceProviderId
                                            ?.name
                                            ? requestData?.reply
                                              ?.serviceProviderId?.name
                                            : "-"}
                                          {requestData?.reply?.serviceProviderId
                                            ?.isCertified !== 0 && (
                                              <CheckBoxIcon />
                                            )}
                                        </div>
                                      </li>

                                      <li>
                                        <div className="star-data">
                                          <Rating
                                            className="user-select-none"
                                            initialRating={
                                              requestData?.reply
                                                ?.serviceProviderId?.rating
                                            }
                                            emptySymbol={
                                              <GoStar
                                                size={14}
                                                fill="#C8C8C8"
                                                stroke="#C8C8C8"
                                              />
                                            }
                                            fullSymbol={
                                              <GoStar
                                                size={14}
                                                fill={"#FFD700"}
                                                stroke={"#FFD700"}
                                              />
                                            }
                                          />
                                          <div className="comment-data">
                                            (
                                            {
                                              requestData?.reply
                                                ?.serviceProviderId?.total
                                            }
                                            )
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="three show-profile">
                                <span>
                                  {t("rfp.showProfile")} <ChevronRight />
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="reply-box my-4">
                        <div className="heading mb20">
                          {t("rfp.details.requiredProfession")}
                        </div>
                      </div>
                      <div className="brief-card white-bg-card my-0">
                        {requestData?.tasks &&
                          requestData?.tasks.length > 0 &&
                          requestData?.tasks.map((obj, index) => (
                            <div
                              className="inner-brief-border-card"
                              key={obj.id}
                            >
                              <div className="reply-box mb-0">
                                <div className="heading mb20 d-flex justify-content-between">
                                  {index + 1} {". "}
                                  {obj.category}
                                  <span
                                    className="collaps-icon"
                                    onClick={() => toggleCollapse(index)}
                                  >
                                    {index === activeIndex ? (
                                      <FaChevronDown />
                                    ) : (
                                      <FaChevronUp />
                                    )}
                                  </span>
                                </div>
                              </div>
                              <Collapse isOpen={index === activeIndex}>
                                <div className="reply-box mb20">
                                  <p className="paratext mb-0">
                                    <div className="paratext" dangerouslySetInnerHTML={{ __html: obj?.formattedDescription }} />
                                  </p>
                                </div>

                                {obj.taskAttachments &&
                                  obj.taskAttachments.length > 0 && (
                                    <Col md={12}>
                                      <div className="off-card">
                                        <div className="heading">
                                          {t("rfp.details.customerAttach")}
                                        </div>
                                        <div className="galary-data">
                                          {obj?.taskAttachments.map(
                                            (item, i) => {
                                              return (
                                                <>
                                                  {item.attachmentType ===
                                                    MEDIA_TYPE_VIDEO && (
                                                      <div
                                                        key={i}
                                                        className="galary-card"
                                                        onClick={() => { }}
                                                      >
                                                        <div className="card-img">
                                                          <video
                                                            src={item.url}
                                                            width="100%"
                                                            height="100%"
                                                          ></video>
                                                          <div className="video-icon pointer">
                                                            <span
                                                              onClick={() => {
                                                                setVideoModal(
                                                                  true
                                                                );
                                                                setVideoDetails({
                                                                  ...item,
                                                                });
                                                              }}
                                                            >
                                                              <AiFillPlayCircle />
                                                            </span>
                                                          </div>
                                                        </div>

                                                        <div className="card-body">
                                                          <span>
                                                            {item?.fileName}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    )}
                                                  {item.attachmentType ===
                                                    MEDIA_TYPE_PDF && (
                                                      <a
                                                        key={i}
                                                        className="galary-card"
                                                        href={item.url}
                                                        target="_blank"
                                                      >
                                                        <div
                                                          className="card-img"
                                                          style={{
                                                            background: `url(${DummyPDFCover})`,
                                                          }}
                                                        ></div>

                                                        <div className="card-body">
                                                          <span>
                                                            {item?.fileName}
                                                          </span>
                                                        </div>
                                                      </a>
                                                    )}
                                                  {item.attachmentType ===
                                                    MEDIA_TYPE_IMAGE && (
                                                      <div
                                                        key={i}
                                                        className="galary-card"
                                                        onClick={() => {
                                                          setGalleryModal(
                                                            !galleryModal
                                                          );
                                                          let temp = [];
                                                          temp.push({ ...item });
                                                          setgalleryItem(temp);
                                                        }}
                                                      >
                                                        <div
                                                          className="card-img"
                                                          style={{
                                                            background: `url(${item?.url ||
                                                              DummyCover
                                                              })`,
                                                          }}
                                                        ></div>

                                                        <div className="card-body">
                                                          <span>
                                                            {item?.fileName}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    )}
                                                </>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                                {obj.questions.general &&
                                  obj.questions.general.length > 0 && (
                                    <div className="off-card mb40 question-box border-0">
                                      <div className="heading">
                                        {t("rfp.generalQuestion")}
                                      </div>
                                      {obj?.questions.general.map((item, i) => {
                                        const answerLang =
                                          i18n?.language === "ar"
                                            ? "arValue"
                                            : i18n?.language === "fr"
                                              ? "frValue"
                                              : "value";
                                        const questionLang =
                                          i18n?.language === "ar"
                                            ? "arQuestion"
                                            : i18n?.language === "fr"
                                              ? "frQuestion"
                                              : "question";
                                        return (
                                          <div
                                            className="question-inner-box mb20"
                                            key={`${i}+"cat`}
                                          >
                                            <p className="label-text font-700">
                                              {item?.[questionLang]}
                                            </p>

                                            <p className="answer-text">
                                              {/* return answer based on question type andlanguage , for multiple answers add comma separated values  */}
                                              {item.answerType ===
                                                QUESTION_TYPE_TEXT
                                                ? item.answer[0].value
                                                : item.answerType ===
                                                  QUESTION_TYPE_SINGLE
                                                  ? item?.answer[0]?.[answerLang]
                                                  : item.answerType ===
                                                    QUESTION_TYPE_MULTI
                                                    ? item?.answer?.map(
                                                      (el, index) =>
                                                        index ===
                                                          item?.answer?.length - 1
                                                          ? el?.[answerLang]
                                                          : `${el?.[answerLang]}, `
                                                    )
                                                    : ""}
                                            </p>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                {obj.questions.category &&
                                  obj.questions.category.length > 0 && (
                                    <div className="off-card mb40 question-box">
                                      <div className="heading">
                                        {t("rfp.categoryQuestion")}
                                      </div>
                                      {obj?.questions.category.map(
                                        (item, i) => {
                                          const answerLang =
                                            i18n?.language === "ar"
                                              ? "arValue"
                                              : i18n?.language === "fr"
                                                ? "frValue"
                                                : "value";
                                          const questionLang =
                                            i18n?.language === "ar"
                                              ? "arQuestion"
                                              : i18n?.language === "fr"
                                                ? "frQuestion"
                                                : "question";
                                          return (
                                            <div
                                              className="question-inner-box mb20"
                                              key={`${i}+"cat`}
                                            >
                                              <p className="label-text font-700">
                                                {item?.[questionLang]}
                                              </p>
                                              <p className="answer-text">
                                                {/* return answer based on question type andlanguage , for multiple answers add comma separated values  */}
                                                {item.answerType ===
                                                  QUESTION_TYPE_TEXT
                                                  ? item.answer[0].value
                                                  : item.answerType ===
                                                    QUESTION_TYPE_SINGLE
                                                    ? item?.answer[0]?.[
                                                    answerLang
                                                    ]
                                                    : item.answerType ===
                                                      QUESTION_TYPE_MULTI
                                                      ? item?.answer?.map(
                                                        (el, index) =>
                                                          index ===
                                                            item?.answer?.length - 1
                                                            ? el?.[answerLang]
                                                            : `${el?.[answerLang]}, `
                                                      )
                                                      : ""}
                                              </p>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                {obj.replyAttachments &&
                                  obj.replyAttachments.length > 0 && (
                                    <div className="off-card mb40">
                                      <div className="heading">
                                        {t("rfp.details.SpAttach")}
                                      </div>
                                      <div className="galary-data">
                                        {obj?.replyAttachments.map(
                                          (item, i) => {
                                            return (
                                              <>
                                                {item.attachmentType ===
                                                  MEDIA_TYPE_VIDEO && (
                                                    <div
                                                      key={i}
                                                      className="galary-card"
                                                      onClick={() => { }}
                                                    >
                                                      <div className="card-img">
                                                        <video
                                                          src={item.url}
                                                          width="100%"
                                                          height="100%"
                                                        ></video>
                                                        <div className="video-icon pointer">
                                                          <img
                                                            src={PlayImg}
                                                            className="img-fluid"
                                                            alt="play-icon"
                                                            onClick={() => {
                                                              setVideoModal(true);
                                                              setVideoDetails({
                                                                ...item,
                                                              });
                                                            }}
                                                          />
                                                        </div>
                                                      </div>

                                                      <div className="card-body">
                                                        <span>
                                                          {item?.fileName}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )}
                                                {item.attachmentType ===
                                                  MEDIA_TYPE_IMAGE && (
                                                    <div
                                                      key={i}
                                                      className="galary-card pointer"
                                                      onClick={() => {
                                                        setGalleryModal(
                                                          !galleryModal
                                                        );
                                                        let temp = [];
                                                        temp.push({ ...item });
                                                        setgalleryItem(temp);
                                                      }}
                                                    >
                                                      <div
                                                        className="card-img"
                                                        style={{
                                                          background: `url(${item?.url ||
                                                            DummyCover
                                                            })`,
                                                        }}
                                                      ></div>

                                                      <div className="card-body">
                                                        <span>
                                                          {item?.fileName}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )}
                                                {item.attachmentType ===
                                                  MEDIA_TYPE_PDF && (
                                                    <a
                                                      key={i}
                                                      className="galary-card pointer"
                                                      href={item.url}
                                                      target="_blank"
                                                    >
                                                      <div
                                                        className="card-img"
                                                        style={{
                                                          background: `url(${DummyPDFCover})`,
                                                        }}
                                                      ></div>

                                                      <div className="card-body">
                                                        <span>
                                                          {item?.fileName}
                                                        </span>
                                                      </div>
                                                    </a>
                                                  )}
                                              </>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  )}
                              </Collapse>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <div className="service-details-left-data text-center">
                      <ShimmerCircularImage size={180} />
                      <ShimmerThumbnail className="h40 br20 mb20" rounded />
                      <ShimmerThumbnail className="h100 br20 mb20" rounded />
                      <ShimmerThumbnail className="h100 br20 mb20" rounded />
                      <ShimmerThumbnail className="h150 br20 mb20" rounded />
                    </div>
                  )}
                  <OrderSummary
                    isLoading={isLoading}
                    fromRfpSummary={true}
                    data={requestData}
                    // dataSubService={requestData.tasks}
                    branchName={requestData.branchName}
                    // providerData={requestData?.reply?.serviceProviderId}
                    reply={requestData?.reply}
                    mainData={requestData?.reply}
                    startDateVal={requestData?.startDate}
                    endDateVal={requestData?.endDate}
                  />
                </div>
                {requestData?.status === APPROVED_ORDER ||
                  requestData?.status === ACCEPTED_ORDER ||
                  requestData?.status === REQUESTED_ORDER ? (
                  <div className="sticky-btn">
                    {/* {requestData?.status === APPROVED_ORDER || requestData?.status === ACCEPTED_ORDER || requestData?.status === REQUESTED_ORDER ? */}
                    <Button
                      color="danger"
                      className="cu-btn br20 min200 ms-2"
                      // block
                      onClick={() => {
                        setModal(true),
                          setShowFeesCancelText(
                            checkTimeGap(requestData?.updatedAt)
                          );
                      }}
                    >
                      {t("btnLabel.cancelOrder")}
                    </Button>
                    {/* : null} */}
                    {requestData?.status === REQUESTED_ORDER && replyId ? (
                      <Button
                        color="main"
                        className="cu-btn br20 min200"
                        // block
                        onClick={() => {
                          sendProposalHandler();
                        }}
                      >
                        {t("rfp.details.approveProposal")}
                      </Button>
                    ) : null}
                  </div>
                ) : null}
              </Container>
            </section>

            {/* Common Footer */}
            <Footer />
          </div>
        </div>
      </div>
      <ReplyModal
        modal={replyModal}
        toggleModal={() => setReplyModal(!replyModal)}
      />
      {/* Gallery Modal */}
      <GalleryModal
        open={galleryModal}
        toggleModal={() => setGalleryModal(!galleryModal)}
        gallaryData={galleryItem}
      />
      {/* Video preview modal */}
      <VideoModal
        open={videoModal}
        toggleModal={() => setVideoModal(!videoModal)}
        videoInfo={videoDetails}
      />
      <ProviderModal
        modal={providerModal}
        toggleModal={() => setProviderModal(!providerModal)}
        isShowProvider={(val) => setSingleProvider(val)}
        isLoading={isLoading}
        dataValue={providerDetails}
        fromRfp={true}
        showConatct={requestData?.status === REQUESTED_ORDER}
      />
      <ConfirmActionModal
        modal={modal}
        toggleModal={() => setModal(!modal)}
        onContinue={() => {
          setModal(false), handleCancelOrder(rfpId);
        }}
        titleText={
          showFeesCancelText && requestData?.status !== REQUESTED_ORDER
            ? t("btnLabel.feesText", {
              fees: problemLimits?.orderCancelFees,
            })
            : t("btnLabel.confirmText")
        }
        succesBtnText={t("btnLabel.continue")}
        rejectBtnText={t("btnLabel.cancel")}
        icon={<CancelIcon />}
      />
    </>
  );
};

export default RfpDetailsView;

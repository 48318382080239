import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { CloseIcon } from "../../assets/images/svg";
import { useNavigate } from "react-router-dom";

function ExpressSendModal({
  modal,
  toggleModal,
  onContinue,
  icon,
  areYouSureYouNeedTheServiceProviderNow,
  scheduleForLater,
  yes,
  categoryId,
  subCategoryId,
  data,
}) {
  const navigate = useNavigate();
  return (
    <>
      <Modal
        backdropClassName="opw1"
        isOpen={modal}
        toggle={() => {
          toggleModal();
        }}
        backdrop="static"
        className="preview-model"
        centered
        contentClassName="border-green"
      >
        <ModalBody className="">
          <div
            className="close-icon diff "
            onClick={() => {
              toggleModal();
            }}
          >
            <CloseIcon />
          </div>
          {icon ? (
            <img src={icon} className="text-center my-3 d-flex m-auto" />
          ) : null}
          <p className="text-center fs-5 mb-4">
            {areYouSureYouNeedTheServiceProviderNow}
          </p>
          <div className="d-flex gap-2 justify-content-center">
            <Button
              color="main-outline"
              className="cu-btn br12 min150"
              type="button"
              onClick={() => {
              
                navigate(
                    `/direct-award-details?categoryId=${categoryId}&subcategoryId=${subCategoryId}`, {
                      state: {
                        payloadData: data
                      }
                    }
                  );
              }}
            >
              {scheduleForLater}
            </Button>
            <Button
              color="main"
              className="cu-btn br12 min150"
              type="button"
              onClick={() => {
                onContinue();
              }}
            >
              {yes}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ExpressSendModal;

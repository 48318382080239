/*
 * File: loginReducer.js
 * Author: Harsh Chauhan
 * Created Date: November 2nd, 2022
 * Last Modified Date: November 2nd, 2022
 * Description: This file contains the login reducer.
 */


import {
  LOGIN_WITH_EMAIL,
  LOGIN_WITH_JWT,
  LOGOUT_WITH_JWT,
  CHANGE_ROLE,
  SET_LOGIN_FLAG,
  FORGET_PASS,
} from '../../actions/types';

export const login = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_WITH_EMAIL: {
      return { ...state, values: action.payload };
    }
    case LOGIN_WITH_JWT: {
      return { ...state, values: action.payload };
    }
    case LOGOUT_WITH_JWT: {
      return { ...state, values: action.payload };
    }
    case CHANGE_ROLE: {
      return { ...state, userRole: action.payload.data };
    }
    case SET_LOGIN_FLAG: {
      return { ...state, loginFlag: action.payload.data };
    }
    case FORGET_PASS: {
      return { ...state, values: action.payload };
    }
    default: {
      return state;
    }
  }
};

import { useState, useEffect } from "react";

//* Multilangual
import { useTranslation } from "react-i18next";

import "../../pages/Service/Service.scss";
import { ChevronRight } from "react-feather";
import { useDispatch } from "react-redux";

import NoDataFound from "../common/NoDataFound";
import DummyCircle from "../../assets/images/icon/user.png";
import { getCategoryApi } from "../../redux/actions/profile/profileAction";

const CategorySubCategorySelect = ({ setCategoryId, setSubCategoryId }) => {
  //* Multilangual
  const { t, i18n } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [showSubService, setSubService] = useState(false);
  const [active, setActive] = useState(null);
  const [serviceData, setServiceData] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setsubCategoryData] = useState([]);

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();

  //* Updates category dropdown and clears sub-service selection when language changes.
  useEffect(() => {
    getCategoryDropdown();
    setSubService(false);
  }, [i18n.language]);

  /**
   * Function for get all category from API
   */
  const getCategoryDropdown = async () => {
    setLoading(true);
    const res = await dispatch(getCategoryApi());
    if (!res.data.error) {
      let temp = [...res.data.data];
      const finalCategoryData = temp.filter(
        (item) => item.subcategory && item.subcategory.length
      );

      setCategoryData([...finalCategoryData]);
    }
    setLoading(false);
  };

  /**
   * on category select handler
   * @param {obj} item
   * @param {string} index
   */
  const serviceClickHandler = (item, index) => {
    setSubService(true);
    setActive(index);
    setServiceData(item);
    setCategoryId(item.id);
    const temp = [...categoryData];
    const subData = [];
    temp.map((obj) => {
      if (obj.id === item.id) {
        item.subcategory.map((subTrans) => {
          let subCategoryData = {
            ...subTrans.translation,
            icon: subTrans.icon,
          };
          subData.push(subCategoryData);
        });
        setsubCategoryData([...subData]);
      }
    });
  };

  /**
   * on sub-category select handler
   */
  const onSubServiceClick = (id) => {
    setSubCategoryId(id);
  };

  return (
    <div className="service-section-data">
      {!isLoading ? (
        <div className="service-left-data w-100 pe-0 mb-4">
          <div className="head-data">
            <h3>{t("service.title")}</h3>
          </div>
          <div className={`inner-service-data ${showSubService ? "" : "full"}`}>
            <div className="service-listing">
              <ul>
                {categoryData.map((item, index) => {
                  return (
                    <li key={index}>
                      <div
                        onClick={() => {
                          serviceClickHandler(item, index);
                        }}
                        className={`service-main-card ${active === index ? "active" : ""
                          }`}
                      >
                        <div
                          className="one"
                          style={{
                            background: `url(${item?.icon || DummyCircle})`,
                          }}
                        ></div>

                        <div className="two">
                          <span>{item?.translation?.value}</span>
                        </div>
                        <div className="three">
                          <ChevronRight />
                        </div>
                      </div>
                      {showSubService && active === index && (
                        <div className="d-block d-md-none">

                          <div className="service-inner-listing">
                            {subCategoryData && subCategoryData.length ? (
                              <ul>
                                {subCategoryData.map((item, i) => {
                                  return (
                                    <li key={i}>
                                      <div
                                        onClick={() => onSubServiceClick(item.categoryId)}
                                        className="service-inner-list-card"
                                      >
                                        <div
                                          className="one"
                                          style={{
                                            background: `url(${item?.icon || DummyCircle})`,
                                          }}
                                        ></div>
                                        <div className="two">
                                          <span>{item?.value}</span>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <NoDataFound
                                textData={t("noDataFound.subService")}
                                shadow={true}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            {showSubService && (
              <div className="service-inner-listing d-none d-md-block">
                {subCategoryData && subCategoryData.length ? (
                  <ul>
                    {subCategoryData.map((item, i) => {
                      return (
                        <li key={i}>
                          <div
                            onClick={() => onSubServiceClick(item.categoryId)}
                            className="service-inner-list-card"
                          >
                            <div
                              className="one"
                              style={{
                                background: `url(${item?.icon || DummyCircle})`,
                              }}
                            ></div>
                            <div className="two">
                              <span>{item?.value}</span>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <NoDataFound
                    textData={t("noDataFound.subService")}
                    shadow={true}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="service-left-data">
          <div className=" cu-shimmer h-100 br20"></div>
        </div>
      )}
    </div>
  );
};

export default CategorySubCategorySelect;

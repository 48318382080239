/*
 * File: PageAlert.js
 * Author: Harsh Chauhan
 * Created Date: January 10th, 2023
 * Last Modified Date: January 10th, 2023
 * Description: This code snippet creates a component that displays a message and a button on a web page, with the ability to translate the text dynamically.
 */
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

const PageAlert = () => {
  const { t } = useTranslation();
  return (
    <div className="new-tab">
      <div className="new-tab-box text-center">
        <p>{t("alertBox.paraText")}</p>
        <Button
          type="button"
          className="cu-btn cu-btn br15 min150"
          color="main"
          onClick={() => {
            window.location.reload();
          }}
        >
          {t("alertBox.btnText")}
        </Button>
      </div>
    </div>
  );
};

export default PageAlert;

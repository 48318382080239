/*
 * File: Header.js
 * Author: Harsh Chauhan
 * Created Date: November 3rd, 2022
 * Last Modified Date: January 31st, 2024
 * Description: This code snippet represents a header component with navigation functionality and dynamic text translations.
 */
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap";
//* Multilangual
import { useTranslation } from "react-i18next";

//* Images scss
import Logo from "../../assets/images/logo/dark-logo.svg";

//* LanguagePicker
import LanguagePicker from "./LanguagePicker";
import Notification from "./Notification";
import ProfileDropdown from "./ProfileDropdown";
import ContactUsModel from "../modal/ContactUsModal";
import { useSelector } from "react-redux";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [modal, setModal] = useState(false)

  //* Multilangual
  const { t } = useTranslation();

  //* Retrieve the value of isRfpNotification from the Redux store
  const isRfpNotification = useSelector((state) => state.notification.isRfpNotification)

  //* Retrieve the value of isMyOrdersNotification from the Redux store
  const isMyOrdersNotification = useSelector((state) => state.notification.isMyOrdersNotification)
  
  return (
    <>
      <header>
        {isOpen && <div className="overlay-header" onClick={toggle}></div>}
        <Navbar
          expand="lg"
          container="lg"
          className={`${isOpen ? " header-visibility" : ""}`}
          onClick={() => setIsOpen(false)}
        >
          <Link className="navbar-brand" to="/home">
            <img src={Logo} className="img-fluid main-logo" alt="images" />
          </Link>
          <ul className="mobile-list align-items-center">
            <Notification />
            <LanguagePicker />
            <ProfileDropdown />
            <li>
              <NavbarToggler
                onClick={(e) => {
                  e.stopPropagation();
                  toggle();
                }}
              />
            </li>
          </ul>

          <Collapse isOpen={isOpen} navbar>
            <Nav className="first" navbar>
              <NavItem>
                <NavLink className="nav-link" to="/home">
                  {t("header.homeText")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link position-relative" to="/my-orders">
                  {t("header.myOrdersText")}
                  {isMyOrdersNotification ? <div className="badge"></div> : null}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link position-relative" to="/rfps">
                  {t("header.rfpsText")}
                  {isRfpNotification ? <div className="badge"></div> : null}
                </NavLink>
              </NavItem>
              <NavItem>
                <span className="nav-link pointer" onClick={() => setModal(true)}>
                  {t('landing.contact')}
                </span>
              </NavItem>
            </Nav>
            <Nav className="second navbar-nav align-items-center">
              <Notification />
              <LanguagePicker />
              <ProfileDropdown />
            </Nav>
          </Collapse>
        </Navbar>
      </header>
      <ContactUsModel
        modal={modal}
        toggleModal={() => setModal(!modal)}
      />
    </>
  );
};

export default Header;

import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useNavigate } from "react-router-dom";
import email from '../../assets/images/icon/icon_email.svg'
import phone from '../../assets/images/icon/icon_mobile (1).svg'
// Multilangual
import { useTranslation } from "react-i18next";
const ContactUsModel = ({ modal, toggleModal }) => {
    const closeBtn = (
        <button className='close position-absolute' onClick={toggleModal} type='button'>
            &times;
        </button>
    )
    // Multilangual
    const { t, i18n } = useTranslation();
    //* Hook for navigation
    const navigate = useNavigate();
    return (
        <>
            <Modal
                backdropClassName="opw1"
                isOpen={modal}
                toggle={() => {
                    toggleModal();
                }}
                backdrop="static"
                keyboard={false}
                className="contact-modal position-relative"
                centered
            >
                <ModalHeader className="d-flex align-align-items-baseline" close={closeBtn}>
                    <h2>{t('contactModel.title')} </h2>
                </ModalHeader>
                <ModalBody >
                    <div>
                        <div className="d-flex flex-column ps-4">
                            <div className="d-flex align-items-center mb-10 gap-1">
                                <img src={phone} alt="email" />
                                <span className="ms-2">{t('contactModel.phone')} </span>
                            </div>
                            <div className="d-flex align-items-center mb-10 gap-1">
                                <img src={phone} alt="email" className="op0" />
                                <a href="tel:00962770305002" className="ms-2">00962770305002</a>
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex flex-column ps-4">
                            <div className="d-flex align-items-center mb-10  gap-1">
                                <img src={email} alt="email" className="mt-1" />
                                <span className="ms-2">{t('contactModel.email')}</span>
                            </div>

                            <div className="d-flex align-items-center mb-10 gap-1">
                                <img src={email} alt="email" className="op0" />
                                <a href="mailto:info@ejadapp.com" className="ms-2">info@ejadapp.com</a>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};
export default ContactUsModel;

/*
 * File: serviceAction.js
 * Author: Harsh Chauhan
 * Created Date: November 15th, 2022 
 * Last Modified Date: March 12th, 2024 
 * Description: This file contains the service actions.
 */

import { api } from "../../../api/api";
import {
  BRANCH_GET,
  CANCEL_DIRECT_ORDER,
  GET_DIRECT_REQUEST_ORDER,
  PROVIDER_GET,
  SEND_APPROVE_PROVIDER_REQUEST,
  SEND_PROVIDER_REQUEST,
  SINGLE_PROVIDER_GET,
  GET_PROBLEM_DESCRIPTION_LIMITATIONS,
} from "../../../constants/ApiUrl";

/**
 * This API is get all the branches as per we created in profile page and signup page.
 * @returns
 */
export const getBranchNameApi = () => {
  return async () => {
    const response = await api(BRANCH_GET, {}, null);
    return response;
  };
};

/**
 * This API is get all provider list in talzeem service details page.
 * @param {*} data
 * @returns
 */
export const getProviderListApi = (data) => {
  return async () => {
    const response = await api(PROVIDER_GET, {}, null, data);
    return response;
  };
};
/**
 * This API is get particualar selected service provider details afrer hired.
 * @param {*} data
 * @returns
 */
export const getSingleProviderApi = (data) => {
  return async () => {
    const response = await api(SINGLE_PROVIDER_GET, {}, data);
    return response;
  };
};

/**
 * This API is used for send the request to service provider for perticular service.
 * @param {*} data
 * @returns
 */
export const sendProviderRequest = (data) => {
  return async () => {
    const response = await api(SEND_PROVIDER_REQUEST, data);
    return response;
  };
};

/**
 * This API is used for send the request for approvel for accepting order.
 * @param {*} data
 * @returns
 */
export const sendApproveProviderRequest = (data) => {
  return async () => {
    const response = await api(SEND_APPROVE_PROVIDER_REQUEST, data);
    return response;
  };
};

// This api returns active and recent direct orders in my orders page
export const getDirectAwardOrder = (data) => {
  return async () => {
    const response = await api(GET_DIRECT_REQUEST_ORDER, {}, null, data);
    return response;
  };
};

/**
 * Retrieves problem limitation data from the API.
 *
 * @param {Object} data - The data needed to retrieve the problem limitation data.
 * @return {Promise} A promise that resolves with the response from the API call.
 */
export const getProblemLimitationData = (data) => {
  return async () => {
    const response = await api(
      GET_PROBLEM_DESCRIPTION_LIMITATIONS,
      {},
      null,
      data
    );
    return response;
  };
};

/**
 * Cancels a direct order by making an API call.
 *
 * @param {Object} data - The data needed to cancel the direct order.
 * @return {Promise} A promise that resolves with the response from the API call.
 */
export const cancelDirectOrder = (data) => {
  return async () => {
    const response = await api(CANCEL_DIRECT_ORDER, data);
    return response;
  };
};

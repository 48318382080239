/*
 * File: deviceReducer.js
 * Author: Harsh Chauhan
 * Created Date: November 28th, 2022 
 * Last Modified Date: November 28th, 2022 
 * Description: This file contains the device reducer.
 */

import { DEVICE_TOKEN } from "../../constants/appConstants";

const initState = {
  device: "",
};
//reducer for email
const deviceReducer = (state = initState, action) => {
  switch (action.type) {
    case DEVICE_TOKEN:
      return { ...state, device: action.payload };
    default: {
      return state;
    }
  }
};
export default deviceReducer;

import React from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

export default function ServiceLeftShimmer() {
  return (
    <>
      <ShimmerThumbnail className="h100 br20 mb20" rounded />
      <ShimmerThumbnail className="h40 br20 mb20" rounded />
      <ShimmerThumbnail className="h100 br20 mb20" rounded />
      <ShimmerThumbnail className="h100 br20 mb20" rounded />
      <ShimmerThumbnail className="h100 br20 mb20" rounded />
      <ShimmerThumbnail className="h100 br20 mb20" rounded />
      <ShimmerThumbnail className="h40 br20 mb20" rounded />
    </>
  );
}

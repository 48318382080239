import { t } from "i18next";
import i18n from "../i18n/i18n";
import {
  AcceptedIcon,
  CancelledIcon,
  CompletedIcon,
  CustomerApprovalIcon,
  FinishedIcon,
  OnTheWayIcon,
  OpenIcon,
  RejectedIcon,
  UnderProgressIcon,
} from "../assets/images/svg";
import { BsCheckAll } from "react-icons/bs";

export const TOAST_AUTO_CLOSE = 3000;
export const LOGIN_ROUTE = "/landing";

export const VALID_EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const VALID_PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;
export const INVALID_NUMBER_INPUT = /e|\+|\.|-/i;
export const INVALID_DECIMAL_INPUT = /e|\+|-/i;
export const COMPANY_NAME_MAX_LENGTH = 50;
export const PHONE_NUMBER_MAX_LENGTH = 10;
export const COMPANY_PERSON_MAX_LENGTH = 50;
export const BRANCH_NAME_MAX_LENGTH = 50;
export const DESCRIPTION_MAX_LENGTH = 150;
export const AREA_MAX_LENGTH = 20;
export const CUSTOMER_NAME_MAX_LENGTH = 50;
export const SPACE_RULES = /^[^ ]/;

export const FILE_MAX_LIMIT = 5;
export const FILE_SIZE_LIMIT = 20000000;
export const INDIVIDUAL_FILE_SIZE_LIMIT = 5000;

export const ACCEPT_IMAGE = ["image/png", "image/jpeg", "application/pdf"];
export const ACCEPT_ONLY_IMAGE = ["image/png", "image/jpeg"];
export const ACCEPT_IMAGE_AND_VIDEO = ["image/png", "image/jpeg", "video/mp4"];
export const ACCEPT_DOC = ["application/pdf"];
export const VALID_PROFILE_IMAGE_EXTENSIONS = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/svg+xml",
];
export const GET_PROFILE_DATA = "GET_PROFILE_DATA";
export const GET_SERVICE_DATA = "GET_SERVICE_DATA";
export const GET_LOGIN_DATA = "GET_LOGIN_DATA";

export const libraries = ["places"];

export const ACCEPT_REQUEST = "ACPT";

export const APPROVED_REQUEST = "APRD";

export const DEVICE_TOKEN = "DEVICE_TOKEN";
export const MEDIA_TYPE_PDF = "P";
export const MEDIA_TYPE_VIDEO = "V";
export const MEDIA_TYPE_IMAGE = "I";

//RFP STATUS
export const RFP_REPLY_TYPE = new Map();
RFP_REPLY_TYPE.set("REQD", i18n.t("message.requested"));
RFP_REPLY_TYPE.set("P", i18n.t("message.pending"));
RFP_REPLY_TYPE.set("S", i18n.t("message.sent"));
RFP_REPLY_TYPE.set("SUCC", i18n.t("message.success"));
RFP_REPLY_TYPE.set("CLSE", i18n.t("message.close"));
RFP_REPLY_TYPE.set("DONE", i18n.t("message.done"));
RFP_REPLY_TYPE.set("CNCL", i18n.t("message.cancelled"));

export const RFP_STATUS_DONE = "DONE";

export const TALZEEM_DONE = "talzeemServiceDone";
export const RFP_DONE = "rfpDone";
export const EXPRESS_DONE = "expressServiceDone";

// FOR NOTIFICATION TYPE HANDLING
export const NOTIFICATION_TYPE = [

  //talzeem service notification
  {
    type: "talzeemServiceApproved",
    link: "direct-award-reply?serviceId",
  },
  // { type: "talzeemServiceDone", link: "my-orders?serviceId" },
  { type: "talzeemServiceUnderProgress", link: "direct-award-reply?serviceId" },
  { type: "talzeemServiceOnTheWay", link: "direct-award-reply?serviceId" },
  { type: "talzeemEstimatePaymentDone", link: "direct-award-reply?serviceId" },
  { type: "talzeemServiceWaitingPayment", link: "direct-award-reply?serviceId" },
  { type: "talzeemServiceDone", link: "payment?serviceId" },
  { type: "talzeemServiceCancelled", link: "my-orders?serviceId" },
  { type: "talzeemServiceRejected", link: "my-orders?serviceId" },
  { type: "talzeemEstimatePaymentRejected", link: "my-orders?serviceId" },
  { type: "talzeemEstimatePaymentDone", link: "direct-award-reply?serviceId" },
  { type: "talzeemOrderFinished", link: "direct-award-reply?serviceId" },

  //express service notification
  {
    type: "expressServiceApproved",
    link: "express-reply?expressId",
  },
   { type: "expressServiceOnTheWay", link: "express-reply?expressId" },
  { type: "expressServiceUnderProgress", link: "express-reply?expressId" },
  { type: "expressOrderFinished", link: "express-reply?expressId" },
  { type: "expressServiceDone", link: "payment?serviceId" },
  { type: "expressServiceCancelled", link: "my-orders?expressId" },
  { type: "expressServiceRejected", link: "my-orders?expressId" },
  { type: "expressServiceWaitingPayment", link: "express-reply?expressId" },
  { type: "expressEstimatePaymentRejected", link: "express-reply?expressId" },
  { type: "expressEstimatePaymentDone", link: "express-reply?expressId" },
  
  //rfp service notification
  // { type: "rfpReplied", link: "rfps-reply?rfpId" },
  { type: "rfpServiceOnTheWay", link: "rfps-details?rfpId" },
  { type: "rfpServiceUnderProgress", link: "rfps-details?rfpId" },
  { type: "rfpReplied", link: "rfps-details?rfpId", subParams: '&replyId' },
  { type: "RfpEstimatePaymentDone", link: "rfps-details?rfpId" },
  {type: "rfpServiceWaitingPayment", link: "rfps-details?rfpId"},
  { type: "rfpOrderFinished", link: "rfps-details?rfpId" },
  
  // { type: "rfpDone", link: "rfps?rfpId" },
  // { type: "rfpDone", link: "payment?serviceId" },
  { type: "rfpDone", link: "rfps-details?rfpId", subParams: '&replyId' },
  // { type: "rfpReplied", link: "rfps-details?rfpId",subParams:'&replyId' },
  
  { type: "orderCancelByAdmin", link: "express-reply?expressId" || "direct-award-reply?serviceId" || "rfp-details?rfpId" },
  { tyep: "orderCancelByCustomer", link: "express-reply?expressId" || "direct-award-reply?serviceId" || "rfp-details?rfpId" },
];

//MY ORDER STATUS
export const REQUESTED_ORDER = "REQD";
export const APPROVED_ORDER = "APRD";
export const ACCEPTED_ORDER = "ACPT";
export const NOT_ACCEPTED_ORDER = "NACP";
export const CONFIRMED_ORDER = "CONFIRMED";

export const DONE_ORDER = "DONE";
export const CANCEL_ORDER = "CNCL";
export const CANCEL_BY_CUSTOMER = "CBC";
export const CANCEL_BY_ADMIN = "CBA";
export const REJECTED_ORDER = "REJT";
export const FINISHED_ORDER = "FSD"
export const UNDER_PROGRESS_ORDER = "UP"
export const WAITING_PAYMENT = "WPAY"
export const ON_THE_WAY_ORDER = "OTW"
export const CLOSE_ORDER = "CLSE"
export const RECEIVING_PROPOSAL = 'receivingProposal'

export const QUESTION_TYPE_GENERAL = "G";
export const QUESTION_TYPE_CATEGORY = "C";
export const QUESTION_TYPE_TEXT = "T";
export const QUESTION_TYPE_MULTI = "MC";
export const QUESTION_TYPE_SINGLE = "SC";

export const LANGUAGE_EN = "en";
export const LANGUAGE_AR = "ar";
export const LANGUAGE_FR = "fr";
export const DIRECT_SERVICE = "DA";

export const RFP_ACTIVE_STATUS_OPTIONS = [
  {
    value: REQUESTED_ORDER,
    label: t("myOrder.requestedText"),
  },
  {
    value: ACCEPTED_ORDER,
    label: t("myOrder.acceptedText"),
  },
  {
    value: APPROVED_ORDER,
    label: t("myOrder.approvedText"),
  },
  {
    value: ON_THE_WAY_ORDER,
    label: t("myOrder.onTheWay"),
  },
  {
    value: UNDER_PROGRESS_ORDER,
    label: t("myOrder.underProgress"),
  },
];
export const RFP_RECENT_STATUS_OPTIONS = [
  {
    value: DONE_ORDER,
    label: t("myOrder.doneText"),
  },
  {
    value: CANCEL_ORDER,
    label: t("myOrder.cancelByServiceProvider"),
  },
  {
    value: CANCEL_BY_CUSTOMER,
    label: t("myOrder.cancelByCustomer"),
  },
  {
    value: CANCEL_BY_ADMIN,
    label: t("myOrder.cancelByAdmin"),
  },
  {
    value: REJECTED_ORDER,
    label: t("myOrder.rejectedText"),
  },
  {
    value: FINISHED_ORDER,
    label: t("myOrder.finished"),
  },
  {
    value: CLOSE_ORDER,
    label: t("myOrder.close"),
  },
];
export const DIRECT_ACTIVE_STATUS_OPTIONS = [
  {
    value: REQUESTED_ORDER,
    label: t("myOrder.requestedText"),
  },
  {
    value: ACCEPTED_ORDER,
    label: t("myOrder.acceptedText"),
  },
  {
    value: APPROVED_ORDER,
    label: t("myOrder.approvedText"),
  },
  {
    value: ON_THE_WAY_ORDER,
    label: t("myOrder.onTheWay"),
  },
  {
    value: UNDER_PROGRESS_ORDER,
    label: t("myOrder.underProgress"),
  },
];
export const EXPRESS_ACTIVE_STATUS_OPTIONS = [
  {
    value: REQUESTED_ORDER,
    label: t("myOrder.requestedText"),
  },
  {
    value: APPROVED_ORDER,
    label: t("myOrder.approvedText"),
  },
  {
    value: ON_THE_WAY_ORDER,
    label: t("myOrder.onTheWay"),
  },
  {
    value: UNDER_PROGRESS_ORDER,
    label: t("myOrder.underProgress"),
  },
];
export const EXPRESS_RECENT_STATUS_OPTIONS = [
  {
    value: DONE_ORDER,
    label: t("myOrder.doneText"),
  },
  {
    value: CANCEL_ORDER,
    label: t("myOrder.cancelByServiceProvider"),
  },
  {
    value: CANCEL_BY_CUSTOMER,
    label: t("myOrder.cancelByCustomer"),
  },
  {
    value: CANCEL_BY_ADMIN,
    label: t("myOrder.cancelByAdmin"),
  },
  {
    value: REJECTED_ORDER,
    label: t("myOrder.rejectedText"),
  },
  {
    value: FINISHED_ORDER,
    label: t("myOrder.finished"),
  },
];
export const DIRECT_RECENT_STATUS_OPTIONS = [
  {
    value: DONE_ORDER,
    label: t("myOrder.doneText"),
  },
  {
    value: CANCEL_ORDER,
    label: t("myOrder.cancelByServiceProvider"),
  },
  {
    value: CANCEL_BY_CUSTOMER,
    label: t("myOrder.cancelByCustomer"),
  },
  {
    value: CANCEL_BY_ADMIN,
    label: t("myOrder.cancelByAdmin"),
  },
  {
    value: REJECTED_ORDER,
    label: t("myOrder.rejectedText"),
  },
  {
    value: FINISHED_ORDER,
    label: t("myOrder.finished"),
  },
];

export const SORTING_OPTIONS = [
  {
    value: "ASC",
    label: t("rfp.filterModal.SortAsc"),
  },
  {
    value: "DESC",
    label: t("rfp.filterModal.SortDsc"),
  },
];
// filter model options
export const STATUS_OPTIONS = new Map();
STATUS_OPTIONS.set("expressRecent", EXPRESS_RECENT_STATUS_OPTIONS);
STATUS_OPTIONS.set("expressActive", EXPRESS_ACTIVE_STATUS_OPTIONS);
STATUS_OPTIONS.set("directRecent", DIRECT_RECENT_STATUS_OPTIONS);
STATUS_OPTIONS.set("directActive", DIRECT_ACTIVE_STATUS_OPTIONS);
STATUS_OPTIONS.set("rfpActive", RFP_ACTIVE_STATUS_OPTIONS);
STATUS_OPTIONS.set("rfpRecent", RFP_RECENT_STATUS_OPTIONS);

export const ICONS_MAP = new Map()
ICONS_MAP.set(REQUESTED_ORDER, { icon: <OpenIcon />, text: t('myOrder.requestedText') })
ICONS_MAP.set(REJECTED_ORDER, { icon: <RejectedIcon />, text: t('myOrder.rejectedText') })
ICONS_MAP.set(ACCEPTED_ORDER, { icon: <AcceptedIcon />, text: t('myOrder.acceptedText') })
ICONS_MAP.set(APPROVED_ORDER, { icon: <AcceptedIcon />, text: t('myOrder.approvedText') })
ICONS_MAP.set(DONE_ORDER, { icon: <CompletedIcon />, text: t('myOrder.doneText') })
ICONS_MAP.set(ON_THE_WAY_ORDER, { icon: <OnTheWayIcon />, text: t('myOrder.onTheWay') })
ICONS_MAP.set(UNDER_PROGRESS_ORDER, { icon: <UnderProgressIcon />, text: t('myOrder.underProgress') })
ICONS_MAP.set(WAITING_PAYMENT, {
  icon: <UnderProgressIcon />,
  text: t("myOrder.underProgress"),
});
ICONS_MAP.set(CANCEL_BY_ADMIN, { icon: <CancelledIcon />, text: t('myOrder.cancelText') })
ICONS_MAP.set(CANCEL_BY_CUSTOMER, { icon: <CancelledIcon />, text: t('myOrder.cancelText') })
ICONS_MAP.set(CANCEL_ORDER, { icon: <CancelledIcon />, text: t('myOrder.cancelText') })
ICONS_MAP.set(FINISHED_ORDER, { icon: <FinishedIcon />, text: t('myOrder.finished') })
ICONS_MAP.set(CLOSE_ORDER, { icon: <FinishedIcon />, text: t('myOrder.close') })

//text editor toolbar options
export const  toolbarOptions = {
  options: [
    "inline",
    "textAlign",
    "link",
    "list",
    "history",
    "fontSize",
  ],
  inline: {
    inDropdown: false,
    options: ["bold", "underline"],
  },
  textAlign: {
    inDropdown: false,
    options: ["left", "center", "right", "justify"],
  },
  image: {
    uploadEnabled: true,
    previewImage: true,
    alt: {
      present: true,
      mandatory: false,
    },
  },
  link: {
    inDropdown: false,
    options: ["link"],
  },
  list: {
    inDropdown: false,
    options: ["unordered", "ordered"],
  },
  history: {
    inDropdown: false,
    options: ["undo", "redo"],
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72],
  },
};
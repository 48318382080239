import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody } from 'reactstrap';

function ConfirmActionModal({ modal, toggleModal, onContinue, titleText, icon, succesBtnText, rejectBtnText }) {
    // Multilangual
    const { t } = useTranslation();
    return (
        <>
            <Modal
                backdropClassName="opw1"
                isOpen={modal}
                toggle={() => {
                    toggleModal();
                }}
                backdrop="static"
                // keyboard={false}
                className="preview-model"
                centered
                contentClassName="border-green"
            >
                <ModalBody className=''>
                    {icon ? <div className='text-center my-3'>{icon}</div> : null}
                    <p className='text-center fs-5 mb-4'>{titleText}</p>
                    <div className='d-flex gap-2 justify-content-center'>
                        <Button
                            color="main-outline"
                            className="cu-btn br12 min150"
                            // block
                            type="button"
                            onClick={() => {
                                toggleModal();
                            }}
                        >
                            {rejectBtnText}
                        </Button>
                        <Button
                            color="main"
                            className="cu-btn br12 min150"
                            // block
                            type="button"
                            onClick={() => {
                                onContinue();
                            }}
                        >
                            {succesBtnText}
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default ConfirmActionModal








/*
 * File: Error.js
 * Author: Harsh Chauhan
 * Created Date: November 2nd, 2022
 * Last Modified Date: January 10th, 2023
 * Description: Error component which displays a 404 error page with a custom banner, a logo, and a button to navigate back to the home page.
 */
import React from "react";
import { Button } from "reactstrap";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Banner from "../../assets/images/custom/verify-banner.svg";
import Logo from "../../assets/images/logo/dark-logo.svg";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "react-feather";

function Error() {
  const { t } = useTranslation();
  //* Hook for navigation
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>EJAD - 404</title>
      </Helmet>
      <section className="full-auth-page">
        <div className="left-auth-data">
          <img src={Banner} className="img-fluid log-img" alt="images" />
          <div className="box-data">
            <div className="data">
              <h2 className="mb-0">{t("errorPage.headText")}</h2>
            </div>
          </div>
        </div>
        <div className="right-auth-data">
          <div className="data text-center">
            <img src={Logo} className="img-fluid log-img" alt="images" />
            <div className="text-data">
              <h1>{t("errorPage.paraText")}</h1>
            </div>
            <Button
              type="button"
              className="cu-btn min150 br20"
              color="main"
              onClick={() => navigate("/Home")}
            >
              <ChevronLeft size="18" />
              {t("btnLabel.backToHome")}
            </Button>
          </div>
        </div>
      </section>
    </>
  );
}

export default Error;

/*
 * File: notificationReducer.js
 * Author: Harsh Chauhan
 * Created Date: November 28th, 2022
 * Last Modified Date: February 8th, 2024 
 * Description: This file contains the notification reducer.
 */

import { SET_MYORDERS_UNREAD_FLAG, SET_NOTIFICATION, SET_REMOVE_NOTIFICATION, SET_RFPS_UNREAD_FLAG, SET_UNREAD_NOTIFICATION_DATA, SET_UNREAD_ORDERS_DATA } from "../actions/types";

const initState = {
  notifications: [],
  isRfpNotification: false,
  isMyOrdersNotification: false,
  unReadOrders: []
};
//reducer for email
const notificationReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION:
      return { ...state, notifications: [...state.notifications, action.payload] };
    case SET_REMOVE_NOTIFICATION:
      return { ...state, notifications: action.payload };
    case SET_MYORDERS_UNREAD_FLAG:
      return { ...state, isMyOrdersNotification: action.payload };
    case SET_RFPS_UNREAD_FLAG:
      return { ...state, isRfpNotification: action.payload };
    case SET_UNREAD_ORDERS_DATA:
      return { ...state, unReadOrders: action.payload };
    default: {
      return state;
    }
  }
};
export default notificationReducer;

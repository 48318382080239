/*
 * File: PublicRoute.js
 * Author: Harsh Chauhan
 * Created Date: November 2nd, 2022
 * Last Modified Date: December 28th, 2022
 * Description: Renders the children components if the user is logged in, otherwise redirects to the landing page.
 */
import FullLayout from "../layouts/FullLayout";

const PublicRoute = ({ children }) => {

  return <FullLayout>{children}</FullLayout>;
};

export default PublicRoute;

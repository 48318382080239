/*
 * File: rootReducer.js
 * Author: Harsh Chauhan
 * Created Date: November 2nd, 2022 
 * Last Modified Date: November 29th, 2022 
 * Description: This file contains the root reducer.
 */

// ** Redux Imports
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// ** Reducers Imports
import { login } from "./auth/loginReducer";
import deviceReducer from "./deviceReducer";
import notificationReducer from "./notificationReducer";
import profileReducer from "./profileReducer";

const persistConfig = {
  key: "ejad-root",
  storage,
  whitelist: ["login", "profileReducer", "deviceReducer"],
};

const rootReducer = combineReducers({
  login: login,
  profileReducer: profileReducer,
  notification: notificationReducer,
  device: deviceReducer,
});

export default persistReducer(persistConfig, rootReducer);

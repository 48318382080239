/*
 * File: Payment.js
 * Author: Ankit Pithiya
 * Created Date: December 7th, 2023
 * Last Modified Date: April 24th, 2024
 * Description: It is a page component for handling payment related operations.
 */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import CommonShimmer from "../../component/shimmer/commonShimmer";
import { Button, Container, Form } from "reactstrap";
import OrderSummary from "../../component/modal/service/OrderSummary";
import { getPerticularReqApi } from "../../redux/actions/home/homeAction";
import "./Payment.scss";
import { getReplyDetailsRfpApi } from "../../redux/actions/rfp/rfpAction";
import { getExpressPerticularReqApi } from "../../redux/actions/express/expressAction";
import Footer from "../../component/common/Footer";
import FormLabelInput from "../../component/Form/FormLabelInput";
import { CheckBoxIcon, RedCrossIcon } from "../../assets/images/svg";
import { PriceRequiredRules } from "../../constants/rules";
import { postSparePartsDetails } from "../../redux/actions/review/reviewAction";

const Payment = () => {
  
  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();

  //* MultiLangual
  const { t } = useTranslation();

   //* Get search parameters from URL
  const [searchParams] = useSearchParams();

  //* Initializing react-hook-form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "all" });

  //* State variables
  const [talzeemData, setTalzeemData] = useState({});
  const [rfpData, setRfpData] = useState({});
  const [expressData, setExpressData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [showServiceId, setServiceId] = useState("");
  const [isServiceType, setServiceType] = useState("");

  //* Hook for navigation
  const navigate = useNavigate();

  let isSparePartsIncluded =
    rfpData?.estimateIsSparePartNeeded ||
    talzeemData?.recent?.[0]?.estimateIsSparePartNeeded ||
    expressData?.estimateIsSparePartNeeded;
  let sparePartDescription =
    rfpData?.sparePartDescription ||
    talzeemData?.recent?.[0]?.sparePartDescription ||
    expressData?.sparePartDescription;

  //* Extracting service ID and type from URL params
  const serviceId = searchParams.get("serviceId");
  const serviceType = searchParams.get("type");
  useEffect(() => {
    if (serviceId) {
      setServiceId(serviceId);
      setServiceType(serviceType);
    }
  }, [serviceId]);

  /**
   * Get Talzeem Data
   * @param {*} id
   */
  const getTalzeemData = async (id) => {
    setLoading(true);
    const data = { id: id };
    let res = await dispatch(getPerticularReqApi(data));
    if (!res.data.error) {
      setTalzeemData({ ...res.data.data });
    }
    setLoading(false);
  };

  /**
   * Get RFP Data
   * @param {*} id
   */
  const getRfpData = async (id) => {
    setLoading(true);
    const data = { rfpId: id };
    let res = await dispatch(getReplyDetailsRfpApi(data));
    if (!res.data.error) {
      setRfpData({ ...res.data.data });
    }
    setLoading(false);
  };

  /**
   * Get Express Data
   * @param {*} id
   */
  const getExpressData = async (id) => {
    setLoading(true);
    if (id) {
      let res = await dispatch(getExpressPerticularReqApi(id));
      if (!res.data.error) {
        setExpressData({ ...res.data.data });
      }
    }
    setLoading(false);
  };

  //* Fetch data based on serviceType and serviceId
  useEffect(() => {
    if (isServiceType === "T") {
      getTalzeemData(showServiceId);
    } else if (isServiceType === "R") {
      getRfpData(showServiceId);
    } else {
      getExpressData(showServiceId);
    }
  }, [isServiceType, serviceId]);

   //* Reset form values based on fetched data
  useEffect(() => {
    setLoading(true);
    if (isServiceType === "T") {
      if (talzeemData?.recent?.length) {
        reset({
          totalAmountOfCustomer: talzeemData?.recent[0]?.totalAmountOfCustomer,
        });
      }
    } else if (isServiceType === "R") {
      reset({ totalAmountOfCustomer: rfpData?.totalAmountOfCustomer });
    } else if (isServiceType === "E") {
      reset({ totalAmountOfCustomer: expressData?.totalAmountOfCustomer });
    } else {
      reset();
    }
    setLoading(false);
    if (isDisable) {
      if (rfpData?.id) {
        navigate(`/service-review?serviceId=${rfpData.id}&type=R`);
      } else if (expressData?.id) {
        navigate(`/service-review?serviceId=${expressData?.id}&type=E`);
      } else if (talzeemData?.recent?.[0].id) {
        navigate(
          `/service-review?serviceId=${talzeemData?.recent?.[0].id}&type=T`
        );
      }
    }
  }, [talzeemData, rfpData, expressData, isServiceType]);

  const isDisable =
    (isServiceType === "T" &&
      talzeemData?.recent?.[0]?.totalAmountOfCustomer) ||
    (isServiceType === "R" && rfpData?.totalAmountOfCustomer) ||
    (isServiceType === "E" && expressData?.totalAmountOfCustomer);
  /**
   * Submits the form data and performs actions based on the form values.
   *
   * @param {Object} data - The form data containing the total amount of customer.
   * @return {Promise<void>} - A promise that resolves when the actions are completed.
   */
  const onFormSubmit = async (data) => {
    const payload = {
      serviceId,
      type: serviceType === "T" ? "DA" : serviceType,
      totalAmountOfCustomer: Number(data.totalAmountOfCustomer),
    };
    let res;
    if (!isDisable) res = await dispatch(postSparePartsDetails(payload));
    if (isDisable || !res.data.error) {
      if (rfpData?.id) {
        navigate(`/service-review?serviceId=${rfpData.id}&type=R`);
      } else if (expressData?.id) {
        navigate(`/service-review?serviceId=${expressData?.id}&type=E`);
      } else if (talzeemData?.active?.[0].id) {
        navigate(
          `/service-review?serviceId=${talzeemData?.active?.[0].id}&type=T`
        );
      }
    }
  };

  return (
    <div className="myorder-page common-design-section">
      <div className="common-design-section-data single-data">
        <div className="right-design-data">
          <section className="service-section reply-section">
            <Container fluid="lg">
              <div className="service-section-data">
                {!isLoading ? (
                  <div className="payment-details-left-data">
                    <div className="review-left-data">
                      <h2 className="heads-data">{t("payment.title")}</h2>
                      <Form
                        className="mb-0"
                        onSubmit={handleSubmit(onFormSubmit)}
                      >
                        <h4 className="title-input">
                          {t("payment.inputLabel")}
                          <span className="text-danger">*</span>{" "}
                        </h4>
                        <div className="inner-inpput mb20">
                          <FormLabelInput
                            type="number"
                            id="totalAmountOfCustomer"
                            placeholder={t("payment.placeholder")}
                            autoFocus={false}
                            register={register}
                            errors={errors}
                            name="totalAmountOfCustomer"
                            displayAsterisk={true}
                            rules={PriceRequiredRules}
                            sideText={t("payment.sideText")}
                            InputClassName="border-50"
                            disabled={isDisable}
                          />
                        </div>

                        <div className="spare-parts mb20">
                          {isSparePartsIncluded ? (
                            <div className="order-box mb20">
                              <p className="head-text">
                                <p className="head-text">
                                  <CheckBoxIcon />

                                  {t("newTextRFP.sparePartText1")}
                                </p>
                              </p>
                            </div>
                          ) : (
                            <div className="order-box mb20">
                              <p className="head-text">
                                <p className="head-text">
                                  <RedCrossIcon />
                                  &nbsp;&nbsp;
                                  {t("newTextRFP.sparePartText1")}
                                </p>
                              </p>
                            </div>
                          )}
                          <div>
                            <p className="description">
                              {sparePartDescription}
                            </p>
                          </div>
                        </div>
                        <div className="text-center my-4">
                          <Button
                            color="main"
                            className="cu-btn br20 min400 "
                            type="submit"
                          >
                            {t("payment.btnLabel")}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                ) : (
                  <div className="service-details-left-data text-center">
                    <CommonShimmer />
                  </div>
                )}
                {isServiceType === "T" && (
                  <>
                    <OrderSummary
                      isLoading={isLoading}
                      data={talzeemData?.active?.[0]?.categoryId?.name}
                      dataSubService={
                        talzeemData?.active?.[0]?.subCategoryId?.name
                      }
                      branchName={talzeemData?.active?.[0]?.branchName}
                      providerData={talzeemData?.active?.[0]?.serviceProviderId}
                      mainData={talzeemData?.active?.[0]}
                    />
                  </>
                )}

                {isServiceType === "R" && (
                  <>
                    <OrderSummary
                      isLoading={isLoading}
                      fromRfpSummary={true}
                      data={rfpData}
                      branchName={rfpData.branchName}
                      reply={rfpData?.reply}
                      mainData={rfpData?.reply}
                      startDateVal={rfpData?.startDate}
                      endDateVal={rfpData?.endDate}
                    />
                  </>
                )}
                {isServiceType === "E" && (
                  <>
                    <OrderSummary
                      isLoading={isLoading}
                      data={expressData?.categoryId?.name}
                      dataSubService={expressData?.subCategoryId?.name}
                      branchName={expressData?.branchName}
                      providerData={expressData?.serviceProviderId}
                      mainData={expressData}
                    />
                  </>
                )}
              </div>
            </Container>
          </section>

          {/* Common Footer */}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Payment;

import { Modal, ModalBody } from "reactstrap";
import { CloseIcon } from "../../../assets/images/svg";

const VideoModal = ({ open, toggleModal, videoInfo }) => {
  return (
    <Modal
      backdropClassName="opw1"
      isOpen={open}
      toggle={() => {
        toggleModal();
      }}
      backdrop="static"
      keyboard={false}
      className="provider-modal video-modal"
      centered
    >
      <ModalBody className="p-0">
        <div
          className="close-icon diff above"
          onClick={() => {
            toggleModal();
          }}
        >
          <CloseIcon />
        </div>
        <div className="video-preview">
          <video
            width="100%"
            height="100%"
            src={videoInfo.url}
            playsInline
            controls
            autoPlay
            controlsList="nodownload"
            preload
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default VideoModal;

import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { useNavigate } from "react-router-dom";

// Multilangual
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../../assets/images/svg";
const NoSpModel = ({ modal, toggleModal }) => {
    // Multilangual
    const { t, i18n } = useTranslation();
    //* Hook for navigation
    const navigate = useNavigate();
    return (
        <>
            <Modal
                backdropClassName="opw1"
                isOpen={modal}
                toggle={() => {
                    toggleModal();
                }}
                backdrop="static"
                keyboard={false}
                className="noSp-modal"
                centered
            >
                <ModalBody>
                    <div
                        className="close-icon diff"
                        onClick={() => {
                            toggleModal();
                        }}
                    >
                        <CloseIcon />
                    </div>
                    <div>
                        <p></p>
                        <p className="text-center mt-5">{t('noSpModal.mainText')}{' '}<a href="telto:+9620770305002">0770305002</a></p>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};
export default NoSpModel;

/*
 * File: ComingSoon.js
 * Author: Harsh Chauhan
 * Created Date: March 3rd, 2023
 * Last Modified Date: January 9th, 2024
 * Description: The component renders a footer element containing a paragraph with dynamic text fetched from translation resources, including the current year.
 */
import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";

const Footer = () => {
  //* Multilangual
  const { t } = useTranslation();
  return (
    <footer>
      <Container>
        <p>{t("footer.textData", { year: new Date().getFullYear() })}</p>
      </Container>
    </footer>
  );
};

export default Footer;

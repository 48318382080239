/*
 * File: SignupVerificationSuccess.js
 * Author: Harsh Chauhan
 * Created Date: November 6th, 2022
 * Last Modified Date: March 14th, 2024
 * Description: This component is responsible for displaying a success message and allowing the user to navigate to the login page after verifying their account.
 */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Button } from "reactstrap";
//* Images scss
import Logo from "../../assets/images/logo/dark-logo.svg";
import Banner from "../../assets/images/custom/verify-banner.svg";
//* page scss
import "./Authentication.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyAccountApi } from "../../redux/actions/auth/loginActions";

const SignupVerificationSuccess = () => {

  //* Hook for navigation
  const navigate = useNavigate();

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();

  //* Function to handle login redirection
  const onLoginClick = () => {
    navigate("/landing?is-login=true");
  };
   //* Get search parameters from URL
  const [searchParams] = useSearchParams();

  //* Extracting parameters from URL
  const id = searchParams.get("id");
  const token = searchParams.get("token");

  //* Effect to verify account on component mount
  useEffect(() => {
    verifyAccount();
  }, []);

  //* Function to verify account
  const verifyAccount = async () => {
    const res = await dispatch(verifyAccountApi({ id, token }));
    if (!res.data.error) {
    }
  };
  return (
    <>
      <Helmet>
        <title>EJAD - Sign-up Success</title>
      </Helmet>
      <section className="full-auth-page">
        <div className="left-auth-data">
          <img src={Banner} className="img-fluid log-img" alt="images" />
          <div className="box-data">
            <div className="data">
              <h2 className="mb-0">Sign-up</h2>
            </div>
          </div>
        </div>
        <div className="right-auth-data">
          <div className="data">
            <div className="text-center">
              <img src={Logo} className="img-fluid log-img" alt="images" />
            </div>
            <div className="text-data text-center">
              <p>Success</p>
            </div>
            <Button
              type="submit"
              color="secondary"
              className="cu-btn br20 w-100"
              onClick={onLoginClick}
            >
              Go to Login
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignupVerificationSuccess;

/*
 * File: TopRate.js
 * Author: Harsh Chauhan
 * Created Date: November 9th, 2022
 * Last Modified Date: February 9th, 2023
 * Description: This component fetches provider details from an API and renders them in a slider section.
 */
import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";

//* Rating
import Rating from "react-rating";

//* Slick slider
import Slider from "react-slick";

//* Images scss
import DummyCircle from "../../assets/images/icon/user.png";

import { Star } from "react-feather";
import { getTopServiceProviderApi } from "../../redux/actions/home/homeAction";
import { useDispatch } from "react-redux";

const TopRateSection = ({ title }) => {
  //* Slick slider
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    focusOnSelect: false,
    rtl: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [providerList, setProviderList] = useState([]);

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();
  //* Function for get provider details from API
  const getProviderDetails = async () => {
    let res = await dispatch(getTopServiceProviderApi());
    if (!res.data.error) {
      setProviderList(res?.data?.data);
    }
  };
  //* Effect to fetch Provider Details
  useEffect(() => {
    getProviderDetails();
  }, []);
  return (
    <>
      {/* Slider section */}
      {providerList && providerList.length > 0 && (
        <section className="slider-section">
          <Container fluid="lg">
            <h5>{title}</h5>
            <Slider {...settings}>
              {providerList.map((item, i) => {
                return (
                  <div className="slider-card" key={i}>
                    <div
                      className="slider-card-img"
                      style={{
                        background: `url(${item.profilePhoto || DummyCircle})`,
                      }}
                    ></div>
                    <div className="slider-card-body">
                      <h3>{item.name}</h3>
                      <div className="bottom-data">
                        <div className="star-data">
                          <Rating
                            className="user-select-none"
                            initialRating={item.rating}
                            emptySymbol={
                              <Star size={10} fill="#C8C8C8" stroke="#C8C8C8" />
                            }
                            fullSymbol={
                              <Star
                                size={10}
                                fill={"#FFD700"}
                                stroke={"#FFD700"}
                              />
                            }
                          />
                        </div>
                        <div className="comment-data">({item.total})</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </Container>
        </section>
      )}
    </>
  );
};

export default TopRateSection;

//* Multilangual
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const RfpOrderSummary = ({
  isLoading,
  branchName,
  startDate,
  endDate,
  categoryData,
}) => {
  let convertedStartDate = dayjs(startDate).unix();
  convertedStartDate = dayjs(convertedStartDate * 1000).format("DD-MM-YYYY");

  let convertedEndDate = dayjs(endDate).unix();
  convertedEndDate = dayjs(convertedEndDate * 1000).format("DD-MM-YYYY");

  //* Multilangual
  const { t } = useTranslation();
  return (
    <>
      {!isLoading ? (
        <div className="order-summary-data">
          <div className="head-data mb20">
            <h3>{t("OrderCard.titleWithoutNum")}</h3>
          </div>
          <div className="scroll-data">
            <div className="order-box mb20">
              {((startDate && startDate?.length !== 0) ||
                (endDate && endDate?.length !== 0)) && (
                <p className="head-text">{t("myOrder.dateTimeText")}</p>
              )}
              {((startDate && startDate?.length !== 0) ||
                (endDate && endDate?.length !== 0)) && (
                <p className="list-text list-text-color">
                  {startDate && startDate?.length !== 0 && (
                    <span className="d-block w-100">
                      {t("myOrder.startText1")} : {convertedStartDate}
                    </span>
                  )}
                  {endDate && endDate?.length !== 0 && (
                    <span className="d-block w-100">
                      {t("myOrder.endText1")} : {convertedEndDate}
                    </span>
                  )}
                </p>
              )}
            </div>
            {branchName && (
              <div className="order-box mb20">
                <p className="head-text">
                  {t("profile.branchData.branchTitle")}
                </p>
                <p className="list-text">{branchName}</p>
              </div>
            )}
            <div className="order-box mb20">
              {categoryData &&
                categoryData.length > 0 &&
                categoryData[0].categoryName && (
                  <p className="head-text">{t("service.title")}</p>
                )}
              {categoryData &&
                categoryData.length > 0 &&
                categoryData.map((item, index) => {
                  return (
                    <ul className="outer-ul" key={index}>
                      {item.categoryName && (
                        <li className="">
                          <p className="list-text">
                            {index + 1}.{"  "}
                            {item.categoryName} / {item.subCategory}
                          </p>
                        </li>
                      )}
                    </ul>
                  );
                })}
            </div>
          </div>
        </div>
      ) : (
        <div className="order-summary-shimmer cu-shimmer"></div>
      )}
    </>
  );
};

export default RfpOrderSummary;

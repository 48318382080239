//Login
export const LOGIN_WITH_EMAIL = 'LOGIN_WITH_EMAIL';
export const FORGET_PASS = 'FORGET_PASS';
export const SET_LOADER = 'SET_LOADER';
export const RESET_STATE = 'RESET_STATE';
export const LOGIN_WITH_JWT = 'LOGIN_WITH_JWT';
export const LOGOUT_WITH_JWT = 'LOGOUT_WITH_JWT';
export const CHANGE_ROLE = 'CHANGE_ROLE';
export const SET_LOGIN_FLAG = 'SET_LOGIN_FLAG';

export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_REMOVE_NOTIFICATION = 'SET_REMOVE_NOTIFICATION';
export const SET_RFPS_UNREAD_FLAG = 'SET_RFPS_UNREAD_FLAG';
export const SET_MYORDERS_UNREAD_FLAG = 'SET_MYORDERS_UNREAD_FLAG';
export const SET_UNREAD_ORDERS_DATA = 'SET_UNREAD_ORDERS_DATA';
// export const SET_UNREAD_NOTIFICATION_DATA = 'SET_UNREAD_NOTIFICATION_DATA';

export const SET_LOGOUT = 'SET_LOGOUT';


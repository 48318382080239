/*
 * File: profileReducer.js
 * Author: Harsh Chauhan
 * Created Date: November 14th, 2022
 * Last Modified Date: November 15th, 2022 
 * Description: This file contains the profile reducer.
 */

import { GET_PROFILE_DATA } from "../../constants/appConstants";

const initState = {
  profile: null,
};
//reducer for email
const profileReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_PROFILE_DATA:
      return { ...state, profile: action.payload.data };
    default: {
      return state;
    }
  }
};
export default profileReducer;


import { Input, InputGroup, InputGroupText, Label } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function FormLabelInput({
    label,
    type,
    id,
    placeholder,
    autoFocus,
    register,
    errors,
    name,
    rules,
    rows,
    disabled,
    InputClassName,
    sideText,
    labelClass,
    displayAsterisk
}) {
    const { ref: ref, ...rest } = register(name, rules);

    return (
        <>
            {label && (
                <Label
                    className={labelClass ? labelClass : "floating-label"}
                    htmlFor={id}
                >
                    {label} {"  "}
                    {displayAsterisk && <span className="text-danger">*</span>}
                </Label>
            )}
            <div className="cu-form-group floating-label-group">
                <InputGroup>
                    <Input
                        disabled={disabled}
                        autoComplete="off"
                        step='any'
                        type={type}
                        id={id}
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                        name={name}
                        {...rest}
                        rows={rows}
                        innerRef={ref}
                        className={InputClassName}
                        onWheel={(ev) => ev.target.blur()}
                        onKeyDown={(e) => {
                            if ((type === 'number') && (e.key === "ArrowUp" || e.key === "ArrowDown" || e.key === "+" || e.key === "-")) {
                                e.preventDefault();
                            }
                        }}
                    />
                    <InputGroupText className="fw-bold input-side-label outline-none">{sideText}</InputGroupText>
                </InputGroup>
            </div>

            <span className="error-msg">{errors[name]?.message}</span>
        </>
    );
}
/*
 * File: Map.js
 * Author: Harsh Chauhan
 * Created Date: November 7th, 2022
 * Last Modified Date: January 9th, 2024 
 * Description: This code snippet sets up a map container component with Google Maps integration and handles user interactions with the map.
 */
import React, { useCallback } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { libraries } from "../../constants/appConstants";
import MapPinCu from "../../assets/images/icon/map-pin.svg";
import { ShimmerThumbnail } from "react-shimmer-effects";

const MapContainer = ({
  latitude,
  longitude,
  setMapLat,
  setMapLong,
  setMap = () => { },
  setBranchDragIndex,
  setMainDragIndex = () => { },
  fromSubBranch,
  fromSignup,
  mapHeight,
  draggable,
  removeFocus = () => { }
}) => {

  //* Styles for the map container
  const mapStyles = {
    height: "100%",
    width: "100%",
    minHeight: mapHeight || "150px",
  };

  //* Default center for the map
  const defaultCenter = {
    lat: latitude,
    lng: longitude,
  };

  //* Loading Google Maps API script
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: libraries,
  });

  //* Callback function when map is loaded
  const onLoad = useCallback(function callback(map) {
    setMap(map)
  }, [])
  return (
    <>
      {isLoaded ? (
        <>
          {" "}
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={13}
            center={defaultCenter}
            onLoad={onLoad}
            onClick={(event) => {
              //* Get the clicked lat/lng
              const clickedLat = event.latLng.lat();
              const clickedLng = event.latLng.lng();

              //* Update the marker's position and the state
              if (fromSubBranch) {
                setBranchDragIndex();
              } else if (!fromSignup && !fromSubBranch) {
                setMainDragIndex();
              }
              setMapLong(clickedLng);
              setMapLat(clickedLat);
            }}
            onDragStart={() => removeFocus()}
          >
            <Marker
              position={defaultCenter}
              draggable={draggable}
              onDragStart={() => {
                if (fromSubBranch) {
                  setMapLong(''); setMapLat('')
                }
                removeFocus()
              }
              }
              onDragEnd={(val) => {
                if (fromSubBranch) {
                  setBranchDragIndex();
                } else if (!fromSignup && !fromSubBranch) {
                  setMainDragIndex();
                }
                setMapLong(val.latLng.lng());
                setMapLat(val.latLng.lat());
              }}
              icon={MapPinCu}
              cursor={"drag"}
            />
          </GoogleMap>
        </>
      ) : (
        <ShimmerThumbnail className="h150 br20 mb-0" rounded />
      )}
    </>
  );
};

export default MapContainer;

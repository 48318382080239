/*
 * File: Express.js
 * Author: Harsh Chauhan
 * Created Date: December 26th, 2022
 * Last Modified Date: January 15th, 2024
 * Description: The component renders a section with service listings, allowing users to click on services and sub-services, updating the UI accordingly. It also displays an order summary and a footer.
 */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";

//* Multilangual
import { useTranslation } from "react-i18next";

//* page scss
import "./Express.scss";
import "../Service/Service.scss";
import { Container } from "reactstrap";
import { ChevronRight } from "react-feather";
import OrderSummary from "../../component/modal/service/OrderSummary";
import { useNavigate } from "react-router-dom";
import { getCategoryApi } from "../../redux/actions/profile/profileAction";
import { useDispatch } from "react-redux";
import NoDataFound from "../../component/common/NoDataFound";
import DummyCircle from "../../assets/images/icon/user.png";
import Footer from "../../component/common/Footer";
import NoSpModel from "../../component/modal/service/NoSpModel";
import { getSpAvailabilityApi } from "../../redux/actions/rfp/rfpAction";

const Service = () => {
  //* Multilangual
  const { t, i18n } = useTranslation();
  
  //* Hook for Navigation
  const navigate = useNavigate();

  //* State variables
  const [isLoading, setLoading] = useState(false);
  const [showSubService, setSubService] = useState(false);
  const [active, setActive] = useState(null);
  const [showCategoryId, setCategoryId] = useState("");
  const [showSubCategoryId, setSubCategoryId] = useState("");
  const [serviceData, setServiceData] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setsubCategoryData] = useState([]);
  const [modal, setModal] = useState(false);

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();

  //* Updates category dropdown and clears sub-service selection when language changes.
  useEffect(() => {
    getCategoryDropdown();
    setSubService(false);
  }, [i18n.language]);

   //* Function for get all category from API
  const getCategoryDropdown = async () => {
    setLoading(true);
    const res = await dispatch(getCategoryApi());
    if (!res.data.error) {
      setCategoryData([...res.data.data]);
    }
    setLoading(false);
  };

  /**
   * Function is used for Service listing click management
   * @param {*} item
   * @param {*} index
   */
  const serviceClickHandler = (item, index) => {
    setSubService(true);
    setActive(index);
    setServiceData(item);
    setCategoryId(item.id);
    const temp = [...categoryData];
    const subData = [];
    temp.map((obj) => {
      if (obj.id === item.id) {
        item.subcategory.map((subTrans) => {
          let subCategoryData = {
            ...subTrans.translation,
            icon: subTrans.icon,
          };
          subData.push(subCategoryData);
        });
        setsubCategoryData([...subData]);
      }
    });
  };

  /**
   * Function is used for sub-service listing click management
   * @param {*} id
   */
  const onSubServiceClick = async (id) => {
    const isProviderAvailable = await dispatch(getSpAvailabilityApi({ subCategoryId: id, type: 'E' }))
    if (isProviderAvailable?.data?.data) {
      setSubCategoryId(id);
      navigate(
        `/express-details?categoryId=${showCategoryId}&subcategoryId=${id}`
      )
    } else {
      setModal(true)
    }

  };

  return (
    <>
      <Helmet>
        <title>EJAD - {t("service.title")}</title>
      </Helmet>
      <div className="home-section common-design-section">
        <div className="common-design-section-data single-data">
          <div className="right-design-data">
            <section className="service-section">
              <Container fluid="lg">
                <div className="service-section-data">
                  {!isLoading ? (
                    <div className="service-left-data">
                      <div className="head-data">
                        <h3>{t("jobSection.service1")}</h3>
                      </div>
                      <div
                        className={`inner-service-data ${showSubService ? "" : "full"
                          }`}
                      >
                        <div className="service-listing">
                          <ul>
                            {categoryData.map((item, index) => {
                              return (
                                <li key={index}>
                                  <div
                                    onClick={() => {
                                      serviceClickHandler(item, index);
                                    }}
                                    className={`service-main-card ${active === index ? "active" : ""
                                      }`}
                                  >
                                    <div
                                      className="one"
                                      style={{
                                        background: `url(${item?.icon || DummyCircle
                                          })`,
                                      }}
                                    ></div>

                                    <div className="two">
                                      <span>{item?.translation?.value}</span>
                                    </div>
                                    <div className="three">
                                      <ChevronRight />
                                    </div>
                                  </div>
                                  {showSubService && active === index && (
                                    <div className="service-inner-listing d-block d-md-none">
                                      {subCategoryData && subCategoryData.length ? (
                                        <ul>
                                          {subCategoryData.map((item, i) => {
                                            return (
                                              <li key={i}>
                                                <div
                                                  onClick={() =>
                                                    onSubServiceClick(item.categoryId)
                                                  }
                                                  className="service-inner-list-card"
                                                >
                                                  <div
                                                    className="one"
                                                    style={{
                                                      background: `url(${item?.icon || DummyCircle
                                                        })`,
                                                    }}
                                                  ></div>
                                                  <div className="two">
                                                    <span>{item?.value}</span>
                                                  </div>
                                                </div>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      ) : (
                                        <NoDataFound
                                          textData={t("noDataFound.subService")}
                                        />
                                      )}
                                    </div>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        {showSubService && (
                          <div className="service-inner-listing d-none d-md-block">
                            {subCategoryData && subCategoryData.length ? (
                              <ul>
                                {subCategoryData.map((item, i) => {
                                  return (
                                    <li key={i}>
                                      <div
                                        onClick={() =>
                                          onSubServiceClick(item.categoryId)
                                        }
                                        className="service-inner-list-card"
                                      >
                                        <div
                                          className="one"
                                          style={{
                                            background: `url(${item?.icon || DummyCircle
                                              })`,
                                          }}
                                        ></div>
                                        <div className="two">
                                          <span>{item?.value}</span>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <NoDataFound
                                textData={t("noDataFound.subService")}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="service-left-data">
                      <div className=" cu-shimmer h-100 br20"></div>
                    </div>
                  )}
                  <OrderSummary
                    isLoading={isLoading}
                    data={serviceData?.translation?.value}
                  />
                </div>
              </Container>
            </section>
            {/* Common Footer */}
            <Footer />
          </div>
        </div>
      </div>
      <NoSpModel modal={modal} toggleModal={() => setModal(!modal)} />
    </>
  );
};

export default Service;

/*
 * File: Bundle.js
 * Author: Harsh Chauhan
 * Created Date: November 3rd, 2022
 * Last Modified Date: August 22nd, 2023
 * Description: 
 */
import React from "react";
import { Helmet } from "react-helmet-async";
//* Images scss
import Banner from "../../assets/images/custom/bundle-banner.svg";

//* Multilangual
import { useTranslation } from "react-i18next";

//* page scss
import "./Bundle.scss";
import { Button } from "reactstrap";
import { ChevronLeft } from "react-feather";
import { useNavigate } from "react-router-dom";
import Footer from "../../component/common/Footer";

const Bundle = () => {
  //* Multilangual
  const { t } = useTranslation();
  //* Hook for Navigation
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>EJAD - Subscriptions</title>
      </Helmet>
      <div className="bundle-section common-design-section">
        <div className="common-design-section-data">
          <div className="left-design-data">
            <img src={Banner} className="img-fluid log-img" alt="images" />
            <div className="box-data">
              <div className="data">
                <h2 className="mb-0">{t("BundlesPage.headText")}</h2>
              </div>
            </div>
          </div>
          <div className="right-design-data">
            <div className="spacing-right-data centers">
              <div className="comming-box">
                <h1>{t("comingSoon.headText")}</h1>
                <p>{t("comingSoon.paraText")}</p>
                <Button
                  type="button"
                  className="cu-btn min150"
                  color="main"
                  onClick={() => navigate("/home")}
                >
                  <ChevronLeft size="18" className="me-50" />
                  {t("btnLabel.backToHome")}
                </Button>
              </div>
            </div>

            {/* Common Footer */}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Bundle;

import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { useNavigate } from "react-router-dom";

// Multilangual
import { useTranslation } from "react-i18next";
const RequestFailedModal = ({ modal, toggleModal, name, textData }) => {
  // Multilangual
  const { t, i18n } = useTranslation();
  //* Hook for navigation
  const navigate = useNavigate();
  return (
    <>
      {/* Success Modal */}
      <Modal
        backdropClassName="opw1"
        isOpen={modal}
        toggle={() => {
          toggleModal();
        }}
        backdrop="static"
        keyboard={false}
        className="success-modal"
        centered
      >
        <ModalBody>
          <p>{textData}</p>
          <Button
            color="main"
            className="cu-btn br20"
            block
            type="button"
            onClick={() => {
              toggleModal();
              navigate("/home?is-rfp-reply=true");
            }}
          >
            {t("message.close")}
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
};
export default RequestFailedModal;

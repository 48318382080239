import React, { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
// Images scss
import LoginBanner from "../../../assets/images/custom/login-img.svg";
import { CloseIcon } from "../../../assets/images/svg";
import SignupBox from "./SignupBox";
import LoginBox from "./LoginBox";
import ForgotPasswordBox from "./ForgotPasswordBox";
import { useTranslation } from "react-i18next";

const LoginModal = ({ modal, toggleModal, isLoginBox, setIsLoginBox }) => {
  // Multilangual
  const { t, i18n } = useTranslation();
  
  //* Hook for navigation
  const navigate = useNavigate();
  return (
    <>
      <Modal
        backdropClassName="opw1"
        isOpen={modal}
        toggle={() => {
          toggleModal();
          setIsLoginBox("login");
        }}
        backdrop="static"
        keyboard={false}
        className="login-modal"
        centered
      >
        <ModalBody>
          <div
            className="close-icon close-white-icon"
            onClick={() => {
              toggleModal();
              localStorage.removeItem("ejadLangPicker");
              setIsLoginBox("login");
              navigate("/landing", { state: {} });
            }}
          >
            <CloseIcon />
          </div>
          <div className="login-data">
            <div className="left-login-data">
              <img src={LoginBanner} className="img-fluid" alt="images" />
            </div>
            {isLoginBox === "login" ? (
              <>
                <LoginBox toggleClick={(val) => setIsLoginBox(val)} />
              </>
            ) : isLoginBox === "signup" ? (
              <SignupBox toggleClick={(val) => setIsLoginBox(val)} />
            ) : (
              <ForgotPasswordBox toggleClick={(val) => setIsLoginBox(val)} />
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default LoginModal;

/*
 * File: profileAction.js
 * Author: Harsh Chauhan
 * Created Date: November 14th, 2022
 * Last Modified Date: January 24th, 2023 
 * Description: This file contains the profile actions.
 */

import { api } from "../../../api/api";
import {
  PROFILE_GET,
  CATEGORY_GET,
  PROFILE_POST,
  PROFILE_IMAGE_UPLOAD_URL,
  ATTACHMENT_UPLOAD_URL,
  GET_INDUSTRY,
} from "../../../constants/ApiUrl";

/**
 * This API get all the details of customer in profile page.
 * @returns
 */
export const getProfileApi = () => {
  return async () => {
    const response = await api(PROFILE_GET, {}, null);
    return response;
  };
};

/**
 * This API is used for edit profile details of customer in profile page.
 * @param {*} data
 * @returns
 */
export const editProfileApi = (data) => {
  return async () => {
    const response = await api(PROFILE_POST, data);
    return response;
  };
};

/**
 * This API get all the categories(service) and sub-category(sub-service), it is used in home, profile, signup pages.
 * @returns
 */
export const getCategoryApi = () => {
  return async () => {
    const response = await api(CATEGORY_GET, {}, null, {
      isSignup: true,
    });
    return response;
  };
};
/**
 * This API get all the Industry(service), it is used in home, profile, signup pages.
 * @returns
 */
export const getIndustryApi = (data) => {
  return async () => {
    const response = await api(GET_INDUSTRY, {}, null, data);
    return response;
  };
};

/**
 * This API is used for edit profile image of customer in profile page.
 * @param {*} data
 * @returns
 */
export const editProfilePicApi = (data) => {
  return async () => {
    const response = await api(PROFILE_IMAGE_UPLOAD_URL, data);
    return response;
  };
};
/**
 * This API is used for edit attachments of customer in profile page.
 * @param {*} data
 * @returns
 */
export const editAttachmentApi = (data) => {
  return async () => {
    const response = await api(ATTACHMENT_UPLOAD_URL, data);
    return response;
  };
};

/**
 * Retrieves a particular category from the API based on the provided ID.
 *
 * @param {string} id - The ID of the category to retrieve.
 * @return {Promise} A promise that resolves with the response from the API call.
 */
export const getParticularCategoryApi = (id) => {
  let query = {
    id: id,
    isSignup: true,
  };
  return async () => {
    const response = await api(CATEGORY_GET, {}, null, query);
    return response;
  };
};

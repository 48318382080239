/*
 * File: expressAction.js
 * Author: Harsh Chauhan
 * Created Date: January 19th, 2023
 * Last Modified Date: February 12th, 2024
 * Description: This file contains the express actions.
 */

import { api } from "../../../api/api";
import {
  GET_EXPRESS_ORDER_URL,
  SEND_EXPRESS_PROVIDER_REQUEST,
  PERTICULAR_REQUEST_GET,
  CANCEL_EXPRESS_ORDER,
} from "../../../constants/ApiUrl";

/**
 * This API is used for send the request to service provider for perticular service.
 * @param {*} data
 * @returns
 */
export const sendExpressProviderRequest = (data) => {
  return async () => {
    const response = await api(SEND_EXPRESS_PROVIDER_REQUEST, data);
    return response;
  };
};

/**
 * The function `getExpressOrderApi` is an asynchronous function that makes an API call to retrieve
 * express order data.
 *
 * @param {type} data - The `data` parameter in the `getExpressOrderApi` function likely contains information
 * or payload that needs to be sent along with the API request.
 * @return {type} A function that makes an asynchronous API call to retrieve express order data.
 */
export const getExpressOrderApi = (data) => {
  return async () => {
    const response = await api(GET_EXPRESS_ORDER_URL, {}, null, data);
    return response;
  };
};

/**
 * Retrieves the express particular request API.
 *
 * @param {Object} data - The data to be sent in the request.
 * @return {Promise} A promise that resolves with the response from the API.
 */
export const getExpressPerticularReqApi = (data) => {
  return async () => {
    const response = await api(PERTICULAR_REQUEST_GET, {}, data);
    return response;
  };
};

/**
 * Cancels an express order by making an API call.
 *
 * @param {Object} data - The data needed to cancel the express order.
 * @return {Promise} A promise that resolves with the response from the API call.
 */
export const cancelExpressOrder = (data) => {
  return async () => {
    const response = await api(CANCEL_EXPRESS_ORDER, data);
    return response;
  };
};

import { Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const FormCheckbox = ({ type, id, name, errors, register, rules, label }) => {
  const { ref: ref, ...rest } = register(name, rules);
  const { t } = useTranslation();

  return (
    <div className="form-group mb30">
      <div className="form-check cu-check d-inline-flex justify-content-center w-100 cu-check-pad">
        <Input
          type={type}
          id={id}
          name={name}
          errors={errors}
          innerRef={ref}
          {...rest}
          hidden
        />

        <Label className="form-check-label cu-form-label" htmlFor={id}>
          {t("signupPage.termspolicyText")}{" "}
          <Link to="/termscondition">{t("signupPage.termText")}</Link>{"  "}
          {t("signupPage.and")}{"  "}
          <Link to="/privacypolicy">{t("signupPage.privacyText")}</Link>
        </Label>
      </div>
      <span className="error-msg">{errors[name]?.message}</span>
    </div>
  );
};

export default FormCheckbox;

import React from "react";
import { Button, Form } from "reactstrap";
import { useNavigate } from "react-router-dom";
// Multilangual
import { t } from "i18next";
// Images scss
import Logo from "../../../assets/images/logo/dark-logo.svg";
// Form
import { useForm } from "react-hook-form";
import FormInput from "../../Form/FormInput";
import { EmailRules } from "../../../constants/rules";
// API
import { useDispatch } from "react-redux";
import { forgotPasswordApi } from "../../../redux/actions/auth/loginActions";
import LanguagePicker from "../../common/LanguagePicker";

const ForgotPasswordBox = () => {
  //* Hook for navigation
  const navigate = useNavigate();

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  /**
   * Function for submit the all field in form
   * @param {obj} data
   */
  const onFormSubmit = async (data) => {
    localStorage.removeItem("ejadLangPicker");
    const res = await dispatch(forgotPasswordApi({ ...data, email: data?.email?.trim() }));
    if (!res.data.error) {
      const email = res.data.data.email;
    }
  };
  return (
    <>
      <div className="right-login-data animate__animated animate__fadeIn">
        <div className="inner-data">
          <div className="common-lang-header">
            <img src={Logo} className="img-fluid log-img" alt="images" />
            {/* <ul className="lang-ul">
              <LanguagePicker />
            </ul> */}
          </div>
          <div className="form-data pb-0">
            <p className="note">
              {t("notes.text1")} ( <span className="text-danger">*</span> ){" "}
              {t("notes.text2")}
            </p>
            <Form className="mb-0" onSubmit={handleSubmit(onFormSubmit)}>
              <div className="field-group mb-0">
                <span className="main-label">
                  {t("forgotPassword.heading")}
                </span>
                <FormInput
                  fgClass="mb20"
                  id="email"
                  name="email"
                  type="email"
                  label={t("loginPage.emailLabel")}
                  placeholder={t("loginPage.emailLabel")}
                  required={true}
                  rules={EmailRules}
                  register={register}
                  errors={errors}
                />
                <div className="cu-form-group mb-0 text-center">
                  <Button
                    color="main"
                    className="cu-btn br20 w-100"
                    type="submit"
                  >
                    {t("btnLabel.submitBtn")}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordBox;

import { GoStar } from "react-icons/go";
import Rating from "react-rating";
import { ChevronRight, Sliders } from "react-feather";
import {
  HistoryIcon,
  NewCheckIcon,
  NewCloseIcon,
  RecentIcon,
} from "../../assets/images/svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getRfpRecentListing } from "../../redux/actions/rfp/rfpAction";
import DateLabel from "../common/DateLabel";
import { ACCEPTED_ORDER, ICONS_MAP, RFP_STATUS_DONE } from "../../constants/appConstants";
import NoDataFound from "../common/NoDataFound";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import RfpListDateFilterModal from "../modal/service/RfpListDateFilterModal";
import CommonShimmer from "../shimmer/commonShimmer";
import DummyCircle from "../../assets/images/icon/user.png";
import { BsPersonCheckFill } from "react-icons/bs";

const RecentHistoryRfp = ({ setCurrentActiveTab, handleNotificationRead, unReadOrdersIdArr }) => {
  //* Multilangual
  const { t } = useTranslation();
  //* Get search parameters from URL
  const [searchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(false);
  const [recentRfp, setRecentRfp] = useState([]);
  const [historyRfp, setHistoryRfp] = useState([]);
  const [rfpFilterModal, setRfpFilterModal] = useState(false);
  const [showRFPId, setRFPId] = useState("");
  const [activeListType, setActiveListType] = useState(false);
  const [filteredData, setFilteredData] = useState({
    dateStart: "",
    dateEnd: "",
    dateSort: "",
  });
  const [filteredHistoryData, setFilteredHistoryData] = useState({
    dateStart: "",
    dateEnd: "",
    dateSort: "",
  });

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();

  //* Hook for navigation
  const navigate = useNavigate();
  
   //* Fetches recent and historical RFP data when the component mounts.
  useEffect(() => {
    getRecentRfpData();
    getHistoryRfpData();
  }, []);

  /**
   * Function for get recent rfp data from API
   */
  const getRecentRfpData = async (data = { ...filteredData }) => {
    setLoading(true);
    const res = await dispatch(
      getRfpRecentListing({ forActive: true, ...data })
    );
    if (!res.data.error) {
      setRecentRfp([...res.data.data]);
    }
    setLoading(false);
  };

  /**
   * Function for get history of rfp data from API
   */
  const getHistoryRfpData = async (data = { ...filteredHistoryData }) => {
    setLoading(true);
    const res = await dispatch(
      getRfpRecentListing({ forActive: false, ...data })
    );
    if (!res.data.error) {
      setHistoryRfp([...res.data.data]);
    }
    setLoading(false);
  };

  /**
   * get banner details when customer change language from header
   */
  useEffect(() => {
    const rfpId = searchParams.get("rfpId");
    if (rfpId) {
      setCurrentActiveTab("2");
      setRFPId(rfpId);
      if (document) {
        let element = document.getElementById(`${rfpId}`);
        element?.scrollIntoView();
      }
    }
  }, []);

  return (
    <>
      <Row className="filter-tab mb-1">
        <Col md={12}>
          <ul>
            <li className="">
              <Button
                className="cu-btn rounded-1"
                color="icon"
                onClick={() => {
                  setRfpFilterModal(true);
                  setActiveListType(true);
                }}
              >
                <Sliders />
              </Button>
            </li>
          </ul>
        </Col>
      </Row>

      <div className="order-service-data">
        <div className="title-data">
          <HistoryIcon />
          {t("myOrder.activeStatusText")}
        </div>

        {!isLoading ? (
          <>
            <ul className="order-service-ul">
              {recentRfp && recentRfp.length > 0 ? (
                recentRfp.map((item, i) => {
                  return (
                    <li
                      key={i}
                      className={`order-service-li`}
                      onClick={() => { navigate(`/rfps-details?rfpId=${item.id}`), handleNotificationRead(item.id) }}
                      id={item.id}
                    >
                      <div className={`${unReadOrdersIdArr?.includes(item?.id) ? 'show-border' : ''} order-service-card`}>
                        <div className="one">
                          <div className="grid-data">
                            <div>
                              <span className="label-text">
                                {t("myOrder.tastText")}
                              </span>
                              <ul className="list-inner">
                                <li>
                                  <span className="main">{i + 1}. {item.title}</span>
                                </li>
                              </ul>
                            </div>
                            <div>
                              <span className="label-text">
                                {t("OrderCard.providerTitle")}
                              </span>
                              <ul className="list-inner">
                                <li>
                                  <div className="profile-rate-data">
                                    <div
                                      className="first"
                                      style={{
                                        background: `url(${item?.serviceProviderId
                                          ?.profilePhoto || DummyCircle
                                          })`,
                                      }}
                                    ></div>
                                    <div className="second">
                                      <span className="main">
                                        {item?.serviceProviderId?.name}{" "}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div>
                              <span className="label-text">
                                {t("myOrder.dateTimeText")}
                              </span>
                              <ul className="list-inner">
                                <li>
                                  <span className="main">
                                    {t("myOrder.startText")}:{" "}
                                    <DateLabel date={item?.startDate} format={"DD-MM-YYYY HH:mm"} />
                                  </span>
                                </li>
                                <li>
                                  <span className="main">
                                    {t("myOrder.endText")}:{" "}
                                    <DateLabel date={item?.endDate} format={"DD-MM-YYYY HH:mm"} />
                                  </span>
                                </li>
                                <li>
                                  <span className="main">
                                    {t("myOrder.acceptanceText")}:{" "}
                                    <DateLabel date={item?.actualStartDate} format={"DD-MM-YYYY HH:mm"} />
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="two">
                          <div className="status-box">
                            {ICONS_MAP.get(item?.status)?.icon}
                            <span>{ICONS_MAP.get(item?.status)?.text}</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <NoDataFound
                  textData={t("noDataFound.noDataText")}
                  shadow={true}
                />
              )}
            </ul>
          </>
        ) : (
          <CommonShimmer />
        )}
      </div>

      <Row className="filter-tab mb-1">
        <Col md={12}>
          <ul>
            <li className="">
              <Button
                className="cu-btn rounded-1"
                color="icon"
                onClick={() => {
                  setRfpFilterModal(true);
                  setActiveListType(false);
                }}
              >
                <Sliders />
              </Button>
            </li>
          </ul>
        </Col>
      </Row>

      <div className="order-service-data">
        <div className="title-data">
          <RecentIcon />
          {t("myOrder.recentStatusText")}
        </div>
        {!isLoading ? (
          <>
            <ul className="order-service-ul">
              {historyRfp && historyRfp.length > 0 ? (
                historyRfp.map((item, i) => {
                  return (
                    <li
                      key={i}
                      id={item?.id}
                      className={`order-service-li pointer ${showRFPId === item.id ? "active" : ""
                        }`}
                      onClick={() => {
                        navigate(`/rfps-details?rfpId=${item.id}`)
                        handleNotificationRead(item?.id)
                      }}
                    >
                      <div className={`${unReadOrdersIdArr?.includes(item?.id) ? 'show-border' : ''} order-service-card`}>
                        <div className="one">
                          <div className="grid-data">
                            <div>
                              <span className="label-text">
                                {t("myOrder.tastText")}
                              </span>
                              <ul className="list-inner">
                                <li>
                                  <span className="main">{i + 1}. {item.title}</span>
                                </li>
                              </ul>
                            </div>
                            <div>
                              <span className="label-text">
                                {t("OrderCard.providerTitle")}
                              </span>
                              <ul className="list-inner">
                                <li>
                                  <div className="profile-rate-data">
                                    <div
                                      className="first"
                                      style={{
                                        background: `url(${item?.serviceProviderId
                                          ?.profilePhoto || DummyCircle
                                          })`,
                                      }}
                                    ></div>
                                    <div className="second">
                                      <span className="main">
                                        {" "}
                                        {item?.serviceProviderId?.name}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div>
                              <span className="label-text">
                                {t("myOrder.dateTimeText")}
                              </span>
                              <ul className="list-inner">
                                <li>
                                  <span className="main">
                                    {t("myOrder.startText")}:{" "}
                                    <DateLabel date={item?.startDate} format={"DD-MM-YYYY HH:mm"} />
                                  </span>
                                </li>
                                <li>
                                  <span className="main">
                                    {t("myOrder.endText")}:{" "}
                                    <DateLabel date={item?.endDate} format={"DD-MM-YYYY HH:mm"} />
                                  </span>
                                </li>
                                <li>
                                  <span className="main">
                                    {t("myOrder.acceptanceText")}:{" "}
                                    <DateLabel date={item?.actualStartDate} format={"DD-MM-YYYY HH:mm"} />
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="two">
                          <div className="status-box">
                            {ICONS_MAP.get(item?.status)?.icon}
                            <span>{ICONS_MAP.get(item?.status)?.text}</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <NoDataFound
                  textData={t("noDataFound.noDataText")}
                  shadow={true}
                />
              )}
            </ul>
          </>
        ) : (
          <CommonShimmer />
        )}
      </div>
      <RfpListDateFilterModal
        modal={rfpFilterModal}
        toggleModal={() => setRfpFilterModal(!rfpFilterModal)}
        filteredData={activeListType ? filteredData : filteredHistoryData}
        setFilteredData={(val) =>
          activeListType ? setFilteredData(val) : setFilteredHistoryData(val)
        }
        getDataList={() => {
          activeListType ? getRecentRfpData() : getHistoryRfpData();
        }}
        activeListType={activeListType}
        serviceType={activeListType ? 'rfpActive' : 'rfpRecent'}
      />
    </>
  );
};

export default RecentHistoryRfp;

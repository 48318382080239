/*
 * File: PrivateRoute.js
 * Author: Harsh Chauhan
 * Created Date: November 2nd, 2022
 * Last Modified Date: November 4th, 2022
 * Description: Renders the children components if the user is logged in, otherwise redirects to the landing page.
 */
import React from "react";
import { Navigate } from "react-router-dom";
import { isUserLoggedIn } from "../constants/utils";
import MainLayout from "../layouts/MainLayout";

const PrivateRoute = ({ children }) => {
  //
  return isUserLoggedIn() ? (
    <MainLayout>{children}</MainLayout>
  ) : (
    <Navigate to="/landing" />
  );
};

export default PrivateRoute;

/*
 * File: Landing.js
 * Author: Harsh Chauhan
 * Created Date: November 2nd, 2022
 * Last Modified Date: April 30th, 2024
 * Description: The Landing component orchestrates the layout, data fetching, and modals for the landing page of the website.
 */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import LoginModal from "../../component/modal/Auth/LoginModal";
import ResetPasswordModal from "../../component/modal/Auth/ResetPassword";

//* Images scss
import Landing1 from "../../assets/images/custom/landing1.jpg";
import newLanding from "../../assets/images/custom/newLanding.png";

//* Multilangual
import { useTranslation } from "react-i18next";

//* page scss
import "./Landing.scss";
import { isUserLoggedIn } from "../../constants/utils";
import HeaderStatic from "../../component/common/HeaderStatic";
import { getOnboardingScreenAPI } from "../../redux/actions/home/homeAction";
import { useDispatch } from "react-redux";
import {
  LANGUAGE_AR,
  LANGUAGE_EN,
  LANGUAGE_FR,
} from "../../constants/appConstants";
import Slider from "react-slick";

const Landing = () => {
  //* Multilangual
  const { t, i18n } = useTranslation();

  //* Hook for Navigation
  const navigate = useNavigate();

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();

  //* State variables
  const [loginModal, setLoginModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [authBox, setAuthBox] = useState("login");
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [cardData, setCardData] = useState([]);

  //* Hook for Location
  const { state } = useLocation();

  //* Map language codes to corresponding screen data keys
  const screenDataMapObj = {
    en: "englishOnboardingScreens",
    ar: "arabicOnboardingScreens",
    fr: "frenchOnboardingScreens",
  };
  const [langCodeState, setLangCodeState] = useState(false);

  //* Fetch the language of the current user
  const getLanguageOfCurrentUser = () => {
    const urlString = window.location.href;
    const langCode = urlString
      .substring(urlString.length, urlString.lastIndexOf("/") + 1)
      .split("?");
    //* langCode object to set in local storage
    const langCodeMap = {
      english: LANGUAGE_EN,
      arabic: LANGUAGE_AR,
      french: LANGUAGE_FR,
    };
    if (
      langCode?.[0]?.toLowerCase() === "english" ||
      langCode?.[0]?.toLowerCase() === "arabic" ||
      langCode?.[0]?.toLowerCase() === "french"
    ) {
      i18n.changeLanguage(langCodeMap[langCode?.[0].toLowerCase()]);
      localStorage.setItem(
        "ejadLangCode",
        langCodeMap[langCode?.[0].toLowerCase()]
      );
      setLangCodeState(true);
      navigate("/landing");
      //* change direction according to the current language
      document
        .getElementsByTagName("html")[0]
        .setAttribute("dir", i18n.language === LANGUAGE_AR ? "rtl" : "ltr");
    } else if (localStorage.getItem("ejadLangCode") !== LANGUAGE_EN) {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      i18n.changeLanguage(LANGUAGE_AR);
      localStorage.setItem("ejadLangCode", LANGUAGE_AR);
      setLangCodeState(true);
    }
  };

  //* useEffect for the language
  useEffect(() => {
    getLanguageOfCurrentUser();
  }, []);

  useEffect(() => {}, [langCodeState]);

  /**
   * get location
   * @returns
   */
  const useQueryString = () => {
    const location = useLocation();
    return new URLSearchParams(location.search);
  };
  const queryString = useQueryString();

  useEffect(() => {
    getCardData();
  }, []);

  const getCardData = async () => {
    const res = await dispatch(getOnboardingScreenAPI());
    if (!res.data.error) {
      setCardData(
        res?.data?.data[screenDataMapObj[i18n.language]]?.customerWebScreens
      );
    }
  };

   //* Get token useEffect
  useEffect(() => {
    let email = queryString.get("email");
    let token = queryString.get("token");
    let resetFlag = queryString.get("reset-password");
    if (resetFlag !== null) {
      setResetModal(resetFlag);
    }
    if (token !== null) {
      setToken(token);
    }
    if (email !== null) {
      setEmail(email);
    }
  }, []);

   //* its used when user comes from email forgot password link
  useEffect(() => {
    let isLogin = queryString.get("is-login");
    if (isLogin) {
      setLoginModal(true);
    }
  }, []);

  useEffect(() => {
    if (state?.signUp) {
      setAuthBox("signup");
      setLoginModal(true);
    }
  }, [state?.signUp]);

  let finalPopup = localStorage.getItem("ejadLangPicker");
  useEffect(() => {
    if (finalPopup === "false") {
      setAuthBox("login");
      setLoginModal(true);
    }
    localStorage.removeItem("ejadLangPicker");
  }, [finalPopup, i18n.language]);

  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    focusOnSelect: false,
    rtl: false,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>EJAD - Landing</title>
      </Helmet>
      <section className="h-100 banner-section">
        <Container fluid="lg" className="">
          <HeaderStatic fixed={false} fromHeader={true} />
          <div className="banner-section-data">
            <div className="left-data user-select-none">
              <h1>{t("landing.banner.heading")}</h1>
              <p>{t("landing.banner.paragraph")}</p>
              {!isUserLoggedIn() ? (
                <Button
                  color="warn"
                  className="cu-btn br20"
                  type="button"
                  onClick={() => setLoginModal(!loginModal)}
                >
                  {t("landing.banner.btnText")}
                </Button>
              ) : (
                <Button
                  color="warn"
                  className="cu-btn br20"
                  type="button"
                  onClick={() => navigate("/home")}
                >
                  {t("btnLabel.backToHome")}
                </Button>
              )}
            </div>
            <div className="right-data">
              <img src={newLanding} className="img-fluid" alt="images" />
            </div>
          </div>
        </Container>
      </section>

      <section className="blog-section user-select-none">
        <Container fluid="lg" className="h-100">
          <div className="blog-wrapper">
            <Slider {...settings}>
              {cardData.map((card, index) => {
                return (
                  <div key={index} className="h-100 match-height">
                    <Row className="card-main">
                      <Col className="px-0">
                        <img
                          src={card.image || Landing1}
                          className="w-100 h-100 rad"
                          alt="images"
                        />
                      </Col>
                      <Col className="detail-box">
                        <p className="title">{card.title}</p>
                        <p id={`box${card.id}`}>{card.description}</p>
                      </Col>
                      {card.description.length > 135 ? (
                        <UncontrolledTooltip target={`box${card.id}`}>
                          {card.description}
                        </UncontrolledTooltip>
                      ) : null}
                    </Row>
                  </div>
                );
              })}
            </Slider>
          </div>
        </Container>
      </section>

      {/* LoginModal */}
      <LoginModal
        modal={loginModal}
        toggleModal={() => setLoginModal(!loginModal)}
        isLoginBox={authBox}
        setIsLoginBox={setAuthBox}
      />

      {/* ResetPasswordModal */}
      <ResetPasswordModal
        modal={resetModal}
        token={token}
        email={email}
        setResetModal={(val) => setResetModal(val)}
        setLoginModal={(val) => setLoginModal(val)}
        toggleModal={() => setResetModal(!resetModal)}
      />
    </>
  );
};

export default Landing;

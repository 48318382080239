import React, { useState } from "react"
import { Label } from "reactstrap"
// import { selectThemeColors } from "@utils";
import Select from "react-select"
import { Controller } from "react-hook-form"
import { t } from "i18next"
// import { IconOption } from "../../constants/utils"

export default function FormSelectInput({
  name,
  label,
  id,
  errors,
  options,
  menuPortalTarget,
  optionLabel,
  optionValue,
  inputRef,
  key,
  autoFocus,
  onBlur,
  register = {},
  control,
  isDisabled,
  multiple,
  isSearchable,
  onChangeCallback,
  isClearable,
  rules,
  fromAddLink,
  setCategoryValidation,
  placeholder,
  className,
  fgClass,
  required,
  errorMsg,
}) {
  const [valueLabel, setValueLabel] = useState()
  const [isShow, setIsShow] = useState(false)

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, ref } }) => (
        <>
          <div className={`cu-form-group floating-label-group ${fgClass} `}>
            <Label
              className={`floating-label ${value || isShow ? "show" : ""}`}
              for="pname"
            >
              {label}
            </Label>
            <Select
              innerRef={ref}
              value={
                multiple
                  ? value
                  : value
                    ? options.find((option) => option[optionValue] === value)
                    : null
              }
              onChange={(val) => {
                onChange(multiple ? val : val && val[optionValue])
                onChangeCallback && onChangeCallback(val)
                setValueLabel(val)
              }}
              name={name}
              placeholder={placeholder}
              isDisabled={isDisabled}
              autoFocus={autoFocus}
              options={options}
              className={`react-select ${multiple ? "multi-select" : ""} ${value ? "isActive" : ""
                } ${className}`}
              classNamePrefix="select"
              // getOptionLabel={(option) => option[optionLabel]}
              getOptionLabel={(data) => {
                const Icon = data?.icon
                return (<div className="d-flex justify-content-center align-items-center">
                  {Icon && <Icon />}
                  <span className="ms-1">
                    {data[optionLabel]}
                  </span>
                </div>)
              }
              }
              getOptionValue={(option) => option[optionValue]}
              isMulti={multiple}
              isSearchable={isSearchable}
              menuPortalTarget={menuPortalTarget}
              onBlur={() => setIsShow(false)}
              onFocus={() => setIsShow(true)}
              noOptionsMessage={() => t('errorMessages.noOption')}
              // components={{ Option: IconOption }}
              {...register}
            />
            <span className="error-msg">
              {errorMsg || errors[name]?.message}
            </span>
            {required && <span className="required abs">*</span>}
          </div>
        </>
      )}
    />
  )
}

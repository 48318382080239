/*
 * File: StaticRoute.js
 * Author: Harsh Chauhan
 * Created Date: January 2nd, 2023
 * Last Modified Date: January 2nd, 2023
 * Description: Renders the children components within a CustomLayout component.
 */
import React from "react";
import CustomLayout from "../layouts/CustomLayout";

const StaticRoute = ({ children }) => {
  return <CustomLayout>{children}</CustomLayout>;
};

export default StaticRoute;

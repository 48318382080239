import React, { useEffect, useRef, useState } from "react";
import { Check, Star, X } from "react-feather";
import Rating from "react-rating";
import { Button, Col, Input, Label, Modal, ModalBody, Row } from "reactstrap";
import { CloseIcon } from "../../../assets/images/svg";
import Select from "react-select";
import { Message } from "../../../constants/messages";
import { GoStar } from "react-icons/go";
import { useTranslation } from "react-i18next";

const RfpAllFilterModal = ({
  modal,
  toggleModal,
  filteredData,
  setFilteredData,
  getReplyRfp,
}) => {
  const formRef = useRef();
  // Multilangual
  const { t, i18n } = useTranslation();

  //* State variables
  const [selectedPriceSorting, setSelectedPriceSorting] = useState("");
  const [selectedCompanyType, setSelectedCompanyType] = useState("");
  const [invalidPrice, setInvalidPrice] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isCompanyShow, setIsCompanyShow] = useState(false);

  //* Sorting options
  const sortingOption = [
    {
      value: "ASC",
      label: t("rfp.filterModal.SortAsc"),
    },
    {
      value: "DESC",
      label: t("rfp.filterModal.SortDsc"),
    },
  ];

  //* Company type options
  const companyOption = [
    {
      value: "C",
      label: t("rfp.filterModal.company"),
    },
    {
      value: "I",
      label: t("rfp.filterModal.individual"),
    },
  ];
  
  //* Check if price is valid
  useEffect(() => {
    if (filteredData.priceStart.length && filteredData.priceEnd.length)
      if (Number(filteredData.priceStart) > Number(filteredData.priceEnd)) {
        setInvalidPrice(true);
      } else {
        setInvalidPrice(false);
      }
  }, [filteredData.priceStart, filteredData.priceEnd]);
  // useEffect(() => {
  //   if (modal) allDataClearHandler();
  // }, [modal]);

  /**
   * Function for handle search events
   */
  const onSearchHandler = () => {
    if (Number(filteredData.priceStart) > Number(filteredData.priceEnd)) return;
    getReplyRfp();
    toggleModal();
  };

  /**
   * on clear form handler
   */
  const allDataClearHandler = () => {
    formRef.current?.reset();
    setSelectedPriceSorting("");
    setSelectedCompanyType("");
    setFilteredData({
      guarantee: "",
      rating: "",
      priceSort: "",
      priceStart: "",
      priceEnd: "",
      userType: "",
    });
    getReplyRfp({
      guarantee: "",
      rating: "",
      priceSort: "",
      priceStart: "",
      priceEnd: "",
      userType: "",
    });
  };
  return (
    <Modal
      contentClassName="success-shadow"
      className="common-modal"
      backdropClassName="opw1"
      isOpen={modal}
      toggle={() => toggleModal()}
      backdrop="static"
      keyboard={false}
      centered
    >
      <ModalBody className="filter-body">
        <div
          className="close-icon diff bottom-diff"
          onClick={() => {
            toggleModal();
          }}
        >
          <CloseIcon />
        </div>
        <h3 className="head-data">{t("rfp.filterModal.heading")}</h3>
        <form ref={formRef}>
          <Row>
            <Col md={6}>
              <div className="cu-form-group mb20 rating-filter">
                <p className="rating-head mb-0 ">
                  {t("rfp.filterModal.rattingLabel")}
                </p>
                <Rating
                  fractions={5}
                  className="user-select-none"
                  initialRating={filteredData.rating}
                  emptySymbol={
                    <GoStar size={24} fill="#C8C8C8" stroke="#C8C8C8" />
                  }
                  fullSymbol={
                    <GoStar size={24} fill={"#FFD700"} stroke={"#FFD700"} />
                  }
                  onChange={(val) =>
                    setFilteredData({ ...filteredData, rating: val })
                  }
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="cu-form-group mb20">
                <Label className="mb-0">
                  {t("rfp.filterModal.guaranteeLabel")}
                </Label>
                <div className="form-switch form-check-primary ms-0">
                  <Input
                    type="switch"
                    id="switch-primary"
                    name="primary"
                    className="pointer"
                    checked={filteredData.guarantee}
                    onChange={(e) => {
                      setFilteredData({
                        ...filteredData,
                        guarantee: e.target.checked,
                      });
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col md={12} className="cu-form-group floating-label-group mb20">
              <Input
                name="priceStart"
                id="priceStart"
                type="number"
                value={filteredData.priceStart}
                placeholder={t("rfp.filterModal.startPriceLabel")}
                onChange={(e) =>
                  setFilteredData({
                    ...filteredData,
                    priceStart: e.target.value,
                  })
                }
              />
              <Label className="floating-label">
                {t("rfp.filterModal.startPriceLabel")}
              </Label>
            </Col>
            <Col md={12} className="cu-form-group floating-label-group mb20">
              <Input
                name="priceEnd"
                id="priceEnd"
                type="number"
                value={filteredData.priceEnd}
                placeholder={t("rfp.filterModal.endPriceLabel")}
                onChange={(e) =>
                  setFilteredData({ ...filteredData, priceEnd: e.target.value })
                }
              />
              <Label className="floating-label">
                {t("rfp.filterModal.endPriceLabel")}
              </Label>
              {invalidPrice && (
                <span className="error-msg">{Message.priceValid}</span>
              )}
            </Col>
          </Row>
          <Col md={12} className="cu-form-group floating-label-group mb20">
            <Label
              className={`floating-label ${
                selectedPriceSorting || isShow ? "show" : ""
              }`}
              for="priceSort"
            >
              {t("rfp.filterModal.priceSortingLabel")}
            </Label>
            <Select
              className={`react-select ${
                selectedPriceSorting ? "isActive" : ""
              }`}
              classNamePrefix="select"
              name="priceSort"
              id="priceSort"
              options={sortingOption}
              value={selectedPriceSorting}
              onBlur={() => setIsShow(false)}
              onFocus={() => setIsShow(true)}
              onChange={(val) => {
                setSelectedPriceSorting(val);
                setFilteredData({ ...filteredData, priceSort: val.value });
              }}
              menuPortalTarget={document.body}
              placeholder={t("rfp.filterModal.priceSortingLabel")}
            />
          </Col>
          <Col md={12} className="cu-form-group floating-label-group mb40">
            <Label
              className={`floating-label ${
                selectedCompanyType || isCompanyShow ? "show" : ""
              }`}
              for="companyType"
            >
              {t("rfp.filterModal.userTypeLabel")}
            </Label>
            <Select
              className={`react-select ${
                selectedCompanyType ? "isActive" : ""
              }`}
              classNamePrefix="select"
              name="companyType"
              id="companyType"
              options={companyOption}
              value={selectedCompanyType}
              onBlur={() => setIsCompanyShow(false)}
              onFocus={() => setIsCompanyShow(true)}
              onChange={(val) => {
                setSelectedCompanyType(val);
                setFilteredData({ ...filteredData, userType: val.value });
              }}
              placeholder={t("rfp.filterModal.userTypeLabel")}
            />
          </Col>

          <Col className="all_center">
            <Button
              color="main-outline "
              className="cu-btn br20 min120 me-2"
              type="button"
              onClick={() => allDataClearHandler()}
            >
              {t("btnLabel.clearBtn")}
            </Button>
            <Button
              color="main"
              className="cu-btn br20 min120 ms-2"
              type="button"
              onClick={() => {
                onSearchHandler();
              }}
            >
              {t("btnLabel.applyBtn")}
            </Button>
          </Col>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default RfpAllFilterModal;

/*
 * File: RfpReplyList.js
 * Author: Bhavin Prajapati
 * Created Date: November 28th, 2022 
 * Last Modified Date: December 6th, 2023 
 * Description: It renders a list of Request for Proposals (RFPs) Replies.
 */
import { useState, useEffect } from "react";

//* Multilangual
import { useTranslation } from "react-i18next";

import { GoStar } from "react-icons/go";
import Rating from "react-rating";
import { ChevronLeft, Search, Sliders } from "react-feather";

import { useDispatch } from "react-redux";
import { getReplyRfpApi } from "../../redux/actions/rfp/rfpAction";

//* page scss
import "../MyOrder/MyOrder.scss";
import "../Service/Service.scss";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import {
  BriefCaseIcon,
  CarIcon,
  CheckBoxIcon,
  UserIcon,
} from "../../assets/images/svg";
import NoDataFound from "../../component/common/NoDataFound";
import DummyCircle from "../../assets/images/icon/user.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import RfpAllFilterModal from "../../component/modal/service/RfpAllFilterModal";
import CommonShimmer from "../../component/shimmer/commonShimmer";
import Footer from "../../component/common/Footer";

const RfpReplyList = () => {
  //* Multilangual
  const { t } = useTranslation();

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();

   //* Get search parameters from URL
  const [searchParams] = useSearchParams();
  let rfpId = searchParams.get("rfpId");
  let availableRfp = searchParams.get("available-rfp");

  //* Hook for navigation
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [rfpReplyData, setRfpReplyData] = useState([]);
  const [rfpReplyTitleData, setRfpReplyTitleData] = useState({});

  //state for filter
  const [rfpFilterModal, setRfpFilterModal] = useState(false);
  const [providerName, setProviderName] = useState("");
  const [filteredData, setFilteredData] = useState({
    guarantee: "",
    rating: "",
    priceSort: "",
    priceStart: "",
    priceEnd: "",
    userType: "",
  });

  useEffect(() => {
    getRfpReplyList();
  }, [providerName, rfpId]);

  /**
   * Function for get reply of rfp request from API
   */
  const getRfpReplyList = async (data = { ...filteredData }) => {
    setLoading(true);
    let res = await dispatch(
      getReplyRfpApi({
        rfpId: rfpId,
        name: providerName,
        ...data,
      })
    );
    if (!res.data.error) {
      setRfpReplyData([...res.data.data]);
      setRfpReplyTitleData({ ...res.data.rfp });
    }
    setLoading(false);
  };

  /**
   * redirect service provider detail view
   * @param {string} id
   */
  const rfpDetailsViewHandler = (id) => {
    navigate(`/rfps-details?replyId=${id}&rfpId=${rfpId}`);
  };

  // ** Renders Icon Based On Visibility

  return (
    <>
      <Helmet>
        <title>EJAD - Rfp reply</title>
      </Helmet>
      <div className="home-section common-design-section">
        <div className="common-design-section-data single-data">
          <div className="right-design-data">
            <div className="spacing-min-height">
              <section className="service-section">
                <Container fluid="lg">
                  <div className="service-section-data">
                    <div className="service-details-left-data w-100 pe-0">
                      <Row className="filter-tab">
                        <Col lg={7} xl={8}>
                          <div className="head-data with-big d-flex">
                            {availableRfp && (
                              <span
                                className="text-primary1 pointer back-arrow"
                                onClick={() => navigate("/rfps")}
                              >
                                <ChevronLeft size={22} />
                              </span>
                            )}
                            <h3>
                              {t("rfp.header.replyforRfp")} "
                              {rfpReplyTitleData?.title
                                ? rfpReplyTitleData?.title
                                : "-"}
                              "
                            </h3>
                          </div>
                        </Col>
                        <Col lg={5} xl={4}>
                          <ul>
                            <li className="input-li">
                              <div className="cu-form-group floating-label-group">
                                <InputGroup className="password-group">
                                  <Input
                                    className=""
                                    name="spName"
                                    id="spName"
                                    type="text"
                                    placeholder={t("rfp.header.searchSP")}
                                    onChange={(e) =>
                                      setProviderName(e.target.value)
                                    }
                                  />

                                  <InputGroupText className="cursor-pointer eye-text">
                                    <Search size={16} />
                                  </InputGroupText>
                                </InputGroup>
                              </div>
                            </li>
                            <li className="">
                              <Button
                                className="cu-btn rounded-1"
                                color="icon"
                                onClick={() => setRfpFilterModal(true)}
                              >
                                <Sliders />
                              </Button>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                      {!isLoading ? (
                        <>
                          {rfpReplyData && rfpReplyData.length ? (
                            <div className="choose-provider-data border-0 overflow-unset">
                              <div className="scroll-data scroll-none">
                                <ul className="choose-ul">
                                  {rfpReplyData.map((item, i) => {
                                    return (
                                      <li className="choose-li" key={i}>
                                        <div
                                          className="inner-provider-card"
                                          onClick={() => rfpDetailsViewHandler(item?.id)}
                                        >
                                          <div
                                            className="one"
                                            style={{
                                              background: `url(${item.profilePhoto || DummyCircle
                                                })`,
                                            }}
                                          ></div>
                                          <div className="two">
                                            <div className="small-details-data">
                                              <div className="small-data-one">
                                                <ul>
                                                  <li>
                                                    <div className="name-data">
                                                      {item.name}
                                                      {item?.isCertified ===
                                                        1 && <CheckBoxIcon />}
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <div className="icon-data">
                                                      <div className="text-i">
                                                        {t("newTextRFP.priceText")} :&nbsp;
                                                        {
                                                          item.price || '-'
                                                        }{' '}
                                                        {t(
                                                          "serviceDetails.serviceProviderCard.jdHrLabel2"
                                                        )}
                                                      </div>
                                                    </div>
                                                  </li>
                                                  {item?.total !== 0 && (
                                                    <li>
                                                      <div className="star-data">
                                                        <Rating
                                                          className="user-select-none"
                                                          initialRating={
                                                            item?.rating
                                                          }
                                                          emptySymbol={
                                                            <GoStar
                                                              size={14}
                                                              fill="#C8C8C8"
                                                              stroke="#C8C8C8"
                                                            />
                                                          }
                                                          fullSymbol={
                                                            <GoStar
                                                              size={14}
                                                              fill={"#FFD700"}
                                                              stroke={"#FFD700"}
                                                            />
                                                          }
                                                        />
                                                        <div className="comment-data">
                                                          ({item?.total})
                                                        </div>
                                                      </div>
                                                    </li>
                                                  )}
                                                </ul>
                                              </div>
                                              {/* Her blank div is use for Expertise replace for consistency of css */}
                                              <div></div>
                                              <div className="small-data-one">
                                                <ul>
                                                  <li>
                                                    <div className="icon-data">
                                                      <div className="icon-i">
                                                        <UserIcon />
                                                      </div>
                                                      <div className="text-i">
                                                        {item?.userType ===
                                                          "C" &&
                                                          t(
                                                            "serviceDetails.serviceProviderCard.companyLabel"
                                                          )}
                                                        {item?.userType ===
                                                          "I" &&
                                                          t(
                                                            "serviceDetails.serviceProviderCard.individualLabel"
                                                          )}
                                                      </div>
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <div className="icon-data">
                                                      <div className="icon-i">
                                                        <CarIcon />
                                                      </div>
                                                      <div className="text-i">
                                                        {item.carToggle === 1
                                                          ? t(
                                                            "serviceDetails.serviceProviderCard.availabelLabel"
                                                          )
                                                          : t(
                                                            "serviceDetails.serviceProviderCard.notAvailabelLabel"
                                                          )}
                                                      </div>
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <div className="icon-data">
                                                      <div className="icon-i">
                                                        <BriefCaseIcon />
                                                      </div>
                                                      <div className="text-i ispan">
                                                        {item.ordersCompleted}{" "}
                                                        {t(
                                                          "serviceDetails.serviceProviderCard.taskDoneLabel"
                                                        )}
                                                      </div>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          ) : (
                            <NoDataFound
                              textData={t("noDataFound.provider")}
                              shadow={true}
                            />
                          )}
                        </>
                      ) : (
                        <div className="service-details-left-data w-100 pe-0">
                          <CommonShimmer />
                        </div>
                      )}
                    </div>
                  </div>
                </Container>
              </section>
            </div>
            {/* Common Footer */}
            <Footer />
          </div>
        </div>
      </div>

      <RfpAllFilterModal
        modal={rfpFilterModal}
        toggleModal={() => setRfpFilterModal(!rfpFilterModal)}
        filteredData={filteredData}
        setFilteredData={(val) => setFilteredData(val)}
        getReplyRfp={(data) => getRfpReplyList(data)}
      />
    </>
  );
};

export default RfpReplyList;

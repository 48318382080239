import React from "react";
import { Label, Input } from "reactstrap";
const FormQuestionCheckBox = ({
  type,
  id,
  name,
  register,
  rules,
  label,
  hidden,
  value,
}) => {
  const { ref: ref, ...rest } = register(name, rules);
  return (
    <div className="form-checkbox">
      <Input
        type={type}
        value={value}
        id={id}
        innerRef={ref}
        {...rest}
        hidden={hidden}
      />
      {label && <Label htmlFor={id}>{label}</Label>}
    </div>
  );
};

export default FormQuestionCheckBox;

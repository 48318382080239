import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

//Global Variables
// import { GlobalVariable } from './globleVariable';
// const mapApiKey = GlobalVariable.mapApiKey;
import i18n from "i18next";
import Geocode from "react-geocode";
import axios from "axios";
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY);
Geocode.setLanguage(i18n.language);
//Find lat/long with detail data of address like city , state , country
export const getAddressDetail = async ({ address, map }) => {
  let addressData = {
    lat: "",
    long: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    line1: "",
    line2: "",
  };
  try {
    const geoResult = await geocodeByAddress(address);
    if (!geoResult && !geoResult?.length > 0) {
      return addressData;
    }

    if (geoResult && geoResult?.[0]?.place_id) {

      const promise = new Promise((resolve) => {

        const callback = (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            addressData['placeName'] = results?.name
            resolve()
          }
        };

        // Define the request for nearby places
        let request = {
          placeId: geoResult?.[0]?.place_id,
        };

        // Initialize the PlacesService with the map object
        const service = new google.maps.places.PlacesService(map);

        // Perform the nearbySearch
        if (service) {
          service?.getDetails(request, callback);
        }
      });

      // Wait for the Promise to resolve before continuing
      await promise;
    }
    const latLng = await getLatLng(geoResult[0]);
    if (!latLng) {
      return addressData;
    }
    addressData["lat"] = latLng?.lat;
    addressData["long"] = latLng?.lng;
    const response = await Geocode.fromLatLng(latLng?.lat, latLng?.lng);
    if (!response) {
      return addressData;
    }
    for (
      let i = 0;
      i < response?.results?.[0]?.address_components?.length;
      i++
    ) {
      for (
        let j = 0;
        j < response?.results?.[0]?.address_components?.[i]?.types?.length;
        j++
      ) {
        switch (response?.results?.[0]?.address_components?.[i]?.types?.[j]) {
          case "locality":
            addressData["city"] =
              response?.results?.[0]?.address_components?.[i]?.long_name;
            break;
          case "administrative_area_level_1":
            addressData["state"] =
              response?.results?.[0]?.address_components?.[i]?.long_name;
            break;
          case "country":
            addressData["country"] =
              response?.results?.[0]?.address_components?.[i]?.long_name;
            break;
          case "postal_code":
            addressData["postalCode"] =
              response?.results?.[0]?.address_components?.[i]?.long_name;
            break;
          case "sublocality_level_1" || "route":
            addressData["line1"] =
              response?.results?.[0]?.address_components?.[i]?.long_name;
          case "sublocality_level_2":
            addressData["line2"] =
              response?.results?.[0]?.address_components?.[i]?.long_name;
            break;
        }
      }
    }

    return addressData;
  } catch (err) {
    console.error("Error", err);
    return addressData;
  }
};

//Find lat/long with detail data of address like city , state , country from lat long
export const getAddressDetailFromLatLong = async ({ lat, long, map }) => {
  let addressData = {
    lat: lat,
    long: long,
    address: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    placeName: "",
    line1: "",
    line2: "",
  };

  try {
    const response = await Geocode.fromLatLng(lat, long);
    if (!response) {
      return addressData;
    }

    if (map && response && response?.results?.[0]) {

      const promise = new Promise((resolve) => {
        const callback = (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            addressData['placeName'] = results[1]?.name;
            resolve(); // Resolve the Promise when placeName is assigned
          } else {
            resolve(null)
          }
        };

        // Create a LatLng object
        const location = new google.maps.LatLng(lat, long);

        // Define the request for nearby places
        const request = {
          location,
          radius: 20, // Radius in meters
        };

        // Initialize the PlacesService with the map object
        const service = new google.maps.places.PlacesService(map);

        // Perform the nearbySearch
        if (service) {
          service?.nearbySearch(request, callback);
        }
      });

      // Wait for the Promise to resolve before continuing
      await promise;
    }
    addressData["address"] = response?.results?.[0]?.formatted_address || '';

    for (
      let i = 0;
      i < response?.results?.[0]?.address_components?.length;
      i++
    ) {
      for (
        let j = 0;
        j < response?.results?.[0]?.address_components?.[i]?.types?.length;
        j++
      ) {
        switch (response?.results?.[0]?.address_components?.[i]?.types?.[j]) {
          case "locality":
            addressData["city"] =
              response?.results?.[0]?.address_components?.[i]?.long_name;
            break;
          case "administrative_area_level_1":
            addressData["state"] =
              response?.results?.[0]?.address_components?.[i]?.long_name;
            break;
          case "country" || "political":
            addressData["country"] =
              response?.results?.[0]?.address_components?.[i]?.long_name;
            break;
          case "postal_code":
            addressData["postalCode"] =
              response?.results?.[0]?.address_components?.[i]?.long_name;
            break;
          case "sublocality_level_1" || "route":
            addressData["line1"] =
              response?.results?.[0]?.address_components?.[i]?.long_name;
          case "sublocality_level_2":
            addressData["line2"] =
              response?.results?.[0]?.address_components?.[i]?.long_name;
            break;
        }
      }
    }
    return addressData;
  } catch (err) {
    console.error("Error", err);
    return addressData;
  }
};

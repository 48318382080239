import React, { useState } from "react";
import { Modal, ModalBody, Button, Form } from "reactstrap";
import { useNavigate } from "react-router-dom";
// Multilangual
import { t } from "i18next";
// Images scss
import Logo from "../../../assets/images/logo/dark-logo.svg";
import LoginBanner from "../../../assets/images/custom/login-img.svg";
import { CloseIcon } from "../../../assets/images/svg";
// Form
import { useForm } from "react-hook-form";
import FormPassword from "../../Form/FormPassword";
import {
  ConfirmPasswordRules,
  NewPasswordRules,
  PasswordRules,
} from "../../../constants/rules";
import { useDispatch } from "react-redux";
import { resetPasswordApi } from "../../../redux/actions/auth/loginActions";
import LanguagePicker from "../../common/LanguagePicker";

const ResetPasswordModal = ({
  modal,
  toggleModal,
  token,
  email,
  setResetModal,
  setLoginModal,
}) => {
  //* Hook for navigation
  const navigate = useNavigate();

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const passwordValue = watch("newPassword");

  /**
   * Onsubmit all fields
   * @param {*} data
   */
  const onFormSubmit = async (data) => {
    localStorage.removeItem("ejadLangPicker");
    const res = await dispatch(resetPasswordApi(data, { token, email }));
    if (!res.data.error) {
      setLoginModal("login");
      setResetModal(false);
      navigate("/landing");
    }
  };
  return (
    <>
      <Modal
        backdropClassName="opw1"
        isOpen={modal}
        toggle={() => {
          toggleModal();
        }}
        backdrop="static"
        keyboard={false}
        className="login-modal"
        centered
      >
        <ModalBody>
          <div
            className="close-icon"
            onClick={() => {
              toggleModal();
            }}
          >
            <CloseIcon />
          </div>
          <div className="login-data">
            <div className="left-login-data">
              <img src={LoginBanner} className="img-fluid" alt="images" />
            </div>
            <div className="right-login-data animate__animated animate__fadeIn">
              <div className="inner-data">
                <div className="common-lang-header">
                  <img src={Logo} className="img-fluid log-img" alt="images" />
                  {/* <ul className="lang-ul">
                    <LanguagePicker />
                  </ul> */}
                </div>
                <div className="form-data pb-0">
                  <p className="note">
                    {t("notes.text1")} ( <span className="text-danger">*</span>{" "}
                    ) {t("notes.text2")}
                  </p>
                  <Form className="mb-0" onSubmit={handleSubmit(onFormSubmit)}>
                    <div className="field-group mb-0">
                      <span className="main-label">
                        {t("resetPassword.heading")}
                      </span>
                      <FormPassword
                        fgClass="mb20"
                        id="newPassword"
                        name="newPassword"
                        type="password"
                        label={t("resetPassword.setNewPassword")}
                        placeholder={t("resetPassword.setNewPassword")}
                        required={true}
                        rules={NewPasswordRules}
                        register={register}
                        errors={errors}
                        visible={false}
                      />
                      <FormPassword
                        fgClass="mb20"
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        label={t("resetPassword.retypeNewPassword")}
                        placeholder={t("resetPassword.retypeNewPassword")}
                        required={true}
                        rules={ConfirmPasswordRules}
                        register={register}
                        errors={errors}
                        visible={false}
                        passwordValue={passwordValue}
                        isConfirmPassword={true}
                      />
                      <div className="cu-form-group mb-0 text-center">
                        <Button
                          color="main"
                          className="cu-btn br20 w-100"
                          type="submit"
                        >
                          {t("btnLabel.submitBtn")}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ResetPasswordModal;

//Firebase Messaging
import firebase from "firebase/app";
import "firebase/messaging";

//UUID
import { v4 as uuidv4 } from "uuid";
import { setDevice } from "../redux/actions/device/deviceAction";
import { setNotification } from "../redux/actions/notification/notificationAction";
import { store } from "../redux/storeConfig/store";

//Firebase Configuration
import { config } from "./firebase-config";

export const firebaseNotificationProvider = () => {
  if (firebase?.messaging?.isSupported()) {
    if ("serviceWorker" in navigator) {
      const messaging = firebase?.messaging();
      const swPath = process.env.REACT_APP_NOTIFICATION_URL;
      // const swPath = 'https://zignuts.dev/ejad/webapp/firebase-messaging-sw.js';
      navigator.serviceWorker
        .register(swPath)
        .then(function (registration) {
          messaging
            .getToken({
              vapidKey: config.VapidKey,
              serviceWorkerRegistration: registration,
            })
            .then(async (currentToken) => {
              if (currentToken) {
                let localID = localStorage.getItem("deviceId");
                if (!localID) {
                  localID = uuidv4();
                  localStorage.setItem("deviceId", localID);
                }
                //Update device token in database
                store.dispatch(setDevice(currentToken));
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err);
              // catch error while creating client token
            });
        })
        .catch(function (err) {
          console.log("Service worker registration failed, error:", err);
        });

      navigator.serviceWorker.addEventListener("message", async (e) => {
        // console.log("[navigator one.]")
        let payload = e.data.payload || e.data;
        let data = {
          id: uuidv4(),
          serviceId: payload?.data?.serviceId,
          type: payload?.data?.type,
          message: payload?.data,
        };
        store.dispatch(setNotification(data));
      });
    }
  }
};

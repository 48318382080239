/*
 * File: notificationAction.js
 * Author: Harsh Chauhan
 * Created Date: November 28th, 2022 
 * Last Modified Date: February 29th, 2024 
 * Description: This file contains the notification actions.
 */

import {
  SET_MYORDERS_UNREAD_FLAG,
  SET_NOTIFICATION,
  SET_REMOVE_NOTIFICATION,
  SET_RFPS_UNREAD_FLAG,
  SET_UNREAD_ORDERS_DATA,
} from "../types";

/**
 * used for get notification
 * @param {*} value
 * @returns
 */
export const setNotification = (value) => {
  return {
    type: SET_NOTIFICATION,
    payload: value,
  };
};
/**
 * used for remove notification
 * @param {*} value
 * @returns
 */
export const setRemoveNotification = (value) => {
  return {
    type: SET_REMOVE_NOTIFICATION,
    payload: value,
  };
};

/**
 * Sets the RFP unread flag to the specified value.
 *
 * @param {any} value - The value to set the RFP unread flag to.
 * @return {object} An object with the type SET_RFPS_UNREAD_FLAG and the payload set to the specified value.
 */
export const setRfpFlag = (value) => {
  return {
    type: SET_RFPS_UNREAD_FLAG,
    payload: value,
  };
};

/**
 * A description of the entire function.
 *
 * @param {any} value - The value to set the My Orders unread flag to.
 * @return {object} An object with the type SET_MYORDERS_UNREAD_FLAG and the payload set to the specified value.
 */
export const setMyordersFlag = (value) => {
  return {
    type: SET_MYORDERS_UNREAD_FLAG,
    payload: value,
  };
};

/**
 * Sets the unread orders data.
 *
 * @param {any} value - The value to set the unread orders data to.
 * @return {object} An object with the type SET_UNREAD_ORDERS_DATA and the payload set to the specified value.
 */
export const setUnReadOrders = (value) => {
  return {
    type: SET_UNREAD_ORDERS_DATA,
    payload: value,
  };
};

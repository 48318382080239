/*
 * File: NewOrderAddress.js
 * Author: Ankit Pithiya
 * Created Date: January 9th, 2024
 * Last Modified Date: March 20th, 2024
 * Description: This code snippet defines a functional component NewOrderAddress that handles user input related to address details
 */
import React, { useEffect, useState } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import PlacesAutocomplete from "react-places-autocomplete";
import { Col, Input, Label, Row } from "reactstrap";
import FormInput from "../Form/FormInput";
import MapContainer from "./Map";
import { t } from "i18next";
import "../../assets/scss/common.scss";
import { libraries } from "../../constants/appConstants";
import {
  getAddressDetail,
  getAddressDetailFromLatLong,
} from "../../constants/GetAddressInfo";
import {
  BranchNameRules,
  ContactNameRules,
  EmailRules,
  PhoneNumberRules,
} from "../../constants/rules";
import FormCountryInput from "../Form/FormCountryInput";
import FormCheckboxInput from "../Form/FormCheckboxInput";
import { useSelector } from "react-redux";
import { usePosition } from "../../utility/hooks/usePosition";

function NewOrderAddress({
  register,
  control,
  errors,
  setFullCustomerAddress,
  fullCustomerAddress,
  watch,
  setValue,
}) {
  //* Fetching profile data from Redux store
  const profileData = useSelector((state) => state?.profileReducer?.profile);

  //* State variables for managing address, area value, map, latitude, and longitude
  const [address, setAddress] = useState();
  const [areaValue, setAreaValue] = useState("");
  const [map, setMap] = useState(null);
  const [mapLat, setMapLat] = useState("");
  const [mapLong, setMapLong] = useState("");

  //* Fetching 'isSave' value from form watch
  const isSave = watch("isSave");
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: libraries,
  });
  //* Fetching current position using custom hook 'usePosition'
  const { latitude, longitude, error } = usePosition();
  
  //* set current location or amman-jordan in map
  useEffect(() => {
    setMapLat(profileData?.latitude || latitude || 31.9454);
    setMapLong(profileData?.longitude || longitude || 35.9284);
  }, [latitude, longitude]);

  //* Fetching address from latitude and longitude
  useEffect(() => {
    if (mapLat) getAddressFromLatLong();
  }, [mapLat, mapLong]);

  //* Setting default values for name, phone number, and email from profile data
  useEffect(() => {
    setValue("phoneNo", `${profileData?.countryCode}${profileData?.phoneNo}`);
    setValue("email", profileData?.email);
    setValue("name", profileData?.name);
    setTimeout(() => {
      setAreaValue(profileData?.area);
    }, 500);
  }, [profileData]);

  //* Function to get address from latitude and longitude
  const getAddressFromLatLong = async () => {
    let address = await getAddressDetailFromLatLong({
      lat: mapLat,
      long: mapLong,
      map: map,
    });
    if (!address.line1) {
      address.line1 = address.city;
    } else {
      address.line1 = address.line1;
    }

    setAreaValue(address.placeName ? address.placeName : address.city);
    setFullCustomerAddress(address);
  };

  //* Function to handle address change
  const changeAddress = async (address) => {
    setAddress(address);
    setAreaValue(address);
  };

  //* Function to select an address
  const selectAddress = async (address) => {
    setMapLat("");
    setMapLong("");
    setAddress(address);
    const result = await getAddressDetail({ address, map });
    if (!result.line1) {
      result.line1 = result.city;
    } else {
      result.line1 = result.line1;
    }
    setAreaValue(result.placeName ? result.placeName : result.city);
    setFullCustomerAddress(result);
  };

  return (
    <div>
      <div className="field-group mb20">
        <span className="head-data mb20">
          <h3> {t("signupPage.locationLabel")}</h3>
        </span>
        <div className="map-box">
          <MapContainer
            latitude={mapLat || fullCustomerAddress?.lat || 31.9454}
            longitude={mapLong || fullCustomerAddress?.long || 35.9284}
            setMapLat={(val) => setMapLat(val)}
            setMapLong={(val) => setMapLong(val)}
            setMap={(val) => setMap(val)}
            mapLat={mapLat}
            mapLong={mapLong}
            draggable={true}
          />
        </div>
        <Row className="mt-3">
          <Col md={4}>
            {isLoaded ? (
              <div className="flex-fill">
                <PlacesAutocomplete
                  value={address}
                  onChange={changeAddress}
                  onSelect={(val) => selectAddress(val)}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                  }) => (
                    <div className="cu-form-group floating-label-group  mb20">
                      <Label
                        htmlFor="area"
                        className={`floating-label ${
                          fullCustomerAddress?.country ? "show" : ""
                        }`}
                      >
                        {t("signupPage.areaLabel")}
                      </Label>

                      <Input
                        className="form-control"
                        {...getInputProps({
                          placeholder: t("signupPage.searchText"),
                          className: "location-search-input",
                          id: "area",
                          name: "area",
                        })}
                        value={
                          areaValue ||
                          fullCustomerAddress?.placeName ||
                          fullCustomerAddress?.city
                        }
                      />

                      <span className="required abs">*</span>

                      <div className="autocomplete-dropdown-container">
                        {suggestions.map((suggestion, i) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          //* inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer",
                              }
                            : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                                color: "#7a8897",
                              };
                          return (
                            <div
                              key={i}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
            ) : null}
          </Col>
          <Col md={4}>
            <FormInput
              fgClass="mb20"
              id="city"
              name="city"
              type="text"
              label={t("signupPage.cityLabel")}
              placeholder={t("signupPage.cityLabel")}
              required={true}
              rules={{}}
              register={register}
              errors={errors}
              value={fullCustomerAddress?.city || ""}
              disabled
            />
          </Col>
          <Col md={4}>
            <FormInput
              fgClass="mb20"
              id="country"
              name="country"
              type="text"
              label={t("signupPage.countryLabel")}
              placeholder={t("signupPage.countryLabel")}
              required={true}
              rules={{}}
              register={register}
              errors={errors}
              value={fullCustomerAddress?.country || ""}
              disabled
            />
          </Col>
        </Row>
        <FormInput
          fgClass="mb20"
          id="name"
          name="name"
          type="text"
          label={t("profile.contactNameLabel")}
          placeholder={t("profile.contactNameLabel")}
          required={true}
          rules={ContactNameRules}
          register={register}
          errors={errors}
        />
        <FormCountryInput
          fgClass="mb20"
          required={true}
          id="phoneNo"
          name="phoneNo"
          label={t("signupPage.contactNumberLabel")}
          placeholder={t("signupPage.contactNumberLabel")}
          rules={PhoneNumberRules}
          errors={errors}
          register={register}
          control={control}
        />
        <FormInput
          fgClass="mb20"
          id="email"
          name="email"
          type="email"
          label={t("signupPage.contactEmailLabel")}
          placeholder={t("signupPage.contactEmailLabel")}
          required={true}
          rules={EmailRules}
          register={register}
          errors={errors}
        />
        <div className="field-group mb20 ps-2">
          <div className="checkbox-square">
            <FormCheckboxInput
              type="checkbox"
              id="isSave"
              hidden
              name="isSave"
              register={register}
              rules={{}}
              errors={errors}
              label={t("newOrderAddress.checkboxLabel")}
            />
          </div>
        </div>
        {isSave ? (
          <FormInput
            fgClass="mb20 font-6"
            id={`branchName`}
            name={`branchName`}
            type="text"
            label={t("profile.branchData.branchTitle")}
            placeholder={t("profile.branchData.branchName")}
            required={true}
            rules={BranchNameRules}
            register={register}
            errors={errors}
            maxLength={50}
          />
        ) : null}
      </div>
    </div>
  );
}

export default NewOrderAddress;

/*
 * File: Router.js
 * Author: Harsh Chauhan
 * Created Date: November 2nd, 2022
 * Last Modified Date: February 14th, 2024
 * Description: Renders different routes based on the path provided.
 */
import { Route, Routes, Navigate } from "react-router-dom";
// Route type
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

// pages
import Landing from "../pages/Landing/Landing";
import Error from "../pages/Authentication/Error";
import EmailVerification from "../pages/Authentication/EmailVerification";
import Home from "../pages/Home/Home";
import MyOrder from "../pages/MyOrder/MyOrder";
import Rfp from "../pages/Rfp/Rfp";
import Bundle from "../pages/Bundle/Bundle";
import Adds from "../pages/Adds/Adds";
import Profile from "../pages/Profile/Profile";
import SignupVerificationSuccess from "../pages/Authentication/SignupVerificationSuccess";
import ExpressService from "../pages/Express/Express";
import Service from "../pages/Service/Service";
import ServiceDetails from "../pages/Service/ServiceDetails";
import ServiceProviderReply from "../pages/Service/ServiceProviderReply";
import LicenceAgreement from "../pages/Licence/Licence";
import RfpCreate from "../pages/Rfp/RfpCreate";
import RfpReplyList from "../pages/Rfp/RfpReplyList";
import RfpDetailsView from "../pages/Rfp/RfpDetailsView";
import TermsCondition from "../pages/TermsCondition/TermsCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import StaticRoute from "./StaticRoute";
import ExpressServiceDetails from "../pages/Express/ExpressDetails";
import ExpressReply from "../pages/Express/ExpressReply";
import ServiceReview from "../pages/Review/Review";
import Payment from "../pages/Payment/Payment";
import Estimation from "../pages/estimation/Estimation";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/landing" />} />
      <Route
        path="*"
        exact
        element={
          <PublicRoute fullLayout>
            <Error />
          </PublicRoute>
        }
      />
      <Route
        path="/email-verification"
        exact
        element={
          <PublicRoute fullLayout>
            <EmailVerification />
          </PublicRoute>
        }
      />
      <Route
        path="/verifyAccount"
        exact
        element={
          <PublicRoute fullLayout>
            <SignupVerificationSuccess />
          </PublicRoute>
        }
      />
      <Route
        path="/landing/:id"
        exact
        element={
          <PublicRoute fullLayout>
            <Landing />
          </PublicRoute>
        }
      />
      <Route
        path="/landing"
        exact
        element={
          <PublicRoute fullLayout>
            <Landing />
          </PublicRoute>
        }
      />
      <Route
        path="/licence-agreement"
        exact
        element={
          <PublicRoute fullLayout>
            <LicenceAgreement />
          </PublicRoute>
        }
      />
      <Route
        path="/profile"
        exact
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
      <Route
        path="/home"
        exact
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="/my-orders"
        exact
        element={
          <PrivateRoute>
            <MyOrder />
          </PrivateRoute>
        }
      />
      <Route
        path="/rfps"
        exact
        element={
          <PrivateRoute>
            <Rfp />
          </PrivateRoute>
        }
      />
      <Route
        path="/rfps-reply"
        exact
        element={
          <PrivateRoute>
            <RfpReplyList />
          </PrivateRoute>
        }
      />
      <Route
        path="/rfps-details"
        exact
        element={
          <PrivateRoute>
            <RfpDetailsView />
          </PrivateRoute>
        }
      />
      <Route
        path="/rfps/create"
        exact
        element={
          <PrivateRoute>
            <RfpCreate />
          </PrivateRoute>
        }
      />
      <Route
        path="/bundles"
        exact
        element={
          <PrivateRoute>
            <Bundle />
          </PrivateRoute>
        }
      />
      <Route
        path="/adds"
        exact
        element={
          <PrivateRoute>
            <Adds />
          </PrivateRoute>
        }
      />
      <Route
        path="/express"
        exact
        element={
          <PrivateRoute>
            <ExpressService />
          </PrivateRoute>
        }
      />
      <Route
        path="/express-details"
        exact
        element={
          <PrivateRoute>
            <ExpressServiceDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/express-reply"
        exact
        element={
          <PrivateRoute>
            <ExpressReply />
          </PrivateRoute>
        }
      />
      <Route
        path="/direct-award"
        exact
        element={
          <PrivateRoute>
            <Service />
          </PrivateRoute>
        }
      />
      <Route
        path="/direct-award-details"
        exact
        element={
          <PrivateRoute>
            <ServiceDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/direct-award-reply"
        exact
        element={
          <PrivateRoute>
            <ServiceProviderReply />
          </PrivateRoute>
        }
      />
      <Route
        path="/termscondition"
        exact
        element={
          <StaticRoute>
            <TermsCondition />
          </StaticRoute>
        }
      />
      <Route
        path="/privacypolicy"
        exact
        element={
          <StaticRoute>
            <PrivacyPolicy />
          </StaticRoute>
        }
      />
      <Route
        path="/service-review"
        exact
        element={
          <PrivateRoute>
            <ServiceReview />
          </PrivateRoute>
        }
      />
      <Route
        path="/payment"
        exact
        element={
          <PrivateRoute>
            <Payment />
          </PrivateRoute>
        }
      />
      <Route
        path="/estimation"
        exact
        element={
          <PrivateRoute>
            <Estimation />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default AppRouter;

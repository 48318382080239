import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./i18n/i18n";
import { history } from "./history";
import { BrowserRouter } from "react-router-dom";
// CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css";
// ** Redux Imports
import { Provider } from "react-redux";
import { store, persistor } from "./redux/storeConfig/store";
import { PersistGate } from "redux-persist/integration/react";


// ** React Toastify
import "react-toastify/dist/ReactToastify.css";
import PageAlert from "./component/common/PageAlert";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter
          history={history}
          basename={process.env.REACT_APP_BASENAME}
        >
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </BrowserRouter>
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

localStorage.openpages = Date.now();
const onLocalStorageEvent = (e) => {
  if (e.key === "openpages") {
    ReactDOM.render(<PageAlert />, document.getElementById("root"));
  }
};
window.addEventListener("storage", onLocalStorageEvent, false);

reportWebVitals();

if (
  window.location.pathname === "/" ||
  window.location.pathname === `${process.env.REACT_APP_BASENAME}` ||
  window.location.pathname === `${process.env.REACT_APP_BASENAME}/`
) {
  history.push(`${process.env.REACT_APP_BASENAME}/`);
}

/*
 * File: rfpAction.js
 * Author: Bhavin Prajapati
 * Created Date: November 24th, 2022
 * Last Modified Date: March 12th, 2024 
 * Description: This file contains the rfp actions.
 */

import { api } from "../../../api/api";
import {
  CREATE_RFP,
  UPLOAD_MEDIA_RFP,
  GET_AVAILABLE_RFP,
  GET_REPLY_RFP,
  GET_REPLY_DETAILS_RFP,
  GET_RECENT_RFP_API,
  SEND_PROPOSAL_RFP,
  GET_QUESTION_LIST_URL,
  CHECK_SP_AVAILABILITY,
  CANCEL_RFP_ORDER,
} from "../../../constants/ApiUrl";

/**
 * This API for upload media
 * @returns
 */
export const uploadRfpMediaApi = (data) => {
  return async () => {
    const response = await api(UPLOAD_MEDIA_RFP, data);
    return response;
  };
};

/**
 * This API for upload rfp create
 * @returns
 */
export const createRfpMediaApi = (data) => {
  return async () => {
    const response = await api(CREATE_RFP, data);
    return response;
  };
};

/**
 * This API for get available rfp
 * @returns
 */
export const getAvailableRfpApi = (data) => {
  let dataQuery = { ...data };
  for (const key in dataQuery) {
    if (
      dataQuery[key] === "" ||
      dataQuery[key] === null ||
      dataQuery[key] === undefined ||
      dataQuery[key].length === 0
    ) {
      delete dataQuery[key];
    }
  }
  return async () => {
    const response = await api(GET_AVAILABLE_RFP, {}, null, dataQuery);
    return response;
  };
};

/**
 * This API for get rfp reply list
 * @returns
 */
export const getReplyRfpApi = (data) => {
  let dataQuery = { ...data };
  for (const key in dataQuery) {
    if (
      dataQuery[key] === "" ||
      dataQuery[key] === null ||
      dataQuery[key] === undefined ||
      dataQuery[key].length === 0
    ) {
      delete dataQuery[key];
    }
  }
  return async () => {
    const response = await api(GET_REPLY_RFP, {}, null, dataQuery);
    return response;
  };
};

/**
 * This API for get rfp reply details view
 * @returns
 */
export const getReplyDetailsRfpApi = (data) => {
  return async () => {
    const response = await api(GET_REPLY_DETAILS_RFP, {}, null, data);
    return response;
  };
};

/**
 * This API for accept approval of rfp reply response which we get from service provider
 * @returns
 */
export const sendReplyProposalsRfpApi = (data) => {
  return async () => {
    const response = await api(SEND_PROPOSAL_RFP, data);
    return response;
  };
};

/**
 * This API for get rfp recent list
 * @returns
 */
export const getRfpRecentListing = (data) => {
  let dataQuery = { ...data };
  for (const key in dataQuery) {
    if (
      dataQuery[key] === "" ||
      dataQuery[key] === null ||
      dataQuery[key] === undefined ||
      dataQuery[key].length === 0
    ) {
      delete dataQuery[key];
    }
  }
  return async () => {
    const response = await api(GET_RECENT_RFP_API, {}, null, dataQuery);
    return response;
  };
};

/**
 * Retrieves the question list API.
 *
 * @param {Object} data - The data to be sent in the request.
 * @return {Promise} A promise that resolves with the response from the API call.
 */
export const getQuestionListApi = (data) => {
  return async () => {
    const response = await api(GET_QUESTION_LIST_URL, {}, null, data);
    return response;
  };
};

/**
 * Retrieves the availability of a service provider by making an API call.
 *
 * @param {Object} data - The data needed to retrieve the availability.
 * @return {Promise} A promise that resolves with the response from the API call.
 */
export const getSpAvailabilityApi = (data) => {
  return async () => {
    const response = await api(CHECK_SP_AVAILABILITY, {}, null, data);
    return response;
  };
};

/**
 * Cancels an RFP order by making an API call.
 *
 * @param {Object} data - The data needed to cancel the RFP order.
 * @return {Promise} A promise that resolves with the response from the API call.
 */
export const cancelRfpOrder = (data) => {
  return async () => {
    const response = await api(CANCEL_RFP_ORDER, data);
    return response;
  };
};
/**
 * Retrieves problem limitation data from the API.
 *
 * @param {Object} data - The data needed to retrieve the problem limitation data.
 * @return {Promise} A promise that resolves with the response from the API call.
 */
export const getProblemLimitationData = (data) => {
  return async () => {
    const response = await api(
      GET_PROBLEM_DESCRIPTION_LIMITATIONS,
      {},
      null,
      data
    );
    return response;
  };
};

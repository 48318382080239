import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
// Multilangual
import { useTranslation } from "react-i18next";

const NotificationPreviewModal = ({ modal, toggleModal, data }) => {
    const closeBtn = (
        <button className='close position-absolute' onClick={toggleModal} type='button'>
            &times;
        </button>
    )
    // Multilangual
    const { t } = useTranslation();
    return (
        <>
            <Modal
                backdropClassName="opw1"
                isOpen={modal}
                toggle={() => {
                    toggleModal();
                }}
                backdrop="static"
                keyboard={false}
                className="preview-model position-relative"
                centered
            >
                <ModalHeader className="d-flex align-align-items-baseline" close={closeBtn}>
                    <h2>{t('previewModel.title')} </h2>
                </ModalHeader>
                <ModalBody >
                        <p>
                            {data}
                        </p>
                </ModalBody>
            </Modal>
        </>
    );
};
export default NotificationPreviewModal;

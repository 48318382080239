/*
 * File: Profile.js
 * Author: Harsh Chauhan
 * Created Date: January 13th, 2023
 * Last Modified Date: February 15th, 2024 
 * Description: This component is responsible for displaying a profile page.
 */
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Form, Input, Label, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { phone } from "phone";

//* Get Location
import { usePosition } from "../../utility/hooks/usePosition";

//* Images scss
import Logo from "../../assets/images/logo/dark-logo.svg";
import Banner from "../../assets/images/custom/profile-banner.svg";
import { IoMdClose } from "react-icons/io";
import { FiPlus } from "react-icons/fi";

//* Multilangual
import { useTranslation } from "react-i18next";

//* page scss
import "./Profile.scss";
import FormInput from "../../component/Form/FormInput";
import {
  BranchNameRules,
  CompanyNameRules,
  ContactNameRules,
  DescriptionRules,
  EmailRules,
  IndustrySelectRules,
  PhoneNumberRules,
} from "../../constants/rules";

import { useForm, useFieldArray } from "react-hook-form";
import FormSelectInput from "../../component/Form/FormSelectInput";
import {
  editAttachmentApi,
  editProfileApi,
  editProfilePicApi,
  getIndustryApi,
  getProfileApi,
} from "../../redux/actions/profile/profileAction";
import { AttachIcon } from "../../assets/images/svg";
import {
  FILE_MAX_LIMIT,
  FILE_SIZE_LIMIT,
  GET_PROFILE_DATA,
  LANGUAGE_AR,
  LANGUAGE_EN,
  libraries,
  TOAST_AUTO_CLOSE,
  VALID_PROFILE_IMAGE_EXTENSIONS,
} from "../../constants/appConstants";
import ImageCropping from "../../component/modal/Croping/ImageCropping";
import MapContainer from "../../component/common/Map";
import {
  getAddressDetail,
  getAddressDetailFromLatLong,
} from "../../constants/GetAddressInfo";
import PlacesAutocomplete from "react-places-autocomplete";
import { useJsApiLoader } from "@react-google-maps/api";
import { toast } from "react-toastify";
import ProfileShimmer from "../../component/shimmer/ProfileShimmer";
import i18n from "../../i18n/i18n";
import FormCountryInput from "../../component/Form/FormCountryInput";
import Footer from "../../component/common/Footer";
import { removeFocus } from "../../constants/utils";

const Profile = () => {
  //* Multilangual
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  //* useForm
  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    watch,
    trigger,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      branches: [{}],
    },
  });

  //* Form branch
  const { fields, append } = useFieldArray({
    name: "branches",
    keyName: "key",
    control,
  });
  //* usePosition
  const { latitude, longitude, error } = usePosition();
  //* State variables
  const [attachDoc, setAttachDoc] = useState([]);
  const [defaultAttachDoc, setDefaultAttachDoc] = useState();
  const [defaultAreaValue, setDefaultAreaValue] = useState("");
  const [createdAttach, setCreatedAttach] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [profileImage, setProfileImage] = useState();
  const [defaultProfileImage, setDefaultProfileImage] = useState();
  const [defaultPhoneNo, setDefaultPhoneNo] = useState("");
  const [branchFlag, setBranchFlag] = useState(false);
  const [defaultBranches, setDefaultBranches] = useState([]);
  const [cropModal, setCropModal] = useState(false);
  const [image, setImage] = useState(null);
  const [imgType, setImgType] = useState("");
  const [checkProfile, setCheckProfile] = useState(false);
  const [address, setAddress] = useState("");
  const [fullCustomerAddress, setFullCustomerAddress] = useState({});
  const [mapLat, setMapLat] = useState("");
  const [mapLong, setMapLong] = useState("");
  const [mainDragIndex, setMainDragIndex] = useState();
  const [selectCustomer, setSelectCustomer] = useState(null);

  const [branchMapLat, setBranchMapLat] = useState("");
  const [branchMapLong, setBranchMapLong] = useState("");
  const [branchDragIndex, setBranchDragIndex] = useState();
  const [areaValue, setAreaValue] = useState("");
  const [deletedBranch, setDeletedBranch] = useState([]);
  const [removedAttach, setRemovedAttach] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  const [numberValue, setNumberValue] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [map, setMap] = useState(null);

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();
  const branchFinalData = watch("branches");
  const phoneNoVal = watch("phoneNo");

  //* disable/enable save button based on area value
  useEffect(() => {
    if (defaultBranches?.length === 0) {
      if (areaValue !== defaultAreaValue) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
    for (let i = 0; i < defaultBranches.length; i++) {
      const branch = defaultBranches[i];
      if (areaValue !== defaultAreaValue) {
        setIsDisabled(false);
        break; //* Exit the loop
      } else if (branch.area !== branchFinalData?.[i]?.area) {
        setIsDisabled(false);
        break; //* Exit the loop
      } else {
        setIsDisabled(true);
      }
    }
  }, [branchFinalData, areaValue]);

  //* useEffect to update map coordinates and fetch profile details when latitude or longitude changes.
  useEffect(() => {
    setMapLat(latitude);
    setMapLong(longitude);
    setBranchMapLat(latitude);
    setBranchMapLong(longitude);
    getProfileDetails();
  }, [latitude, longitude]);

  //* useEffect to get address from latitude and longitude when mainDragIndex changes.
  useEffect(() => {
    if (mainDragIndex) getAddressFromLatLong();
  }, [mapLat, mapLong, mainDragIndex]);

  //* useEffect to get branch address from latitude and longitude when branchDragIndex changes.
  useEffect(() => {
    if (branchDragIndex >= 0) {
      if (branchFlag) {
        setBranchMapLat(latitude || 31.9454);
        setBranchMapLong(longitude || 35.9284);
        setBranchFlag(false);
      } else {
        getBranchAddressFromLatLong();
      }
    }
  }, [branchMapLat, branchMapLong, branchFlag]);

  /*
   * Get all category listing in select box
   */
  useEffect(() => {
    if (selectCustomer) getCategoryDropdown(selectCustomer);
  }, [selectCustomer]);

  const onClickAccess = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log(position);
    });
  };
  /*
   * Function for upload Attachment and Manage
   * @param {*} e
   */
  const onAttachChange = async (e) => {
    if (
      e.target.files[0].type !== "image/jpeg" &&
      e.target.files[0].type !== "image/png" &&
      e.target.files[0].type !== "application/pdf"
    ) {
      toast.error(t("errorMessages.fileType"), {
        autoClose: TOAST_AUTO_CLOSE,
      });
      return;
    }
    if (attachDoc.length + e.target.files.length > FILE_MAX_LIMIT) {
      toast.error(t("errorMessages.fileNumber"), {
        autoClose: TOAST_AUTO_CLOSE,
      });
      return;
    }
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > FILE_SIZE_LIMIT) {
        toast.error(t("errorMessages.fileSize"), {
          autoClose: TOAST_AUTO_CLOSE,
        });
        return;
      }
    }
    setLoading(true);
    const formData = new FormData();
    const data = [];
    for (let i = 0; i < e.target.files.length; i++) {
      data.push(e.target.files[i]);
      formData.append("attachments", e.target.files[i]);
    }
    const res = await dispatch(editAttachmentApi(formData));
    if (!res.data.error) {
      let temp = [...res.data.data];
      setAttachDoc([...attachDoc, ...res?.data?.data]);
      setCreatedAttach([...createdAttach, ...res?.data?.data]);
    }
    setLoading(false);
  };
  /*
   * Function for Delete Attachments
   * @param {*} i
   * @param {*} id
   */
  const deleteAttachments = (i, id) => {
    let oldArray = [...attachDoc];
    oldArray.splice(i, 1);
    setAttachDoc(oldArray);
    setRemovedAttach([...removedAttach, id]);
  };

  /*
   * Function for loading google map api
   */
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: libraries,
  });

  /*
   * Function and state for profile picture management
   * @param {*} e
   */
  const profileImageHandler = async (e) => {
    const reader = new FileReader();
    const files = e.target.files;
    let type = "";
    if (e.target.files[0].type === "image/jpeg") {
      type = "jpg";
    }
    if (e.target.files[0].type === "image/png") {
      type = "png";
    }
    if (e.target.files[0].type === "image/svg+xml") {
      type = "svg";
    }
    reader.onload = function () {
      setProfileImage(reader.result);
      e.target.value = "";
    };
    reader.readAsDataURL(files[0]);
    setCropModal(true);
  };

  /*
   * Function for Manage crop functionality of image
   */
  const handleUploadCalled = async () => {
    const formData = new FormData();

    formData.append("profile", image);
    let res = await dispatch(editProfilePicApi(formData));
    if (!res.data?.error) {
      setProfileImage(res.data?.data?.url);
      setCropModal(false);
    }
    setLoading(false);
  };
  /*
   * Function is used for crop profile image
   * @param {*} image
   * @param {*} fileName
   */
  const handleCropImage = async (image, fileName) => {
    setImage(image);
  };

  /*
   * Function for manage selected categories
   */
  const getCategoryDropdown = async (id) => {
    setLoading(true);
    const res = await dispatch(getIndustryApi({ customerType: id }));
    if (!res.data.error) {
      const temp = [...res.data.data].map((e) => ({
        id: e?.industryId,
        name: e.industryEnglishName,
        arabicName: e.industryArabicName,
        frenchName: e.industryFrenchName,
      }));
      setCategoryData([...temp]);
      getProfileDetails();
    }
    setLoading(false);
  };

  /*
   * Function for get profile details
   */
  const getProfileDetails = async () => {
    setLoading(true);
    let res = await dispatch(getProfileApi());
    if (!res.data.error) {
      setAttachDoc(res?.data?.data?.attachments || []);
      setProfileImage(res.data.data.profilePhoto);
      setDefaultProfileImage(res.data.data.profilePhoto);
      let temp = res.data.data.industry.map((item) => item.industryId);
      setProfileData({ ...res.data.data });
      setMapLat(res.data.data.latitude);
      setNumberValue(`${res.data.data.countryCode}${res.data.data.phoneNo}`);
      setMapLong(res.data.data.longitude);
      setDefaultAreaValue(res.data.data?.area);
      setDefaultAttachDoc(res?.data?.data?.attachments?.length || 0);

      setAreaValue(res.data.data?.area);
      if (res.data.data.customerType && res.data.data.customerType.id)
        setSelectCustomer(res.data.data.customerType?.id);
      let branchTemp = [...res.data.data.subBranches];
      for (let i = 0; i < branchTemp.length; i++) {
        const phoneNumber =
          (branchTemp[i]?.countryCode || "") + (branchTemp[i]?.phoneNo || "");
        branchTemp[i]["phoneNo"] = phoneNumber;
      }
      setDefaultBranches([...branchTemp]);
      setDefaultPhoneNo(`${res.data.data.countryCode}${res.data.data.phoneNo}`);

      reset({
        ...res.data.data,
        customerType:
          i18n.language === LANGUAGE_EN
            ? res.data.data?.customerType?.name
            : i18n.language === LANGUAGE_AR
            ? res.data.data?.customerType?.arabicName
            : res.data.data?.customerType?.frenchName,
        industry: temp,
        branches: branchTemp,
        phoneNo: `${res.data.data.countryCode}${res.data.data.phoneNo}`,
      });
      dispatch({
        type: GET_PROFILE_DATA,
        payload: { data: res.data.data },
      });
    }
    setLoading(false);
  };

  const profileListData = useSelector(
    (state) => state?.profileReducer?.profile
  );

  /*
   * profile api flag set
   */
  useEffect(() => {
    if (profileListData?.profilePhoto === profileImage) {
      setCheckProfile(false);
    } else {
      setCheckProfile(true);
    }
  }, [profileListData?.profilePhoto, profileImage]);

  /*
   * Handle the remove branch event
   * @param {*} index
   * @param {*} field
   */
  const handleRemoveBranch = (index, field) => {
    const tempValues = [...branchFinalData];
    const value = getValues();
    const filteredBranches = tempValues?.filter((e) => e?.id !== field.id);

    reset({ ...value, branches: filteredBranches });

    let temp = { ...field };
    temp.deleted = true;
    setDeletedBranch([...deletedBranch, temp]);
    setBranchFlag(false);
  };

  /*
   * Function for submit the all field in form
   * @param {obj} data
   */
  const onFormSubmit = async (data) => {
    setLoading(true);
    let areaError = false;
    const FinalVal = phone(`+${phoneNoVal}`);
    const value = getValues();
    //* remove white spaces from email
    const tempBranch = [...data.branches]?.map((e) => ({
      ...e,
      email: e?.email?.trim(),
    }));
    tempBranch.map((item, i) => {
      if (!item.area) {
        areaError = true;
      }
    });
    if (areaError) {
      setLoading(false);
      return;
    }
    setMainDragIndex(false);
    if (!areaValue) {
      setLoading(false);
      return;
    }
    tempBranch.map((item, i) => {
      const finalBranchVal = phone(`+${item?.phoneNo}`);
      item.phoneNo = finalBranchVal?.phoneNumber?.replace(
        finalBranchVal?.countryCode,
        ""
      );
      item.countryCode = finalBranchVal?.countryCode;
    });

    const tempCategory = [...data?.industry];
    let final = [];
    if (tempCategory && tempCategory.length) {
      tempCategory.map((item) => {
        final.push(item?.id);
      });
    }

    let reqObj = {
      name: data.name,
      companyName: data.companyName,
      email: data.email,
      phoneNo: FinalVal?.phoneNumber?.replace(FinalVal?.countryCode, "") || "",
      countryCode: FinalVal?.countryCode,
      description: data.description,
      latitude:
        mapLat || fullCustomerAddress?.lat || profileData.latitude || 31.9454,
      longitude:
        mapLong ||
        fullCustomerAddress?.long ||
        profileData.longitude ||
        35.9284,
      country: data.country,
      city: data.city,
      area: areaValue,
      branchName: data.branchName,
      profilePhoto: profileImage,
      isProfilepicUploaded: checkProfile,
      industry: final,
      isAttachments: attachDoc && attachDoc.length > 0,
      attachment: {
        created: [...createdAttach],
        removed: [...removedAttach],
      },
      branches: [...tempBranch, ...deletedBranch],
    };
    const res = await dispatch(editProfileApi(reqObj));

    if (!res.data.error) {
      getProfileDetails();
      dispatch({
        type: GET_PROFILE_DATA,
        payload: { data: res.data.data },
      });
    }
    reset({ ...value, branches: tempBranch });
    setLoading(false);
  };
  useEffect(() => {
    getProfileDetails();
  }, [i18n.language]);

  /*
   * Get selected address latitude and longitude
   */
  const getAddressFromLatLong = async () => {
    const value = getValues();
    let address = await getAddressDetailFromLatLong({
      lat: mapLat,
      long: mapLong,
      map: map,
    });
    if (!address.line1) {
      address.line1 = address.city;
    } else {
      address.line1 = address.line1;
    }
    setFullCustomerAddress(address);
    setAreaValue(address.placeName ? address.placeName : address.city);
    reset({ ...value, city: address.city, country: address.country });
  };

  /*
   * Get selected address branch name as par latitude and longitude
   */
  const getBranchAddressFromLatLong = async () => {
    let index = branchDragIndex;

    let address = await getAddressDetailFromLatLong({
      lat: branchMapLat,
      long: branchMapLong,
      map,
    });

    const value = getValues();

    let temp = [...value.branches];

    setAddress(address);
    if (Object.values(address).filter((e) => e !== "").length) {
      if (!address.line1) {
        temp[index].area = address.city;
      } else {
        temp[index].area = address.placeName || address.line1;
      }
      temp[index].latitude = address.lat;
      temp[index].longitude = address.long;
      temp[index].city = address.city;
      temp[index].country = address.country;

      reset({ ...value, branches: temp });
      setBranchDragIndex(null);
    }
  };

  /*
   * Change branches adress function
   * @param {*} address
   * @param {*} index
   */
  const changeBranchesAddress = async (address, index) => {
    const value = getValues();
    let temp = [...value.branches];
    temp[index].area = address;

    reset({ ...value, branches: temp });
    setAddress(address);
  };
  /*
   * Change the address and set the area value.
   *
   * @param {string} address - The new address.
   * @return {Promise<void>} - A promise that resolves when the address is changed and the area value is set.
   */
  const changeAddress = async (address) => {
    setAddress(address);
    setAreaValue(address);
  };

  /*
   * Selects a branch address and updates the state accordingly.
   *
   * @param {string} address - The address to select.
   * @param {number} index - The index of the branch in the branches array.
   * @return {Promise<void>} - A promise that resolves when the state is updated.
   */
  const selectBranchAddress = async (address, index) => {
    const value = getValues();

    let temp = [...value.branches];
    temp[index].area = address;

    setBranchMapLat("");
    setBranchMapLong("");
    setAddress(address);
    const result = await getAddressDetail({ address, map });
    if (!result.line1) {
      temp[index].area = result.city;
    } else {
      temp[index].area = result.placeName || result.line1;
    }
    temp[index].latitude = result.lat;
    temp[index].longitude = result.long;
    temp[index].city = result.city;
    temp[index].country = result.country;

    reset({ ...value, branches: temp });
  };

  /*
   * Select address from google lat, long
   * @param {*} address
   */
  const selectAddress = async (address) => {
    const value = getValues();
    setAddress(address);
    const result = await getAddressDetail({ address, map });
    setMapLat(result.lat);
    setMapLong(result.long);
    if (!result.line1) {
      result.line1 = result?.city;
    } else {
      result.line1 = result.line1;
    }
    setAreaValue(result.placeName ? result.placeName : result.city);
    reset({
      ...value,
      city: result.city,
      country: result.country,
    });
    setFullCustomerAddress(result);
  };

  return (
    <>
      <Helmet>
        <title>EJAD - Profile</title>
      </Helmet>
      <div className="profile-section common-design-section">
        <div className="common-design-section-data">
          <div className="left-design-data hide-in-mobile">
            <img src={Banner} className="img-fluid log-img" alt="images" />
            <div className="box-data">
              <div className="data">
                <h2 className="mb-0">
                  {profileData?.customerType?.name === "Commercial"
                    ? t("profilePage.leftData.heading")
                    : t("profilePage.leftData.customerHeading")}
                </h2>
              </div>
            </div>
          </div>
          <div className="right-design-data">
            <div className="spacing-right-data">
              <div className="box">
                <div className="logo-div text-center">
                  <img
                    src={Logo}
                    className="img-fluid main-logo"
                    alt="images"
                  />
                </div>
                <Form
                  className="mb-0"
                  onSubmit={handleSubmit(onFormSubmit)}
                  noValidate
                >
                  {!isLoading ? (
                    <>
                      <div className="upload-div">
                        <Label
                          htmlFor="profileImage"
                          style={{
                            background: `url(${profileImage})`,
                          }}
                          className={profileImage ? "has" : ""}
                        >
                          {!profileImage && t("profile.uploadLabel")}
                        </Label>
                        <Input
                          type="file"
                          id="profileImage"
                          hidden
                          accept={VALID_PROFILE_IMAGE_EXTENSIONS}
                          onChange={(e) => profileImageHandler(e)}
                        />
                        <span className="main-label">
                          {profileData?.customerType?.name === "Commercial"
                            ? t("profile.companyProfileLabel")
                            : t("profile.customerProfileLabel")}
                        </span>
                      </div>
                      <div className="field-group mb40">
                        <span className="main-label">
                          {t("signupPage.companyInfoLabel")}
                        </span>
                        <FormInput
                          fgClass="mb20"
                          id="companyName"
                          name="companyName"
                          type="text"
                          label={t("signupPage.companyNameLabel")}
                          placeholder={t("signupPage.companyNameLabel")}
                          required={true}
                          rules={CompanyNameRules}
                          register={register}
                          maxLength={50}
                          errors={errors}
                        />
                        <FormInput
                          fgClass="mb20"
                          id="customerType"
                          name="customerType"
                          type="text"
                          label={t("signupPage.customerTypeLabel")}
                          placeholder={t("signupPage.customerTypeLabel")}
                          required={true}
                          rules={{}}
                          register={register}
                          errors={errors}
                          disabled
                        />
                        <FormSelectInput
                          fgClass="mb20"
                          multiple={true}
                          control={control}
                          name={"industry"}
                          label={t("signupPage.industryTypeLabel")}
                          placeholder={t("signupPage.industryTypeLabel")}
                          options={categoryData}
                          errors={errors}
                          optionValue="id"
                          optionLabel={`${
                            i18n.language === LANGUAGE_EN
                              ? "name"
                              : i18n.language === LANGUAGE_AR
                              ? "arabicName"
                              : "frenchName"
                          }`}
                          required={true}
                          isSearchable={false}
                          rules={IndustrySelectRules}
                        />
                      </div>
                      <div className="field-group mb40">
                        <span className="main-label">
                          {t("profile.contactPersonLabel")}
                        </span>
                        <FormInput
                          fgClass="mb20"
                          id="name"
                          name="name"
                          type="text"
                          label={t("profile.contactNameLabel")}
                          placeholder={t("profile.contactNameLabel")}
                          required={true}
                          rules={ContactNameRules}
                          register={register}
                          errors={errors}
                        />
                        <FormCountryInput
                          fgClass="mb20"
                          required={true}
                          id="phoneNo"
                          name="phoneNo"
                          label={t("signupPage.contactNumberLabel")}
                          placeholder={t("signupPage.contactNumberLabel")}
                          rules={PhoneNumberRules}
                          errors={errors}
                          register={register}
                          control={control}
                        />
                        <FormInput
                          fgClass="mb-0"
                          id="email"
                          name="email"
                          type="email"
                          label={t("signupPage.contactEmailLabel")}
                          placeholder={t("signupPage.contactEmailLabel")}
                          required={true}
                          rules={EmailRules}
                          register={register}
                          errors={errors}
                          disabled={true}
                        />
                      </div>
                      {profileData?.customerType?.name === "Commercial" ? (
                        <div className="field-group mb40">
                          <span className="main-label">
                            {t("profile.aboutCompanyLabel")}
                          </span>
                          <FormInput
                            fgClass="mb-0"
                            id="description"
                            name="description"
                            type="textarea"
                            placeholder={t("profile.aboutLabel")}
                            required={true}
                            rules={DescriptionRules}
                            register={register}
                            errors={errors}
                          />
                        </div>
                      ) : null}
                      <div className="field-group mb40">
                        <span className="main-label">
                          {t("signupPage.attachLabel")}
                        </span>
                        {attachDoc && attachDoc.length > 0 && (
                          <ul className="listing-attach">
                            {attachDoc.map((item, i) => {
                              return (
                                <li key={i}>
                                  <div className="list-data">
                                    <div
                                      className="one"
                                      onClick={() =>
                                        deleteAttachments(i, item.id)
                                      }
                                    >
                                      <IoMdClose />
                                    </div>
                                    <div className="two">{item.fileName}</div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                        <div className="cu-form-group mb-0">
                          <Input
                            type="file"
                            hidden
                            id="attach"
                            multiple
                            onChange={onAttachChange}
                          />
                          <Label for="attach" className="attach-btns">
                            <AttachIcon />
                            {t("signupPage.attachBtn")}
                          </Label>
                        </div>
                      </div>
                      <div className="field-group mb20">
                        <span className="main-label">
                          {profileData?.customerType?.name === "Commercial"
                            ? t("profile.branchData.mainBranchTitle")
                            : t("profile.branchData.branchTitle")}
                        </span>

                        {!error && !latitude ? (
                          <div className="allow-box">
                            <p>{t("profile.branchData.allowAcessMsg")}</p>
                            <Button
                              color="main"
                              className="cu-btn cu-sm-btn br15 min150"
                              type="button"
                              onClick={() => onClickAccess()}
                            >
                              {t("profile.branchData.allowAccessBtn")}
                            </Button>
                          </div>
                        ) : (
                          <div className="map-box">
                            <MapContainer
                              latitude={
                                mapLat ||
                                profileData.latitude ||
                                fullCustomerAddress?.lat ||
                                31.9454
                              }
                              longitude={
                                mapLong ||
                                profileData.longitude ||
                                fullCustomerAddress?.long ||
                                35.9284
                              }
                              setMapLat={(val) => setMapLat(val)}
                              setMapLong={(val) => setMapLong(val)}
                              setMap={(val) => setMap(val)}
                              setMainDragIndex={() => setMainDragIndex("main")}
                              mapLat={mapLat}
                              mapLong={mapLong}
                              id={`area${profileData.id}`}
                              draggable={true}
                              removeFocus={() =>
                                removeFocus([
                                  `area${profileData.id}`,
                                  "branchName",
                                  "phoneNo",
                                  "name",
                                ])
                              }
                            />
                          </div>
                        )}
                        <Row>
                          <Col md={4}>
                            {isLoaded ? (
                              <div className="flex-fill">
                                <PlacesAutocomplete
                                  value={address}
                                  onChange={changeAddress}
                                  onSelect={(val) => selectAddress(val)}
                                  searchOptions={{}}
                                >
                                  {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                  }) => (
                                    <div className="cu-form-group floating-label-group mb20">
                                      <Label
                                        htmlFor={`area${profileData.id}`}
                                        className={`floating-label  ${
                                          areaValue ? "show" : ""
                                        }`}
                                      >
                                        {t("signupPage.areaLabel")}
                                      </Label>

                                      <Input
                                        {...getInputProps({
                                          placeholder: t(
                                            "signupPage.searchText"
                                          ),
                                          className: "location-search-input",
                                          id: `area${profileData.id}`,
                                          name: "area",
                                        })}
                                        value={areaValue}
                                      />

                                      <span className="required abs">*</span>

                                      <div className="autocomplete-dropdown-container">
                                        {suggestions.map((suggestion, i) => {
                                          const className = suggestion.active
                                            ? "suggestion-item--active"
                                            : "suggestion-item";
                                          //* inline style for demonstration purpose
                                          const style = suggestion.active
                                            ? {
                                                backgroundColor: "#fafafa",
                                                cursor: "pointer",
                                              }
                                            : {
                                                backgroundColor: "#ffffff",
                                                cursor: "pointer",
                                                color: "#7a8897",
                                              };
                                          return (
                                            <div
                                              key={i}
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className,
                                                  style,
                                                }
                                              )}
                                            >
                                              <span>
                                                {suggestion.description}
                                              </span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                      {!areaValue && (
                                        <span className="error-msg">
                                          {t("errorMessages.areaRequired")}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </PlacesAutocomplete>
                              </div>
                            ) : null}
                          </Col>
                          <Col md={4}>
                            <FormInput
                              fgClass="mb20"
                              id="city"
                              name="city"
                              type="text"
                              label={t("signupPage.cityLabel")}
                              placeholder={t("signupPage.cityLabel")}
                              required={true}
                              rules={{}}
                              register={register}
                              errors={errors}
                              disabled
                            />
                          </Col>
                          <Col md={4}>
                            <FormInput
                              fgClass="mb20"
                              id="country"
                              name="country"
                              type="text"
                              label={t("signupPage.countryLabel")}
                              placeholder={t("signupPage.countryLabel")}
                              required={true}
                              rules={{}}
                              register={register}
                              errors={errors}
                              value={fullCustomerAddress?.country}
                              disabled
                            />
                          </Col>
                        </Row>
                        <FormInput
                          fgClass="mb20"
                          id={`branchName`}
                          name={`branchName`}
                          type="text"
                          label={t("profile.branchData.branchTitle")}
                          placeholder={t("profile.branchData.branchName")}
                          required={true}
                          rules={BranchNameRules}
                          register={register}
                          errors={errors}
                          maxLength={50}
                        />
                      </div>
                      {/* branch data */}
                      {fields.map((field, index) => {
                        return (
                          <div className="field-group mb20" key={index}>
                            <div className="branch-header">
                              <div
                                className="close-branch"
                                onClick={() => handleRemoveBranch(index, field)}
                              >
                                <IoMdClose />
                              </div>
                              <div className="two">
                                <span className="main-label mb-0">
                                  {t("profile.branchData.branchTitle")} #
                                  {index + 2}
                                </span>
                              </div>
                            </div>
                            {latitude || error ? (
                              <div className="map-box">
                                <MapContainer
                                  latitude={
                                    field.latitude ||
                                    branchMapLat ||
                                    fullCustomerAddress?.lat
                                  }
                                  longitude={
                                    field.longitude ||
                                    branchMapLong ||
                                    fullCustomerAddress?.long
                                  }
                                  setMapLat={(val) => setBranchMapLat(val)}
                                  setMapLong={(val) => setBranchMapLong(val)}
                                  setBranchDragIndex={() =>
                                    setBranchDragIndex(index)
                                  }
                                  fromSubBranch={true}
                                  mapLat={branchMapLat}
                                  mapLong={branchMapLong}
                                  id={`area${field.key}`}
                                  draggable={true}
                                  removeFocus={() =>
                                    removeFocus([
                                      `branches.${index}.area`,
                                      `branches.${index}.branchName`,
                                      `branches.${index}.phoneNo`,
                                      `branches.${index}.name`,
                                      `branches.${index}.email`,
                                    ])
                                  }
                                />
                              </div>
                            ) : (
                              <div className="allow-box">
                                <p>{t("profile.branchData.allowAcessMsg")}</p>
                                <Button
                                  color="main"
                                  className="cu-btn cu-sm-btn br15 min150"
                                  type="button"
                                  onClick={() => onClickAccess()}
                                >
                                  {t("profile.branchData.allowAccessBtn")}
                                </Button>
                              </div>
                            )}
                            <Row>
                              <Col md={4}>
                                {" "}
                                {isLoaded ? (
                                  <div className="flex-fill">
                                    <PlacesAutocomplete
                                      value={address}
                                      onChange={(value) =>
                                        changeBranchesAddress(value, index)
                                      }
                                      onSelect={(val) =>
                                        selectBranchAddress(val, index)
                                      }
                                    >
                                      {({
                                        getInputProps,
                                        suggestions,
                                        getSuggestionItemProps,
                                        loading,
                                      }) => (
                                        <div className="cu-form-group floating-label-group mb20">
                                          <Label
                                            className="floating-label show"
                                          >
                                            {t("signupPage.areaLabel")}
                                          </Label>

                                          <Input
                                            {...getInputProps({
                                              placeholder: t(
                                                "signupPage.searchText"
                                              ),
                                              className:
                                                "location-search-input",
                                              id: `branches.${index}.area`,
                                              name: `branches.${index}.area`,
                                            })}
                                            value={field.area}
                                          />

                                          <span className="required abs">
                                            *
                                          </span>
                                          <div className="autocomplete-dropdown-container">
                                            {suggestions.map(
                                              (suggestion, i) => {
                                                const className =
                                                  suggestion.active
                                                    ? "suggestion-item--active"
                                                    : "suggestion-item";
                                                //* inline style for demonstration purpose
                                                const style = suggestion.active
                                                  ? {
                                                      backgroundColor:
                                                        "#fafafa",
                                                      cursor: "pointer",
                                                    }
                                                  : {
                                                      backgroundColor:
                                                        "#ffffff",
                                                      cursor: "pointer",
                                                      color: "#7a8897",
                                                    };
                                                return (
                                                  <div
                                                    key={i}
                                                    {...getSuggestionItemProps(
                                                      suggestion,
                                                      {
                                                        className,
                                                        style,
                                                      }
                                                    )}
                                                  >
                                                    <span>
                                                      {suggestion.description}
                                                    </span>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>

                                          {!field?.area && (
                                            <span className="error-msg">
                                              {t("errorMessages.areaRequired")}
                                            </span>
                                          )}
                                        </div>
                                      )}
                                    </PlacesAutocomplete>
                                  </div>
                                ) : null}
                              </Col>

                              <Col md={4}>
                                <FormInput
                                  fgClass="mb20"
                                  id={`branches.${index}.city`}
                                  name={`branches.${index}.city`}
                                  type="text"
                                  label={t("signupPage.cityLabel")}
                                  placeholder={t("signupPage.cityLabel")}
                                  required={true}
                                  rules={{}}
                                  register={register}
                                  errors={errors}
                                  disabled
                                />
                              </Col>
                              <Col md={4}>
                                <FormInput
                                  fgClass="mb20"
                                  id={`branches.${index}.country`}
                                  name={`branches.${index}.country`}
                                  type="text"
                                  label={t("signupPage.countryLabel")}
                                  placeholder={t("signupPage.countryLabel")}
                                  required={true}
                                  rules={{}}
                                  register={register}
                                  errors={errors}
                                  disabled
                                />
                              </Col>
                            </Row>
                            <FormInput
                              fgClass="mb20"
                              id={`branches.${index}.branchName`}
                              name={`branches.${index}.branchName`}
                              type="text"
                              label={t("profile.branchData.branchTitle")}
                              placeholder={t("profile.branchData.branchName")}
                              required={true}
                              rules={BranchNameRules}
                              register={register}
                              errors={errors}
                              errorMsg={
                                errors["branches"]?.[index]?.["branchName"]
                                  ?.message
                              }
                              maxLength={50}
                            />
                            <FormInput
                              fgClass="mb20"
                              id={`branches.${index}.name`}
                              name={`branches.${index}.name`}
                              type="text"
                              label={t("profile.contactNameLabel")}
                              placeholder={t("profile.contactNameLabel")}
                              required={true}
                              rules={ContactNameRules}
                              register={register}
                              errors={errors}
                              errorMsg={
                                errors["branches"]?.[index]?.["name"]?.message
                              }
                            />
                            <FormCountryInput
                              fgClass="mb20"
                              required={true}
                              id={`branches.${index}.phoneNo`}
                              name={`branches.${index}.phoneNo`}
                              label={t("signupPage.contactNumberLabel")}
                              placeholder={t("signupPage.contactNumberLabel")}
                              rules={PhoneNumberRules}
                              errors={errors}
                              register={register}
                              control={control}
                              value={`${`branches.${index}.countryCode`}${`branches.${index}.phoneNo`}`}
                              errorMsg={
                                errors["branches"]?.[index]?.["phoneNo"]
                                  ?.message
                              }
                            />
                            <FormInput
                              fgClass="mb-0"
                              id={`branches.${index}.email`}
                              name={`branches.${index}.email`}
                              type="text"
                              label={t("signupPage.contactEmailLabel")}
                              placeholder={t("signupPage.contactEmailLabel")}
                              required={true}
                              rules={EmailRules}
                              register={register}
                              errors={errors}
                              errorMsg={
                                errors["branches"]?.[index]?.["email"]?.message
                              }
                            />
                          </div>
                        );
                      })}

                      <div className="field-group mb40 add-branch-btn">
                        <Button
                          color="main-light"
                          className="cu-btn br20 w-100"
                          type="button"
                          onClick={async () => {
                            const result = await trigger("branches");
                            result &&
                              append({
                                name: "",
                                email: "",
                                branchName: "",
                                phoneNo: phoneNoVal,
                              });
                            setBranchDragIndex(fields.length);
                            setBranchFlag(true);
                          }}
                        >
                          <span className="plus-icon">
                            <FiPlus size="18" />
                          </span>
                          <span className="text">
                            {t("profile.branchData.addBranchBtn")}
                          </span>
                        </Button>
                      </div>
                      <div className="cu-form-group mb-0 text-center">
                        <Button
                          color={`${
                            !isDirty &&
                            defaultAttachDoc === attachDoc?.length &&
                            isDisabled &&
                            profileImage === defaultProfileImage
                              ? "dis-new"
                              : "main"
                          }`}
                          className="cu-btn br20 w-100"
                          type="submit"
                          disabled={
                            !isDirty &&
                            defaultAttachDoc === attachDoc?.length &&
                            isDisabled &&
                            profileImage === defaultProfileImage
                          }
                        >
                          {t("btnLabel.saveandproceed")}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <ProfileShimmer />
                  )}
                </Form>
              </div>
            </div>
            {/* Common Footer */}
            <Footer />
          </div>
        </div>
      </div>
      {cropModal && (
        <ImageCropping
          isOpen={cropModal}
          className="modal-dialog-centered"
          src={profileImage}
          toggle={() => setCropModal(!cropModal)}
          cancelClick={() => {
            setCropModal(!cropModal);
            getProfileDetails();
          }}
          doneClick={handleUploadCalled}
          doneBtnColor="main"
          cancelBtnColor="main-outline"
          isHorizontal={true}
          setCropImage={handleCropImage}
          ext={imgType}
        />
      )}
    </>
  );
};

export default Profile;

/*eslint-disable */
import axios from "axios";
import { toast, Slide } from "react-toastify";
import Cookies from "js-cookie";
import {
  clearUserCookies,
  convertObjToQueryString,
  isObjEmpty,
} from "../constants/utils";
import { TOAST_AUTO_CLOSE } from "../constants/appConstants";
import i18n from "i18next";
const URLS = new Map();
URLS.set("USER", process.env.REACT_APP_API_URL_USER);
// URLS.set("NOTIFICATION", process.env.REACT_APP_API_URL_NOTIFICATION);
// URLS.set("PAYMENT", process.env.REACT_APP_API_URL_PAYMENT);
/**
 *
 * @param {Object} endpoint (One Obj get from ApiUrls)
 * @param {Object} data (data that we send in request... Generally used in POST, PUT methods)
 * @param {String} id (append data to URL...usually used tn PUT methods)
 * @param {Object} params (generates query string from the object)
 * @returns
 */
export const api = async (endpoint, data, id = null, params = null) => {
  const { method, isMultipart, url, showToast, msg, module, isWithStaticToken = false } = endpoint;
  let res = null;
  // let deviceId = getLocalStorage("pwFingerPrintVisitorId");

  let token = Cookies.get("ejadCustomerToken");
  try {
    let obj = {
      data,
      method: method,
      headers: {
        "Content-Type": isMultipart
          ? "multipart/form-data"
          : "application/json",
        lang: i18n.language,
        
      },

      url:
        URLS.get(module) +
        url +
        (id ? id : "") +
        (params ? convertObjToQueryString(params) : ""),
    };

    if (method === "GET" && data && typeof data === "string") {
      obj.url += data;
    }
    if (method === "POST") {
      if (!data) data = {};
      // data.deviceId = deviceId;
    }

    if (token)
      obj.headers.Authorization = `Bearer ${Cookies.get("ejadCustomerToken")}`;
    if (isWithStaticToken)
      obj.headers.Authorization = process.env.REACT_APP_STATIC_AUTH_TOKEN;
    res = await axios(obj);
  } catch (err) {
    res = err.response;
    const { error, message, status } = res?.data;
    console.log(error);

    try {
      showToast &&
        toast.error(
          !isObjEmpty(error)
            ? error[Object.keys(error)[0]][0]
            : message
              ? message
              : "Something went wrong!",
          {
            transition: Slide,
            autoClose: TOAST_AUTO_CLOSE,
          }
        );

      if (status === 401 || status === 403) {
        clearUserCookies();
        window.location.replace(`${process.env.REACT_APP_BASENAME}/landing`);
      }
      return {
        data: {
          error: true,
          data: res?.data?.data,
          isServiceProvider: res?.data?.isServiceProvider,
        },
      };
    } catch (error) {
      return {
        data: { error: true, data: res.data.data },
      };
    }
  }

  if (res && res.data && !res.data.error && showToast)
    toast.success(res.data.message, {
      transition: Slide,
      autoClose: TOAST_AUTO_CLOSE,
    });

  return res;
};

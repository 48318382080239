/*
 * File: homeAction.js
 * Author: Harsh Chauhan
 * Created Date: November 14th, 2022
 * Last Modified Date: February 29th, 2024
 * Description: This file contains the home actions.
 */

import { api } from "../../../api/api";
import {
  GET_ONBOARDING_SCREENS,
  HEADER_NOTIFICATION_GET,
  HEADER_NOTIFICATION_POST,
  HOME_BANNER_GET,
  PROVIDER_REQUEST_GET,
  TOP_SERVICE_PROVIDER_GET,
  MARK_ALL_AS_READ,
} from "../../../constants/ApiUrl";

/**
 *  This API get all the banner images in landing page and if you change language from top API will call again.
 * @returns
 */
export const getHomeBannerApi = () => {
  return async () => {
    const response = await api(HOME_BANNER_GET, {}, null);
    return response;
  };
};

/**
 *  This API get top 10 rated service provider and it is used in common compnant
 * @returns
 */
export const getTopServiceProviderApi = () => {
  return async () => {
    const response = await api(TOP_SERVICE_PROVIDER_GET, {}, null, {
      limit: 10,
    });
    return response;
  };
};

/**
 * This API get Request of particular service provider details in home page for notification.
 * @param {*} data
 * @returns
 */
export const getPerticularReqApi = (data) => {
  return async () => {
    const response = await api(PROVIDER_REQUEST_GET, {}, null, data);
    return response;
  };
};

/**
 * This API get all orders of talzeem service in my order page as per status of order
 * @returns
 */
export const getProviderReqApi = () => {
  return async () => {
    const response = await api(PROVIDER_REQUEST_GET, {}, null);
    return response;
  };
};

/**
 * Retrieves the notification API.
 *
 * @return {Promise<Object>} The response from the API call.
 */
export const getNotificationAPI = () => {
  return async () => {
    const response = await api(HEADER_NOTIFICATION_GET, {}, null);
    return response;
  };
};

/**
 * Sends a POST request to the notification API with the provided data.
 *
 * @param {Object} data - The data to be sent in the request body.
 * @return {Promise<Object>} A promise that resolves with the response from the API.
 */
export const postNotificationAPI = (data) => {
  return async () => {
    const response = await api(HEADER_NOTIFICATION_POST, data);
    return response;
  };
};

/**
 * Executes an API call to mark all notifications as read.
 *
 * @return {Promise<Object>} A promise that resolves with the response from the API call.
 */
export const markAllAsReadAPI = () => {
  return async () => {
    const response = await api(MARK_ALL_AS_READ, {});
    return response;
  };
};

/**
 * A description of the entire function.
 *
 * @return {Promise<Object>} A promise that resolves with the response from the API call.
 */
export const getOnboardingScreenAPI = () => {
  return async () => {
    const response = await api(GET_ONBOARDING_SCREENS, {}, null);
    return response;
  };
}
/*
 * File: RfpCreate.js
 * Author: Bhavin Prajapati
 * Created Date: November 22nd, 2022
 * Last Modified Date: April 19th, 2024
 * Description: It renders a page for creating Request for Proposals (RFPs).
 */
import {
  Container,
  Form,
  Row,
  Col,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { ChevronRight } from "react-feather";
import {
  BranchNameRules,
  RfpTitleRules,
} from "../../constants/rules";
import FormSelectInput from "../../component/Form/FormSelectInput";
import { AttachIcon, CameraIcon } from "../../assets/images/svg";
import DummyCircle from "../../assets/images/icon/user.png";
import { IoMdClose } from "react-icons/io";
import FormInput from "../../component/Form/FormInput";
import {
  ACCEPT_DOC,
  ACCEPT_IMAGE_AND_VIDEO,
  FILE_SIZE_LIMIT,
  INDIVIDUAL_FILE_SIZE_LIMIT,
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_VIDEO,
  QUESTION_TYPE_MULTI,
  QUESTION_TYPE_SINGLE,
  QUESTION_TYPE_TEXT,
  TOAST_AUTO_CLOSE,
} from "../../constants/appConstants";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray, useForm } from "react-hook-form";
import "flatpickr/dist/themes/material_green.css";
import CategorySubCategorySelect from "../../component/rfp/CategorySubCategorySelect";
//* page scss
import "../Service/Service.scss";
import "./Rfp.scss";
import { FiPlus } from "react-icons/fi";

import { getParticularCategoryApi } from "../../redux/actions/profile/profileAction";
import { useDispatch } from "react-redux";
import {
  getBranchNameApi,
  getProblemLimitationData,
} from "../../redux/actions/service/serviceAction";
import FormCheckboxInput from "../../component/Form/FormCheckboxInput";
import ReactDatePicker from "../../component/common/ReactDatePicker";
import {
  createRfpMediaApi,
  getQuestionListApi,
  getSpAvailabilityApi,
  uploadRfpMediaApi,
} from "../../redux/actions/rfp/rfpAction";
import { Message } from "../../constants/messages";
import { Slide, toast } from "react-toastify";
import dayjs from "dayjs";
import SuccessModal from "../../component/modal/service/SuccessModal";
import ServiceLeftShimmer from "../../component/shimmer/serviceLeftShimmer";
import FormQuestionRadio from "../../component/Form/FormQuestionRadio";
import FormQuestionCheckBox from "../../component/Form/FormQuestionCheckBox";
import RfpOrderSummary from "../../component/rfp/RfpOrderSummary";
import Footer from "../../component/common/Footer";
import RequestFailedModal from "../../component/modal/service/RequestFailedModal";
import { useNavigate } from "react-router-dom"
import NewOrderAddress from "../../component/common/NewOrderAddress"
import phone from "phone"
import NoSpModel from "../../component/modal/service/NoSpModel";
import TextEditor from "../../component/common/TextEditor";
import { checkVideoLength } from "../../constants/utils";

const RfpCreate = () => {

  //* Multilangual
  const { t, i18n } = useTranslation();

  //* Initializing the dispatch function from Redux
  const dispatch = useDispatch();

  //* Hook for navigation
  const navigate = useNavigate();

  //* Initializing react-hook-form
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      isSave: false,
      task: [{}],
    },
  })

  //* Watching form fields for changes
  const answers = watch('questionText')
  const answersCat = watch('GeneralText')
  const addNewAddress = watch('branches') === 'new'

  //* Managing dynamic fields in form using useFieldArray
  const { fields, append, remove } = useFieldArray({
    name: "task",
    control,
  });

  //* State variables
  const [isLoading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isMediaLoading, setIsMediaLoading] = useState(false);
  const [clickSubmit, setClickSubmit] = useState(false);
  const [branchData, setBranchData] = useState([]);
  const [showBranchValue, setBranchValue] = useState({});
  const [problemLimits, setProblemLimits] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minDate, setMinDate] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [requestFailedModal, setRequestFailedModal] = useState(false);
  const [endDateValidaiton, setEndDateValidaiton] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [fullCustomerAddress, setFullCustomerAddress] = useState();
  const [filteredData, setFilterdData] = useState({
    forGeneral: true,
  });
  const [modal, setModal] = useState(false);

  const taskDetail = watch("task")
  const mobileNumber = watch("phoneNo");

  //* Setting minimum date on component mount
  useEffect(() => {
    const now = new Date().setHours(0, 0, 0, 0);
    setMinDate(now);
  }, []);

  //* Setting branch value on showBranchValue change
  useEffect(() => {
    setValue('branches', showBranchValue?.id)
    setFullCustomerAddress({})
  }, [showBranchValue])

  //* Validating end date against start date
  useEffect(() => {
    if (
      startDate &&
      startDate?.length !== 0 &&
      endDate &&
      endDate?.length !== 0
    ) {
      let start = dayjs(startDate).unix();
      let end = dayjs(endDate).unix();
      if (start > end) {
        setEndDateValidaiton(true);
      } else {
        setEndDateValidaiton(false);
      }
    }
  }, [startDate, endDate]);

  //* Fetching problem limitations and branch dropdown on component mount
  useEffect(() => {
    getProblemLimitation();
    getBranchDropdown();
  }, []);

  /*
   * Function for get individual category details
   * @param {string} id
   * @param {number} index
   * @param {string} subId
   */
  const getCategoryDetails = async (id, index, subId) => {
    const res = await dispatch(getParticularCategoryApi(id));
    const resCategory = await dispatch(
      getQuestionListApi({ category: id, subCategory: subId })
    );
    if (!resCategory.data.error) {
      const values = getValues();
      let temp = [...values.task];
      temp[index].categoryQuestionList1 = [...resCategory.data.data];
      reset({ ...values, task: [...temp] });
    }
    if (!res.data.error) {
      const values = getValues();
      let temp = [...values.task];
      temp[index].categoryName = res.data?.data[0]?.translation?.value;
      temp[index].url = res.data?.data[0]?.icon;
      let subTemp = [...res.data?.data[0]?.subcategory];

      if (subTemp && subTemp.length) {
        subTemp?.map((obj) => {
          if (obj.id === subId) {
            temp[index].subCategory = obj.translation.value;
          }
        });
      }

      reset({ ...values, task: [...temp] });
    }
  };

   //* Function for get all branch list
  const getBranchDropdown = async () => {
    setLoading(true);
    const res = await dispatch(getBranchNameApi());
    if (!res.data.error) {
      setBranchData([
        ...res.data.data,
        { branchName: t("newOrderAddress.addAddressOpt"), id: 'new', icon: FiPlus }
      ])
      setBranchValue(res?.data?.data[0])
    }
    setLoading(false);
  };

  //* Function for get all problem limitations
  const getProblemLimitation = async () => {
    setLoading(true);
    try {
      const res = await dispatch(getProblemLimitationData());
      if (!res.data.error) {
        const problemLimits = res.data.data.reduce((acc, item) => {
          acc[item.key] = parseInt(item.value, 10);
          return acc;
        }, {});
        setProblemLimits(problemLimits);
      }
    } catch (error) {
      console.error("Error fetching problem limitations:", error);
    } finally {
      setLoading(false);
    }
  };

  //* Function for get all category list
  const categoryHandler = (val, index) => {
    const value = getValues();
    let temp = [...value.task];
    temp[index].categoryId = val;

    reset({ ...value, task: [...temp] });
  };

  //* Function for get all sub-category list
  const subCategoryHandler = async (val, index) => {
    const isProviderAvailable = await dispatch(
      getSpAvailabilityApi({ subCategoryId: val, type: "R" })
    );
    if (isProviderAvailable?.data?.data) {
      const value = getValues();
      let temp = [...value.task];
      temp[index].subCategoryId = val;

      await reset({ ...value, task: [...temp] });
    } else {
      setModal(true);
    }
  };

  /*
   * Function for replace selected category handler
   * @param {number} index
   */
  const onReplaceCategoryHandler = (index) => {
    const value = getValues();
    let temp = [...value.task];
    temp[index].categoryId = "";
    temp[index].subCategoryId = "";
    temp[index].categoryName = "";
    reset({ ...value, task: [...temp] });
  };

  /*
   * Function for upload Attachment and Manage
   * @param {object} e
   */
  const onAttachChange = async (e, index) => {
    const values = getValues();
    let temp = [...values.task];
    const file = e.target.files[0];
    const totalImages = problemLimits.noOfGalleryPhotos;
    const totalVideos = problemLimits.noOfGalleryVideos;
    const MaxMediaLimit = totalImages + totalVideos;

    const formData = new FormData();

    if (
      !ACCEPT_IMAGE_AND_VIDEO.includes(file.type) &&
      !ACCEPT_DOC.includes(file.type)
    ) {
      toast.error(t("errorMessages.fileMediaType"), {
        autoClose: TOAST_AUTO_CLOSE,
      });
      return;
    }

    //* file size validation
    if (file.type === "video/mp4") {
      if (Math.round(file.size) > FILE_SIZE_LIMIT) {
        toast.error(t("errorMessages.fileSize"), {
          autoClose: TOAST_AUTO_CLOSE,
        });
        e.target.value = "";
        return;
      } else {
        //* check if video duration is more than allowed value
        const res = await checkVideoLength(file, problemLimits?.durationOfGalleryVideo,);
        if (res) {
          e.target.value = null;
          toast.error(
            t("errorMessages.videoDuration", {
              duration: problemLimits?.durationOfGalleryVideo,
            }),
            {
              autoClose: TOAST_AUTO_CLOSE,
            }
          );
        }
      }
    }
    //* file size validation
    if (
      file.type !== "video/mp4" &&
      Math.round(file.size / 1024) > INDIVIDUAL_FILE_SIZE_LIMIT
    ) {
      if (file.type === "application/pdf") {
        toast.error(t("errorMessages.filePDFSizeLimit"), {
          autoClose: TOAST_AUTO_CLOSE,
        });
        e.target.value = "";
        return;
      } else {
        toast.error(t("errorMessages.fileSizeLimit"), {
          autoClose: TOAST_AUTO_CLOSE,
        });
        e.target.value = "";
        return;
      }
    }

    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > FILE_SIZE_LIMIT) {
        toast.error(t("errorMessages.fileSize"), {
          autoClose: TOAST_AUTO_CLOSE,
        });
        return;
      }
    }

    //* limit check for videos
    if (file.type === "video/mp4") {
      if (
        temp?.[index]?.attachments?.filter(
          (item) => item?.attachmentType === MEDIA_TYPE_VIDEO
        ).length >= totalVideos
      ) {
        toast.error(t("errorMessages.totalVideos", { number: totalVideos }), {
          autoClose: TOAST_AUTO_CLOSE,
        });
        e.target.value = "";
        return;
      }
    }
    //* limit check for images
    if (
      file.type.startsWith("image/png") ||
      file.type.startsWith("image/jpeg")
    ) {
      if (
        temp?.[index]?.attachments?.filter(
          (item) => item?.attachmentType === MEDIA_TYPE_IMAGE
        )?.length >= totalImages
      ) {
        toast.error(t("errorMessages.totalImages", { number: totalImages }), {
          autoClose: TOAST_AUTO_CLOSE,
        });
        e.target.value = "";
        return;
      }
    }

    let targetFile = e.target.files.length;

    let dataFile = temp[index]?.attachments?.length
      ? temp[index]?.attachments?.length
      : 0;

    let totalFile = targetFile + dataFile;
    if (totalFile > MaxMediaLimit + 2) {
      toast.error(t("errorMessages.totalFiles"), {
        transition: Slide,
        autoClose: TOAST_AUTO_CLOSE,
      });

      return;
    }

    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("attachments", e.target.files[i]);
    }
    setIsMediaLoading(true);
    const res = await dispatch(uploadRfpMediaApi(formData));
    if (!res.data.error) {
      let tempAttach = [];
      if (temp[index].attachments && temp[index].attachments.length) {
        temp[index].attachments?.map((attach) => {
          if (attach.fileName) {
            tempAttach.push(attach);
          }
        });
      }
      temp[index].attachments = [...tempAttach, ...res.data.data];
    }
    setIsMediaLoading(false);

    reset({ ...values, task: temp });
  };

  /*
   * Function for rfp create handler
   * @param {obj} data
   */
  const onFormSubmit = async (data) => {
    setIsSubmit(true);
    //* flags to prevent api call if any required question is not answered
    let isErrorFromGeneral = false;
    let isErrorFromCategory = false;
    if (!endDate || !startDate || endDateValidaiton) {
      if (!startDate) toast.error(Message.startDate);
      if (!endDate) toast.error(Message.endDate);
      return;
    }
    setShowLoader(true);
    let temp = [...taskDetail];

    temp?.map((item, mainIndex) => {
      if (
        data?.description.description.length >
        problemLimits.noOfCharactersPerText
      ) {
        toast.error(
          t("errorMessages.descriptionMaxLength", {
            number: problemLimits.noOfCharactersPerText,
          }),
          {
            autoClose: TOAST_AUTO_CLOSE,
          }
        );
        return;
      }
      item.category = item?.categoryName;
      item.description = data?.description.description;
      item.formattedDescription = data?.description.formattedDescription;
      delete item.id;
      delete item.categoryName;
      delete item.url;
      //* General Question
      let generalTemp = [...questionList];
      let FinalgeneralTemp = [];
      let tempArr = [];
      let resultTemp = generalTemp?.map((item, i) => {
        //* check if any required question is not answered and update flag accordingly
        if (
          item?.isRequired &&
          (!answers?.[mainIndex]?.[i]?.["description"] ||
            answers?.[mainIndex]?.[i]?.["description"]?.length === 0)
        ) {
          isErrorFromGeneral = true;
        }

        let questionIds = [];
        let optionTitle = [];
        let answerArr = [];
        let answerVal = data.questionText?.[mainIndex]?.[i]?.description;
        //* push object if question type id text
        //* push text type question answer whose value is not empty from form data
        if (item?.answerType === "T" && answerVal?.trim()?.length) {
          answerArr?.push({ value: answerVal });
        }
        item.options.map((innerItem) => {
          if (innerItem?.questionId === item?.id) {
            questionIds.push(innerItem?.id);
            optionTitle.push({
              value: innerItem?.value,
              arValue: innerItem?.arValue,
              frValue: innerItem?.frValue,
            });

            //* construct array of object according to answerType
            if (
              item?.answerType === "SC" &&
              answerVal &&
              innerItem?.value === answerVal
            ) {
              answerArr.push({
                value: innerItem?.value,
                arValue: innerItem?.arValue,
                frValue: innerItem?.frValue,
              });
            } else if (
              item?.answerType === "MC" &&
              answerVal &&
              answerVal.includes(innerItem?.id)
            ) {
              answerArr.push({
                value: innerItem?.value,
                arValue: innerItem?.arValue,
                frValue: innerItem?.frValue,
              });
            }
          }
        });

        FinalgeneralTemp = {
          questionId: item?.id,
          question: item?.question,
          arQuestion: item?.arQuestion,
          frQuestion: item?.frQuestion,
          prefix: item?.prefix,
          answerType: item?.answerType,
          questionType: item?.questionType,
          optionsId: item?.answerType === "T" ? null : questionIds,
          options: item?.answerType === "T" ? null : optionTitle,
          answer: answerArr,
          isRequired: item?.isRequired,
        };
        return FinalgeneralTemp;
      });

      for (let i = 0; i < resultTemp.length; i++) {
        if (
          resultTemp[i].answer === "" ||
          resultTemp[i].answer?.length === 0 ||
          resultTemp[i].answer === null ||
          !resultTemp[i].answer
        ) {
          tempArr.push(resultTemp[i]);
        }
      }
      if (tempArr.length > 0) {
        for (let i = 0; i < tempArr.length; i++) {
          resultTemp = resultTemp.filter(
            (item) => item?.questionId !== tempArr[i].questionId
          );
        }
      }

      //* category  Question
      let values = getValues();

      let finalCatData = values?.task;

      let FinalcategoryTemp = [];

      let tempCatArr = [];
      let resultCatTemp = [];
      finalCatData?.map((obj) => {
        resultCatTemp = obj?.categoryQuestionList1?.map((item, i) => {
          //* check if any required question is not answered and update flag accordingly
          if (
            item?.isRequired &&
            (!answersCat?.[mainIndex]?.[i]?.["description"] ||
              answersCat?.[mainIndex]?.[i]?.["description"]?.length === 0)
          ) {
            isErrorFromCategory = true;
          }

          let categoryAnsArr = [];
          let questionCatIds = [];
          let optionCarTitle = [];

          //* push text type question answer whose value is not empty from form data
          if (
            item?.answerType === "T" &&
            data.GeneralText?.[mainIndex]?.[i]?.description?.trim()?.length
          ) {
            categoryAnsArr.push({
              value: data.GeneralText?.[mainIndex]?.[i]?.description,
            });
          }
          item.options.map((innerItem) => {
            if (innerItem?.questionId === item?.id) {
              questionCatIds.push(innerItem?.id);
              optionCarTitle.push({
                value: innerItem?.value,
                arValue: innerItem?.arValue,
                frValue: innerItem?.frValue,
              });

              if (
                item?.answerType === "SC" &&
                data.GeneralText?.[mainIndex]?.[i]?.description?.length &&
                innerItem.value ===
                data.GeneralText?.[mainIndex]?.[i]?.description
              ) {
                categoryAnsArr.push({
                  value: innerItem?.value,
                  arValue: innerItem?.arValue,
                  frValue: innerItem?.frValue,
                });
              } else if (
                item?.answerType === "MC" &&
                data.GeneralText?.[mainIndex]?.[i]?.description?.length &&
                data.GeneralText?.[mainIndex]?.[i]?.description.includes(
                  innerItem.id
                )
              ) {
                categoryAnsArr.push({
                  value: innerItem?.value,
                  arValue: innerItem?.arValue,
                  frValue: innerItem?.frValue,
                });
              }
            }
          });
          FinalcategoryTemp = {
            questionId: item?.id,
            question: item?.question,
            arQuestion: item?.arQuestion,
            frQuestion: item?.frQuestion,
            answerType: item?.answerType,
            questionType: item?.questionType,
            optionsId: item?.answerType === "T" ? null : questionCatIds,
            options: item?.answerType === "T" ? null : optionCarTitle,
            answer: categoryAnsArr,
            isRequired: item?.isRequired,
          };
          return FinalcategoryTemp;
        });
      });

      if (resultCatTemp?.length > 0) {
        for (let i = 0; i < resultCatTemp?.length; i++) {
          if (
            resultCatTemp[i].answer === "" ||
            resultCatTemp[i].answer?.length === 0 ||
            resultCatTemp[i].answer === null ||
            !resultCatTemp[i].answer
          ) {
            tempCatArr.push(resultCatTemp[i]);
          }
        }
      }
      if (tempCatArr.length > 0) {
        for (let i = 0; i < tempCatArr?.length; i++) {
          resultCatTemp = resultCatTemp.filter(
            (item) => item?.questionId !== tempCatArr[i].questionId
          );
        }
      }
      //* Push the data
      temp[mainIndex][`categoryQuestionList1`][0] = {
        ...temp[mainIndex][`categoryQuestionList1`][0],
        ...resultCatTemp[0]
      };
      temp[mainIndex][`question`] = [...resultTemp, ...resultCatTemp];
    });

    const finalPhoneValue = phone(`+${mobileNumber}`);
    //* if customer choose add new address option then,
    //*  here we are adding new address values like name, number, isSave, city, country, area,lat,long,phoneNo,countryCode
    let dataToSend = {
      startDate: dayjs(startDate).unix(),
      endDate: dayjs(endDate).endOf('day').unix(),
      title: data.rfpTitle,
      isGuarantee: data.terms,
      branchName: !data?.isSave && data?.branches !== 'new' ?
        showBranchValue.branchName || showBranchValue.name || '' : data?.isSave && data?.branches === 'new' ?
          data?.branchName : '',
      latitude: fullCustomerAddress?.lat || showBranchValue.latitude,
      longitude: fullCustomerAddress.long || showBranchValue.longitude,
      country: fullCustomerAddress.country || showBranchValue.country,
      city: fullCustomerAddress.city || showBranchValue.city,
      area: fullCustomerAddress.placeName || showBranchValue.area,
      isSave: data.isSave || '',
      name: data.name || '',
      email: data.email || '',
      countryCode: finalPhoneValue?.countryCode || '',
      phoneNo: finalPhoneValue?.phoneNumber?.replace(finalPhoneValue.countryCode, "") || '',
      tasks: temp,
    }
    if (!isErrorFromCategory && !isErrorFromGeneral) {
      const res = await dispatch(createRfpMediaApi(dataToSend));

      if (res.data.isServiceProvider === false) {
        setRequestFailedModal(true);
      } else if (!res.data.error) {
        setShowLoader(false);
        navigate("/rfps");
      }
    }
    setShowLoader(false);
  };

  /*
   * Function for handle remove uploaded attachments
   * @param {string} id
   * @param {number} index
   */
  const removeAttachmentHandler = (id, index) => {
    const values = getValues();
    let temp = [...taskDetail];

    if (temp[index].attachments && temp[index].attachments.length) {
      temp[index].attachments = temp[index].attachments.filter(
        (obj) => obj.id !== id
      );
    }
    reset({ ...values, task: temp });
  };

  /*
   * For get question list
   */
  const getGeneralQuestionList = async () => {
    setLoading(true);
    const res = await dispatch(getQuestionListApi(filteredData));
    if (!res.data.error) {
      setQuestionList([...res.data.data]);
    }
    setLoading(false);
  };
  useEffect(() => {
    getGeneralQuestionList();
  }, []);

  return (
    <div className="rfp-section common-design-section">
      <div className="common-design-section-data single-data">
        <div className="right-design-data">
          <section className="service-section">
            <Container fluid="lg">
              <div className="service-section-data">
                {!isLoading ? (
                  <div className="service-details-left-data">
                    <Form
                      className="mb-0"
                      onSubmit={handleSubmit(onFormSubmit)}
                      noValidate
                    >
                      <div>
                        <div className="head-data mb20">
                          <h3>{t("myOrder.dateText")}</h3>
                        </div>
                        <Row>
                          <Col md={6}>
                            <div className=" mb20">
                              <ReactDatePicker
                                selectedDate={startDate}
                                setSelectedDate={(val) => setStartDate(val)}
                                minDate={minDate}
                                label={t("rfp.details.startDate")}
                                options={{ minDate: minDate }}
                              />

                              {clickSubmit &&
                                (!startDate || startDate?.length === 0) && (
                                  <span className="error-msg">
                                    {Message.startDate}
                                  </span>
                                )}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className=" mb20">
                              <ReactDatePicker
                                selectedDate={endDate}
                                setSelectedDate={(val) => setEndDate(val)}
                                minDate={minDate}
                                label={t("rfp.details.endDate")}
                                options={{ minDate: minDate }}
                              />
                              {clickSubmit &&
                                (!endDate || endDate?.length === 0) && (
                                  <span className="error-msg">
                                    {Message.endDate}
                                  </span>
                                )}
                              {endDateValidaiton && (
                                <span className="error-msg">
                                  {Message.endDateValid}
                                </span>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <div className="head-data mb20 mt20">
                          <h3>{t("rfp.rfpTitle")}</h3>
                        </div>
                        <FormInput
                          fgClass="mb20"
                          type="text"
                          id="rfpTitle"
                          placeholder={t("rfp.rfpTitle")}
                          name="rfpTitle"
                          register={register}
                          rules={RfpTitleRules}
                          errors={errors}
                          required={true}
                          label={t("rfp.rfpTitle")}
                        />
                      </div>
                      <div>
                        <div className="head-data mb20">
                          <h3>{t("rfp.branchName")}</h3>
                        </div>
                        {branchData && branchData.length >= 0 && (
                          <FormSelectInput
                            fgClass="mb40"
                            multiple={false}
                            control={control}
                            id={`branches`}
                            name={`branches`}
                            label={t("profile.branchData.branchTitle")}
                            placeholder={t("profile.branchData.branchName")}
                            options={branchData}
                            errors={errors}
                            optionValue="id"
                            optionLabel="branchName"
                            isSearchable={false}
                            required={true}
                            rules={BranchNameRules}
                            onChangeCallback={(val) => {
                              setBranchValue(val)
                              setValue('name', '')
                              setValue('phoneNo', '')
                              setValue('email', '')
                              setValue('branchName', '')
                              setValue('isSave', false)
                            }}
                          />
                        )}
                        {
                          addNewAddress ?
                            <NewOrderAddress
                              register={register}
                              control={control}
                              errors={errors}
                              fullCustomerAddress={fullCustomerAddress}
                              setFullCustomerAddress={setFullCustomerAddress}
                              watch={watch}
                              setValue={setValue}
                            />
                            : null
                        }
                      </div>
                      <div className="head-data">
                        <h3>{t("rfp.professionTitle")}</h3>
                      </div>
                      <div>
                        {fields?.map((field, index) => {
                          return (
                            <div className="brief-card mt-0" key={index}>
                              {index !== 0 && (
                                <div className="branch-header rfp-close-header">
                                  <div
                                    className="close-branch"
                                    onClick={() => remove(index)}
                                  >
                                    <IoMdClose />
                                  </div>
                                </div>
                              )}
                              {field?.categoryId && field?.subCategoryId ? (
                                <>
                                  <div className="service-details-header">
                                    <div className="head-data">
                                      <h3>{t("service.title")}</h3>
                                    </div>
                                    <div className="replace-data">
                                      <span
                                        className="btn-light"
                                        onClick={() =>
                                          onReplaceCategoryHandler(index)
                                        }
                                      >
                                        {t("serviceDetails.replaceBtnText")}
                                        <ChevronRight size={12} />
                                      </span>
                                    </div>
                                  </div>

                                  <div className="service-main-card full mb40">
                                    <div
                                      className="one"
                                      style={{
                                        background: `url(${field.url ? field.url : DummyCircle
                                          })`,
                                      }}
                                    ></div>
                                    <div className="two">
                                      <span>{`${field?.categoryName} / ${field?.subCategory}`}</span>
                                    </div>
                                  </div>

                                  <div className="heading">
                                    {t("serviceDetails.briefCard.title")}
                                  </div>
                                  <TextEditor
                                    control={control}
                                    setValue={setValue}
                                    name={"description"}
                                    errors={errors}
                                    register={register}
                                    placeholder={t("profile.aboutLabel")}
                                  />
                                  <div className="heading">
                                    {t("serviceDetails.briefCard.attachLabel")}
                                  </div>
                                  <div className="cu-form-group mb40">
                                    {!isMediaLoading ? (
                                      field.attachments &&
                                      field.attachments.length > 0 && (
                                        <ul className="listing-attachments">
                                          {field?.attachments?.map(
                                            (item, i) => {
                                              return (
                                                <li key={i}>
                                                  <div className="list-data">
                                                    <div
                                                      className="one"
                                                      onClick={() =>
                                                        removeAttachmentHandler(
                                                          item.id,
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <IoMdClose />
                                                    </div>
                                                    <div className="two">
                                                      <p>
                                                        {item?.name ||
                                                          item?.fileName}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      )
                                    ) : (
                                      <ShimmerThumbnail
                                        className="h40 br20 mb20 cu-shimmer-white"
                                        rounded
                                      />
                                    )}

                                    <Input
                                      type="file"
                                      hidden
                                      id={`attachFile${index}`}
                                      multiple
                                      onChange={(e) => onAttachChange(e, index)}
                                      accept={ACCEPT_DOC}
                                    />

                                    <Input
                                      type="file"
                                      hidden
                                      id={`attach${index}`}
                                      multiple
                                      onChange={(e) => {
                                        onAttachChange(e, index);
                                      }}
                                      accept={ACCEPT_IMAGE_AND_VIDEO}
                                    />
                                    <div className="btn-box">
                                      <Label
                                        for={`attachFile${index}`}
                                        className="attach-btns with-white"
                                      >
                                        <AttachIcon />
                                        {t("signupPage.attachBtn")}
                                      </Label>
                                      <Label
                                        for={`attach${index}`}
                                        className="attach-btns with-white"
                                      >
                                        <CameraIcon />
                                        {t(
                                          "serviceDetails.briefCard.mediaBtnText"
                                        )}
                                      </Label>
                                    </div>
                                  </div>
                                  {questionList && questionList.length > 0 && (
                                    <div className="question-box">
                                      <div className="heading">
                                        {t("rfp.generalQuestion")}
                                      </div>
                                      {questionList?.map((item, i) => {
                                        return (
                                          <div
                                            className="question-inner-box mb20"
                                            key={`${i}+"general`}
                                          >
                                            <p className="label-text">
                                              {i + 1}.{" "}
                                              {i18n?.language === "ar"
                                                ? item?.arQuestion
                                                : i18n?.language === "fr"
                                                  ? item?.frQuestion
                                                  : item?.question}
                                              {item?.isRequired ? (
                                                <span className="required abs">
                                                  *
                                                </span>
                                              ) : null}
                                            </p>

                                            {item.answerType ===
                                              QUESTION_TYPE_TEXT && (
                                                <>
                                                  <FormInput
                                                    fgClass="mb20"
                                                    id={`questionText.${index}.${i}.description`}
                                                    name={`questionText.${index}.${i}.description`}
                                                    type="textarea"
                                                    placeholder={t(
                                                      "profile.aboutLabel"
                                                    )}
                                                    rules={{}}
                                                    register={register}
                                                    errors={errors}
                                                  />
                                                  {isSubmit &&
                                                    item?.isRequired &&
                                                    (!answers?.[index]?.[i]?.[
                                                      "description"
                                                    ] ||
                                                      answers?.[index]?.[i]?.[
                                                        "description"
                                                      ]?.length === 0) ? (
                                                    <span className="error-msg">
                                                      {Message?.questionRequired}
                                                    </span>
                                                  ) : null}
                                                </>
                                              )
                                            }
                                            {
                                              item.answerType ===
                                              QUESTION_TYPE_MULTI && (
                                                <ul className="all-question-type d-flex flex-column">
                                                  {item?.options.map(
                                                    (innerItem, innerIndex) => {
                                                      return (
                                                        <li key={innerIndex}>
                                                          <FormQuestionCheckBox
                                                            type="checkbox"
                                                            id={`questionText.${index}.${i}.${innerItem?.id}`}
                                                            name={`questionText.${index}.${i}.description`}
                                                            register={register}
                                                            rules={{}}
                                                            errors={errors}
                                                            label={
                                                              i18n?.language ===
                                                                "ar"
                                                                ? innerItem.arValue
                                                                : i18n?.language ===
                                                                  "fr"
                                                                  ? innerItem.frValue
                                                                  : innerItem.value
                                                            }
                                                            value={innerItem?.id}
                                                            hidden
                                                          />
                                                        </li>
                                                      );
                                                    }
                                                  )}
                                                  {isSubmit &&
                                                    item?.isRequired &&
                                                    (!answers?.[index]?.[i]?.[
                                                      "description"
                                                    ] ||
                                                      answers?.[index]?.[i]?.[
                                                        "description"
                                                      ]?.length === 0) ? (
                                                    <span className="error-msg">
                                                      {Message?.questionRequired}
                                                    </span>
                                                  ) : null}
                                                </ul>
                                              )
                                            }
                                            {
                                              item.answerType ===
                                              QUESTION_TYPE_SINGLE && (
                                                <ul className="all-question-type d-flex flex-column">
                                                  {item.options.map(
                                                    (innerItem, innerIndex) => {
                                                      return (
                                                        <li key={innerIndex}>
                                                          <div className="form-checkbox with-radio">
                                                            <FormQuestionRadio
                                                              type="radio"
                                                              id={`questionText.${index}.${i}.${innerItem?.id}`}
                                                              name={`questionText.${index}.${i}.description`}
                                                              register={register}
                                                              rules={{}}
                                                              errors={errors}
                                                              label={
                                                                i18n?.language ===
                                                                  "ar"
                                                                  ? innerItem.arValue
                                                                  : i18n?.language ===
                                                                    "fr"
                                                                    ? innerItem.frValue
                                                                    : innerItem.value
                                                              }
                                                              value={innerItem?.id}
                                                              hidden
                                                            />
                                                          </div>
                                                        </li>
                                                      );
                                                    }
                                                  )}
                                                  {isSubmit &&
                                                    item?.isRequired &&
                                                    (!answers?.[index]?.[i]?.[
                                                      "description"
                                                    ] ||
                                                      answers?.[index]?.[i]?.[
                                                        "description"
                                                      ]?.length === 0) ? (
                                                    <span className="error-msg">
                                                      {Message?.questionRequired}
                                                    </span>
                                                  ) : null}
                                                </ul>
                                              )}
                                          </div >
                                        );
                                      })}
                                    </div >
                                  )}
                                  {
                                    field?.categoryQuestionList1 &&
                                    field?.categoryQuestionList1.length !==
                                    0 && (
                                      <div className="question-box">
                                        <div className="heading">
                                          {t("rfp.categoryQuestion")}
                                        </div>
                                        {field?.categoryQuestionList1?.map(
                                          (item, i) => {
                                            return (
                                              <div
                                                className="question-inner-box mb20"
                                                key={`${i}+"cat`}
                                              >
                                                <p className="label-text">
                                                  {i + 1}.{" "}
                                                  {i18n?.language === "ar"
                                                    ? item?.arQuestion
                                                    : i18n?.language === "fr"
                                                      ? item?.frQuestion
                                                      : item?.question}
                                                  {item?.isRequired ? (
                                                    <span className="required abs">
                                                      *
                                                    </span>
                                                  ) : null}
                                                </p>
                                                {item.answerType ===
                                                  QUESTION_TYPE_TEXT && (
                                                    <>
                                                      <FormInput
                                                        fgClass="mb20"
                                                        id={`GeneralText.${index}.${i}.description`}
                                                        name={`GeneralText.${index}.${i}.description`}
                                                        type="textarea"
                                                        placeholder={t(
                                                          "profile.aboutLabel"
                                                        )}
                                                        rules={{}}
                                                        register={register}
                                                        errors={errors}
                                                      />
                                                      {isSubmit &&
                                                        item?.isRequired &&
                                                        (!answersCat?.[index]?.[
                                                          i
                                                        ]?.["description"] ||
                                                          answersCat?.[index]?.[
                                                            i
                                                          ]?.["description"]
                                                            ?.length === 0) ? (
                                                        <span className="error-msg">
                                                          {
                                                            Message.questionRequired
                                                          }
                                                        </span>
                                                      ) : null}
                                                    </>
                                                  )
                                                }
                                                {
                                                  item.answerType ===
                                                  QUESTION_TYPE_MULTI && (
                                                    <ul className="all-question-type d-flex flex-column">
                                                      {item.options.map(
                                                        (
                                                          innerItem,
                                                          innerIndex
                                                        ) => {
                                                          return (
                                                            <li key={innerIndex}>
                                                              <FormQuestionCheckBox
                                                                type="checkbox"
                                                                id={`GeneralText.${index}.${i}.${innerItem?.id}`}
                                                                name={`GeneralText.${index}.${i}.description`}
                                                                register={
                                                                  register
                                                                }
                                                                rules={{}}
                                                                errors={errors}
                                                                label={
                                                                  i18n?.language ===
                                                                    "ar"
                                                                    ? innerItem.arValue
                                                                    : i18n?.language ===
                                                                      "fr"
                                                                      ? innerItem.frValue
                                                                      : innerItem.value
                                                                }
                                                                value={
                                                                  innerItem?.id
                                                                }
                                                                hidden
                                                              />
                                                            </li>
                                                          );
                                                        }
                                                      )}
                                                      {isSubmit &&
                                                        item?.isRequired &&
                                                        (!answersCat?.[index]?.[
                                                          i
                                                        ]?.["description"] ||
                                                          answersCat?.[index]?.[
                                                            i
                                                          ]?.["description"]
                                                            ?.length === 0) ? (
                                                        <span className="error-msg">
                                                          {
                                                            Message.questionRequired
                                                          }
                                                        </span>
                                                      ) : null}
                                                    </ul>
                                                  )
                                                }
                                                {
                                                  item.answerType ===
                                                  QUESTION_TYPE_SINGLE && (
                                                    <ul className="all-question-type d-flex flex-column">
                                                      {item.options.map(
                                                        (
                                                          innerItem,
                                                          innerIndex
                                                        ) => {
                                                          return (
                                                            <li key={innerIndex}>
                                                              <div className="form-checkbox with-radio">
                                                                <FormQuestionRadio
                                                                  type="radio"
                                                                  id={`GeneralText.${index}.${i}.${innerItem?.id}`}
                                                                  name={`GeneralText.${index}.${i}.description`}
                                                                  register={
                                                                    register
                                                                  }
                                                                  rules={{}}
                                                                  errors={errors}
                                                                  label={
                                                                    i18n?.language ===
                                                                      "ar"
                                                                      ? innerItem.arValue
                                                                      : i18n?.language ===
                                                                        "fr"
                                                                        ? innerItem.frValue
                                                                        : innerItem.value
                                                                  }
                                                                  value={
                                                                    innerItem?.value
                                                                  }
                                                                  hidden
                                                                />
                                                              </div>
                                                            </li>
                                                          );
                                                        }
                                                      )}
                                                      {isSubmit &&
                                                        item?.isRequired &&
                                                        (!answersCat?.[index]?.[
                                                          i
                                                        ]?.["description"] ||
                                                          answersCat?.[index]?.[
                                                            i
                                                          ]?.["description"]
                                                            ?.length === 0) ? (
                                                        <span className="error-msg">
                                                          {
                                                            Message.questionRequired
                                                          }
                                                        </span>
                                                      ) : null}
                                                    </ul>
                                                  )}
                                              </div >
                                            );
                                          }
                                        )}
                                      </div >
                                    )}
                                </>
                              ) : (
                                <CategorySubCategorySelect
                                  setCategoryId={(val) => {
                                    categoryHandler(val, index);
                                  }}
                                  setSubCategoryId={async (val) => {
                                    await subCategoryHandler(val, index);
                                    getCategoryDetails(
                                      field.categoryId,
                                      index,
                                      val
                                    );
                                  }}
                                />
                              )}
                            </div >
                          );
                        })}
                        <div className="field-group mb40">
                          <div className="guarantee-check full-check">
                            <FormCheckboxInput
                              type="checkbox"
                              id="accept"
                              hidden
                              name="terms"
                              register={register}
                              rules={{}}
                              errors={errors}
                              label={t("rfp.guaranteeLabel")}
                            />
                          </div>
                        </div>

                        <Button
                          color="main"
                          className="cu-btn br20"
                          block
                          type="submit"
                          onClick={() => setClickSubmit(true)}
                          disabled={showLoader}
                        >
                          {showLoader ? (
                            <Spinner size={"sm"} />
                          ) : (
                            t("btnLabel.send")
                          )}
                        </Button>
                      </div >
                    </Form >
                  </div >
                ) : (
                  <>
                    <div className="service-details-left-data">
                      <ServiceLeftShimmer />
                    </div>
                  </>
                )}

                <RfpOrderSummary
                  isLoading={isLoading}
                  startDate={startDate}
                  endDate={endDate}
                  categoryData={fields}
                  branchName={
                    showBranchValue.branchName || showBranchValue.name
                  }
                />
                <SuccessModal
                  modal={successModal}
                  toggleModal={() => setSuccessModal(!successModal)}
                  textData={`${t("successModal.success1")} ${t(
                    "rfp.serviceProvider"
                  )}. ${t("successModal.success2")}`}
                />
                <RequestFailedModal
                  modal={requestFailedModal}
                  toggleModal={() => setRequestFailedModal(!requestFailedModal)}
                  textData={
                    <div className="text-center">
                      <span className="contact">
                        {t("rfp.requestModal.contactUs")}
                      </span>
                      <a
                        href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@ejadapp.com"
                        target="_blank"
                        className="modal-text"
                      >
                        {t("rfp.requestModal.emailId")}
                      </a>
                    </div>
                  }
                />
              </div >
            </Container >
          </section >

          {/* Common Footer */}
          < Footer />
        </div >
      </div >
      <NoSpModel modal={modal} toggleModal={() => setModal(!modal)} />
    </div >
  );
};

export default RfpCreate;

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyAohinrN5iS4y-aKwvIDdo41-EryQmieCg",
  authDomain: "ejad-live-c31c4.firebaseapp.com",
  projectId: "ejad-live-c31c4",
  storageBucket: "ejad-live-c31c4.appspot.com",
  messagingSenderId: "1010469230363",
  appId: "1:1010469230363:web:18842f5d83a1e2fff8a5be",
  measurementId: "G-02M05Y3W5L",
};
export const config = {
  VapidKey:
    "BJe3HAfmJojJC7HGu2d6dl-Gs0lggevcOTe0AjeFqGRp1h0QLGJNUGR3G8xSPEsaB2zNvTvGmRfwDRovDXP51dc",
};

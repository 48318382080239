/*
 * File: privacyPolicyAction.js
 * Author: Niyati Shah
 * Created Date: April 1st, 2024
 * Last Modified Date: April 1st, 2024
 * Description: This file contains the privacy policy actions.
 */

import { api } from "../../../api/api";
import { GET_PRIVACY_POLICY } from "../../../constants/ApiUrl";

/**
 * Retrieves the privacy policy API.
 *
 * @param {Object} data - The data to be sent in the request.
 * @return {Promise} A promise that resolves with the response from the API call.
 */
export const getPrivacyPolicyApi = (data) => {
    return async () => {
      const response = await api(GET_PRIVACY_POLICY, {data}, null, data);
      return response;
    };
  }
/*
 * File: NoDataFound.js
 * Author:  Harsh Chauhan
 * Created Date: November 17th, 2022
 * Last Modified Date: December 26th, 2022
 * Description: This code snippet is used to render a no data found component with an image and text.
 */
import React from "react";
import ICON from "../../assets/images/icon/no-data-found.png";

export default function NoDataFound({ textData, shadow }) {
  return (
    <div className={`no-data-section ${shadow ? "" : "no-shadow"}`}>
      <img src={ICON} className="img-fluid" alt="images" />
      <p>{textData}</p>
    </div>
  );
}

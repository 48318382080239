/*eslint-disable */

/**
 * @name ImageCropping
 * @description This component is used to crop the image of desire dimension to upload on s3 bucket.
 */
import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { connect } from "react-redux";
import { X } from "react-feather";
import { t } from "i18next";

class ImageCropping extends React.Component {
  state = {
    cropBanner: {
      x: 10,
      y: 10,
      width: this.props.isHorizontal ? 110 : 110, //default dimention of image
      height: this.props.isHorizontal ? 110 : 110,
      aspect: this.props.isHorizontal
        ? (this.props.aspectWidth || 3) / (this.props.aspectHeight || 3)
        : (this.props.aspectWidth || 3) / (this.props.aspectHeight || 3),
    },
    croppedImgBanner: null,
    banner: null,
  };
  onImageLoadedBanner = (image) => {
    this.imageRefBanner = image;
  };
  fileName = () => {
    const string =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      "." +
      this.props.ext;
    return string;
  };
  onCropCompleteBanner = async (cropBanner) => {
    const croppedImageUrl = await this.getCroppedImg(
      this.imageRefBanner,
      cropBanner,
      this.fileName()
    );
    this.setState({ croppedImgBanner: croppedImageUrl });
    this.props.setCropImage(croppedImageUrl, this.fileName());
  };
  getCroppedImg(image, cropBanner, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(cropBanner.width * scaleX);
    canvas.height = Math.ceil(cropBanner.height * scaleY);
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, cropBanner.width, cropBanner.heigh);
    ctx.drawImage(
      image,
      cropBanner.x * scaleX,
      cropBanner.y * scaleY,
      cropBanner.width * scaleX,
      cropBanner.height * scaleY,
      0,
      0,
      cropBanner.width * scaleX,
      cropBanner.height * scaleY
    );
    let mime = "";
    if (this.props.ext === "jpg") {
      mime = "image/jpeg";
    }
    if (this.props.ext === "png") {
      mime = "image/png";
    }
    if (this.props.ext === "svg") {
      mime = "image/svg+xml";
    }
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            resolve(this.dataURLtoFile(reader.result, fileName));
          };
        },
        mime,
        1
      );
    });
  }
  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    return croppedImage;
  }
  onCropChangeBanner = (cropBanner) => {
    this.setState({ cropBanner });
  };
  render() {
    const {
      isOpen,
      className,
      doneBtnColor,
      cancelBtnColor,
      doneClick,
      cancelClick,
      isDisabled,
    } = this.props;
    return (
      <Modal size="lg" isOpen={isOpen} className={`${className} crop-modal`}>
        <ModalBody>
          <div className="crop-header">
            <h4>{t("cropModal.cropImage")}</h4>
            <span onClick={cancelClick}>
              <X />
            </span>
          </div>
          {this.props.src && (
            <ReactCrop
              src={this.props.src}
              crop={this.state.cropBanner}
              onImageLoaded={this.onImageLoadedBanner}
              onComplete={this.onCropCompleteBanner}
              onChange={this.onCropChangeBanner}
            />
          )}
          <div className="crop-modal-btn">
            <Button
              color={doneBtnColor}
              disabled={isDisabled}
              onClick={doneClick}
              className="cu-btn br20"
            >
              {t("cropModal.crop")}
            </Button>
            <Button
              color={cancelBtnColor}
              onClick={cancelClick}
              className="cu-btn br20"
            >
              {t("cropModal.cancel")}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  //  const { src, cropImg } = state.imageCrop;
  return {};
};
export default connect(mapStateToProps, {})(ImageCropping);

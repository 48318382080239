/*
 * File: userCountAction.js
 * Author: Ankit Pithiya
 * Created Date: December 28th, 2023 
 * Last Modified Date: February 4th, 2024
 * Description: This file contains the user count actions.
 */

import { api } from "../../../api/api";
import { POST_VISIT_COUNT } from "../../../constants/ApiUrl";

/**
 * Sends a POST request to the API with the user visit count data and returns the response.
 *
 * @param {Object} data - The data to be sent in the request body.
 * @return {Promise<Object>} A promise that resolves with the response from the API.
 */
export const postUserVisitCount = (data) => {
    return async () => {
      const response = await api(POST_VISIT_COUNT, data);
      return response;
    };
  };
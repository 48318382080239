import { t } from "i18next";
import {
  AREA_MAX_LENGTH,
  BRANCH_NAME_MAX_LENGTH,
  COMPANY_NAME_MAX_LENGTH,
  COMPANY_PERSON_MAX_LENGTH,
  CUSTOMER_NAME_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH,
  PHONE_NUMBER_MAX_LENGTH,
  SPACE_RULES,
  VALID_EMAIL_REGEX,
  VALID_PASSWORD_REGEX,
} from "./appConstants";
import { Message } from "./messages";

export const EmailRules = {
  required: Message.email,
  validate: (value) => {
    const trimmedValue = value.trim(); // Trim leading and trailing whitespaces
    if (!VALID_EMAIL_REGEX.test(trimmedValue)) {
      return Message.invalidEmail;
    }
    return true;
  },
  // pattern: {
  //   value: VALID_EMAIL_REGEX,
  //   message: Message.invalidEmail,
  // },
};
export const CompanyNameRules = {
  required: Message.companyName,
  pattern: {
    value: SPACE_RULES,
    message: Message.companyName,
  },
  maxLength: {
    value: COMPANY_NAME_MAX_LENGTH,
    message: Message.companyNameMaxLength,
  },
};
export const ContactNameRules = {
  required: Message.contactName,
  pattern: {
    value: SPACE_RULES,
    message: Message.contactName,
  },
  maxLength: {
    value: COMPANY_PERSON_MAX_LENGTH,
    message: Message.contactNameMaxLength,
  },
};

export const BranchNameRules = {
  required: Message.branchName,
  pattern: {
    value: SPACE_RULES,
    message: Message.branchName,
  },
  maxLength: {
    value: BRANCH_NAME_MAX_LENGTH,
    message: Message.branchNameMaxLength,
  },
};

export const PasswordRules = {
  required: Message.password,
  pattern: {
    value: VALID_PASSWORD_REGEX,
    message: Message.invalidPassword,
  },
};

export const NewPasswordRules = {
  required: Message.newPassword,
  pattern: {
    value: VALID_PASSWORD_REGEX,
    message: Message.invalidNewPassword,
  },
};
export const passwordInvalidRules = {
  required: Message.passwordInvalid,
  minLength: {
    value: 6,
    message: Message.passwordInvalid,
  },
  maxLength: {
    value: 32,
    message: Message.passwordInvalid,
  },
};

export const ConfirmPasswordRules = {
  required: Message.confirmPassword,
};

export const PhoneNumberRules = {
  required: Message.phoneNumber,
  // maxLength: {
  //   value: PHONE_NUMBER_MAX_LENGTH,
  //   message: Message.phoneNumberMaxLength,
  // },
  // minLength: {
  //   value: PHONE_NUMBER_MAX_LENGTH,
  //   message: Message.phoneNumberMaxLength,
  // },
};

export const IndustrySelectRules = { required: Message.IndustrySelectRequired };
export const customerSelectRules = { required: Message.CustomerSelectRequired };
export const CountrySelectRules = { required: Message.CountrySelectRequired };
export const CitySelectRules = { required: Message.CitySelectRequired };

export const checkboxRules = {
  required: Message.checkbox,
};
export const radioRules = {
  required: Message.radio,
};

export const DescriptionRules = {
  required: Message.description,
  pattern: {
    value: SPACE_RULES,
    message: Message.descriptionSpace,
  },
  maxLength: {
    value: DESCRIPTION_MAX_LENGTH,
    message: Message.descriptionMaxLength,
  },
};
export const AreaRules = {
  required: Message.areaRequired,
  pattern: {
    value: SPACE_RULES,
    message: Message.areaRequired,
  },
  maxLength: {
    value: AREA_MAX_LENGTH,
    message: Message.areaMaxLength,
  },
};
export const CustomerNameRules = {
  required: Message.customerName,
  pattern: {
    value: SPACE_RULES,
    message: Message.customerName,
  },
  maxLength: {
    value: CUSTOMER_NAME_MAX_LENGTH,
    message: Message.customerNameMaxLength,
  },
};
export const guaranteeRules = {
  required: Message.guarantee,
};

export const RfpTitleRules = {
  required: Message.rfpTilte,
};
export const PriceRequiredRules = {
  required: Message.priceRequired,

}
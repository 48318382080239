/*
 * File: Home.js
 * Author: Harsh Chauhan
 * Created Date: November 2nd, 2022
 * Last Modified Date: April 18th, 2024
 * Description: It is a page that displays a home section of a website.
 */
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"

//* Slick slider
import Slider from "react-slick"

//* Images scss
import Icon1 from "../../assets/images/icon/service-concept-icon.svg"
import Icon2 from "../../assets/images/icon/service-provider-icon.svg"
import Icon3 from "../../assets/images/icon/new-rfp-icon.svg"

//* Multilangual
import { useTranslation } from "react-i18next"

import { setRemoveNotification } from "../../redux/actions/notification/notificationAction"
import { store } from "../../redux/storeConfig/store"

//* page scss
import "./Home.scss"
import { Container, Alert, Button } from "reactstrap"
import TopRateSection from "../../component/common/TopRate"
//* API
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getHomeBannerApi } from "../../redux/actions/home/homeAction"
import {
  EXPRESS_DONE,
  LANGUAGE_AR,
  LANGUAGE_EN,
  LANGUAGE_FR,
  NOTIFICATION_TYPE,
  RFP_DONE,
  TALZEEM_DONE,
} from "../../constants/appConstants"
import { getLoginUser } from "../../constants/utils"

const Home = () => {
  //* Reducer data
  const notifications = useSelector((state) => state.notification.notifications)
  //* Multilangual
  const { t, i18n } = useTranslation()

  //* State variables
  const [isLoading, setLoading] = useState(false)
  const [profileDone, setProfileDone] = useState(false)
  const [serviceRply, setServiceRply] = useState(true)
  const [webBanner, setWebBanner] = useState([])
  const [mobileBanner, setMobileBanner] = useState([])
  const [showRfpReply, setShowRfpReply] = useState(true)

  //* Get search parameters from URL
  const [searchParams] = useSearchParams()

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch()
  
  //* Hook for Navigation
  const navigate = useNavigate()
  
  //* Slick slider
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: false,
    rtl: false,
    autoplay: true,
    autoplaySpeed: 2000,
  }

   //* Function for get all banner from API
  const getBannerDetails = async () => {
    setLoading(true)
    let res = await dispatch(getHomeBannerApi())
    
    if (!res.data.error) {
      if (i18n.language === LANGUAGE_EN) {
        setWebBanner(res?.data?.data?.webDesktopBanners.englishBanners || [])
        setMobileBanner(
          res?.data?.data?.webResponsiveBanners.englishBanners || []
        )
      } else if (i18n.language === LANGUAGE_AR) {
        setWebBanner(res?.data?.data?.webDesktopBanners.arabicBanners || [])
        setMobileBanner(
          res?.data?.data?.webResponsiveBanners.arabicBanners || []
        )
      } else if (i18n.language === LANGUAGE_FR) {
        setWebBanner(res?.data?.data?.webDesktopBanners.frenchBanners || [])
        setMobileBanner(
          res?.data?.data?.webResponsiveBanners.frenchBanners || []
        )
      }
    }
    setLoading(false)
  }

   //* get banner details when customer change language from header
  useEffect(() => {
    getBannerDetails()
    const isService = searchParams.get("is-service")
    const isRfp = searchParams.get("is-rfp-reply")
    if (isService) {
      setServiceRply(true)
    }
    if (isRfp) {
      setShowRfpReply(true)
    }
  }, [i18n.language])

   //* useEffect to fetch banner details and handle URL parameters for service and RFP replies
  useEffect(() => {
    getBannerDetails()
    const isService = searchParams.get("is-service")
    if (isService) {
      setServiceRply(true)
    }
  }, [i18n.language])

  /**
   * Function to remove notification item from the list
   * @param {Object} item - Notification item to be removed
   */
  const removeNotificationHandler = (item) => {
    let temp = [...notifications]
    let result = temp.filter((obj) => item.id !== obj.id)
    store.dispatch(setRemoveNotification(result))
  }

  //* Redux state selector to check if profile completion data is available
  const profileCompleted = useSelector((state) => state?.profileReducer)

  //* useEffect to set profile completion status based on login user data
  useEffect(() => {
    const profileData = getLoginUser()
    const data = JSON.parse(profileData)
    if (profileCompleted.profile && profileCompleted.profile.id) {
      setProfileDone(profileCompleted?.profile?.isProfileCompleted)
    } else {
      setProfileDone(data?.isProfileCompleted)
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>EJAD - Home</title>
      </Helmet>
      <div className="home-section common-design-section">
        <div className="common-design-section-data single-data">
          <div className="right-design-data">
            {/* <HomeHeader /> */}

            {/* alert-section Start */}
            <section className="alert-section">
              <Container fluid="lg">
                <Alert
                  color="info"
                  isOpen={!profileDone}
                  toggle={() => setProfileDone(true)}
                >
                  <div className="alert-body">
                    {t("home.profileIncomplete")}
                    {"   "}
                    <Link to="/profile">{t("home.tapComplete")}</Link>
                  </div>
                </Alert>
                {notifications.map((not) => {
                  return (
                    <Alert
                      color="info"
                      isOpen={true}
                      toggle={() => removeNotificationHandler(not)}
                      key={not.id}
                    >
                      <div className="alert-body">
                        {i18n.language === LANGUAGE_AR
                          ? not?.message?.arBody
                          : not?.message?.enBody}{" "}
                        {NOTIFICATION_TYPE.map((typeData, index) => {
                          if (typeData.type === not.type)
                            return (
                              <span
                                key={index}
                                className="mb-0 fw700 pointer d-inline-block"
                                onClick={() => {
                                  removeNotificationHandler(not)

                                  if (
                                    typeData.type === TALZEEM_DONE ||
                                    typeData.type === EXPRESS_DONE ||
                                    typeData.type === RFP_DONE
                                  ) {
                                    navigate(
                                      `/${typeData.link}=${not.serviceId
                                      }&type=${typeData.type === TALZEEM_DONE
                                        ? "T"
                                        : typeData.type === RFP_DONE
                                          ? "R"
                                          : "E"
                                      }`
                                    )
                                    window.location.reload()
                                  } else {
                                    navigate(
                                      `/${typeData.link}=${not.serviceId}`
                                    )
                                  }
                                }}
                              >
                                {t("responseMessage.tapToSee")}
                              </span>
                            )
                        })}
                      </div>
                    </Alert>
                  )
                })}
              </Container>
            </section>
            {/* alert-section End */}

            {/* banner-home-section Start */}
            <section className="banner-home-section">
              <Container fluid="lg">
                {!isLoading ? (
                  <>
                    <div className="d-md-block d-none">
                      <Slider {...settings}>
                        {webBanner.map((item, i) => {
                          return (
                            <div className="full-box" key={i}>
                              <a
                                href={item?.link || "#"}
                                className="full-link"
                                target="_blank"
                              >
                                <img
                                  src={item?.image}
                                  className="img-fluid inner-img w-100"
                                  alt="images"
                                />
                              </a>
                            </div>
                          )
                        })}
                      </Slider>
                    </div>
                    <div className="d-md-none d-block">
                      <Slider {...settings}>
                        {mobileBanner.map((item, i) => {
                          return (
                            <div className="full-box" key={i}>
                              <a
                                href={item?.link || "#"}
                                className="full-link"
                                target="_blank"
                              >
                                <img
                                  src={item?.image}
                                  className="img-fluid inner-img w-100"
                                  alt="images"
                                />
                              </a>
                            </div>
                          )
                        })}
                      </Slider>
                    </div>
                  </>
                ) : (
                  <div className="cu-shimmer mh400 br20 p-4"></div>
                )}
              </Container>
            </section>
            {/* Banner-home-section End */}

            {/* job-section Start */}
            <section className="job-section">
              <Container fluid="lg">
                <div className="job-section-data">
                  <h2>{t("jobSection.title")}</h2>
                  <div className="job-box-data">
                    <div className="job-box">
                      <div className="img-box">
                        <img src={Icon1} className="img-fluid" alt="images" />
                      </div>
                      <div className="one d-flex justify-content-between flex-column">
                        <span>{t("jobSection.service1")}</span>
                        <p>{t("jobSection.description1")}</p>
                        <div className="order-btn">
                          <Button
                            color="main"
                            className="cu-btn cu-sm-btn br15 "
                            type="button"
                            onClick={() => navigate("/express")}
                          >{t('home.orderBtn')}</Button>
                        </div>
                      </div>
                    </div>
                    <div className="job-box">
                      <div className="img-box">
                        <img src={Icon2} className="img-fluid" alt="images" />
                      </div>
                      <div className="one d-flex justify-content-between flex-column">
                        <div>
                          <span>{t("jobSection.service2")}</span>
                          <p>{t("jobSection.description2")}</p>
                        </div>
                        <div className="order-btn">
                          <Button
                            color="main"
                            className="cu-btn cu-sm-btn br15 "
                            type="button"
                            onClick={() => navigate("/direct-award")}
                          >{t('home.orderBtn')}</Button>
                        </div>
                      </div>
                    </div>
                    <div className="job-box">
                      <div className="img-box">
                        <img src={Icon3} className="img-fluid" alt="images" />
                      </div>
                      <div className="one d-flex justify-content-between flex-column">
                        <span>{t("jobSection.service3")}</span>
                        <p>{t("jobSection.description3")}</p>
                        <div className="order-btn">
                          <Button
                            color="main"
                            className="cu-btn cu-sm-btn br15 "
                            type="button"
                            onClick={() => navigate("/rfps/create")}
                          >{t('home.orderBtn')}</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </section>
            {/* job-section End */}

            <TopRateSection title={t("landing.sliderSection.heading")} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Home

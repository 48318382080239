/*
 * File: loginActions.js
 * Author: Harsh Chauhan
 * Created Date: November 2nd, 2022
 * Last Modified Date: January 12th, 2024
 * Description: This file contains the login actions.
 */

import { api } from "../../../api/api";
import {
  LOGIN_URL,
  LOGOUT_URL,
  FORGOT_PASSWORD_URL,
  RESET_PASSWORD_URL,
  SIGNUP_URL,
  VERIFY_ACCOUNT_URL,
  RESEND_VERIFY_ACCOUNT_URL,
  SEND_DEVICE_TOKEN,
  GET_CUSTOMER_TYPE_LIST,
} from "../../../constants/ApiUrl";

/**
 * API is used for Login in customer panel
 * @param {*} user
 * @returns
 */
export const customerLoginApi = (user) => {
  return async () => {
    let email = user?.email?.trim();
    let password = user.password;
    let deviceToken = user.deviceToken;
    let deviceType = "W";
    let formData = { email, password, deviceToken, deviceType };

    let res = await api(LOGIN_URL, formData);
    return res;
  };
};

/**
 * API is used for logout from customer panel
 * @returns
 */
export const logoutApi = () => {
  return async () => {
    const response = await api(LOGOUT_URL, {});
    return response;
  };
};

/**
 * API is used for signup new customer in this panel.
 * @param {*} data
 * @returns
 */
export const signupApi = (data) => {
  return async () => {
    const response = await api(SIGNUP_URL, data);
    return response;
  };
};

/**
 * API is used for forgot password of customer in Authentication popups.
 * @param {*} data
 * @returns
 */
export const forgotPasswordApi = (data) => {
  return async () => {
    const response = await api(FORGOT_PASSWORD_URL, data);
    return response;
  };
};

/**
 * API is used from Verify customer account
 * @param {*} queryObj
 * @returns
 */
export const verifyAccountApi = (queryObj) => {
  return async () => {
    const response = await api(VERIFY_ACCOUNT_URL, {}, null, queryObj);
    return response;
  };
};

/**
 * API is used for verify customer account on resend
 * @param {*} data
 * @returns
 */
export const resendVerifyAccountApi = (data) => {
  return async () => {
    const response = await api(RESEND_VERIFY_ACCOUNT_URL, data);
    return response;
  };
};

/**
 * API is used for reset password of customer in Authentication popups.
 * @param {*} data
 * @param {*} queryObj
 * @returns
 */
export const resetPasswordApi = (data, queryObj) => {
  queryObj.forgotPwdToken = queryObj.token;
  delete queryObj.token;
  return async () => {
    const response = await api(RESET_PASSWORD_URL, data, null, queryObj);
    return response;
  };
};

/**
 * API is send device token.
 * @param {*} data
 * @returns
 */
export const sendDeviceToken = (data) => {
  return async () => {
    const response = await api(SEND_DEVICE_TOKEN, data);
    return response;
  };
};

/**
 * Retrieves the customer type list from the API.
 *
 * @return {Promise} A promise that resolves with the response from the API call.
 */
export const getCustomerTypeListApi = () => {
  return async () => {
    const response = await api(GET_CUSTOMER_TYPE_LIST);
    return response;
  };
}
import React, { useEffect, useState } from "react";
import { ChevronRight } from "react-feather";
import { GoStar } from "react-icons/go";
import Rating from "react-rating";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import {
  BackIcon,
  BriefCaseIcon,
  CallIcon,
  CheckBoxIcon,
  CloseIcon,
  EmailIcon,
  ExpertiseIcon,
  MapIcon,
} from "../../../assets/images/svg";

import { FaGraduationCap } from "react-icons/fa";

// Multilangual
import { useTranslation } from "react-i18next";

import DummyCover from "../../../assets/images/custom/dummy-cover.png";
import DummyCircle from "../../../assets/images/icon/user.png";

import { ShimmerCircularImage, ShimmerThumbnail } from "react-shimmer-effects";
import MapContainer from "../../common/Map";
import GalleryModal from "./GalleryModal";
import DateLabel from "../../common/DateLabel";
import { useDispatch } from "react-redux";
import { postUserVisitCount } from "../../../redux/actions/user-count/userCountAction";

const ProviderModal = ({
  modal,
  toggleModal,
  isShowProvider,
  dataValue,
  isLoading,
  fromRfp,
  showConatct,
}) => {
  const [gallaryModal, setGallaryModal] = useState(false);
  const [mapModal, setMapModal] = useState(false);
  const [gallaryItem, setgallaryItem] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [showAllFeedback, setAllFeedback] = useState(false);
  const [latitude, setLat] = useState(null);
  const [longitude, setLong] = useState(null);

  // Multilangual
  const { t, i18n } = useTranslation();

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();
  const onLoadMore = () => {
    setAllFeedback(!showAllFeedback);
  };

  //* Post user visit count API
  const postUserVisitCountApi = async () => {
    dispatch(
      postUserVisitCount({ serviceProviderId: dataValue?.id, visitTo: "SP" })
    );
  };
  
  //* is useEffect hook triggers the postUserVisitCountApi function when the dataValue?.id changes and the modal is true. 
  //* It helps to avoid unnecessary API calls when the modal is closed or the dataValue id is not available.
  useEffect(() => {
    if (modal && dataValue?.id) postUserVisitCountApi();
  }, [dataValue?.id]);

  return (
    <>
      {/* provider-modal */}
      <Modal
        backdropClassName="opw1"
        isOpen={modal}
        toggle={() => {
          toggleModal();
        }}
        backdrop="static"
        keyboard={false}
        className="provider-modal"
        centered
      >
        <ModalBody>
          {!isLoading ? (
            <div className="modal-body-scroll">
              <div
                className="close-icon diff"
                onClick={() => {
                  toggleModal();
                }}
              >
                <CloseIcon />
              </div>

              {showAllFeedback && (
                <div className="back-icon" onClick={() => onLoadMore()}>
                  <BackIcon />
                </div>
              )}
              <div className="profile-box">
                <div
                  className={dataValue?.isGoldenTechnician ? "profile-img golden-border" : "profile-img"}
                  style={{
                    background: `url(${
                      dataValue?.profilePhoto || DummyCircle
                    })`,
                  }}
                ></div>
                <div className="name-data">
                  {dataValue?.name}
                  {dataValue?.isCertified && (
                    <>
                      <div id={`customers${dataValue.id}`}>
                        <CheckBoxIcon />
                      </div>
                      <UncontrolledTooltip
                        className="primary-tip"
                        placement="bottom"
                        target={`customers${dataValue.id}`}
                      >
                        {t("noData.classifiedText")}
                      </UncontrolledTooltip>
                    </>
                  )}
                </div>
                <div className="rate-data">
                  {dataValue?.rating && (
                    <div className="one">
                      <Rating
                        className="user-select-none"
                        initialRating={dataValue?.rating}
                        emptySymbol={
                          <GoStar size={13} fill="#C8C8C8" stroke="#C8C8C8" />
                        }
                        fullSymbol={
                          <GoStar
                            size={13}
                            fill={"#FFD700"}
                            stroke={"#FFD700"}
                          />
                        }
                      />
                      <div className="comment-data">({dataValue?.total})</div>
                    </div>
                  )}
                  <div className="one">
                    <div className="icon-i">
                      <BriefCaseIcon />
                    </div>
                    <div className="text-i ispan">
                      {dataValue?.ordersCompleted}{" "}
                      {t("serviceDetails.serviceProviderCard.taskDoneLabel")}
                    </div>
                  </div>
                </div>
                <span className="designation">
                  {dataValue?.userType === "C" &&
                    t("serviceDetails.serviceProviderCard.companyLabel")}
                  {dataValue?.userType === "I" &&
                    t("serviceDetails.serviceProviderCard.individualLabel")}
                </span>
                {!fromRfp && (
                  <Button
                    color="main"
                    className="cu-btn br20"
                    block
                    type="button"
                    onClick={() => {
                      isShowProvider(dataValue);
                      //set local storage data to avoid selecting sp again on language change 
                      localStorage.setItem('providerData', JSON.stringify(dataValue));
                      toggleModal();
                    }}
                  >
                    {t("providerCard.hireBtnText")}
                  </Button>
                )}
              </div>
              <Row>
                {!showAllFeedback && (
                  <>
                    <>
                      {((!showConatct &&
                        dataValue?.contactDetails?.phoneNo?.length > 0 &&
                        dataValue?.contactDetails?.email?.length > 0) ||
                        dataValue?.locations?.length > 0) && (
                        <Col md={dataValue?.education.length > 0 ? "6" : "12"}>
                          <div className="off-card">
                            <div className="heading">
                              {t("providerCard.contactInfoLabel")}
                            </div>
                            <div className="contact-data">
                              <ul>
                                {!showConatct && (
                                  <>
                                    <li>
                                      <div className="exp-box">
                                        <div className="one">
                                          <CallIcon />
                                        </div>
                                        <div className="two">
                                          <a
                                            href={`callto:${dataValue?.countryCode}${dataValue?.phoneNo}`}
                                          >
                                            {dataValue?.countryCode}
                                            {dataValue?.phoneNo}
                                          </a>
                                          {dataValue?.contactDetails?.phoneNo &&
                                            dataValue?.contactDetails?.phoneNo
                                              .length > 0 && (
                                              <>
                                                {dataValue?.contactDetails?.phoneNo.map(
                                                  (item, i) => {
                                                    return (
                                                      <a
                                                        href={`callto:${item?.countryCode}${item?.phoneNo}`}
                                                        key={i}
                                                      >
                                                        {item?.countryCode}
                                                        {item?.phoneNo}
                                                      </a>
                                                    );
                                                  }
                                                )}
                                              </>
                                            )}
                                        </div>
                                      </div>
                                    </li>

                                    {dataValue?.contactDetails?.email &&
                                      dataValue?.contactDetails?.email.length >
                                        0 && (
                                        <li>
                                          <div className="exp-box">
                                            <div className="one">
                                              <EmailIcon />
                                            </div>
                                            <div className="two">
                                              {dataValue?.contactDetails?.email.map(
                                                (item, i) => {
                                                  return (
                                                    <a
                                                      href={`mailto:${item?.email}`}
                                                      key={i}
                                                    >
                                                      {item?.email}
                                                    </a>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                        </li>
                                      )}
                                  </>
                                )}

                                {dataValue?.locations &&
                                  dataValue?.locations.length > 0 && (
                                    <li>
                                      <div className="exp-box location-box">
                                        <div className="one">
                                          <MapIcon />
                                        </div>
                                        <div className="two">
                                          {dataValue?.locations.map(
                                            (item, i) => {
                                              return (
                                                <div
                                                  className="map-inner"
                                                  key={i}
                                                >
                                                  <p>
                                                    {item?.city}, {item.state},{" "}
                                                    {item.country}
                                                  </p>
                                                  <span
                                                    onClick={() => {
                                                      setMapModal(!mapModal);
                                                      setLat(item?.latitude);
                                                      setLong(item?.longitude);
                                                    }}
                                                  >
                                                    {t(
                                                      "providerCard.showOnMapText"
                                                    )}
                                                    <ChevronRight size={14} />
                                                  </span>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </li>
                                  )}
                              </ul>
                            </div>
                          </div>
                        </Col>
                      )}
                      {dataValue?.education?.length > 0 && (
                        <Col
                          md={
                            (!showConatct &&
                              dataValue?.contactDetails?.phoneNo?.length > 0 &&
                              dataValue?.contactDetails?.email?.length > 0) ||
                            dataValue?.locations?.length > 0
                              ? "6"
                              : "12"
                          }
                        >
                          <div className="off-card">
                            <div className="heading">
                              {t("providerCard.educationLabel")}
                            </div>
                            <div className="education-data">
                              <ul>
                                {dataValue?.education.map((item, i) => {
                                  return (
                                    <li key={i}>
                                      <div className="exp-box">
                                        <div className="one">
                                          <FaGraduationCap size={20} />
                                        </div>
                                        <div className="two">
                                          <p>{item?.education}</p>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </Col>
                      )}
                    </>

                    {dataValue?.albums && dataValue?.albums.length > 0 && (
                      <Col md={12}>
                        <div className="off-card">
                          <div className="heading">
                            {t("providerCard.galleryLabel")}
                          </div>
                          <div className="galary-data">
                            {dataValue?.albums.map((item, i) => {
                              return (
                                <div
                                  key={i}
                                  className="galary-card"
                                  onClick={() => {
                                    setGallaryModal(!gallaryModal);
                                    setgallaryItem({ ...item });
                                  }}
                                >
                                  <div
                                    className="card-img"
                                    style={{
                                      background: `url(${
                                        item?.images[0]?.url || DummyCover
                                      })`,
                                    }}
                                  ></div>
                                  <div className="card-body">
                                    <span>{item.name}</span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </Col>
                    )}
                  </>
                )}
                {dataValue?.ratingData &&
                  dataValue?.ratingData.length > 0 &&
                  dataValue?.total !== 0 && (
                    <Col md={12}>
                      <div className="off-card h-100 mb-0">
                        <div className="heading">
                          {t("providerCard.reviewLabel")}
                        </div>
                        {!showAllFeedback && (
                          <>
                            {dataValue?.total !== 0 && (
                              <div className="all-review-box">
                                <div className="rate-sec">
                                  <h4>{dataValue?.rating}</h4>
                                  <div className="star-data">
                                    <Rating
                                      className="user-select-none"
                                      initialRating={dataValue?.rating}
                                      emptySymbol={
                                        <GoStar
                                          size={20}
                                          fill="#C8C8C8"
                                          stroke="#C8C8C8"
                                        />
                                      }
                                      fullSymbol={
                                        <GoStar
                                          size={20}
                                          fill={"#FFD700"}
                                          stroke={"#FFD700"}
                                        />
                                      }
                                    />
                                  </div>
                                  <p>({dataValue?.total} review)</p>
                                </div>
                                <div className="star-sec">
                                  {dataValue?.countByRating.map((item, i) => {
                                    return (
                                      <div className="star-data" key={i}>
                                        <p>{item?.total}</p>
                                        <Rating
                                          className="user-select-none"
                                          initialRating={item?.rating}
                                          readonly
                                          emptySymbol={
                                            <GoStar
                                              size={14}
                                              fill="#C8C8C8"
                                              stroke="#C8C8C8"
                                            />
                                          }
                                          fullSymbol={
                                            <GoStar
                                              size={14}
                                              fill={"#FFD700"}
                                              stroke={"#FFD700"}
                                            />
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </Col>
                  )}
              </Row>
            </div>
          ) : (
            <div className="text-center">
              <ShimmerCircularImage size={180} />
              <ShimmerThumbnail className="h40 br20 mb20" rounded />
              <ShimmerThumbnail className="h120 br20 mb20" rounded />
              <ShimmerThumbnail className="h40 br20 mb20" rounded />
              <ShimmerThumbnail className="h150 br20 mb20" rounded />
            </div>
          )}
        </ModalBody>
      </Modal>

      {/* Gallery Modal */}
      <GalleryModal
        open={gallaryModal}
        toggleModal={() => setGallaryModal(!gallaryModal)}
        gallaryData={gallaryItem?.images}
      />

      {/* Map Modal */}
      <Modal
        backdropClassName="opw1"
        isOpen={mapModal}
        toggle={() => {
          setMapModal(!mapModal);
        }}
        backdrop="static"
        keyboard={false}
        className="provider-modal"
        centered
      >
        <ModalBody className="p-0 over-unset">
          <div
            className="close-icon diff above"
            onClick={() => {
              setMapModal(!mapModal);
            }}
          >
            <CloseIcon />
          </div>
          <div className="off-card mb-0 p-0 h-100">
            <div className="map-box mb-0 bigger">
              <MapContainer
                latitude={latitude || 60.2517722}
                longitude={longitude || 60.2517722}
                mapHeight="400px"
                draggable={false}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default ProviderModal;

import { Modal, ModalBody } from "reactstrap";
import { CloseIcon } from "../../../assets/images/svg";

import Dummy from "../../../assets/images/custom/dummy-Image.svg";

// Slick slider
import Slider from "react-slick";
import NoDataFound from "../../common/NoDataFound";
import { useTranslation } from "react-i18next";

const GalleryModal = ({ open, toggleModal, gallaryData }) => {
  const { t, i18n } = useTranslation();

  /**
   * slider settings
   */
  const leftSettings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Modal
      backdropClassName="opw1"
      isOpen={open}
      toggle={() => {
        toggleModal();
      }}
      backdrop="static"
      keyboard={false}
      className="provider-modal"
      centered
    >
      <ModalBody className="p-0">
        <div
          className="close-icon diff"
          onClick={() => {
            toggleModal();
          }}
        >
          <CloseIcon />
        </div>
        <div className="off-card mb-0 h-100">
          <div className="heading">{t("providerCard.galleryLabel")}</div>
          {gallaryData && gallaryData.length ? (
            <>
              <Slider className="main-slider" {...leftSettings}>
                {gallaryData.map((item, i) => {
                  return (
                    <div key={i}>
                      <div
                        className="bg-data"
                        style={{
                          background: `url(${item?.url || Dummy})`,
                        }}
                      ></div>
                    </div>
                  );
                })}
              </Slider>
            </>
          ) : (
            <NoDataFound textData={t("noDataFound.images")} shadow={true} />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default GalleryModal;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  HistoryIcon,
  RecentIcon,
} from "../../assets/images/svg";
import { GoStar } from "react-icons/go";
import {
  ICONS_MAP,
} from "../../constants/appConstants";
import DateLabel from "../common/DateLabel";
import NoDataFound from "../common/NoDataFound";
import CommonShimmer from "../shimmer/commonShimmer";
import { useDispatch } from "react-redux";
import { getExpressOrderApi } from "../../redux/actions/express/expressAction";
import DummyCircle from "../../assets/images/icon/user.png";
import { useNavigate } from "react-router-dom";
import Rating from "react-rating";
import { Button } from "reactstrap";
import { Sliders } from "react-feather";
import RfpListDateFilterModal from "../modal/service/RfpListDateFilterModal";

const ExpressOrderTab = ({ showServiceId, unReadOrdersIdArr, handleNotificationRead }) => {
  const [activeData, setActiveData] = useState([]);
  const [recentData, setRecentData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [activeListType, setActiveListType] = useState(false);
  //* Multilangual
  const { t } = useTranslation();

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();
  //* Hook for navigation
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState({
    dateStart: "",
    dateEnd: "",
    dateSort: "",
    status: "",
  });
  const [filteredHistoryData, setFilteredHistoryData] = useState({
    dateStart: "",
    dateEnd: "",
    dateSort: "",
    status: "",
  });

  /**
   * Function for get provider request from API
   */
  const getRecentExpressOrderData = async (
    data = { ...filteredHistoryData }
  ) => {
    setLoading(true);
    const resRecent = await dispatch(
      getExpressOrderApi({ forActive: false, ...data })
    );
    if (!resRecent.data.error) {
      setRecentData([...resRecent.data.data]);
    }
    setLoading(false);
  };
  /**
   * Function for get provider request from API
   */
  const getActiveExpressOrderData = async (data = { ...filteredData }) => {
    setLoading(true);
    const resActive = await dispatch(
      getExpressOrderApi({ forActive: true, ...data })
    );
    if (!resActive.data.error) {
      setActiveData([...resActive.data.data]);
    }
    setLoading(false);
  };
  /**
   * call the provider request function
   */
  useEffect(() => {
    getRecentExpressOrderData();
    getActiveExpressOrderData();
  }, []);

  return (
    <>
      <div className="order-service-data">
        <div className="title-data justify-content-between">
          <div>
            <HistoryIcon />
            {t("myOrder.activeStatusText")}
          </div>
          <div className="filter-tab mb-0">
            <ul>
              <li className="">
                <Button
                  className="cu-btn rounded-1"
                  color="icon"
                  onClick={() => {
                    setIsModelOpen(true);
                    setActiveListType(true);
                  }}
                >
                  <Sliders />
                </Button>
              </li>
            </ul>
          </div>
        </div>

        {!isLoading ? (
          <>
            {activeData && activeData.length ? (
              <>
                <ul className="order-service-ul">
                  {activeData?.map((item, i) => {
                    return (
                      <li
                        className="order-service-li pointer"
                        id={item.id}
                        key={i}
                        onClick={() => {
                          navigate(`/express-reply?expressId=${item.id}`)
                          handleNotificationRead(item.id)
                        }
                        }
                      >
                        <div className={`${unReadOrdersIdArr?.includes(item?.id) ? 'show-border' : ''} order-service-card`}>
                          <div className="one">
                            <div className="grid-data">
                              <div>
                                <span className="label-text">
                                  {t("myOrder.tastText")}
                                </span>
                                <ul className="list-inner">
                                  <li>
                                    <span className="main">
                                      {item?.description || "-"}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              {item?.serviceProviderId !== null ? (
                                <div>
                                  <span className="label-text">
                                    {t("OrderCard.providerTitle")}
                                  </span>
                                  <ul className="list-inner">
                                    <li>
                                      <div className="profile-rate-data">
                                        <div
                                          className="first"
                                          style={{
                                            background: `url(${item?.serviceProviderId
                                              ?.profilePhoto || DummyCircle
                                              })`,
                                          }}
                                        ></div>
                                        <div className="second">
                                          <span className="main">
                                            {item?.serviceProviderId?.name ||
                                              "-"}
                                          </span>
                                          {item?.total !== 0 && (
                                            <div className="star-data">
                                              <Rating
                                                className="user-select-none"
                                                initialRating={
                                                  item?.rating || "0"
                                                }
                                                emptySymbol={
                                                  <GoStar
                                                    size={12}
                                                    fill="#C8C8C8"
                                                    stroke="#C8C8C8"
                                                  />
                                                }
                                                fullSymbol={
                                                  <GoStar
                                                    size={12}
                                                    fill={"#FFD700"}
                                                    stroke={"#FFD700"}
                                                  />
                                                }
                                              />
                                              <div className="comment-data">
                                                ({item?.total || "0"})
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              ) : (
                                <div>
                                  <span className="label-text">
                                    {t("OrderCard.providerTitle")}
                                  </span>
                                  <span className="main">{"-"}</span>
                                </div>
                              )}
                              {/*  */}
                              <div>
                                <span className="label-text">
                                  {t("myOrder.dateTimeText")}
                                </span>
                                <ul className="list-inner">
                                  <li>
                                    <span className="main">
                                      {t("myOrder.startText")}:{" "}
                                      <DateLabel
                                        date={item?.startDate}
                                        format={"DD-MM-YYYY HH:mm"}
                                      />
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="two">
                            <div className="status-box">
                              {ICONS_MAP.get(item?.status)?.icon}
                              <span>{ICONS_MAP.get(item?.status)?.text}</span>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : (
              <NoDataFound
                textData={t("noDataFound.noDataText")}
                shadow={true}
              />
            )}
          </>
        ) : (
          <CommonShimmer />
        )}
      </div>

      {/* Recent */}
      <div className="order-service-data">
        <div className="title-data justify-content-between">
          <div>
            <RecentIcon />
            {t("myOrder.recentStatusText")}
          </div>
          <div className="filter-tab mb-0">
            <ul>
              <li className="">
                <Button
                  className="cu-btn rounded-1"
                  color="icon"
                  onClick={() => {
                    setIsModelOpen(true);
                    setActiveListType(false);
                  }}
                >
                  <Sliders />
                </Button>
              </li>
            </ul>
          </div>
        </div>

        {!isLoading ? (
          <>
            {recentData && recentData.length ? (
              <>
                <ul className="order-service-ul">
                  {recentData.map((item, i) => {
                    return (
                      <li
                        className={`order-service-li pointer ${showServiceId === item.id ? "active" : ""}`}
                        id={item.id}
                        key={i}
                        onClick={() => {
                          navigate(`/express-reply?expressId=${item.id}`)
                          handleNotificationRead(item.id)
                        }
                        }
                      >
                        <div className={`${unReadOrdersIdArr?.includes(item?.id) ? 'show-border' : ''} order-service-card`}>
                          <div className="one">
                            <div className="grid-data">
                              {/*  */}
                              <div>
                                <span className="label-text">
                                  {t("myOrder.tastText")}
                                </span>
                                <ul className="list-inner">
                                  <li>
                                    <span className="main">
                                      {item?.description || "-"}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              {/*  */}
                              <div>
                                <span className="label-text">
                                  {t("OrderCard.providerTitle")}
                                </span>
                                <ul className="list-inner">
                                  <li>
                                    <div className="profile-rate-data">
                                      <div
                                        className="first"
                                        style={{
                                          background: `url(${item?.serviceProviderId
                                            ?.profilePhoto || DummyCircle
                                            })`,
                                        }}
                                      ></div>
                                      <div className="second">
                                        <span className="main">
                                          {item?.serviceProviderId?.name || "-"}
                                        </span>
                                        {item?.total !== 0 && (
                                          <div className="star-data">
                                            <Rating
                                              className="user-select-none"
                                              initialRating={
                                                item?.rating || "0"
                                              }
                                              emptySymbol={
                                                <GoStar
                                                  size={12}
                                                  fill="#C8C8C8"
                                                  stroke="#C8C8C8"
                                                />
                                              }
                                              fullSymbol={
                                                <GoStar
                                                  size={12}
                                                  fill={"#FFD700"}
                                                  stroke={"#FFD700"}
                                                />
                                              }
                                            />
                                            <div className="comment-data">
                                              ({item?.total || "0"})
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              {/*  */}
                              <div>
                                <span className="label-text">
                                  {t("myOrder.dateTimeText")}
                                </span>
                                <ul className="list-inner">
                                  <li>
                                    <span className="main">
                                      {t("myOrder.startText")}:{" "}
                                      <DateLabel
                                        date={item?.startDate}
                                        format={"DD-MM-YYYY HH:mm"}
                                      />
                                    </span>
                                  </li>
                                  <li>
                                    <span className="main">
                                      {t("myOrder.endText")}:{" "}
                                      <DateLabel
                                        date={item?.endDate}
                                        format={"DD-MM-YYYY HH:mm"}
                                      />
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="two">
                            <div className="status-box">
                              {ICONS_MAP.get(item?.status)?.icon}
                              <span>{ICONS_MAP.get(item?.status)?.text}</span> 
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : (
              <NoDataFound
                textData={t("noDataFound.noDataText")}
                shadow={true}
              />
            )}
          </>
        ) : (
          <CommonShimmer />
        )}
      </div>
      <RfpListDateFilterModal
        key={"express"}
        modal={isModelOpen}
        toggleModal={() => setIsModelOpen(!isModelOpen)}
        filteredData={activeListType ? filteredData : filteredHistoryData}
        setFilteredData={(val) =>
          activeListType ? setFilteredData(val) : setFilteredHistoryData(val)
        }
        getDataList={(data) => {
          activeListType
            ? getActiveExpressOrderData(data)
            : getRecentExpressOrderData(data);
        }}
        activeListType={activeListType}
        serviceType={activeListType ? "expressActive" : "expressRecent"}
      />
    </>
  );
};

export default ExpressOrderTab;

/**
 * url: backend url endpoint
 * method: (post, get, delete, PUT, PATCH)
 * withToken: to send auth token in header or not
 * isMultipart: if form contains files
 * showToast: to show success/ error toast notification or not
 * msg: success msg to display on toast
 */

// Auth Routes

export const SIGNUP_URL = {
  url: "/customer/signup",
  method: "post",
  withToken: false,
  isMultipart: true,
  showToast: true,
  module: "USER",
};

export const VERIFY_ACCOUNT_URL = {
  url: "/customer/verifyAccount",
  method: "get",
  withToken: false,
  isMultipart: false,
  showToast: true,
  module: "USER",
};
export const RESEND_VERIFY_ACCOUNT_URL = {
  url: "/customer/resendVerifyAccount",
  method: "post",
  withToken: false,
  isMultipart: false,
  showToast: true,
  module: "USER",
};
export const LOGIN_URL = {
  url: "/customer/login",
  method: "post",
  withToken: false,
  isMultipart: false,
  showToast: true,
  module: "USER",
};
export const FORGOT_PASSWORD_URL = {
  url: "/customer/forgetPwd",
  method: "post",
  withToken: false,
  isMultipart: false,
  showToast: true,
  module: "USER",
};
export const RESET_PASSWORD_URL = {
  url: "/customer/resetPwd",
  method: "post",
  withToken: true,
  isMultipart: false,
  showToast: true,
  module: "USER",
};
export const LOGOUT_URL = {
  url: "/customer/logout",
  method: "post",
  withToken: true,
  isMultipart: false,
  showToast: true,
  module: "USER",
  // msg: LOGOUT_SUCCESS,
};
export const PROFILE_GET = {
  url: "/customer/profile/view",
  method: "get",
  withToken: true,
  isMultipart: false,
  showToast: false,
  module: "USER",
};
export const CATEGORY_GET = {
  url: "/admin/category/get",
  method: "get",
  withToken: true,
  isMultipart: false,
  showToast: false,
  module: "USER",
};
export const GET_INDUSTRY = {
  url: "/admin/industry/get",
  method: "get",
  withToken: true,
  isMultipart: false,
  showToast: false,
  module: "USER",
};
export const PROFILE_POST = {
  url: "/customer/profile/edit",
  method: "post",
  withToken: false,
  isMultipart: false,
  showToast: true,
  module: "USER",
};
export const PROFILE_IMAGE_UPLOAD_URL = {
  url: "/customer/profile/upload",
  method: "post",
  withToken: true,
  isMultipart: true,
  showToast: true,
  module: "USER",
};
export const ATTACHMENT_UPLOAD_URL = {
  url: "/customer/profile/attachments/upload",
  method: "post",
  withToken: true,
  isMultipart: true,
  showToast: true,
  module: "USER",
};
export const HOME_BANNER_GET = {
  url: "/customer/homepage/banner/get",
  method: "get",
  withToken: false,
  isMultipart: false,
  showToast: false,
  module: "USER",
};
export const TOP_SERVICE_PROVIDER_GET = {
  url: "/customer/homepage/service-provider/get",
  method: "get",
  withToken: false,
  isMultipart: false,
  showToast: false,
  module: "USER",
};
export const BRANCH_GET = {
  url: "/service/talzeem/branch/get",
  method: "get",
  withToken: false,
  isMultipart: false,
  showToast: false,
  module: "USER",
};
export const PROVIDER_GET = {
  url: "/service/talzeem/service-provider/get",
  method: "get",
  withToken: false,
  isMultipart: false,
  showToast: false,
  module: "USER",
};
export const SINGLE_PROVIDER_GET = {
  url: "/service/talzeem/service-provider/get/",
  method: "get",
  withToken: false,
  isMultipart: false,
  showToast: false,
  module: "USER",
};
export const SEND_PROVIDER_REQUEST = {
  url: "/service/talzeem/request/send",
  method: "post",
  withToken: true,
  isMultipart: true,
  showToast: true,
  module: "USER",
};
export const PROVIDER_REQUEST_GET = {
  url: "/service/talzeem/request/get",
  method: "get",
  withToken: false,
  isMultipart: false,
  showToast: false,
  module: "USER",
};

export const SEND_APPROVE_PROVIDER_REQUEST = {
  url: "/service/talzeem/request/accept",
  method: "post",
  withToken: true,
  isMultipart: true,
  showToast: true,
  module: "USER",
};
export const SEND_DEVICE_TOKEN = {
  url: "/deviceToken/edit",
  method: "post",
  withToken: false,
  isMultipart: false,
  showToast: false,
  module: "USER",
};

//Create Rfp

export const UPLOAD_MEDIA_RFP = {
  url: "/service/rfp/attachment/upload",
  method: "post",
  withToken: true,
  isMultipart: true,
  showToast: false,
  module: "USER",
};
export const CREATE_RFP = {
  url: "/v2/service/rfp/create",
  method: "post",
  withToken: true,
  isMultipart: false,
  showToast: true,
  module: "USER",
};
export const GET_AVAILABLE_RFP = {
  url: "/service/rfp/customer/available/rfp/get",
  method: "GET",
  withToken: true,
  isMultipart: false,
  showToast: false,
  module: "USER",
};
export const GET_REPLY_RFP = {
  url: "/service/rfp/customer/rfp/reply/get",
  method: "GET",
  withToken: true,
  isMultipart: false,
  showToast: false,
  module: "USER",
};
export const GET_REPLY_DETAILS_RFP = {
  url: "/v2/service/rfp/customer/details/get",
  method: "GET",
  withToken: true,
  isMultipart: false,
  showToast: false,
  module: "USER",
};
export const SEND_PROPOSAL_RFP = {
  url: "/service/rfp/customer/request/approve",
  method: "POST",
  withToken: true,
  isMultipart: false,
  showToast: true,
  module: "USER",
};
export const GET_RECENT_RFP_API = {
  url: "/service/rfp/customer/rfp/get",
  method: "GET",
  withToken: true,
  isMultipart: false,
  showToast: false,
  module: "USER",
};

export const HEADER_NOTIFICATION_GET = {
  url: "/homepage/notification/get",
  method: "get",
  withToken: false,
  isMultipart: false,
  showToast: false,
  module: "USER",
};
export const HEADER_NOTIFICATION_POST = {
  url: "/homepage/notification/read",
  method: "POST",
  withToken: true,
  isMultipart: false,
  module: "USER",
};
export const GET_QUESTION_LIST_URL = {
  url: "/v2/admin/question/get",
  method: "GET",
  withToken: true,
  isMultipart: false,
  showToast: false,
  module: "USER",
};
// express
export const SEND_EXPRESS_PROVIDER_REQUEST = {
  url: "/service/express/create",
  method: "post",
  withToken: true,
  isMultipart: true,
  showToast: true,
  module: "USER",
};
export const GET_EXPRESS_ORDER_URL = {
  url: "/customer/service/express/request/get",
  method: "GET",
  withToken: true,
  isMultipart: false,
  showToast: false,
  module: "USER",
};
export const PERTICULAR_REQUEST_GET = {
  url: "/customer/service/express/request/get/",
  method: "get",
  withToken: false,
  isMultipart: false,
  showToast: false,
  module: "USER",
};

//Customer type
export const GET_CUSTOMER_TYPE_LIST = {
  url: "/admin/customer-type/get",
  method: "get",
  withToken: false,
  isMultipart: false,
  showToast: false,
  module: "USER",
};

// Review and rating

export const POST_REVIEW = {
  url: "/v1/service/service-provider/rating/add",
  method: "post",
  withToken: false,
  isMultipart: false,
  showToast: true,
  module: "USER",
};

//spare-parts
export const POST_SPARE_PARTS = {
  url: "/customer/sparePart-payment/add",
  method: "post",
  withToken: false,
  isMultipart: false,
  showToast: true,
  module: "USER",
};
// v2 
export const GET_DIRECT_REQUEST_ORDER = {
  url: "/v3/service/talzeem/request/active/get",
  method: "get",
  withToken: false,
  isMultipart: false,
  showToast: false,
  module: "USER",
};
export const POST_VISIT_COUNT = {
  url: "/customer/visit-count-and-last-visit",
  method: "post",
  withToken: true,
  isMultipart: false,
  showToast: false,
  module: "USER",
};

export const CHECK_SP_AVAILABILITY = {
  url: "/service/category/service-provider/get",
  method: "get",
  withToken: false,
  isMultipart: false,
  showToast: false,
  module: "USER",
};
export const GET_ONBOARDING_SCREENS = {
  url: "/master/web/onboarding-screen/get",
  method: "GET",
  withToken: false,
  isMultipart: false,
  showToast: false,
  module: "USER",
  isWithStaticToken: true,
};
//cancel orders 
export const CANCEL_DIRECT_ORDER = {
  url: "/customer/service/talzeem/cancel",
  method: "post",
  withToken: true,
  isMultipart: false,
  showToast: true,
  module: "USER",
};
export const CANCEL_RFP_ORDER = {
  url: "/customer/service/rfp/cancel",
  method: "post",
  withToken: true,
  isMultipart: false,
  showToast: true,
  module: "USER",
};
export const CANCEL_EXPRESS_ORDER = {
  url: "/customer/service/express/cancel",
  method: "post",
  withToken: true,
  isMultipart: false,
  showToast: true,
  module: "USER",
};
export const ESTIMATION_STATUS_UPDATE = {
  url: "/customer/fees-status/update",
  method: "post",
  withToken: true,
  isMultipart: false,
  showToast: true,
  module: "USER",
};

export const MARK_ALL_AS_READ = {
  url: "/homepage/notification/read-all",
  method: "POST",
  withToken: true,
  isMultipart: false,
  module: "USER",
};


export const GET_PROBLEM_DESCRIPTION_LIMITATIONS = {
  url: "/customer/validation-data/get",
  method: "GET",
  withToken: false,
  isMultipart: false,
  showToast: false,
  module: "USER",
};

export const GET_PRIVACY_POLICY = {
  url: "/user/terms-and-privacy/get",
  method: "GET",
  withToken: false,
  isMultipart: false,
  showToast: false,
  module: "USER",
  isWithStaticToken: true
}
/*
 * File: EmailVerification.js
 * Author: Harsh Chauhan
 * Created Date: November 2nd, 2022
 * Last Modified Date: March 14th, 2024
 * Description: It is used to handle the verification of an email address.
 */

import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button } from "reactstrap";
//* Images scss
import Logo from "../../assets/images/logo/dark-logo.svg";
import Banner from "../../assets/images/custom/verify-banner.svg";
//* Multilangual
import { useTranslation } from "react-i18next";
//* page scss
import "./Authentication.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  resendVerifyAccountApi,
  verifyAccountApi,
} from "../../redux/actions/auth/loginActions";

const EmailVerification = () => {

  //* Hook for Navigation
  const navigate = useNavigate();

  //* Multilangual
  const { t } = useTranslation();

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();

  //* Get search parameters from URL
  const [searchParams] = useSearchParams();

  //* Extracting parameters from URL
  const id = searchParams.get("id");
  const token = searchParams.get("token");
  const verifyAcount = searchParams.get("verify-account");

  //* State variables
  const [showLogin, setShowLogin] = useState(false);
  const [hideResend, setHideResend] = useState(false);
  const [countdown, setCountdown] = useState(120);

  //* Effect to automatically verify account if URL parameter present
  useEffect(() => {
    if (verifyAcount) verifyAccount();
  }, []);

  //* Effect to update countdown timer
  useEffect(() => {
    const timer = setTimeout(() => {
      setCountdown(countdown - 1);
    }, 1000);
    if (countdown <= 0) {
      setHideResend(true);
      return clearTimeout(timer);
    }
  }, [countdown]);

  //* Function to handle account verification
  const verifyAccount = async () => {
    const res = await dispatch(verifyAccountApi({ id, token }));
    if (!res.data.error) {
      setHideResend(true);
      setShowLogin(true);
    } else {
      if (res.data.data?.accountAlreadyVerified) {
        setCountdown(0);
        setHideResend(true);
        setShowLogin(true);
      }
    }
  };

  //* Function to convert time for countdown
  const timeConvert = () => {
    const hours = Math.floor(countdown / 60);
    const minutes = countdown % 60;
    return minutes > 9 ? hours + ":" + minutes : hours + ":" + "0" + minutes;
  };

  //* Function to resend verification link
  const resendLinkHandler = async () => {
    const res = await dispatch(resendVerifyAccountApi({ id }));
    if (!res.data.error) {
      setCountdown(60);
      setHideResend(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>EJAD - HOME</title>
      </Helmet>
      <section className="full-auth-page">
        <div className="left-auth-data">
          <img src={Banner} className="img-fluid log-img" alt="images" />
          <div className="box-data">
            <div className="data">
              <h2 className="mb-0">{t("emailpage.leftData.heading")}</h2>
            </div>
          </div>
        </div>
        <div className="right-auth-data">
          <div className="data">
            <div className="text-center">
              <img src={Logo} className="img-fluid log-img" alt="images" />
            </div>
            <div className="text-data">
              <p>{t("emailpage.rightData.paragraph1")}</p>
              <p>{t("emailpage.rightData.paragraph2")}</p>
            </div>
            {!hideResend && (
              <Button
                type="button"
                color="secondary"
                className="cu-btn br20 w-100"
                disabled
              >
                {timeConvert()}
              </Button>
            )}
            {countdown === 0 && !showLogin && (
              <Button
                type="button"
                color="secondary"
                className="cu-btn br20 w-100"
                onClick={() => resendLinkHandler()}
              >
                {t("btnLabel.resendBtn")}
              </Button>
            )}
            {showLogin && (
              <Button
                type="button"
                color="main"
                className="cu-btn br20 w-100"
                onClick={() => navigate("/landing?is-login=true")}
              >
                {t("btnLabel.goToLogin")}
              </Button>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default EmailVerification;

import { HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import "./assets/scss/common.scss";
import Routers from "./router/Router";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { firebaseConfig } from "./firebase/firebase-config";
import { firebaseNotificationProvider } from "./firebase/firebaseNotificationProvider";
import { LANGUAGE_AR } from "./constants/appConstants";

/**
 *
 */
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that onen
}

const App = () => {
  // Multilangual
  const { t, i18n } = useTranslation();

  document
    .getElementsByTagName("html")[0]
    .setAttribute("dir", i18n.language === LANGUAGE_AR ? "rtl" : "ltr");

  useEffect(() => {
    firebaseNotificationProvider();
  }, []);

  return (
    <div className="App">
      <HelmetProvider>
        <Routers />
        <ToastContainer
          rtl={i18n.language === LANGUAGE_AR && true}
          position={i18n.language === LANGUAGE_AR ? "top-left" : "top-right"}
        />
      </HelmetProvider>
    </div>
  );
};

export default App;

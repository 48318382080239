/*
 * File: ServiceProviderReply.js
 * Author: Harsh Chauhan
 * Created Date: November 14th, 2022
 * Last Modified Date: April 12th, 2024
 * Description: It renders a page for displaying details of services provider replies.
 */
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";

//* Multilangual
import { useTranslation } from "react-i18next";

//* page scss
import "./Service.scss";
import { Button, Container } from "reactstrap";
import OrderSummary from "../../component/modal/service/OrderSummary";
import { CallIcon, CancelIcon, CheckBoxIcon, DollerIcon, EmailIcon } from "../../assets/images/svg";
import DummyCircle from "../../assets/images/icon/user.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getPerticularReqApi } from "../../redux/actions/home/homeAction";
import { useDispatch } from "react-redux";
import {
  cancelDirectOrder,
  getProblemLimitationData,
  getSingleProviderApi,
  sendApproveProviderRequest,
} from "../../redux/actions/service/serviceAction";
import {
  ACCEPTED_ORDER,
  ACCEPT_REQUEST,
  APPROVED_ORDER,
  APPROVED_REQUEST,
  DONE_ORDER,
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_PDF,
  MEDIA_TYPE_VIDEO,
  REQUESTED_ORDER,
  WAITING_PAYMENT,
} from "../../constants/appConstants";
import ReplyModal from "../../component/modal/service/ReplyModal";
import { ShimmerCircularImage, ShimmerThumbnail } from "react-shimmer-effects";
import ProviderModal from "../../component/modal/service/ProviderModal";
import Footer from "../../component/common/Footer";
import { ChevronRight } from "react-feather";
import Rating from "react-rating";
import { GoStar } from "react-icons/go";
//* assets
import DummyCover from "../../assets/images/custom/dummy-cover.png";
import DummyPDFCover from "../../assets/images/custom/dummy-pdf-cover.png";
import VideoModal from "../../component/modal/service/VideoModal";
import GalleryModal from "../../component/modal/service/GalleryModal";
import { AiFillPlayCircle } from "react-icons/ai";
import ConfirmActionModal from "../../component/modal/ConfirmActionModal";
import { checkTimeGap } from "../../constants/utils";
import ProgressBar from "../../component/common/progressBar";

const ServiceProviderReply = () => {
  //* Multilangual
  const { t } = useTranslation();

  //* State variables
  const [isLoading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState({});
  const [orderStatus, setOrderStatus] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [replyModal, setReplyModal] = useState(false);
  const [showServiceId, setServiceId] = useState("");
  const [providerModal, setProviderModal] = useState(false);
  const [providerDetails, setProviderDetails] = useState([]);
  const [attachments, setAttachments] = useState([])
  const [galleryModal, setGalleryModal] = useState(false);
  const [galleryItem, setgalleryItem] = useState([]);
  const [videoModal, setVideoModal] = useState(false);
  const [videoDetails, setVideoDetails] = useState({});
  const [modal, setModal] = useState(false)
  const [showFeesCancelText, setShowFeesCancelText] = useState(false)
  const [problemLimits, setProblemLimits] = useState(null);
  
  //* Get search parameters from URL
  const [searchParams] = useSearchParams();

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();
  
  //* Hook for navigation
  const navigate = useNavigate();

  //* Extracting service ID and type from URL params
  const serviceId = searchParams.get("serviceId");

   //* Effect to fetch provider request data when serviceId changes
  useEffect(() => {
    if (serviceId) {
      setServiceId(serviceId);
    }
  }, [serviceId]);

   //* Function for get request from API
  const getProviderRequest = async () => {
    setLoading(true);
    const data = { id: serviceId };
    let res = await dispatch(getPerticularReqApi(data));
    if (!res.data.error) {
      getSingleData(res.data.data.active[0]?.serviceProviderId?.id);
      setRequestData({ ...res.data.data });
      setOrderStatus(res?.data?.data?.active[0]?.status);
      setAttachments([
        ...(res?.data?.data.active[0]?.attachments ||
          res?.data?.data.recent[0]?.attachments),
      ]);
      if (
        res?.data?.data?.active[0]?.status === APPROVED_REQUEST
      ) {
        setSubmitted(false);
      } else {
        setSubmitted(true);
      }
      //* redirect user to the estimation screen when sp has submitted Estimation
      if ((res?.data?.data?.recent?.[0]?.customerFeesStatus === 'PEND' ||
        res?.data?.data?.active?.[0]?.customerFeesStatus === 'PEND')
        || ((res?.data?.data?.active?.[0]?.customerFeesStatus === 'ACPT' && !res?.data?.data?.active?.[0]?.totalAmountOfCustomer) || (res?.data?.data?.recent?.[0]?.customerFeesStatus === 'ACPT' && !res?.data?.data?.recent?.[0]?.totalAmountOfCustomer))) {
        navigate(`/estimation?serviceId=${serviceId}&type=T`)
      } else if (res?.data?.data?.recent?.[0]?.status === WAITING_PAYMENT ||
        res?.data?.data?.active?.[0]?.status === WAITING_PAYMENT) {
        navigate(`/estimation?serviceId=${serviceId}&type=T`)
      }
    }
    setLoading(false);
  };

  //* Effect to update serviceId state when URL parameter changes
  useEffect(() => {
    if (showServiceId) {
      getProviderRequest();
    }
  }, [showServiceId, serviceId]);
  
  /**
   * Function is used for get particular provider data after hired
   * @param {*} id
   */
  const getSingleData = async (id) => {
    setLoading(true);
    const res = await dispatch(getSingleProviderApi(id));

    if (!res.data.error) {
      setProviderDetails({ ...res.data.data });
    }
    setLoading(false);
  };

  /**
   * Function to handle order cancellation
   * @param {string} id - ID of the service
   */
  const handleCancelOrder = async (id) => {
    await dispatch(cancelDirectOrder({ serviceId: id }));

    navigate("/my-orders");
  };

  /**
   * Function to get problem limitation data
   * @param {string} id - ID of the service
   */
  const getProblemLimitation = async (id) => {
    setLoading(true);
    try {
      const res = await dispatch(getProblemLimitationData(id));
      if (!res.data.error) {
        const problemLimits = res.data.data.reduce((acc, item) => {
          acc[item.key] = parseInt(item.value, 10);
          return acc;
        }, {});
        setProblemLimits(problemLimits);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  
  //* Effect to fetch problem limitation data when serviceId changes
  useEffect(() => {
    getProblemLimitation(serviceId);
  }, []);

  return (
    <>
      <Helmet>
        <title>EJAD - Service Details</title>
      </Helmet>
      <div className="home-section common-design-section">
        <div className="common-design-section-data single-data">
          <div className="right-design-data">
            <section className="service-section reply-section">
              <Container fluid="lg">
                <div className="service-section-data">
                  {!isLoading ? (
                    <div className="service-details-left-data">
                      <div className="head-data mb-0 text-center">
                        <ProgressBar
                          orderStatus={
                            orderStatus || requestData?.recent?.[0]?.status
                          }
                          lastStatus={requestData?.recent?.[0]?.lastOrderStatus}
                          orderType="direct"
                        />
                      </div>
                      <div className="choose-provider-data grey-border">
                        <ul className="choose-ul">
                          <li className="choose-li">
                            <div
                              className="inner-provider-card"
                              onClick={() => {
                                setProviderModal(!providerModal);
                                getSingleData(
                                  requestData?.active?.[0]?.serviceProviderId
                                    ?.id ||
                                    requestData?.recent?.[0]?.serviceProviderId
                                      ?.id
                                );
                              }}
                            >
                              <div
                                className="one"
                                style={{
                                  background: `url(${
                                    requestData?.active?.[0]?.serviceProviderId
                                      ?.profilePhoto ||
                                    requestData?.recent?.[0]?.serviceProviderId
                                      ?.profilePhoto ||
                                    DummyCircle
                                  })`,
                                }}
                              ></div>
                              <div className="two profile-card-two">
                                <div className="small-details-data">
                                  <div className="small-data-one span-two">
                                    <ul>
                                      <li>
                                        <div className="name-data">
                                          {requestData?.active?.[0]
                                            ?.serviceProviderId?.name ||
                                            requestData?.recent?.[0]
                                              ?.serviceProviderId?.name ||
                                            "-"}
                                          {requestData?.active?.[0]
                                            ?.serviceProviderId?.name
                                            .isCertified !== 0 && (
                                            <CheckBoxIcon />
                                          )}
                                        </div>
                                      </li>

                                      <li>
                                        <div className="star-data">
                                          <Rating
                                            className="user-select-none"
                                            initialRating={
                                              requestData?.active?.[0]
                                                ?.rating ||
                                              requestData?.recent?.[0]?.rating
                                            }
                                            emptySymbol={
                                              <GoStar
                                                size={14}
                                                fill="#C8C8C8"
                                                stroke="#C8C8C8"
                                              />
                                            }
                                            fullSymbol={
                                              <GoStar
                                                size={14}
                                                fill={"#FFD700"}
                                                stroke={"#FFD700"}
                                              />
                                            }
                                          />
                                          <div className="comment-data">
                                            (
                                            {requestData?.active?.[0]?.total ||
                                              requestData?.recent?.[0]?.total ||
                                              0}
                                            )
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="icon-data">
                                          <div className="icon-i">
                                            <DollerIcon />
                                          </div>
                                          <div className="text-i">
                                            {requestData?.active?.[0]
                                              ?.serviceProviderId?.dailyRate ||
                                              requestData?.recent?.[0]
                                                ?.serviceProviderId
                                                ?.dailyRate ||
                                              "-"}{" "}
                                            {t(
                                              "serviceDetails.serviceProviderCard.jdHrLabel"
                                            )}
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="three show-profile">
                                <span>
                                  {t("rfp.showProfile")} <ChevronRight />
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      {requestData?.recent?.[0]?.status === DONE_ORDER ||
                      !submitted ? (
                        <>
                          <div className="reply-box my-5 ms-2">
                            <div className="heading mb5">
                              {t("serviceReplyPage.briefLabel")}
                            </div>
                            <p className="paratext">
                              <div
                                className="paratext"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    requestData?.active?.[0]
                                      ?.formattedDescription ||
                                    requestData?.recent?.[0]
                                      ?.formattedDescription,
                                }}
                              />
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          {orderStatus === ACCEPT_REQUEST && (
                            <>
                              <div className="contact-box mt-4">
                                <div className="heading">
                                  {t("providerCard.contactInfoLabel")}
                                </div>
                                <ul>
                                  <li>
                                    <div className="exp-box">
                                      <div className="one">
                                        <CallIcon />
                                      </div>
                                      <div className="two">
                                        {requestData?.active?.[0]
                                          ?.serviceProviderId?.phoneNo ? (
                                          <a
                                            href={`callto:${requestData?.active?.[0]?.serviceProviderId?.countryCode}${requestData?.active?.[0]?.serviceProviderId?.phoneNo}`}
                                          >
                                            {
                                              requestData?.active?.[0]
                                                ?.serviceProviderId?.countryCode
                                            }
                                            {
                                              requestData?.active?.[0]
                                                ?.serviceProviderId?.phoneNo
                                            }
                                          </a>
                                        ) : (
                                          "-"
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="exp-box">
                                      <div className="one">
                                        <EmailIcon />
                                      </div>

                                      <div className="two">
                                        {providerDetails?.contactDetails?.email.map(
                                          (item) => {
                                            return <p>{item.email}</p>;
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {/* {media section for done order } */}
                      {attachments?.length && orderStatus === DONE_ORDER ? (
                        // <Col md={12}>
                        <div className="off-card">
                          <div className="heading mb-3 ms-2">
                            {t("rfp.details.customerAttach")}
                          </div>
                          <div className="galary-data">
                            {attachments.map((item, i) => {
                              return (
                                <>
                                  {item.attachmentType === MEDIA_TYPE_VIDEO && (
                                    <div
                                      key={i}
                                      className="galary-card"
                                      onClick={() => {}}
                                    >
                                      <div className="card-img">
                                        <video
                                          src={item.url}
                                          width="100%"
                                          height="100%"
                                        ></video>
                                        <div className="video-icon pointer">
                                          <span
                                            onClick={() => {
                                              setVideoModal(true);
                                              setVideoDetails({
                                                ...item,
                                              });
                                            }}
                                          >
                                            <AiFillPlayCircle />
                                          </span>
                                        </div>
                                      </div>

                                      <div className="card-body">
                                        <span>{item?.fileName}</span>
                                      </div>
                                    </div>
                                  )}
                                  {item.attachmentType === MEDIA_TYPE_PDF && (
                                    <a
                                      key={i}
                                      className="galary-card"
                                      href={item.url}
                                      target="_blank"
                                    >
                                      <div
                                        className="card-img"
                                        style={{
                                          background: `url(${DummyPDFCover})`,
                                        }}
                                      ></div>

                                      <div className="card-body">
                                        <span>{item?.fileName}</span>
                                      </div>
                                    </a>
                                  )}
                                  {item.attachmentType === MEDIA_TYPE_IMAGE && (
                                    <div
                                      key={i}
                                      className="galary-card"
                                      onClick={() => {
                                        setGalleryModal(!galleryModal);
                                        let temp = [];
                                        temp.push({ ...item });
                                        setgalleryItem(temp);
                                      }}
                                    >
                                      <div
                                        className="card-img"
                                        style={{
                                          background: `url(${
                                            item?.url || DummyCover
                                          })`,
                                        }}
                                      ></div>

                                      <div className="card-body">
                                        <span>{item?.fileName}</span>
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </div>
                      ) : // </Col>
                      null}
                      {!submitted ? (
                        <>
                        </>
                      ) : (
                        <Button
                          color="main"
                          className="cu-btn br20 mt-3"
                          block
                          type="submit"
                          onClick={() => {
                            navigate("/home");
                            setSubmitted(!submitted);
                          }}
                        >
                          {t("btnLabel.backToHome")}
                        </Button>
                      )}
                      {/* cancel order button  */}
                      {orderStatus === APPROVED_ORDER ||
                      orderStatus === ACCEPTED_ORDER ||
                      orderStatus === REQUESTED_ORDER ? (
                        <Button
                          color="danger"
                          className="cu-btn br20 mt-3"
                          block
                          type="submit"
                          onClick={() => {
                            setModal(true),
                              setShowFeesCancelText(
                                checkTimeGap(
                                  requestData?.active?.[0]?.updatedAt
                                )
                              );
                          }}
                        >
                          {t("btnLabel.cancelOrder")}
                        </Button>
                      ) : null}
                    </div>
                  ) : (
                    <div className="service-details-left-data text-center">
                      <ShimmerCircularImage size={180} />
                      <ShimmerThumbnail className="h40 br20 mb20" rounded />
                      <ShimmerThumbnail className="h100 br20 mb20" rounded />
                      <ShimmerThumbnail className="h100 br20 mb20" rounded />
                      <ShimmerThumbnail className="h150 br20 mb20" rounded />
                    </div>
                  )}
                  <OrderSummary
                    isLoading={isLoading}
                    data={
                      requestData?.active?.[0]?.categoryId?.name ||
                      requestData?.recent?.[0]?.categoryId?.name
                    }
                    dataSubService={
                      requestData?.active?.[0]?.subCategoryId?.name ||
                      requestData?.recent?.[0]?.subCategoryId?.name
                    }
                    branchName={
                      requestData?.active?.[0]?.branchName ||
                      requestData?.recent?.[0]?.branchName
                    }
                    providerData={
                      requestData?.active?.[0]?.serviceProviderId ||
                      requestData?.recent?.[0]?.serviceProviderId
                    }
                    mainData={
                      requestData?.active?.[0] || requestData?.recent?.[0]
                    }
                    orderDate={
                      requestData?.active?.[0]?.startDate || 
                      requestData?.recent?.[0]?.startDate
                    }
                    />
                </div>
              </Container>
            </section>

            {/* Common Footer */}
            <Footer />
          </div>
        </div>
      </div>
      {/* Gallery Modal */}
      <GalleryModal
        open={galleryModal}
        toggleModal={() => setGalleryModal(!galleryModal)}
        gallaryData={galleryItem}
      />
      {/* Video preview modal */}
      <VideoModal
        open={videoModal}
        toggleModal={() => setVideoModal(!videoModal)}
        videoInfo={videoDetails}
      />
      <ReplyModal
        modal={replyModal}
        toggleModal={() => setReplyModal(!replyModal)}
      />
      <ProviderModal
        modal={providerModal}
        toggleModal={() => setProviderModal(!providerModal)}
        isShowProvider={(val) => getSingleProviderApi(val)}
        isLoading={isLoading}
        dataValue={providerDetails}
        fromRfp={true}
        showConatct={requestData?.active?.[0]?.status !== ACCEPTED_ORDER}
      />
      <ConfirmActionModal
        modal={modal}
        toggleModal={() => setModal(!modal)}
        onContinue={() => {
          setModal(false), handleCancelOrder(requestData?.active?.[0]?.id);
        }}
        titleText={
          showFeesCancelText &&
          requestData?.active?.[0]?.status !== REQUESTED_ORDER
            ? t("btnLabel.feesText", {
                fees: problemLimits?.orderCancelFees,
              })
            : t("btnLabel.confirmText")
        }
        succesBtnText={t("btnLabel.continue")}
        rejectBtnText={t("btnLabel.cancel")}
        icon={<CancelIcon />}
      />
    </>
  );
};

export default ServiceProviderReply;

import { useState, useEffect } from "react";

//* Multilangual
import { useTranslation } from "react-i18next";

import { Sliders } from "react-feather";

import { useDispatch } from "react-redux";
import { getAvailableRfpApi } from "../../redux/actions/rfp/rfpAction";
import DateLabel from "../common/DateLabel";
import {useNavigate } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import RfpListDateFilterModal from "../modal/service/RfpListDateFilterModal";
import NoDataFound from "../common/NoDataFound";
import CommonShimmer from "../shimmer/commonShimmer";
import { ICONS_MAP } from "../../constants/appConstants";

const AvailableRfp = ({ unReadOrdersIdArr, handleNotificationRead }) => {
  //* Multilangual
  const { t, i18n } = useTranslation();

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();
  //* Hook for navigation
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [availableListData, setAvailableListData] = useState([]);
  const [rfpFilterModal, setRfpFilterModal] = useState(false);
  const [filteredData, setFilteredData] = useState({
    dateStart: "",
    dateEnd: "",
    dateSort: "",
  });
  useEffect(() => {
    getAvailableRfpList();
  }, []);

  /**
   * Function for get available rfp data from API
   */
  const getAvailableRfpList = async (data = { ...filteredData }) => {
    setLoading(true);
    let res = await dispatch(getAvailableRfpApi(data));
    if (!res.data.error) {
      setAvailableListData([...res.data.data]);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="order-service-data">
        <ul className="order-service-ul scroll-none overflow-unset">
          <Row className="filter-tab">
            <Col md={12}>
              <ul>
                <li className="">
                  <Button
                    className="cu-btn rounded-1"
                    color="icon"
                    onClick={() => setRfpFilterModal(true)}
                  >
                    <Sliders />
                  </Button>
                </li>
              </ul>
            </Col>
          </Row>
          {!isLoading ? (
            <>
              {availableListData && availableListData.length ? (
                availableListData.map((item, i) => {
                  return (
                    <li
                      className="order-service-li pointer"
                      onClick={() => {
                        navigate(
                          `/rfps-reply?rfpId=${item.id}&available-rfp=true`
                        )
                        handleNotificationRead(item?.id)
                      }
                      }
                      id={item.id}
                      key={i}
                    >
                      <div className={`${unReadOrdersIdArr?.includes(item?.id) ? 'show-border' : ''} order-service-card`}>
                        <div className="one">
                          <div className="grid-data">
                            <div>
                              <span className="label-text">
                                {t("myOrder.tastText")}
                              </span>
                              <ul className="list-inner">
                                <li>
                                  <span className="main">
                                    {i + 1}. {item.title}
                                  </span>
                                </li>
                              </ul>
                            </div>

                            <div>
                              <span className="label-text">
                                {t("myOrder.dateTimeText")}
                              </span>
                              <ul className="list-inner">
                                <li>
                                  <span className="main">
                                    {t("myOrder.startText")}:{" "}
                                    <DateLabel
                                      date={item?.startDate}
                                      format={"DD-MM-YYYY HH:mm"}
                                    />
                                  </span>
                                </li>
                                <li>
                                  <span className="main">
                                    {t("myOrder.endText")}:{" "}
                                    <DateLabel
                                      date={item?.endDate}
                                      format={"DD-MM-YYYY HH:mm"}
                                    />
                                  </span>
                                </li>
                                <li>
                                  <span className="main">
                                    {t("myOrder.creationText")}:{" "}
                                    <DateLabel
                                      date={item?.createdAt}
                                      format={"DD-MM-YYYY HH:mm"}
                                    />
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="two">
                          <div className="status-box">
                            {ICONS_MAP.get(item?.status)?.icon}
                            <span>{ICONS_MAP.get(item?.status)?.text}</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <NoDataFound
                  textData={t("noDataFound.availableRFP")}
                  shadow={true}
                />
              )}
            </>
          ) : (
            <>
              <CommonShimmer />
            </>
          )}
        </ul>
      </div>
      <RfpListDateFilterModal
        modal={rfpFilterModal}
        toggleModal={() => setRfpFilterModal(!rfpFilterModal)}
        filteredData={filteredData}
        setFilteredData={(val) => setFilteredData(val)}
        getDataList={(data) => getAvailableRfpList(data)}
        isFromAvailableRfpList={true}
      />
    </>
  );
};

export default AvailableRfp;

/*
 * File: MyOrder.js
 * Author: Harsh Chauhan
 * Created Date: November 4th, 2022
 * Last Modified Date: March 22nd, 2024
 * Description: It is a page that displays a user's orders, both active and recent.
 */
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";

//* Multilangual
import { useTranslation } from "react-i18next";

//* page scss
import "./MyOrder.scss";
import {
  Button,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { GoStar } from "react-icons/go";
import Rating from "react-rating";
import { HistoryIcon, RecentIcon } from "../../assets/images/svg";
import DummyCircle from "../../assets/images/icon/user.png";
import { getDirectAwardOrder } from '../../redux/actions/service/serviceAction'
import { useDispatch, useSelector } from "react-redux";
import NoDataFound from "../../component/common/NoDataFound";
import DateLabel from "../../component/common/DateLabel";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CommonShimmer from "../../component/shimmer/commonShimmer";
import { ICONS_MAP } from "../../constants/appConstants";
import ExpressOrderTab from "../../component/myOrder/ExpressOrderTab";
import Footer from "../../component/common/Footer";
import { Sliders } from "react-feather";
import RfpListDateFilterModal from "../../component/modal/service/RfpListDateFilterModal";
import { postNotificationAPI } from "../../redux/actions/home/homeAction";

const MyOrder = () => {
  //* Selecting unread orders from the Redux state
  const unReadOrders = useSelector((state) => state.notification.unReadOrders)

  //* Extracting service IDs from the unread orders
  const unReadOrdersIdArr = unReadOrders?.map((e) => e?.serviceId)

  //* Multilangual
  const { t } = useTranslation();
  
  //* Get search parameters from URL
  const [searchParams] = useSearchParams();

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();

  //* Hook for navigation
  const navigate = useNavigate();

    //* Extracting service ID and type from URL params
  const serviceId = searchParams.get("serviceId");
  const expressId = searchParams.get("expressId");

  const [isLoading, setLoading] = useState(false);
  const [showServiceId, setServiceId] = useState("");
  const [isModelOpen, setIsModelOpen] = useState(false);

  const [activeData, setActiveData] = useState([]);
  const [recentData, setRecentData] = useState([]);
  const [activeListType, setActiveListType] = useState(false);
  const [filteredData, setFilteredData] = useState({
    dateStart: "",
    dateEnd: "",
    dateSort: "",
    status: "",
  });
  const [filteredHistoryData, setFilteredHistoryData] = useState({
    dateStart: "",
    dateEnd: "",
    dateSort: "",
    status: "",
  });

  //* State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState("1");

  //* Toggle active state for Tab
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

   //* Function for get provider request from API
  const getRecentDirectOrderData = async (
    data = { ...filteredHistoryData }
  ) => {
    setLoading(true);
    const resRecent = await dispatch(
      getDirectAwardOrder({ forActive: false, ...data })
    );
    if (!resRecent.data.error) {
      setRecentData([...resRecent.data.data]);
    }
    setLoading(false);
  };

   //* Function for get provider request from API
  const getActiveDirectOrderData = async (data = { ...filteredData }) => {
    setLoading(true);
    const resActive = await dispatch(
      getDirectAwardOrder({ forActive: true, ...data })
    );
    if (!resActive.data.error) {
      setActiveData([...resActive.data.data]);
    }
    setLoading(false);
  };

   //* call the provider request function
  useEffect(() => {
    getActiveDirectOrderData();
    getRecentDirectOrderData();
  }, []);

   //* get banner details when customer change language from header
  useEffect(() => {
    if (serviceId) {
      setCurrentActiveTab("2");
      setServiceId(serviceId);
      if (document) {
        let element = document.getElementById(`${serviceId}`);
        element?.scrollIntoView();
      }
    }
  }, [serviceId]);

   //* get banner details when customer change language from header
  useEffect(() => {
    if (expressId) {
      setCurrentActiveTab("1");
      setServiceId(expressId);
      if (document) {
        let element = document.getElementById(`${expressId}`);
        element?.scrollIntoView();
      }
    }
  }, [expressId]);
  //* read notification of order once user clicks on card having border
  const handleNotificationRead = async (orderId) => {
    const NotificationId = unReadOrders?.filter(e => e?.serviceId === orderId)?.[0]?.notificationId;
    if (NotificationId) {
      await dispatch(postNotificationAPI({ id: NotificationId }))
    }
  }
  return (
    <>
      <Helmet>
        <title>EJAD - My Order</title>
      </Helmet>
      <div className="myorder-page common-design-section">
        <div className="common-design-section-data single-data">
          <div className="right-design-data">
            <div className="spacing-min-height">
              <section className="myorder-section">
                <Container fluid="lg">
                  <div className="order-tab-header">
                    <Nav tabs className="mb30">
                      <NavItem>
                        <NavLink
                          className={`${
                            currentActiveTab === "1" ? "active" : ""
                          }`}
                          onClick={() => {
                            toggle("1");
                          }}
                        >
                          {t("myOrder.tab1Text")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={`${
                            currentActiveTab === "2" ? "active" : ""
                          }`}
                          onClick={() => {
                            toggle("2");
                          }}
                        >
                          {t("myOrder.tab2Text")}
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <div className="me-2 me-md-3 mb-2">
                      <Link to={currentActiveTab !== '1' ? "/direct-award" : "/express"} >
                        <Button
                          color="main"
                          className="cu-btn br8"
                        >
                          {t("myOrder.newOrder")}
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <div className="order-tab-body">
                    <TabContent activeTab={currentActiveTab}>
                      <TabPane tabId="1">
                        <ExpressOrderTab
                          showServiceId={showServiceId}
                          unReadOrdersIdArr={unReadOrdersIdArr}
                          handleNotificationRead={handleNotificationRead}
                        />
                      </TabPane>
                      <TabPane tabId="2">
                        <div className="order-service-data">
                          <div className="title-data justify-content-between">
                            <div>
                              <HistoryIcon />
                              {t("myOrder.activeStatusText")}
                            </div>
                            <div className="filter-tab mb-0">
                              <ul>
                                <li className="">
                                  <Button
                                    className="cu-btn rounded-1"
                                    color="icon"
                                    onClick={() => {
                                      setIsModelOpen(true);
                                      setActiveListType(true);
                                    }}
                                  >
                                    <Sliders />
                                  </Button>
                                </li>
                              </ul>
                            </div>
                          </div>
                          {!isLoading ? (
                            <>
                              {activeData && activeData.length ? (
                                <>
                                  <ul className="order-service-ul">
                                    {activeData.map((item, i) => {
                                      return (
                                        <li
                                          className="order-service-li pointer"
                                          id={item.id}
                                          key={i}
                                          onClick={() => {
                                            navigate(
                                              `/direct-award-reply?serviceId=${item.id}`
                                            )
                                            handleNotificationRead(item.id)
                                          }
                                          }
                                        >
                                          <div className={`${unReadOrdersIdArr?.includes(item?.id) ? 'show-border' : ''} order-service-card`}>
                                            <div className="one">
                                              <div className="grid-data">
                                                {/*  */}
                                                <div>
                                                  <span className="label-text">
                                                    {t("myOrder.tastText")}
                                                  </span>
                                                  <ul className="list-inner">
                                                    <li>
                                                      <span className="main">
                                                        {item?.description ||
                                                          "-"}
                                                      </span>
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div>
                                                  <span className="label-text">
                                                    {t(
                                                      "OrderCard.providerTitle"
                                                    )}
                                                  </span>
                                                  <ul className="list-inner">
                                                    <li>
                                                      <div className="profile-rate-data">
                                                        <div
                                                          className="first"
                                                          style={{
                                                            background: `url(${
                                                              item
                                                                ?.serviceProviderId
                                                                ?.profilePhoto ||
                                                              DummyCircle
                                                            })`,
                                                          }}
                                                        ></div>
                                                        <div className="second">
                                                          <span className="main">
                                                            {item
                                                              ?.serviceProviderId
                                                              ?.name || "-"}
                                                          </span>
                                                          {item?.total !==
                                                            0 && (
                                                            <div className="star-data">
                                                              <Rating
                                                                className="user-select-none"
                                                                initialRating={
                                                                  item?.rating ||
                                                                  "0"
                                                                }
                                                                emptySymbol={
                                                                  <GoStar
                                                                    size={12}
                                                                    fill="#C8C8C8"
                                                                    stroke="#C8C8C8"
                                                                  />
                                                                }
                                                                fullSymbol={
                                                                  <GoStar
                                                                    size={12}
                                                                    fill={
                                                                      "#FFD700"
                                                                    }
                                                                    stroke={
                                                                      "#FFD700"
                                                                    }
                                                                  />
                                                                }
                                                              />
                                                              <div className="comment-data">
                                                                (
                                                                {item?.total ||
                                                                  "0"}
                                                                )
                                                              </div>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div>
                                                  <span className="label-text">
                                                    {t("myOrder.dateTimeText")}
                                                  </span>
                                                  <ul className="list-inner">
                                                    <li>
                                                      <span className="main">
                                                        {t("myOrder.startText")}
                                                        :{" "}
                                                        <DateLabel
                                                          date={item?.startDate}
                                                          format={
                                                            "DD-MM-YYYY HH:mm"
                                                          }
                                                        />
                                                      </span>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="two">
                                              <div className="status-box">
                                                {
                                                  ICONS_MAP.get(item?.status)
                                                    ?.icon
                                                }
                                                <span>
                                                  {
                                                    ICONS_MAP.get(item?.status)
                                                      ?.text
                                                  }
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </>
                              ) : (
                                <NoDataFound
                                  textData={t("noDataFound.noDataText")}
                                  shadow={true}
                                />
                              )}
                            </>
                          ) : (
                            <CommonShimmer />
                          )}
                        </div>
                        {/* recent */}
                        <div className="order-service-data">
                          <div className="title-data justify-content-between">
                            <div>
                              <RecentIcon />
                              {t("myOrder.recentStatusText")}
                            </div>
                            <div className="filter-tab mb-0">
                              <ul>
                                <li className="">
                                  <Button
                                    className="cu-btn rounded-1"
                                    color="icon"
                                    onClick={() => {
                                      setIsModelOpen(true);
                                      setActiveListType(false);
                                    }}
                                  >
                                    <Sliders />
                                  </Button>
                                </li>
                              </ul>
                            </div>
                          </div>
                          {!isLoading ? (
                            <>
                              {recentData && recentData.length ? (
                                <>
                                  <ul className="order-service-ul">
                                    {recentData.map((item, i) => {
                                      return (
                                        <li
                                          className={`order-service-li pointer ${showServiceId === item.id
                                            ? "active"
                                            : ""
                                            }`}
                                          id={item.id}
                                          key={i}
                                          onClick={() => {
                                            navigate(
                                              `/direct-award-reply?serviceId=${item.id}`
                                            )
                                            handleNotificationRead(item.id)
                                          }
                                          }
                                        >
                                          <div className={`${unReadOrdersIdArr?.includes(item?.id) ? 'show-border' : ''} order-service-card`}>
                                            <div className="one">
                                              <div className="grid-data">
                                                {/*  */}
                                                <div>
                                                  <span className="label-text">
                                                    {t("myOrder.tastText")}
                                                  </span>
                                                  <ul className="list-inner">
                                                    <li>
                                                      <span className="main">
                                                        {item?.description ||
                                                          "-"}
                                                      </span>
                                                    </li>
                                                  </ul>
                                                </div>
                                                {/*  */}
                                                <div>
                                                  <span className="label-text">
                                                    {t(
                                                      "OrderCard.providerTitle"
                                                    )}
                                                  </span>
                                                  <ul className="list-inner">
                                                    <li>
                                                      <div className="profile-rate-data">
                                                        <div
                                                          className="first"
                                                          style={{
                                                            background: `url(${
                                                              item
                                                                ?.serviceProviderId
                                                                ?.profilePhoto ||
                                                              DummyCircle
                                                            })`,
                                                          }}
                                                        ></div>
                                                        <div className="second">
                                                          <span className="main">
                                                            {item
                                                              ?.serviceProviderId
                                                              ?.name || "-"}
                                                          </span>
                                                          {item?.total !==
                                                            0 && (
                                                            <div className="star-data">
                                                              <Rating
                                                                className="user-select-none"
                                                                initialRating={
                                                                  item?.rating ||
                                                                  "0"
                                                                }
                                                                emptySymbol={
                                                                  <GoStar
                                                                    size={12}
                                                                    fill="#C8C8C8"
                                                                    stroke="#C8C8C8"
                                                                  />
                                                                }
                                                                fullSymbol={
                                                                  <GoStar
                                                                    size={12}
                                                                    fill={
                                                                      "#FFD700"
                                                                    }
                                                                    stroke={
                                                                      "#FFD700"
                                                                    }
                                                                  />
                                                                }
                                                              />
                                                              <div className="comment-data">
                                                                (
                                                                {item?.total ||
                                                                  "0"}
                                                                )
                                                              </div>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                                {/*  */}
                                                <div>
                                                  <span className="label-text">
                                                    {t("myOrder.dateTimeText")}
                                                  </span>
                                                  <ul className="list-inner">
                                                    <li>
                                                      <span className="main">
                                                        {t("myOrder.startText")}
                                                        :{" "}
                                                        <DateLabel
                                                          date={item?.startDate}
                                                          format={
                                                            "DD-MM-YYYY HH:mm"
                                                          }
                                                        />
                                                      </span>
                                                    </li>
                                                    <li>
                                                      <span className="main">
                                                        {t("myOrder.endText")}:{" "}
                                                        <DateLabel
                                                          date={item?.endDate}
                                                          format={
                                                            "DD-MM-YYYY HH:mm"
                                                          }
                                                        />
                                                      </span>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="two">
                                              <div className="status-box">
                                                {
                                                  ICONS_MAP.get(item?.status)
                                                    ?.icon
                                                }
                                                <span>
                                                  {
                                                    ICONS_MAP.get(item?.status)
                                                      ?.text
                                                  }
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </>
                              ) : (
                                <NoDataFound
                                  textData={t("noDataFound.noDataText")}
                                  shadow={true}
                                />
                              )}
                            </>
                          ) : (
                            <CommonShimmer />
                          )}
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </Container>
              </section>
            </div>
            {/* Common Footer */}
            <Footer />
          </div>
        </div>
      </div>
      <RfpListDateFilterModal
        key={"direct"}
        modal={isModelOpen}
        toggleModal={() => setIsModelOpen(!isModelOpen)}
        filteredData={activeListType ? filteredData : filteredHistoryData}
        setFilteredData={(val) =>
          activeListType ? setFilteredData(val) : setFilteredHistoryData(val)
        }
        getDataList={(data) => {
          activeListType
            ? getActiveDirectOrderData(data)
            : getRecentDirectOrderData(data);
        }}
        activeListType={activeListType}
        serviceType={activeListType ? "directActive" : "directRecent"}
      />
    </>
  );
};

export default MyOrder;

import React from "react";
import { Label, Input } from "reactstrap";
import {
  INVALID_DECIMAL_INPUT,
  INVALID_NUMBER_INPUT,
} from "../../constants/appConstants";
const FormInput = ({
  fgClass,
  id,
  name,
  type,
  placeholder,
  required,
  label,
  rules,
  errors,
  register,
  decimal,
  disabled,
  errorMsg,
  value,
  maxLength,
  pattern,
  readOnlyType,
  onFocusEvent,
}) => {
  const { ref: ref, ...rest } = register(name, rules);
  function onKeyPress(ev) {
    if (
      type === "number" &&
      ev.key.match(decimal ? INVALID_DECIMAL_INPUT : INVALID_NUMBER_INPUT)
    )
      ev.preventDefault();
  }
  return (
    <>
      <div
        className={`cu-form-group floating-label-group with-low-index ${fgClass}`}
      >
        <Input
          onKeyPress={onKeyPress}
          id={id}
          name={name}
          type={type}
          autoComplete="off"
          placeholder={placeholder}
          {...rest}
          innerRef={ref}
          disabled={disabled}
          value={value}
          maxLength={maxLength}
          pattern={pattern}
          readOnly={readOnlyType}
          onFocus={onFocusEvent}
        />
        {label && (
          <Label htmlFor={id} className="floating-label">
            {label}
          </Label>
        )}
        {required && <span className="required abs">*</span>}
        <span className="error-msg">{errorMsg || errors[name]?.message}</span>
      </div>
    </>
  );
};

export default FormInput;

/*
 * File: Review.js
 * Author: Harsh Chauhan
 * Created Date: February 9th, 2023
 * Last Modified Date: March 29th, 2024
 * Description: This component is responsible for displaying a review page of a service.
 */
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Rating from "react-rating";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import CommonShimmer from "../../component/shimmer/commonShimmer";
import { Button, Container, Form } from "reactstrap";
import FormInput from "../../component/Form/FormInput";
import OrderSummary from "../../component/modal/service/OrderSummary";
import { getPerticularReqApi } from "../../redux/actions/home/homeAction";
import { GoStar } from "react-icons/go";
import "./Review.scss";
import { getReplyDetailsRfpApi } from "../../redux/actions/rfp/rfpAction";
import { getExpressPerticularReqApi } from "../../redux/actions/express/expressAction";
import { addRatingReview } from "../../redux/actions/review/reviewAction";
import Footer from "../../component/common/Footer";

const ServiceReview = () => {

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();
  //* Multilangual
  const { t } = useTranslation();

  //* Get current search parameters from URL using React Router's useSearchParams hook
  const [searchParams] = useSearchParams();

  //* Initializing react-hook-form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "all" });

  //* State variables
  const [rating, setRating] = useState(0);
  const [finalVal, setFinalVal] = useState();
  const [talzeemData, setTalzeemData] = useState({});
  const [rfpData, setRfpData] = useState({});
  const [expressData, setExpressData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [showServiceId, setServiceId] = useState("");
  const [isServiceType, setServiceType] = useState("");
  
  //* Hook for navigation
  const navigate = useNavigate();
  
  //* Extracting service provider name based on id and type from URL params
  const spName = expressData?.serviceProviderId?.name || rfpData?.serviceProviderId?.name || talzeemData?.active?.[0]?.serviceProviderId?.name || talzeemData?.recent?.[0]?.serviceProviderId?.name
  
  //* Extracting service ID and type from URL params
  const serviceId = searchParams.get("serviceId");
  const serviceType = searchParams.get("type");
  useEffect(() => {
    if (serviceId) {
      setServiceId(serviceId);
      setServiceType(serviceType);
    }
  }, [serviceId]);

  /*
   * Get Talzeem Data
   * @param {*} id
   */
  const getTalzeemData = async (id) => {
    setLoading(true);
    const data = { id: id };
    let res = await dispatch(getPerticularReqApi(data));
    if (!res.data.error) {
      setTalzeemData({ ...res.data.data });
    }
    setLoading(false);
  };

  /*
   * Get RFP Data
   * @param {*} id
   */
  const getRfpData = async (id) => {
    setLoading(true);
    const data = { rfpId: id };
    let res = await dispatch(getReplyDetailsRfpApi(data));
    if (!res.data.error) {
      setRfpData({ ...res.data.data });
    }
    setLoading(false);
  };

  /*
   * Get Express Data
   * @param {*} id
   */
  const getExpressData = async (id) => {
    setLoading(true);
    if (id) {
      let res = await dispatch(getExpressPerticularReqApi(id));
      if (!res.data.error) {
        setExpressData({ ...res.data.data });
      }
    }
    setLoading(false);
  };

  //* Fetching service data based on service type
  useEffect(() => {
    if (isServiceType === "T") {
      getTalzeemData(showServiceId);
    } else if (isServiceType === "R") {
      getRfpData(showServiceId);
    } else {
      getExpressData(showServiceId);
    }
  }, [isServiceType, serviceId]);

  //* Reset form values on service data change
  useEffect(() => {
    setLoading(true);
    if (isServiceType === "T") {
      if (talzeemData?.recent?.length) {
        reset({
          brief: talzeemData?.recent[0]?.ratingForServiceProvider?.description,
        });
        setRating(talzeemData?.recent[0]?.ratingForServiceProvider?.rating);
      }
    } else if (isServiceType === "R") {
      reset({
        brief: rfpData?.ratingForServiceProvider?.description,
      });
      setRating(rfpData?.ratingForServiceProvider?.rating);
    } else if (isServiceType === "E") {
      reset({
        brief: expressData?.ratingForServiceProvider?.description,
      });
      setRating(expressData?.ratingForServiceProvider?.rating);
    } else {
      reset({
        brief: "",
      });
      setRating(0);
    }
    setLoading(false);
  }, [talzeemData, rfpData, expressData, isServiceType]);

  /*
   *
   * @param {*} newRating
   */
  const handleRatingChange = (newRating) => {
    setRating(newRating);
    setFinalVal(newRating);
  };

   //* Button click handler  
  const onButtonClick = async () => {
    if (isDisable) {
      navigate('/home');
    };
  }

  /*
   *
   * @param {*} data
   */
  const onFormSubmit = async (data) => {
    if (rating > 0) {
      let serviceType =
        isServiceType === "T" ? "T" : isServiceType === "R" ? "R" : "E";
      const finalData = {
        serviceType: serviceType,
        serviceId: showServiceId,
        rating: rating,
        description: data?.brief,
      };

      let res = await dispatch(addRatingReview(finalData));
      if (!res.data.error) {
        navigate("/home");
      }
    } else {
      setFinalVal(0);
    }
  };

  //* Disable condition for the button
  const isDisable =
    (isServiceType === "T" &&
      talzeemData?.recent?.[0]?.ratingForServiceProvider) ||
    (isServiceType === "R" && rfpData?.ratingForServiceProvider) ||
    (isServiceType === "E" && expressData?.ratingForServiceProvider);
  return (
    <div className="myorder-page common-design-section">
      <div className="common-design-section-data single-data">
        <div className="right-design-data">
          <section className="service-section reply-section">
            <Container fluid="lg">
              <div className="service-section-data">
                {!isLoading ? (
                  <div className="service-details-left-data">
                    <h2 className="heads-data">{t("reviewPage.heading")}</h2>
                    <div className="review-left-data">
                      <Form
                        className="mb-0"
                        onSubmit={handleSubmit(onFormSubmit)}
                        noValidate
                      >
                        <div className="inner-inpput mb20">
                          <h4 className="heading">
                            {t("reviewPage.ratingHeading", { name: spName })}
                          </h4>
                          <Rating
                            className={`stars-data `}
                            name="rating"
                            onChange={handleRatingChange}
                            initialRating={rating}
                            readonly={isDisable}
                            emptySymbol={
                              <GoStar
                                size={35}
                                fill="#C8C8C8"
                                stroke="#C8C8C8"
                              />
                            }
                            fullSymbol={
                              <GoStar
                                size={35}
                                fill={"#FFD700"}
                                stroke={"#FFD700"}
                              />
                            }
                          />
                          {finalVal === 0 && (
                            <span className="error-msg">
                              {t("errorMessages.ratingValidMsg")}
                            </span>
                          )}
                        </div>
                        <div className="inner-inpput">
                          <h4 className="heading">
                            {t("reviewPage.descriptionHeading")}
                          </h4>
                          <FormInput
                            fgClass="mb40"
                            id={`brief`}
                            name={`brief`}
                            type="textarea"
                            placeholder={t("profile.aboutLabel")}
                            required={false}
                            register={register}
                            errors={errors}
                            disabled={isDisable}
                            className
                          />
                        </div>
                        <Button
                          color="main"
                          className="cu-btn br20 min120"
                          type={isDisable ? "button" : "submit"}
                          block
                          onClick={() => onButtonClick()}
                        >
                          {isDisable ? t("btnLabel.backToHome") : t("noData.postBtnText")}
                        </Button>
                      </Form>
                    </div>
                  </div>
                ) : (
                  <div className="service-details-left-data text-center">
                    <CommonShimmer />
                  </div>
                )}
                {isServiceType === "T" && (
                  <>
                    <OrderSummary
                      isLoading={isLoading}
                      data={talzeemData?.recent?.[0]?.categoryId?.name}
                      dataSubService={
                        talzeemData?.recent?.[0]?.subCategoryId?.name
                      }
                      branchName={talzeemData?.recent?.[0]?.branchName}
                      providerData={talzeemData?.recent?.[0]?.serviceProviderId}
                      mainData={talzeemData?.recent?.[0]}
                    />
                  </>
                )}

                {isServiceType === "R" && (
                  <>
                    <OrderSummary
                      isLoading={isLoading}
                      fromRfpSummary={true}
                      data={rfpData}
                      branchName={rfpData.branchName}
                      reply={rfpData?.reply}
                      mainData={rfpData?.reply}
                      startDateVal={rfpData?.startDate}
                      endDateVal={rfpData?.endDate}
                    />
                  </>
                )}
                {isServiceType === "E" && (
                  <>
                    <OrderSummary
                      isLoading={isLoading}
                      data={expressData?.categoryId?.name}
                      dataSubService={expressData?.subCategoryId?.name}
                      branchName={expressData?.branchName}
                      providerData={expressData?.serviceProviderId}
                      mainData={expressData}
                    />
                  </>
                )}
              </div>
            </Container>
          </section>

          {/* Common Footer */}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ServiceReview;

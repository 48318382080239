/*
 * File: PrivacyPolicy.js
 * Author: Harsh Chauhan
 * Created Date: November 4th, 2022
 * Last Modified Date: February 8th, 2024
 * Description: It renders a page for managing Request for Proposals (RFPs).
 */
import { useState } from "react";
import { Helmet } from "react-helmet-async";

//* Multilangual
import { useTranslation } from "react-i18next";

//* page scss
import "../MyOrder/MyOrder.scss";
import "../Service/Service.scss";

import {
  Button,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import AvailableRfp from "../../component/rfp/AvailableRfp";
import RecentHistoryRfp from "../../component/rfp/RecentHistoryRfp";
import Footer from "../../component/common/Footer";
import { Link } from "react-router-dom";
import { postNotificationAPI } from "../../redux/actions/home/homeAction";
import { useDispatch, useSelector } from "react-redux";

const Rfp = () => {

  //* Selecting unread orders from the Redux state
  const unReadOrders = useSelector((state) => state.notification.unReadOrders)

  //* Extracting service IDs from the unread orders
  const unReadOrdersIdArr = unReadOrders?.map((e) => e?.serviceId)

  //* Multilangual
  const { t } = useTranslation();

  //* Initializing the dispatch function from Redux
  const dispatch = useDispatch()

  //* State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState("1");

  //* Toggle active state for Tab
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  //* read notification of order once user clicks on card having border
  const handleNotificationRead = async (orderId) => {
    const NotificationId = unReadOrders?.filter(e => e?.serviceId === orderId)?.[0]?.notificationId;
    if (NotificationId) {
      await dispatch(postNotificationAPI({ id: NotificationId }))
    }
  }

  return (
    <>
      <Helmet>
        <title>EJAD - Rfp</title>
      </Helmet>
      <div className="myorder-page common-design-section">
        <div className="common-design-section-data single-data">
          <div className="right-design-data">
            <div className="spacing-min-height">
              <section className="myorder-section">
                <Container fluid="lg">
                  <div className="order-tab-header">
                    <Nav tabs className="mb30">
                      <NavItem>
                        <NavLink
                          className={`${currentActiveTab === "1" ? "active" : ""
                            }`}
                          onClick={() => {
                            toggle("1");
                          }}
                        >
                          {t("rfp.tab.availableText")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={`${currentActiveTab === "2" ? "active" : ""
                            }`}
                          onClick={() => {
                            toggle("2");
                          }}
                        >
                          {t("rfp.tab.myRFPText")}
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <div className="me-2 me-md-3">
                      <Link to="/rfps/create" className="job-box">
                        <Button
                          color="main"
                          className="cu-btn br8"
                        >
                          {t("rfp.newOrder")}
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <div className="order-tab-body">
                    <TabContent activeTab={currentActiveTab}>
                      <TabPane tabId="1">
                        <AvailableRfp unReadOrdersIdArr={unReadOrdersIdArr} handleNotificationRead={handleNotificationRead} />
                      </TabPane>

                      <TabPane tabId="2">
                        <RecentHistoryRfp
                          setCurrentActiveTab={setCurrentActiveTab}
                          unReadOrdersIdArr={unReadOrdersIdArr}
                          handleNotificationRead={handleNotificationRead}
                        />
                      </TabPane>
                    </TabContent>
                  </div>
                </Container>
              </section>
            </div>
            {/* Common Footer */}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Rfp;

import { Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const FormCheckboxInput = ({
  type,
  id,
  name,
  errors,
  register,
  rules,
  label,
  hidden,
  labelClass,
}) => {
  const { ref: ref, ...rest } = register(name, rules);
  const { t } = useTranslation();

  return (
    <div className="form-group mb30">
      <div className="form-check cu-check d-inline-flex w-100  px-0">
        <Input
          type={type}
          id={id}
          name={name}
          errors={errors}
          innerRef={ref}
          {...rest}
          hidden={hidden}
        />

        <Label
          className={`form-check-label cu-form-label ${
            labelClass ? labelClass : ""
          }`}
          htmlFor={id}
        >
          {label}
        </Label>
      </div>
      <span className="error-msg">{errors[name]?.message}</span>
    </div>
  );
};

export default FormCheckboxInput;

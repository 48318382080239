/*
 * File: estimationAction.js
 * Author: Ankit Pithiya
 * Created Date: February 14th, 2024
 * Last Modified Date: February 14th, 2024
 * Description: This file contains the estimation actions.
 */

import { api } from "../../../api/api";
import { ESTIMATION_STATUS_UPDATE } from "../../../constants/ApiUrl";

/**
 * Sends a POST request to update the estimation status.
 *
 * @param {Object} data - The data to be sent in the request.
 * @return {Promise} A promise that resolves with the response from the API.
 */
export const postEstimationStatusApi = (data) => {
    return async () => {
        const response = await api(ESTIMATION_STATUS_UPDATE, data);
        return response;
    };
};
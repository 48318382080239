import React from 'react'
import { ShimmerCircularImage, ShimmerThumbnail } from 'react-shimmer-effects'
import { Col, Row } from 'reactstrap'

const ProfileShimmer = () => {
  return (
    <div className="text-center">
      <ShimmerCircularImage size={180} />
      <ShimmerThumbnail className="h40 br20 mb20" rounded />
      <ShimmerThumbnail className="h40 br20 mb20" rounded />
      <ShimmerThumbnail className="h40 br20 mb20" rounded />
      <ShimmerThumbnail className="h40 br20 mb20" rounded />
      <ShimmerThumbnail className="h150 br20 mb20" rounded />
      <ShimmerThumbnail className="h40 br20 mb20" rounded />
      <Row>
        <Col md={12}>
          <ShimmerThumbnail
            className="h150 br20 mb20"
            rounded
          />
        </Col>
        <Col md={4}>
          <ShimmerThumbnail className="h40 br20 mb20" rounded />
        </Col>
        <Col md={4}>
          <ShimmerThumbnail className="h40 br20 mb20" rounded />
        </Col>
        <Col md={4}>
          <ShimmerThumbnail className="h40 br20 mb20" rounded />
        </Col>
      </Row>
      <ShimmerThumbnail className="h40 br20 mb20" rounded />
    </div>
  )
}

export default ProfileShimmer

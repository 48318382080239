/*
 * File: Notification.js
 * Author: Ankit Pithiya
 * Created Date: November 5th, 2022
 * Last Modified Date: April 29th, 2024
 * Description: This component handles the display and interaction of a notification dropdown menu in a React application.
 */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ShimmerThumbnail } from "react-shimmer-effects";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//* Images scss
import NotifiactionIcon from "../../assets/images/icon/notification-icon.svg";
import {
  EXPRESS_DONE,
  LANGUAGE_AR,
  LANGUAGE_EN,
  NOTIFICATION_TYPE,
  RFP_DONE,
  TALZEEM_DONE,
} from "../../constants/appConstants";
import {
  getNotificationAPI,
  markAllAsReadAPI,
  postNotificationAPI,
} from "../../redux/actions/home/homeAction";
import DateLabel from "./DateLabel";
import NoDataFound from "./NoDataFound";
import NotificationPreviewModal from "../modal/NotificationPreviewModal";
import {
  setMyordersFlag,
  setRfpFlag,
  setUnReadOrders,
} from "../../redux/actions/notification/notificationAction";

const Notification = () => {
  //* Multilangual
  const { t, i18n } = useTranslation();

   //* State variables for notification data, modal, loading state, and notification count
  const [notificationData, setNotificationData] = useState([]);
  const [modelData, setModelData] = useState("");
  const [modal, setModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);

  //* Redux state for notifications
  const notifications = useSelector(
    (state) => state.notification.notifications
  );

  //* Initializing dispatch function from Redux
  const dispatch = useDispatch();

  //* Navigation hook
  const navigate = useNavigate();

  //* Function to fetch notification data
  const getNotificationData = async (fromInterval) => {
    if (!fromInterval) setLoading(true);

    let res = await dispatch(getNotificationAPI());
    if (!res.data.error) {
      setNotificationData(res?.data?.data);
      setNotificationCount(res?.data?.unreadNotifications);
      setNotificationFlags(res?.data?.data);
    }
    setLoading(false);
  };

  //* Effect to fetch notification data on component mount and when language or notifications change
  useEffect(() => {
    getNotificationData();
  }, [i18n.language, notifications]);

  useEffect(() => {
    //* Set up the interval to call the API every 10 seconds (10000 milliseconds)
    const intervalId = setInterval(() => getNotificationData(true), 10000);

    //* Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  //* Function to handle notification click
  const handleNotificationReader = async (notification) => {
    let res = await dispatch(postNotificationAPI({ id: notification.id }));
    if (notification.type === "SN") {
      setModal(true);
      setModelData(
        i18n.language === LANGUAGE_EN
          ? notification?.body
          : i18n.language === LANGUAGE_AR
          ? notification?.arBody
          : notification?.frBody
      );
      return true;
    }
    if (!res.data.error) {
      {
        NOTIFICATION_TYPE.map((typeData) => {
          if (typeData.type === notification.event) {
            if (
              typeData.type === TALZEEM_DONE ||
              typeData.type === EXPRESS_DONE ||
              typeData.type === RFP_DONE
            ) {
              navigate(
                `/${typeData.link}=${notification.serviceId}&type=${
                  typeData.type === TALZEEM_DONE
                    ? "T"
                    : typeData.type === RFP_DONE
                    ? "R"
                    : "E"
                }`
              );
              window.location.reload();
            } else {
              let url = `/${typeData.link}=${notification.serviceId}`;
              if (typeData.subParams) {
                const replyData = JSON.parse(notification.payload);
                url += `${typeData.subParams}=${replyData?.replyId}`;
              }
              navigate(url);
            }
          }
        });
      }
      getNotificationData();
    }
  };

  /**
   * The function `setNotificationFlags` checks the type and read status of an item and dispatches
   * actions based on the conditions.
   * @param item - The `item` parameter is an object that contains two properties: `type` and `isRead`.
   */
  const setNotificationFlags = (item) => {
    const isRfpUnread = item?.some((e) => e?.type === "R" && !e?.isRead);
    const isMyOrdersUnread = item?.some(
      (e) => (e?.type === "E" && !e?.isRead) || (e?.type === "T" && !e?.isRead)
    );
    //* set unread order data in redux to use in order list to show border
    const unReadOrders = item
      ?.filter((e) => !e.isRead && e?.serviceId)
      ?.map((e) => ({ serviceId: e?.serviceId, notificationId: e?.id }));
    dispatch(setUnReadOrders(unReadOrders));

    //* rfp order
    if (isRfpUnread) {
      dispatch(setRfpFlag(true));
    } else {
      dispatch(setRfpFlag(false));
    }
    //* direct and express award
    if (isMyOrdersUnread) {
      dispatch(setMyordersFlag(true));
    } else {
      dispatch(setMyordersFlag(false));
    }
  };

   //* Function to mark all notifications as read
  const handleMarkAllAsRead = async () => {
    await dispatch(markAllAsReadAPI());
  };

  return (
    <>
      <UncontrolledDropdown nav inNavbar className="notification-dropdown">
        <DropdownToggle nav>
          <span className="icon-text position-relative">
            <img src={NotifiactionIcon} className="img-fluid" alt="images" />
            {notificationCount !== 0 && (
              <span className="badge-notification">{notificationCount}</span>
            )}
          </span>
        </DropdownToggle>
        <DropdownMenu>
          <div className="inner-notofication">
            <div
              className={`w-100 ${
                i18n?.language === LANGUAGE_AR ? "text-end pe-1" : ""
              }`}
            >
              <a className="markAllAsRead" onClick={handleMarkAllAsRead}>
                {t("notification.markAllAsRead")}
              </a>
            </div>
            {!isLoading ? (
              <>
                {notificationData && (
                  <ul>
                    {notificationData.map((item, index) => {
                      return (
                        <>
                          <DropdownItem
                            tag={"li"}
                            key={index}
                            onClick={() => handleNotificationReader(item)}
                            className={`${item?.isRead ? "readOnly" : ""}`}
                          >
                            <div className="not-body">
                              <div className="not-image">
                                <div className="badge"></div>
                              </div>
                              <div className="not-data">
                                <span>
                                  <DateLabel
                                    date={item?.createdAt}
                                    format={"DD-MM-YYYY h:mm A"}
                                  />
                                </span>
                                <p>
                                  {i18n.language === LANGUAGE_EN
                                    ? item?.body
                                    : i18n.language === LANGUAGE_AR
                                    ? item?.arBody
                                    : item?.frBody}
                                </p>
                              </div>
                            </div>
                          </DropdownItem>
                          {item.type === "SN" ? (
                            <NotificationPreviewModal
                              modal={modal}
                              toggleModal={() => setModal(!modal)}
                              data={modelData}
                            />
                          ) : null}
                        </>
                      );
                    })}
                  </ul>
                )}
                {notificationData.length === 0 && (
                  <NoDataFound
                    textData={t("noDataFound.notification")}
                    shadow={false}
                  />
                )}
              </>
            ) : (
              <>
                <ShimmerThumbnail className="h40 mb-1 w-100" rounded />
                <ShimmerThumbnail className="h40 mb-1" rounded />
                <ShimmerThumbnail className="h40 mb-1" rounded />
                <ShimmerThumbnail className="h40 mb-1" rounded />
                <ShimmerThumbnail className="h40 mb-1" rounded />
                <ShimmerThumbnail className="h40 mb-1" rounded />
              </>
            )}
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default Notification;

/*
 * File: DateLabel.js
 * Author: Harsh Chauhan
 * Created Date: November 21st, 2022
 * Last Modified Date: February 29th, 2024
 * Description: this code snippet is a reusable component that formats and displays dates based on the provided date and format values.
 */
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { t } from "i18next";
dayjs.extend(timezone);
dayjs.extend(utc);

//* Get the user's current timezone
const userTimezone = dayjs.tz.guess();

export default function DateLabel({ date, format }) {
  let displayDate = !date
    ? "-"
    : dayjs(date * 1000).format(format || "DD-MM-YYYY");
  if (date && format === "DD-MM-YYYY HH:mm") {
    const splitDate = displayDate.split(" ");
    //* displayDate = `${splitDate[0]} at ${splitDate[1]}`;
    displayDate = t("myOrder.at", {
      dateVal: splitDate[0],
      time: splitDate[1],
    });
  }
  if (date && format === "DD-MM-YYYY h:mm A") {
    const currentTimeZoneDateValue = dayjs(date * 1000)
      .tz(userTimezone)
      .format(format);
    const splitDate = currentTimeZoneDateValue?.split(" ");
    displayDate = `${splitDate[0]} / ${splitDate[1]} ${splitDate[2]}`;
  }
  return <>{displayDate}</>;
}
